﻿.list {
    padding-left: 1.25em;

    &--border-dividers {
        padding-left: 0;
        list-style: none outside;
        .list__item {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $lightgrey;
            &:last-child { border-bottom: none; }
        }
    }
    // List with Checkmarks (Used on topnav - support tab)
    &-checkmarks {
        list-style: none outside;
        padding-left: 0;

        li {
            padding-left: 1.25em;
            position: relative;
            margin-bottom: 0.75em;

            &:before {
                content: '\f00c';
                font-family: FontAwesome;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    // List with Arrows (Used on /for-the-pros)
    &-arrows {
        list-style: none outside;
        padding-left: 0;

        &-wrapper h3 {
            font-size: 1.5rem;
        }

        li {
            position: relative;
            padding: 1rem 0;
            border-bottom: 1px solid #e6e6e6;
            padding-left: 1.3em;

            &:before {
                font-size: 1rem;
                content: '\f0a9';
                font-family: FontAwesome;
                display: inline-block;
                position: relative;
                color: $red;
                margin-right: 0.5em;
                margin-left: -1.3em;
            }

            &:last-child {
                border-bottom: none;
            }

            a {
                line-height: 1.4;
            }
        }
    }
    // List with Icons (Used on /smart-locks/basics, /innovation and /innovation/the-kwikset-home)
    &-icons {
        list-style: none outside;
        padding: 0;
        max-width: 520px;
        margin: auto;
        margin-top: 1.5rem;
        // (Used on /smart-locks/basics on capabilities)
        &--full {
            max-width: none;
        }
        &--small-kicker-text{
            & .list-icons__kicker{
                @media (max-width: 375px){
                    font-size: .75rem !important;
                }
            }
        }
        // (Used on /smart-locks/basics on capabilities)
        &--extratop {
            margin-top: 2.5rem;

            @media (min-width: 415px) {
                margin-top: 3.25rem;
            }
        }
        // (Used on /smart-locks/basics on capabilities)
        &--extrabottom {
            margin-bottom: 5.5rem;
        }
        &--no-col-padding{
          @media (max-width: 768px){
            padding-left: 0;
            padding-right: 0;
          }
        }

        @media (min-width: 992px) {
            max-width: 1520px;
        }

        li {
            text-align: center;
            &:not(:last-child){
                margin-bottom: 2.5rem;
            }
            .visible-sm, .visible-xs {
                margin-bottom: 0;
            }

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            &.list--extrapadding {
                padding: 0 45px;
            }

            img.list-icons__icons {
                max-width: 84px;
            }

            p.list-icons__kicker {
                font-size: 1rem;
                letter-spacing: 0.8px;
                color: #000000;
                margin: 1rem 0 0;
                padding: 0 0.5rem;
                font-weight: $book;

                &--bold {
                    font-weight: bold;
                }

                &--allcap {
                    text-transform: uppercase;
                }

                @media (min-width: 992px) {
                    padding: 0;
                }
            }

            p.list-icons__text {
                font-size: 1.125rem;
                line-height: 1.31;
                text-align: center;
                color: #231f20;
                margin-right: auto;
                margin-left: auto;
                font-weight: $light;

                &--sm {
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 1.63;
                    letter-spacing: -0.3px;
                }

                &--light {
                    font-weight: 300;
                }

                &--wide {
                    max-width: 290px;
                }

                &--narrow {
                    max-width: 150px;
                }
                &--md{
                  max-width: 90%;
                  margin: 18px auto;
                  @media screen and (min-width: 1024px){
                    max-width: 12rem;
                  }
                }
                &--more-md{
                  width: 95%;
                  max-width: 14rem;
                }
            }

            @media (min-width: 992px) {
                margin-top: 0;
            }
        }
        // Specifically for Smart Locks
        &--smartlocks {
            li img.list-icons__icons {
                max-width: 80px;
            }

            .btn {
                letter-spacing: 0.8px;
            }

            @media (max-width: 414px) {
                li {
                    margin-bottom: 2.25rem;

                    p.list-icons__kicker {
                        font-size: 0.875rem;
                        margin: 0.35rem 0 0;
                    }

                    p.list-icons__text {
                        font-size: 1rem;
                    }

                    .btn {
                        margin-top: 0.35rem;
                    }
                }

                &.list-icons--extrabottom {
                    margin-bottom: 1rem;
                }
            }
        }

        &--collapsable {

            &-toggle {
                display: none;
                text-decoration: none;
                font-size: 1rem;
                margin-top: 0.5rem;
                font-weight: $book;

                @media (max-width: 414px) {
                    display: inline-block;
                }
            }

            &-icon {
                color: #d90114;
                font-size: 1.375rem;
                margin-right: 0.25rem;
                vertical-align: middle;

                &-minus {
                    display: none;
                }
            }

            li {
                margin-bottom: 4.5rem;

                @media (max-width: 414px) {
                    &.list--extrapadding {
                        border-bottom: 1px solid #e9e9e9;
                        padding: 0 2.8125rem 1.5rem;
                        margin-bottom: 1.875rem;

                        &:last-child {
                            border-bottom: none;
                            margin-bottom: 0;
                        }
                    }

                    p.list-icons__kicker {
                        margin-top: 0.25rem;
                    }

                    p.list-icons__text {
                        display: none;
                    }
                }
            }
        }

        &--opened {
            .list-icons--collapsable-icon {
                &-plus {
                    display: none;
                }

                &-minus {
                    display: inline-block;
                }
            }
        }
        &__max-width{
            max-width: 235px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    // List with Boxed Logo Images (Used on /smart-locks/compatibility)
    &-boxedlogo {
        list-style-type: none;
        max-width: 1530px;
        padding: 0 0.5rem;
        margin: 1.75rem auto 3rem;

        &--narrow {
            max-width: 1256px;
        }

        @media (min-width: 768px) {
            margin: 2.75rem auto 3rem;
            padding: 0 2.25rem;
        }

        @media (min-width: 992px) {
            padding: 0 3rem;
        }

        li {
            text-align: center;
            margin-bottom: 2.5rem;
            padding: 0 0.5rem;

            @media (min-width: 415px) {
                margin-bottom: 3.25rem;
            }

            @media (min-width: 992px) {
                padding: 0 1rem;
                margin-bottom: 2.75rem;
            }
        }

        &__image {
            max-width: 100%;
            max-height: 60%;
            flex: 0 0 auto;

            &-wrapper {
                position: relative;
                width: 287px;
                height: 115px;
                max-width: 100%;
                margin: auto;
                border: solid 1px #e6e6e6;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;

                @media (min-width: 768px) {
                    &:before {
                        content: '';
                        display: block;
                        border: 1px solid transparent;
                        transition: all 0.25s;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }

                    &:hover:before {
                        border-color: #e6e6e6;
                        border-width: 6px;
                    }
                }

                @media (min-width: 992px) {
                    height: 200px;
                }
            }
        }

        &__title {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.67;
            letter-spacing: -0.4px;
            margin-top: 0.9rem;
            margin-bottom: 0.25rem;
        }

        &__link {
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.3px;
        }
    }
    &--accordion {

    }
}


.cm-icon-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        border-bottom: 1px solid $grey;
        padding: 20px 0 20px 80px;
        position: relative;

        &--phone {
            &:before {
                content: "";
                display: block;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                top: 20px;
                object-fit: contain;
                background: url("//images.kwikset.com/is/image/Kwikset/Phone?wid=200&hei=200") no-repeat center center;
                background-size: 50px;
            }
        }

        &--fob {
            &:before {
                content: "";
                display: block;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                top: 20px;
                object-fit: contain;
                background: url("//images.kwikset.com/is/image/Kwikset/Fob?wid=200&hei=200") no-repeat center center;
                background-size: 50px;
            }
        }

        &--card {
            &:before {
                content: "";
                display: block;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                top: 20px;
                object-fit: contain;
                background: url("//images.kwikset.com/is/image/Kwikset/Card?wid=200&hei=200") no-repeat center center;
                background-size: 50px;
            }
        }
    }

    &__kicker {
        @extend .kicker;
    }

    &__cm-icon-list__content {
        font-size: 14px;
        line-height: 20px;
    }
}

.spec-list {
	@include list-unstyled;
	margin: 0;
	padding: 0;
	font-size: 14px;

	&__item {
		border-bottom: 1px solid $black;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;

		&:last-child {
			border-bottom: 0;
		}
	}

	&__title {
		@include gothamBold;
		text-transform: uppercase;
		padding: 10px 10px 0 0;
		flex: 0 0 100%;
		max-width: 100%;

        @media(min-width: 992px) {
			flex: 0 0 250px;
			max-width: 250px;
			padding: 10px 10px 10px 0;
		}
	}

	&__value {
		@include gothamBook;
		padding: 0 10px 10px 0;
		flex: 0 0 100%;
		max-width: 100%;

        @media(min-width: 992px){
			padding: 10px;
			flex: 1 1 calc(100% - 250px);
			max-width: calc(100% - 250px);
		}
	}
}

