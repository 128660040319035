﻿// colors
$red: #D11B32;
$lightred: #e21e31;
$blue: #00aeef;
$lightblue: #39bef1;
$offwhite: #f7f7f7;
$lightgrey: #dedede;
$grey: #a7a7a7;
$darkgrey: #313131;
$medgrey: #474747;
$black: #000;
$white: #fff;

// color associations
// do not map these directly to a #color! map them to the color variables above so it's easier to maintain
$accent: $red;
$accentlight: $lightred;
$link: $red;
$striping: $offwhite;
$primary: $black;
$bodytext: $darkgrey;
$btnhover: $darkgrey;
$textlight: $white;
$headertext: $white;
$navbg: $white;
$bodybg: $white;
$lightborder: $lightgrey;
$medborder: $grey;
$darkborder: $darkgrey;

// font weights
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;

// z-index
$zindexBase: 1;
$zindexOver: 100;
$zindexOverlay: 900;
$zindexModal: 1000;

// spacing values for margins and padding
$spacing-xxs: 0.25em;
$spacing-xs: 0.5em;
$spacing-sm: 1em;
$spacing-md: 1.5em;
$spacing-lg: 2em;
$spacing-xl: 4em;
$spacing-xxl: 6em;

// heading sizes
$heading-primary: 2.5em;
$heading-secondary: 2em;
$heading-tertiary: 1.5em;
