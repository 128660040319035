﻿$font: "Gotham SSm A", "Gotham SSm B", Tahoma, Sans-Serif;

html {
    font-size: 16px !important
}

body {
    color: #000 !important
}

html, body, h1, h2, h3, h4, h5, h6, p, a, form, select, option, label {
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    letter-spacing: -0.04em
}

.text--center {
    text-align: center
}

.heading {
    font-weight: 800;
    letter-spacing: -0.05em
}

.heading--secondary {
    margin-bottom: 1em
}

.heading--tertiary {
    line-height: 1.4
}

.site-pad {
    padding: 0 1rem
}

@media (min-width: 768px) {
    .site-pad {
        padding: 0 2rem
    }
}

@media (min-width: 992px) {
    .site-pad {
        padding: 0 2.5rem
    }
}

@media (min-width: 1100px) {
    .site-pad {
        padding: 0 4rem
    }
}

.site-wrapper--top {
    padding: 1em 0 0
}

.site-wrapper--mid {
    padding: 0
}

.site-wrapper--bot {
    padding: 0 0 1em
}

@media (min-width: 860px) {
    .site-wrapper--top {
        padding: 52px 0 0
    }

    .site-wrapper--mid {
        padding: 0 0
    }

    .site-wrapper--bot {
        padding: 0 0 2em
    }
}

@media (min-width: 1100px) {
    .site-wrapper--top {
        padding-top: 58px
    }
}

@media (min-width: 1200px) {
    .site-wrapper--top {
        padding-top: 70px
    }
}

.min-wrapper {
    max-width: 1284px;
    margin: 0 auto
}

.image-content.image-content--surround {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1284px;
    margin: 0 auto
}

    .image-content.image-content--surround .image-content__picture {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33%;
        flex: 1 1 33.33%;
        overflow: hidden
    }

        .image-content.image-content--surround .image-content__picture > img {
            position: absolute;
            top: 0;
            left: 50%;
            width: auto;
            height: 100%;
            max-width: initial;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%)
        }

    .image-content.image-content--surround .image-content__content-wrap {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        padding: 110px 30px 120px;
        color: #fff;
        text-align: center;
        background-color: #848484
    }

    .image-content.image-content--surround .image-content__content {
        max-width: 300px;
        margin: 0 auto;
        padding: 0
    }

        .image-content.image-content--surround .image-content__content > p {
            line-height: 1.67;
            font-size: 1.125em
        }

    .image-content.image-content--surround .image-content__logo {
        max-width: 60%;
        max-height: 190px;
        margin: 0 auto 65px
    }

@media (max-width: 850px) {
    .image-content.image-content--surround .image-content__picture > img {
        height: 105%
    }

    .image-content.image-content--surround .image-content__content-wrap {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .image-content.image-content--surround .image-content__content > p {
        font-size: 1em;
        line-height: 1.5
    }

    .image-content.image-content--surround .image-content__logo {
        margin-bottom: 30px
    }
}

@media (max-width: 649px) {
    .image-content.image-content--surround {
        display: block
    }

        .image-content.image-content--surround .image-content__picture {
            display: block
        }

            .image-content.image-content--surround .image-content__picture > img {
                position: static;
                height: auto;
                width: 100%;
                -webkit-transform: none;
                -ms-transform: none;
                transform: none
            }
}

@media (min-width: 768px) {
    .clear-3-col li:nth-of-type(2n+1) {
        clear: left
    }
}

@media (min-width: 1200px) {
    .clear-3-col li:nth-of-type(3n+1) {
        clear: left
    }

    .clear-3-col li:nth-of-type(2n+1) {
        clear: none
    }
}

.product-purchase-list {
    margin-bottom: 40px;
    padding: 0;
    list-style: none
}

.product-purchase-list__item {
    margin: 5px -10px;
    padding: 55px 20px 60px;
    text-align: center
}

    .product-purchase-list__item .btn {
        width: 230px;
        margin-top: 27px;
        padding: 9px 32px 10px;
        font-weight: normal
    }

.product-purchase-list__img {
    margin-bottom: 50px
}

.product-purchase-list__img--align-left {
    padding-right: 32%
}

@media (max-width: 992px) {
    .demo__options-container.demo__options-container {
        float: left;
        width: 33.333%;
        padding-left: 0;
        padding-right: 0
    }

        .demo__options-container.demo__options-container:not(:first-child) {
            padding-left: 6px
        }

        .demo__options-container.demo__options-container:not(:last-child) {
            padding-right: 6px
        }
}

@media (max-width: 766px) {
    .demo__options-container.demo__options-container {
        display: table;
        height: auto
    }

        .demo__options-container.demo__options-container > a {
            display: table-cell;
            height: 68px;
            padding: 10px 5px;
            border-color: #525252;
            line-height: 1;
            vertical-align: middle
        }

            .demo__options-container.demo__options-container > a:before {
                display: none
            }

        .demo__options-container.demo__options-container .demo__options-arrows {
            top: 90%
        }
}

.entry-mode {
    min-height: 490px;
    padding: 85px 70px 70px;
    background-color: #eee;
    background-size: 50%;
    background-position: left 5% bottom;
    background-repeat: no-repeat
}

.entry-mode--autolock {
    display: none;
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D955%2Dpassage?scl=1&fmt=png-alpha)
}

.entry-mode--passage {
    display: none;
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D955%2Dauto%2Dlock?scl=1&fmt=png-alpha)
}

.entry-mode--disable-passage {
    display: none;
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D955%2Ddisable%2Dpassage?scl=1&fmt=png-alpha)
}

.entry-mode--selected {
    display: block
}

.entry-mode__content {
    float: right;
    max-width: 400px;
    width: 40%
}

.entry-mode__p {
    font-size: 1.125em;
    line-height: 1.33
}

.entry-mode__icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 55px 25px 0 -25px;
    text-align: center
}

@media (max-width: 992px) {
    .entry-mode__icons {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.entry-mode__icon {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    padding: 0 15px
}

    .entry-mode__icon + .entry-mode__icon:before {
        content: '';
        position: absolute;
        top: 5%;
        left: 0;
        width: 1px;
        height: 80%;
        background-color: #bdbdbd
    }

    .entry-mode__icon > img {
        max-height: 65px;
        max-width: 65px
    }

.entry-mode__btns {
    max-width: 750px !important;
    margin-bottom: 45px
}

    .entry-mode__btns a {
        color: #000000
    }

        .entry-mode__btns a:hover, .entry-mode__btns a.demo__options--selected {
            color: #ffffff
        }

@media (max-width: 992px) {
    .entry-mode {
        padding: 20px 20px 48%;
        background-position: left 8% bottom;
        background-size: 62%
    }

    .entry-mode__content {
        float: none;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        text-align: center
    }

    .entry-mode__icons {
        margin: 55px 0 0
    }
}

@media (max-width: 500px) {
    .entry-mode {
        padding-bottom: 89%;
        background-size: 118%;
        background-position: left 214% bottom
    }

    .entry-mode__p {
        font-size: 1em
    }
}
.demo__options {
    text-decoration: none;
    border: 2px solid #525252;
    width: 100%;
    height: 100%;
    display: inline-block;
    max-width: 250px;
    font-size: 1.2em
}

.demo__options-container {
    height: 70px;
    float: none;
    display: inline-block;
    width: 32.333333%
}

.demo__options-icons {
    margin-right: 12px;
    color: #7F7F7F
}

.demo__options:hover {
    background-color: #46474c
}

.demo__options:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle
}

.demo__options-arrows {
    display: none;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #D11B32;
    position: absolute;
    margin: 0 auto;
    top: 90%;
    left: 0;
    right: 0
}

.demo__options-arrows--selected {
    display: block
}

.demo__options--selected {
    background-color: #D11B32;
    border: none
}

.demo__options--selected .demo__options-icons {
    color: rgba(0,0,0,0.4)
}

.demo__options--selected:hover {
    background-color: #D11B32
}

.demo__options--selected:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #D11B32;
    position: absolute;
    margin: 0 auto;
    top: 100%;
    left: 0;
    right: 0;
}


html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline
}

    audio:not([controls]) {
        display: none;
        height: 0
    }

[hidden], template {
    display: none
}

a {
    background-color: transparent
}

    a:active, a:hover {
        outline: 0
    }

abbr[title] {
    border-bottom: 1px dotted
}

b, strong {
    font-weight: bold
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button, select {
    text-transform: none
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer
}

    button[disabled], html input[disabled] {
        cursor: default
    }

    button::-moz-focus-inner, input::-moz-focus-inner {
        border: 0;
        padding: 0
    }

input {
    line-height: normal
}

    input[type="checkbox"], input[type="radio"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0
    }

    input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
        height: auto
    }

    input[type="search"] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
        box-sizing: content-box
    }

        input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
            -webkit-appearance: none
        }

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: bold
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td, th {
    padding: 0
}
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

@media print {
    *, *:before, *:after {
        background: transparent !important;
        color: #000 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important
    }

    a, a:visited {
        text-decoration: underline
    }

        a[href]:after {
            content: " (" attr(href) ")"
        }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    a[href^="#"]:after, a[href^="javascript:"]:after {
        content: ""
    }

    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr, img {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3
    }

    h2, h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .btn > .caret, .dropup > .btn > .caret {
        border-top-color: #000 !important
    }

    .label {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

        .table td, .table th {
            background-color: #fff !important
        }

    .table-bordered th, .table-bordered td {
        border: 1px solid #ddd !important
    }
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

    *:before, *:after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

    a:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px
    }

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

[role="button"] {
    cursor: pointer
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

    h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
        font-weight: normal;
        line-height: 1;
        color: #777
    }

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

    h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
        font-size: 65%
    }

h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

    h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
        font-size: 75%
    }

h1, .h1 {
    font-size: 36px
}

h2, .h2 {
    font-size: 30px
}

h3, .h3 {
    font-size: 24px
}

h4, .h4 {
    font-size: 18px
}

h5, .h5 {
    font-size: 14px
}

h6, .h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width: 768px) {
    .lead {
        font-size: 21px
    }
}

small, .small {
    font-size: 85%
}

mark, .mark {
    background-color: #fcf8e3;
    padding: .2em
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-muted {
    color: #777
}

.text-primary {
    color: #337ab7
}

a.text-primary:hover, a.text-primary:focus {
    color: #286090
}

.text-success {
    color: #3c763d
}

a.text-success:hover, a.text-success:focus {
    color: #2b542c
}

.text-info {
    color: #31708f
}

a.text-info:hover, a.text-info:focus {
    color: #245269
}

.text-warning {
    color: #8a6d3b
}

a.text-warning:hover, a.text-warning:focus {
    color: #66512c
}

.text-danger {
    color: #a94442
}

a.text-danger:hover, a.text-danger:focus {
    color: #843534
}

.bg-primary {
    color: #fff;
    background-color: #337ab7
}

a.bg-primary:hover, a.bg-primary:focus {
    background-color: #286090
}

.bg-success {
    background-color: #dff0d8
}

a.bg-success:hover, a.bg-success:focus {
    background-color: #c1e2b3
}

.bg-info {
    background-color: #d9edf7
}

a.bg-info:hover, a.bg-info:focus {
    background-color: #afd9ee
}

.bg-warning {
    background-color: #fcf8e3
}

a.bg-warning:hover, a.bg-warning:focus {
    background-color: #f7ecb5
}

.bg-danger {
    background-color: #f2dede
}

a.bg-danger:hover, a.bg-danger:focus {
    background-color: #e4b9b9
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee
}

ul, ol {
    margin-top: 0;
    margin-bottom: 10px
}

    ul ul, ol ul, ul ol, ol ol {
        margin-bottom: 0
    }

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px
}

    .list-inline > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px
    }

dl {
    margin-top: 0;
    margin-bottom: 20px
}

dt, dd {
    line-height: 1.42857143
}

dt {
    font-weight: bold
}

dd {
    margin-left: 0
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 180px
    }
}

abbr[title], abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #777
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee
}

    blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
        margin-bottom: 0
    }

    blockquote footer, blockquote small, blockquote .small {
        display: block;
        font-size: 80%;
        line-height: 1.42857143;
        color: #777
    }

        blockquote footer:before, blockquote small:before, blockquote .small:before {
            content: '\2014 \00A0'
        }

    .blockquote-reverse, blockquote.pull-right {
        padding-right: 15px;
        padding-left: 0;
        border-right: 5px solid #eee;
        border-left: 0;
        text-align: right
    }

        .blockquote-reverse footer:before, blockquote.pull-right footer:before, .blockquote-reverse small:before, blockquote.pull-right small:before, .blockquote-reverse .small:before, blockquote.pull-right .small:before {
            content: ''
        }

        .blockquote-reverse footer:after, blockquote.pull-right footer:after, .blockquote-reverse small:after, blockquote.pull-right small:after, .blockquote-reverse .small:after, blockquote.pull-right .small:after {
            content: '\00A0 \2014'
        }

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width: 768px) {
    .container {
        width: 750px
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width: 1200px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }
}

table {
    background-color: transparent
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: left
}

th {
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px
}

    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd
    }

    .table > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid #ddd
    }

    .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
        border-top: 0
    }

    .table > tbody + tbody {
        border-top: 2px solid #ddd
    }

    .table .table {
        background-color: #fff
    }

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
    padding: 5px
}

.table-bordered {
    border: 1px solid #ddd
}

    .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
        border: 1px solid #ddd
    }

    .table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
        border-bottom-width: 2px
    }

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9
}

.table-hover > tbody > tr:hover {
    background-color: #f5f5f5
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column
}

table td[class*="col-"], table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell
}

.table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active, .table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
    background-color: #f5f5f5
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
    background-color: #e8e8e8
}

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
    background-color: #dff0d8
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
    background-color: #d0e9c6
}

.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
    background-color: #d9edf7
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
    background-color: #c4e3f3
}

.table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
    background-color: #fcf8e3
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
    background-color: #faf2cc
}

.table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {
    background-color: #f2dede
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
    background-color: #ebcccc
}

.table-responsive {
    overflow-x: auto;
    min-height: 0.01%
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd
    }

        .table-responsive > .table {
            margin-bottom: 0
        }

            .table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
                white-space: nowrap
            }

        .table-responsive > .table-bordered {
            border: 0
        }

            .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
                border-left: 0
            }

            .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
                border-right: 0
            }

            .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
                border-bottom: 0
            }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold
}

input[type="search"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

input[type="radio"], input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal
}

input[type="file"] {
    display: block
}

input[type="range"] {
    display: block;
    width: 100%
}

select[multiple], select[size] {
    height: auto
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s
}

    .form-control:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6)
    }

    .form-control::-moz-placeholder {
        color: #999;
        opacity: 1
    }

    .form-control:-ms-input-placeholder {
        color: #999
    }

    .form-control::-webkit-input-placeholder {
        color: #999
    }

    .form-control::-ms-expand {
        border: 0;
        background-color: transparent
    }

    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
        background-color: #eee;
        opacity: 1
    }

    .form-control[disabled], fieldset[disabled] .form-control {
        cursor: not-allowed
    }

textarea.form-control {
    height: auto
}

input[type="search"] {
    -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
        line-height: 34px
    }

    input[type="date"].input-sm, input[type="time"].input-sm, input[type="datetime-local"].input-sm, input[type="month"].input-sm, .input-group-sm input[type="date"], .input-group-sm input[type="time"], .input-group-sm input[type="datetime-local"], .input-group-sm input[type="month"] {
        line-height: 30px
    }

    input[type="date"].input-lg, input[type="time"].input-lg, input[type="datetime-local"].input-lg, input[type="month"].input-lg, .input-group-lg input[type="date"], .input-group-lg input[type="time"], .input-group-lg input[type="datetime-local"], .input-group-lg input[type="month"] {
        line-height: 46px
    }
}

.form-group {
    margin-bottom: 15px
}

.radio, .checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

    .radio label, .checkbox label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer
    }

    .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
        position: absolute;
        margin-left: -20px;
        margin-top: 4px \9
    }

    .radio + .radio, .checkbox + .checkbox {
        margin-top: -5px
    }

.radio-inline, .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer
}

    .radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
        margin-top: 0;
        margin-left: 10px
    }

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"].disabled, input[type="checkbox"].disabled, fieldset[disabled] input[type="radio"], fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed
}

.radio-inline.disabled, .checkbox-inline.disabled, fieldset[disabled] .radio-inline, fieldset[disabled] .checkbox-inline {
    cursor: not-allowed
}

.radio.disabled label, .checkbox.disabled label, fieldset[disabled] .radio label, fieldset[disabled] .checkbox label {
    cursor: not-allowed
}

.form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 34px
}

    .form-control-static.input-lg, .form-control-static.input-sm {
        padding-left: 0;
        padding-right: 0
    }

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-sm {
    height: 30px;
    line-height: 30px
}

textarea.input-sm, select[multiple].input-sm {
    height: auto
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
    height: auto
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-lg {
    height: 46px;
    line-height: 46px
}

textarea.input-lg, select[multiple].input-lg {
    height: auto
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
    height: auto
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.has-feedback {
    position: relative
}

    .has-feedback .form-control {
        padding-right: 42.5px
    }

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none
}

.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px
}

.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
    color: #3c763d
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
}

    .has-success .form-control:focus {
        border-color: #2b542c;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #67b168;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #67b168
    }

.has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8
}

.has-success .form-control-feedback {
    color: #3c763d
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
    color: #8a6d3b
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
}

    .has-warning .form-control:focus {
        border-color: #66512c;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #c0a16b;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #c0a16b
    }

.has-warning .input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3
}

.has-warning .form-control-feedback {
    color: #8a6d3b
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #a94442
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
}

    .has-error .form-control:focus {
        border-color: #843534;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ce8483;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ce8483
    }

.has-error .input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede
}

.has-error .form-control-feedback {
    color: #a94442
}

.has-feedback label ~ .form-control-feedback {
    top: 25px
}

.has-feedback label.sr-only ~ .form-control-feedback {
    top: 0
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-static {
        display: inline-block
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle
    }

        .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
            width: auto
        }

        .form-inline .input-group > .form-control {
            width: 100%
        }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .radio, .form-inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

        .form-inline .radio label, .form-inline .checkbox label {
            padding-left: 0
        }

        .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
            position: relative;
            margin-left: 0
        }

    .form-inline .has-feedback .form-control-feedback {
        top: 0
    }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px
}

.form-horizontal .radio, .form-horizontal .checkbox {
    min-height: 27px
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px
    }
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}

    .input-group[class*="col-"] {
        float: none;
        padding-left: 0;
        padding-right: 0
    }

    .input-group .form-control {
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0
    }

        .input-group .form-control:focus {
            z-index: 3
        }

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-group-lg > .form-control, select.input-group-lg > .input-group-addon, select.input-group-lg > .input-group-btn > .btn {
    height: 46px;
    line-height: 46px
}

textarea.input-group-lg > .form-control, textarea.input-group-lg > .input-group-addon, textarea.input-group-lg > .input-group-btn > .btn, select[multiple].input-group-lg > .form-control, select[multiple].input-group-lg > .input-group-addon, select[multiple].input-group-lg > .input-group-btn > .btn {
    height: auto
}

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-group-sm > .form-control, select.input-group-sm > .input-group-addon, select.input-group-sm > .input-group-btn > .btn {
    height: 30px;
    line-height: 30px
}

textarea.input-group-sm > .form-control, textarea.input-group-sm > .input-group-addon, textarea.input-group-sm > .input-group-btn > .btn, select[multiple].input-group-sm > .form-control, select[multiple].input-group-sm > .input-group-addon, select[multiple].input-group-sm > .input-group-btn > .btn {
    height: auto
}

.input-group-addon, .input-group-btn, .input-group .form-control {
    display: table-cell
}

    .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
        border-radius: 0
    }

.input-group-addon, .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px
}

    .input-group-addon.input-sm {
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 3px
    }

    .input-group-addon.input-lg {
        padding: 10px 16px;
        font-size: 18px;
        border-radius: 6px
    }

    .input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
        margin-top: 0
    }

    .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }

    .input-group-addon:first-child {
        border-right: 0
    }

    .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }

    .input-group-addon:last-child {
        border-left: 0
    }

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

    .input-group-btn > .btn {
        position: relative
    }

        .input-group-btn > .btn + .btn {
            margin-left: -1px
        }

        .input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
            z-index: 2
        }

    .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
        margin-right: -1px
    }

    .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
        z-index: 2;
        margin-left: -1px
    }

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after {
    clear: both
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.hide {
    display: none !important
}

.show {
    display: block !important
}

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.hidden {
    display: none !important
}

.affix {
    position: fixed
}

@-ms-viewport {
    width: device-width
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
    display: none !important
}

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
    display: none !important
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important
    }

    table.visible-xs {
        display: table !important
    }

    tr.visible-xs {
        display: table-row !important
    }

    th.visible-xs, td.visible-xs {
        display: table-cell !important
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important
    }

    table.visible-sm {
        display: table !important
    }

    tr.visible-sm {
        display: table-row !important
    }

    th.visible-sm, td.visible-sm {
        display: table-cell !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important
    }

    table.visible-md {
        display: table !important
    }

    tr.visible-md {
        display: table-row !important
    }

    th.visible-md, td.visible-md {
        display: table-cell !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important
    }

    table.visible-lg {
        display: table !important
    }

    tr.visible-lg {
        display: table-row !important
    }

    th.visible-lg, td.visible-lg {
        display: table-cell !important
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: block !important
    }

    table.visible-print {
        display: table !important
    }

    tr.visible-print {
        display: table-row !important
    }

    th.visible-print, td.visible-print {
        display: table-cell !important
    }
}

.visible-print-block {
    display: none !important
}

@media print {
    .visible-print-block {
        display: block !important
    }
}

.visible-print-inline {
    display: none !important
}

@media print {
    .visible-print-inline {
        display: inline !important
    }
}

.visible-print-inline-block {
    display: none !important
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }
}

@media print {
    .hidden-print {
        display: none !important
    }
}

ï»¿ _::-webkit-full-page-media, _:future, :root .cta-box a {
    display: inline-block;
    margin-left: 8%;
    margin-top: 20%
}

_::-webkit-full-page-media, _:future, :root .microsite-image-content--compatibility .microsite-image-content__image img {
    position: relative;
    bottom: -20px
}

_::-webkit-full-page-media, _:future, :root .icon-content.icon-content--thin {
    margin-top: 20%
}

_::-webkit-full-page-media, _:future, :root .image-list {
    display: block
}

.alert-banner {
    margin-bottom: 2em;
    margin-top: -2em;
    background-color: #FFC13A;
    margin-left: -2em;
    margin-right: -2em;
    line-height: 2.5em;
    color: #fff;
    display: none;
    position: relative;
    padding-left: 1em;
    padding-right: 2.5em
}

    .alert-banner a {
        text-decoration: none
    }

    .alert-banner .arrow {
        color: #fff
    }

        .alert-banner .arrow:after {
            content: '\f0a9';
            font-family: FontAwesome;
            color: #fff;
            margin-left: 0.25em;
            -webkit-transition: -webkit-transform 0.25s;
            transition: -webkit-transform 0.25s;
            -o-transition: transform 0.25s;
            transition: transform 0.25s;
            transition: transform 0.25s, -webkit-transform 0.25s;
            display: inline-block
        }

    .alert-banner .close {
        position: absolute;
        right: .5em;
        top: 0
    }

.pin-image-only {
    display: none
}

.section-padding {
    padding: 1.5em 1em
}

.section-padding--small {
    padding: 1.5em 1em
}

.section-padding-t-b {
    padding-top: 1.5em;
    padding-bottom: 1.5em
}

.section-padding-t-b--small {
    padding-top: 1.5em;
    padding-bottom: 1.5em
}

@media (min-width: 992px) {
    .section-padding {
        padding: 4em 1.5em
    }

    .section-padding-t-b {
        padding-top: 4em;
        padding-bottom: 4em
    }

    .section-padding-t-b--small {
        padding-top: 1.5em;
        padding-bottom: 1.5em
    }
}

.section-padding-t-small {
    padding-top: 1em
}

.section-padding-t-med {
    padding-top: 1.5em
}

.section-padding-t-xl {
    padding-top: 4em
}

.section-padding-b-large {
    padding-bottom: 2em
}

.section-padding--large {
    padding: 2em 1.5em
}

@media (min-width: 768px) {
    .section-padding--large {
        padding-top: 4em;
        padding-bottom: 4em
    }
}

.price-buy a {
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none
}

.group:after {
    content: '';
    display: table;
    clear: both
}

body {
    font-size: 16px
}

p + .btn {
    margin-top: 1em
}

.text--centered {
    text-align: center
}

.text--centered-block {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 760px
}

.text--light {
    font-weight: 100
}

.text--heavy {
    font-weight: 700
}

.text--uppercase {
    text-transform: uppercase
}

.text--larger {
    font-size: 1.1em
}

.text--2x {
    font-size: 1.4em
}

.text--em {
    font-weight: 400
}

.text--link {
    text-decoration: underline
}

.text--link-out:after {
    content: '\f08e';
    font-family: FontAwesome;
    color: #D11B32;
    display: inline-block;
    margin-left: 10px
}

.text--link-arrow:after {
    content: '\f0a9';
    font-family: FontAwesome;
    color: #D11B32;
    display: inline-block;
    margin-left: 10px
}

.text--link-white {
    color: #fff
}

    .text--link-white:hover {
        color: #fff
    }

.text--link-black {
    color: #000
}

.text--has-bottom-border {
    border-bottom: 1px solid #000;
    padding-bottom: 2em
}

.iframe--blend {
    border: none
}

.iframe--email-signup {
    margin-top: 2em;
    max-width: 380px;
    width: 100%;
    height: 60px
}

.iframe-holder {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    width: 100%
}

    .iframe-holder video, .iframe-holder iframe {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%
    }

.section-top-spacing {
    margin-top: 2em
}

@media (min-width: 768px) {
    .section-top-spacing {
        margin-top: 4em
    }
}

.content-block {
    padding-top: 2em;
    margin-top: 2em
}

.content-block--top-border {
    border-top: 1px solid #dedede
}

.content-block > *:first-child {
    margin-top: 0
}

.content-block > *:last-child {
    margin-bottom: 0
}

.section-top-border {
    padding-top: 2em;
    border-top: 1px solid #dedede
}

@media (min-width: 768px) {
    .section-top-border {
        padding-top: 4em
    }
}

.section-bottom-spacing {
    margin-bottom: 2em
}

@media (min-width: 768px) {
    .section-bottom-spacing {
        margin-bottom: 4em
    }
}

.link-out:after {
    content: '\f08e';
    font-family: 'FontAwesome';
    margin-left: 0.5em;
    text-decoration: none;
    display: inline-block
}

.link-out--icon-blue:after {
    color: #D11B32
}

.site-header {
    background: #000;
    color: #fff;
    padding: 1.15em;
    position: relative;
    z-index: 900;
    font-weight: 300
}

.heading--has-micro-divider:after {
    content: '';
    background: #D11B32;
    height: 2px;
    width: 48px;
    display: block;
    margin: 0.5em 0 0.75em
}

.heading--has-micro-divider-white:after {
    background-color: #fff
}

.heading--has-micro-divider-centered:after {
    margin-left: auto;
    margin-right: auto
}

.heading--bold-caps {
    font-weight: 700;
    text-transform: uppercase
}

.heading--no-margin {
    margin-top: 0;
    margin-bottom: 0
}

.heading--secondary {
    font-size: 1.625rem
}

@media (min-width: 768px) {
    .heading--secondary {
        font-size: 2.5rem
    }
}

.heading--bold {
    font-weight: 700
}

.heading--thin {
    font-weight: 100
}

.container {
    width: auto;
    padding: 0
}

.container--max {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto
}

.container--1280 {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto
}

.container--condensed {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto
}

.container--text {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto
}

.container--text-sm {
    max-width: 605px;
    margin-left: auto;
    margin-right: auto
}

.site-wrapper {
    padding: 1em;
    background: #fff
}

@media (min-width: 768px) {
    .site-wrapper {
        font-size: 1.1em
    }
}

@media (min-width: 992px) {
    .site-wrapper {
        font-size: 1.3em
    }
}

.site-body {
    background: #f7f7f7;
    padding: 1em;
    min-height: calc(100vh - 73px - 2em)
}

.copyright {
    font-size: 0.7em;
    margin-top: 1.5em
}

@media (min-width: 768px) {
    .copyright {
        display: inline-block;
        font-size: 1em;
        margin-left: 0.5em;
        margin-right: 0.5em
    }
}

@media (min-width: 992px) {
    .copyright {
        margin-left: 1.5em;
        margin-right: 1.5em
    }
}

.main-nav-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 59px
}

.main-nav-container--open {
    display: block
}

.main-nav {
    list-style: none outside;
    margin: 0;
    padding: 0;
    font-size: 1.3em;
    background: #fff;
    color: #313131;
    display: none;
    position: relative;
    z-index: 100
}

.main-nav__link {
    text-decoration: none;
    display: block;
    -webkit-transition: color 0.25s;
    -o-transition: color 0.25s;
    transition: color 0.25s;
    padding: 1em 2em;
    color: #313131
}

    .main-nav__link:hover {
        color: #D11B32;
        text-decoration: none
    }

.main-nav__link--btn {
    background: #D11B32;
    color: #fff;
    border-radius: 1em;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s;
    font-size: 0.8em;
    text-transform: uppercase
}

    .main-nav__link--btn:hover {
        background-color: #313131;
        color: #fff
    }

.main-nav__subnav {
    list-style: none outside;
    margin: 0;
    padding: 0;
    background: #f7f7f7;
    font-size: 0.8em;
    display: none;
    border-top: 1px solid #a7a7a7;
    font-weight: 300
}

    .main-nav__subnav .main-nav__link {
        padding: 1em 0.5em
    }

.main-nav__subnav--open {
    display: block
}

.main-nav--open {
    display: block
}

.main-nav__item--subitem {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto
}

.main-nav__item--has-image {
    text-transform: none;
    font-weight: 400;
    font-size: 1.1em
}

    .main-nav__item--has-image .main-nav__product-name {
        display: block;
        margin-top: 0.5em
    }

    .main-nav__item--has-image .main-nav__link {
        overflow: auto;
        padding-top: 0.5em;
        padding-bottom: 0.5em
    }

    .main-nav__item--has-image img {
        float: left;
        max-width: 60px;
        margin-right: 15px
    }

    .main-nav__item--has-image p {
        font-size: 0.7em;
        font-weight: 300;
        margin: 0
    }

@media (min-width: 860px) {
    .main-nav {
        position: static
    }

    .main-nav__item--has-image {
        font-weight: 300;
        font-size: inherit
    }

        .main-nav__item--has-image .main-nav__product-name {
            font-weight: 400
        }

        .main-nav__item--has-image .main-nav__link {
            padding-bottom: 1em
        }

        .main-nav__item--has-image img {
            float: none;
            max-width: 100%;
            margin-right: 0
        }
}

.main-nav-toggle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 66px;
    padding: 0.25em 1.15em 1.15em;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border: none;
    border-right: 1px solid #313131;
    text-decoration: none;
    background: transparent;
    font-size: 13px
}

    .main-nav-toggle:hover {
        color: #fff;
        text-decoration: none
    }

    .main-nav-toggle:focus {
        color: #fff;
        text-decoration: none;
        outline: none
    }

.main-nav-toggle--open {
    background: #D11B32
}

    .main-nav-toggle--open .icon-bar {
        background: transparent
    }

        .main-nav-toggle--open .icon-bar:before {
            -webkit-transform: "rotate3d(0,0,1,45deg)";
            -ms-transform: "rotate3d(0,0,1,45deg)";
            transform: "rotate3d(0,0,1,45deg)";
            top: 0
        }

        .main-nav-toggle--open .icon-bar:after {
            -webkit-transform: "rotate3d(0,0,1,-45deg)";
            -ms-transform: "rotate3d(0,0,1,-45deg)";
            transform: "rotate3d(0,0,1,-45deg)";
            top: 0
        }

.main-nav-toggle__label {
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    bottom: 0.5em;
    left: 1em;
    right: 1em;
    line-height: 1
}

.microsite-header__right-link {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 66px;
    color: #fff;
    text-decoration: none;
    border-left: 1px solid #313131;
    padding: 0.75em 0.5em;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.8em;
    height: 57px;
}

.microsite-header__right-link:hover {
    color: #fff;
}

@media (min-width: 860px) {
    .microsite-header__right-link {
        display: none;
    }
}

.icon-bar {
    background: #fff;
    height: 2px;
    display: block;
    margin: 0 auto 0.5em auto;
    position: relative;
    -webkit-transition: background 0.25s;
    -o-transition: background 0.25s;
    transition: background 0.25s
}

    .icon-bar:before, .icon-bar:after {
        background: #fff;
        height: 2px;
        display: block;
        margin: 0 auto 0.5em auto;
        content: '';
        position: absolute;
        top: -0.65em;
        left: 0;
        right: 0;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }

    .icon-bar:after {
        position: absolute;
        top: 0.65em
    }

.mobile:after {
    content: '';
    position: fixed;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden
}

.nav-open:after {
    background: rgba(0,0,0,0.5);
    opacity: 1;
    visibility: visible;
    z-index: 100
}

.main-nav__item {
    border-bottom: 1px solid #a7a7a7
}

.main-nav__item--has-subitem > a:after {
    content: '+';
    color: #D11B32;
    float: right
}

.main-nav__item--subitem {
    border-color: #dedede
}

    .main-nav__item--subitem:last-child {
        border: none
    }

.branding {
    text-align: center
}

    .branding a {
        display: inline-block
    }

.branding__logo {
    display: block;
    text-indent: -9999px
}

.branding__logo--inline-small {
    width: 100px
}

.branding__logo--kwikset {
    background: transparent url("//images.kwikset.com/is/image/Kwikset/logo?scl=1&fmt=png-alpha") center center no-repeat;
    width: 140px;
    background-size: contain;
    margin: 0 auto;
    height: 0;
    padding-bottom: 15.21197%
}

.branding--inline {
    display: inline-block
}

    .branding--inline .branding__logo {
        width: 310px
    }

.branding--has-text {
    margin-bottom: 1em
}

    .branding--has-text .branding__logo {
        padding-bottom: 55px
    }

    .branding--has-text .branding__text {
        line-height: 55px;
        font-size: 2em;
        font-weight: 100
    }

@media (min-width: 400px) {
    .branding--has-text .branding__logo {
        float: left
    }

    .branding--has-text .branding__text {
        margin-left: 0.5em;
        border-left: 1px solid #dedede;
        padding-left: 0.5em
    }
}

.branding--kwikset {
    background: #000
}

.section-bg-color--accent {
    background: #D11B32;
    color: #fff
}

.section-bg-color--offwhite {
    background-color: #f7f7f7
}

.section-bg-color--white {
    background-color: #fff
}

.section-bg-color--grey {
    background-color: #e9e8e8
}

.section-bg-color--darkgrey {
    background-color: #2D2E32;
    color: #fff
}

.section-bg-color--black {
    background-color: #000;
    color: #fff
}

.section-bg-color--dark {
    color: #fff
}

@media (min-width: 992px) {
    .section-bg-color--lg-white {
        background-color: #fff
    }
}

.section-bg-offset--dark-grey {
    position: relative
}

    .section-bg-offset--dark-grey:before {
        content: '';
        display: block;
        background-color: #2D2E32;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 40%
    }

.heading--on-dark-offset {
    color: #fff;
    position: relative;
    z-index: 2
}

.copy--on-dark-offset {
    color: #fff;
    position: relative;
    z-index: 2
}

.section-bg-image {
    padding-left: 1em;
    padding-right: 1em
}

.section-bg-image--dark {
    color: #fff
}

.section-bg-image--is-header {
    margin-top: -2em;
    padding-top: 2em
}

    .section-bg-image--is-header .microsite-image-content--content-first .microsite-image-content__content {
        padding-top: 0
    }

.btn {
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    padding: 0.75em 2em;
    text-transform: uppercase;
    font-weight: 300;
    -webkit-transition: background-color 0.25s, color 0.25s;
    -o-transition: background-color 0.25s, color 0.25s;
    transition: background-color 0.25s, color 0.25s;
    letter-spacing: 0.03em
}

    .btn:hover {
        color: #fff
    }

.btn--scroll-down-link {
    font-weight: 100
}

    .btn--scroll-down-link:after {
        content: '\f0ab';
        font-family: FontAwesome;
        display: inline-block;
        margin-left: 0.5em;
        color: #76dbff
    }

.btn--white {
    color: #fff
}

    .btn--white:hover {
        color: #D11B32
    }

.btn--large {
    padding: 1.5em
}

.btn--wide-short {
    padding: 0.75em 2em
}

.btn--fullbg {
    background: #D11B32;
    color: #fff
}

    .btn--fullbg:hover {
        background: #e21e31
    }

.btn--large-margins {
    margin-top: 1.5em;
    margin-bottom: 1.5em
}

.btn--transparent {
    background: transparent none;
    border: 2px solid #222;
    color: #222;
    font-weight: 300
}

    .btn--transparent span {
        color: #D11B32
    }

    .btn--transparent:hover {
        color: #fff;
        background-color: #D11B32;
        border-color: #D11B32
    }

        .btn--transparent:hover span {
            color: #fff
        }

    .btn--transparent.btn--white {
        color: #fff;
        border-color: #fff
    }

        .btn--transparent.btn--white:hover {
            border-color: #D11B32
        }

.page-header--buzz .btn--transparent.btn--white:hover, .section-bg-color--accent .btn--transparent.btn--white:hover {
    color: #D11B32;
    background-color: #fff
}

.btn--has-arrow-right {
    color: #000
}

    .btn--has-arrow-right:after {
        content: '\f0a9';
        font-family: FontAwesome;
        color: #D11B32;
        margin-left: 0.5em;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s;
        display: inline-block
    }

    .btn--has-arrow-right:hover {
        color: #D11B32
    }

.btn--has-arrow-up {
    color: #000;
    text-transform: uppercase !important;
    border-bottom: none !important;
    margin-top: 65px;
    margin-bottom: 65px
}

    .btn--has-arrow-up:after {
        content: '\f0aa';
        font-family: FontAwesome;
        color: #D11B32;
        margin-left: 0.5em;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s;
        display: inline-block
    }

    .btn--has-arrow-up:hover {
        color: #D11B32
    }

.btn--text {
    padding: 0
}

.btn--no-border {
    border-color: transparent
}

    .btn--no-border:hover {
        color: currentColor;
        background: transparent;
        border-color: transparent
    }

@media (min-width: 992px) {
    .btn--lg-nowrap {
        white-space: nowrap
    }
}

.btn-group {
    margin-bottom: 1em
}

    .btn-group a {
        margin-bottom: 1em
    }

        .btn-group a:last-child {
            margin-bottom: 0
        }

.btn-group--inline a {
    display: block;
    margin-bottom: 1em
}

    .btn-group--inline a:last-child {
        margin-bottom: 0
    }

@media (min-width: 560px) {
    .btn-group--inline a {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 1em
    }

        .btn-group--inline a:last-child {
            margin-right: 0
        }
}

.btn-group--equal-widths {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto
}

    .btn-group--equal-widths .btn {
        width: 100%;
        text-align: center
    }

@media (min-width: 992px) {
    .btn-group--equal-widths {
        margin-left: 0;
        margin-right: 0
    }
}

.btn-group--in-content {
    margin-top: 1.5em
}

.has-play-btn {
    position: relative;
    text-decoration: none
}

    .has-play-btn:before {
        content: '\f144';
        font-family: FontAwesome;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        font-size: calc(1em + 20vw)
    }

.has-play-btn--white:before {
    color: #fff
}

@media (min-width: 480px) {
    .has-play-btn:before {
        font-size: 115px
    }
}

.small-note {
    font-size: 0.8em;
    display: block
}

.list--dots {
    padding-left: 0;
    list-style: none outside
}

    .list--dots li {
        position: relative;
        padding-left: 1.5em
    }

        .list--dots li:before {
            content: '\2B1B';
            position: absolute;
            top: 0.7em;
            left: 0.7em;
            font-size: 10px;
            color: #D11B32;
            line-height: 1
        }

.list--checkmarks {
    padding-left: 0;
    list-style: none outside;
    text-align: left
}

    .list--checkmarks li {
        position: relative;
        padding-left: 1.5em;
        margin-bottom: 0.75em;
        line-height: 1.2
    }

        .list--checkmarks li:before {
            content: '\f058';
            font-family: 'FontAwesome';
            position: absolute;
            top: 0.15em;
            left: 0;
            line-height: 1
        }

        .list--checkmarks li:last-child {
            margin-bottom: 0
        }

.list--large-margins {
    margin-top: 1.5em;
    margin-bottom: 1.5em
}

.list--number-boxes {
    counter-reset: number;
    list-style: none outside;
    margin: 0;
    padding: 0
}

    .list--number-boxes:after {
        content: '';
        display: table;
        clear: both
    }

    .list--number-boxes li:before {
        content: counter(number);
        counter-increment: number;
        display: block;
        float: left;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.5;
        width: 1.5em;
        border: 1px solid #fff;
        text-align: center;
        border-radius: 50%
    }

@media (min-width: 400px) {
    .list--number-boxes li:before {
        float: none
    }
}

@media (min-width: 768px) {
    .list--number-boxes li {
        width: 50%;
        float: left;
        padding: 0.5em
    }

        .list--number-boxes li:nth-child(odd) {
            clear: left
        }
}

@media (min-width: 992px) {
    .list--number-boxes li {
        width: 25%
    }

        .list--number-boxes li:nth-child(odd) {
            clear: none
        }
}

@media (min-width: 768px) {
    .list--number-boxes {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.list--number-boxes-4 {
    counter-reset: number 3
}

.news-card {
    color: #000;
    margin-top: 3em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    border-bottom: 1px solid #dedede
}

    .news-card p, .news-card blockquote {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto
    }

    .news-card blockquote {
        padding: 0;
        display: block;
        margin-bottom: 1.5em;
        border: none;
        font-size: 1em
    }

        .news-card blockquote:before {
            content: '\201C'
        }

        .news-card blockquote:after {
            content: '\201D'
        }

    .news-card cite {
        font-style: normal
    }

.news-card__heading {
    font-weight: 400;
    text-transform: uppercase;
    margin: 2em 0 1em;
    max-width: 100%
}

.news-card:last-child {
    border: none
}

@media (min-width: 768px) {
    .news-card {
        margin-top: 0;
        margin-bottom: 0;
        border: none
    }
}

.file-list {
    list-style: none outside;
    margin: 0 0 1em;
    padding: 0
}

.file-list__item {
    margin-bottom: 0.5em
}

    .file-list__item .file-list__link:before {
        font-family: FontAwesome;
        color: #D11B32;
        display: inline-block;
        margin-right: 0.5em
    }

.file-list__item--video .file-list__link:before {
    content: '\f144'
}

.file-list__item--doc .file-list__link:before {
    content: '\f0f6'
}

.file-list__link {
    color: #000;
    text-decoration: none
}

    .file-list__link:hover {
        color: #D11B32
    }

@media (min-width: 992px) {
    .media-block-container--has-right-border {
        border-right: 1px solid #dedede
    }
}

.subheading {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto
}

.media-block {
    max-width: 360px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    font-weight: 100
}

    .media-block a {
        text-decoration: none
    }

    .media-block .text--link {
        text-decoration: underline
    }

.media-block__heading {
    font-size: 1.5em
}

@media (min-width: 768px) {
    .media-block__heading {
        font-size: 1.3em
    }
}

.section--compatibility .media-block {
    margin-bottom: 3em
}

    .section--compatibility .media-block .media-block__heading {
        font-size: 1em;
        margin-top: 1em
    }

@media (min-width: 992px) {
    .section--compatibility .media-block {
        display: inline-block;
        width: 48%;
        float: none;
        vertical-align: top
    }
}

.media-block__image-circle {
    display: inline-block;
    width: 130px;
    height: 130px;
    border: 2px solid #D11B32;
    line-height: 130px;
    border-radius: 100%
}

.media-block--callout .media-block__heading {
    text-transform: uppercase;
    color: #000;
    font-weight: 400;
    font-size: 1em;
    margin: 1.5em auto
}

@media (min-width: 992px) {
    .media-block--callout .media-block__heading {
        font-size: 0.9em
    }
}

.media-block--copy-275 p {
    max-width: 275px;
    margin-left: auto;
    margin-right: auto
}

.nav-tabs {
    list-style: none outside;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    font-size: 16px;
    font-weight: 300
}

    .nav-tabs:after {
        content: '';
        display: table;
        clear: both
    }

.nav-tabs__link {
    background: #fff;
    border: 3px solid #dedede;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1em;
    text-decoration: none;
    color: #313131;
    position: relative;
    -webkit-transition: border-color 0.25s;
    -o-transition: border-color 0.25s;
    transition: border-color 0.25s;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

    .nav-tabs__link span {
        width: 100%
    }

    .nav-tabs__link:hover {
        text-decoration: none;
        border-color: #D11B32
    }

.no-flexbox .nav-tabs__link {
    display: block
}

.nav-tabs__tab {
    float: left;
    width: 50%;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0.5em
}

.nav-tabs__tab--thirds {
    width: 33.333%
}

.nav-tabs__tab--fourths {
    width: 50%
}

@media (min-width: 768px) {
    .nav-tabs__tab--fourths {
        width: 25%
    }
}

@media (min-width: 992px) {
    .nav-tabs__tab--fourths {
        font-weight: 300;
        font-size: 16px
    }
}

@media (min-width: 1200px) {
    .nav-tabs__tab--fourths {
        font-weight: 100;
        font-size: 26px
    }
}

.nav-tabs__tab:last-child {
    padding-right: 0;
    padding-left: 0.5em
}

.nav-tabs__tab--selected {
    color: #313131
}

    .nav-tabs__tab--selected .nav-tabs__link {
        border-color: #D11B32
    }

        .nav-tabs__tab--selected .nav-tabs__link:after {
            opacity: 1;
            max-width: 100%
        }

.nav-tabs--wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 768px) {
    .nav-tabs--wrap {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.nav-tabs--wrap .nav-tabs__tab {
    padding: 0.25em
}

@media (min-width: 768px) {
    .nav-tabs--wrap .nav-tabs__tab {
        padding: 0 0.5em 0 0
    }
}

@media (min-width: 480px) {
    .nav-tabs {
        font-size: 24px
    }

    .nav-tabs__link {
        padding: 0.75em
    }
}

@media (min-width: 768px) {
    .nav-tabs {
        font-size: 20px;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 100
    }

    .nav-tabs--wrap {
        max-width: 1315px
    }

    .nav-tabs__link {
        padding: 0.5em 0.75em
    }

        .nav-tabs__link span {
            width: auto
        }

    .nav-tabs-wrap__content {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 992px) {
    .nav-tabs {
        font-size: 26px
    }
}

@media (min-width: 768px) {
    .nav-tabs--inline {
        max-width: none
    }

        .nav-tabs--inline .nav-tabs__tab {
            float: none;
            width: auto;
            display: inline-block
        }
}

.nav-tabs + * {
    margin-top: 1em
}

.nav-tabs-wrap {
    position: relative;
    overflow: hidden
}

.nav-tabs-wrap__content-wrapper {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 0.25s, transform 0.5s;
    -o-transition: opacity 0.25s, transform 0.5s;
    -webkit-transition: opacity 0.25s, -webkit-transform 0.5s;
    transition: opacity 0.25s, -webkit-transform 0.5s;
    transition: opacity 0.25s, transform 0.5s;
    transition: opacity 0.25s, transform 0.5s, -webkit-transform 0.5s;
    position: relative;
    z-index: 100
}

.nav-tabs-wrap__content-wrapper--hidden {
    opacity: 0;
    -webkit-transform: translate3d(0, 4em, 0);
    transform: translate3d(0, 4em, 0);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1
}

    .nav-tabs-wrap__content-wrapper--hidden .nav-tabs-wrap--accordion__header {
        border-top: 1px solid #ebedec;
        color: #000
    }

        .nav-tabs-wrap__content-wrapper--hidden .nav-tabs-wrap--accordion__header:after {
            -webkit-transform: rotateZ(0);
            -ms-transform: rotate(0);
            transform: rotateZ(0)
        }

        .nav-tabs-wrap__content-wrapper--hidden .nav-tabs-wrap--accordion__header ~ * {
            display: none
        }

@media (min-width: 768px) {
    .nav-tabs-wrap__content-wrapper--hidden {
        opacity: 0;
        -webkit-transform: translate3d(0, 4em, 0);
        transform: translate3d(0, 4em, 0);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1
    }
}

.nav-tabs-wrap--accordion .nav-tabs-wrap__content-wrapper--hidden {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    position: static;
    left: auto;
    top: auto;
    right: auto
}

@media (min-width: 768px) {
    .nav-tabs-wrap--accordion .nav-tabs-wrap__content-wrapper--hidden {
        opacity: 0;
        -webkit-transform: translate3d(0, 4em, 0);
        transform: translate3d(0, 4em, 0);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1
    }
}

@media (max-width: 768px) {
    .nav-tabs-wrap--accordion {
        margin-top: 1.5em;
        margin-bottom: 1.5em
    }

        .nav-tabs-wrap--accordion .nav-tabs-wrap__content {
            padding: 0
        }
}

.nav-tabs-wrap--accordion__header {
    position: relative;
    cursor: pointer;
    border-top: 2px solid #000;
    color: #D11B32;
    padding: 1em 0;
    font-size: 1.5em;
    margin: 0
}

    .nav-tabs-wrap--accordion__header:after {
        content: '\f107';
        font-family: FontAwesome;
        position: absolute;
        right: 0;
        font-size: 14px;
        line-height: 37px;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s;
        -webkit-transform: rotateZ(90deg);
        -ms-transform: rotate(90deg);
        transform: rotateZ(90deg)
    }

@media (min-width: 768px) {
    .nav-tabs-wrap--accordion__header {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        cursor: default
    }

        .nav-tabs-wrap--accordion__header:after {
            content: none
        }
}

.inline-modal-window {
    max-width: 800px;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    position: relative;
    margin-top: 44px
}

    .inline-modal-window .mfp-close {
        top: -44px;
        color: #fff;
        opacity: 1;
        font-size: 44px
    }

    .inline-modal-window#emailThanks {
        padding: 2em
    }

@media (min-width: 992px) {
    .inline-modal-window {
        padding: 2em
    }

        .inline-modal-window#emailThanks {
            padding: 6em
        }
}

.alert {
    background: #e7e7e7;
    padding: 1.5em
}

.alert--info:before {
    font-family: FontAwesome;
    font-size: 40px;
    color: #D11B32;
    content: '\f05a';
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1em;
    text-align: center
}

@media (min-width: 768px) {
    .alert--info {
        padding-left: 100px;
        position: relative
    }

        .alert--info:before {
            font-size: 60px;
            position: absolute;
            left: 50px;
            top: 50%;
            margin-top: -30px;
            margin-left: -30px;
            line-height: 1
        }
}

@media (min-width: 992px) {
    .alert--info {
        padding-left: 150px
    }

        .alert--info:before {
            left: 75px
        }
}

.cta-container {
    padding: 1em 0 4em
}

.cta-container__wrap-ctas {
    max-width: 1265px;
    margin-left: auto;
    margin-right: auto
}

.cta-container .col-sm-6 {
    padding: 0
}

    .cta-container .col-sm-6:last-child {
        margin-top: 1em
    }

@media (min-width: 768px) {
    .cta-container .col-sm-6:last-child {
        margin-top: 0
    }
}

.cta-box {
    font-size: 1.75em;
    font-weight: 100;
    background: #000 none center center no-repeat;
    background-size: cover;
    text-align: center;
    height: 0;
    padding-bottom: 57.198952879581%;
    position: relative
}

    .cta-box a {
        color: #fff;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

        .cta-box a:after {
            content: '\f0a9';
            font-family: FontAwesome;
            color: #e21e31;
            display: inline-block;
            margin-left: 1em;
            -webkit-transition: -webkit-transform 0.25s;
            transition: -webkit-transform 0.25s;
            -o-transition: transform 0.25s;
            transition: transform 0.25s;
            transition: transform 0.25s, -webkit-transform 0.25s;
            font-size: 0.7em
        }

.no-flexbox .cta-box a {
    top: 45%;
    bottom: auto
}

@media (min-width: 768px) {
    .cta-box a:hover:after {
        -webkit-transform: translateX(4px);
        -ms-transform: translateX(4px);
        transform: translateX(4px)
    }
}

@media (min-width: 768px) {
    .cta-container .col-sm-6 {
        padding-right: 0.5em
    }

        .cta-container .col-sm-6:last-child {
            padding-right: 0;
            padding-left: 0.5em
        }

    .addthis_sharing_toolbox {
        display: inline-block
    }
}

@media (min-width: 992px) {
    .cta-box {
        font-size: 3.75em
    }
}

.divider {
    color: #a7a7a7;
    margin-left: 0.25em;
    margin-right: 0.25em
}

.page-header {
    text-align: center;
    margin: 0;
    border: none;
    padding: 0 0 2em
}

    .page-header h1 {
        font-size: 33px
    }

@media (min-width: 480px) {
    .page-header h1 {
        font-size: 2em
    }
}

@media (min-width: 560px) {
    .page-header h1 {
        font-size: 2.5em;
        margin-bottom: 0.5em
    }
}

@media (min-width: 1100px) {
    .page-header h1 {
        font-size: 2.5em
    }
}

.page-header .heading--no-margin {
    margin-top: 0;
    margin-bottom: 0
}

.page-header p {
    font-size: 22px;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 100
}

    .page-header p.text--larger {
        font-size: 20px
    }

    .page-header p.text--smaller {
        font-size: 16px
    }

    .page-header p.text--no-max {
        max-width: 100%
    }

    .page-header p.text--em {
        font-size: 24px;
        font-weight: 400
    }

.page-header--compatibility {
    text-align: center
}

.page-header--left {
    text-align: left
}

.page-header--hero-image {
    background: transparent none center bottom no-repeat;
    padding: 2.5em 0 400px
}

    .page-header--hero-image h1 {
        margin-bottom: 1em;
        margin-top: 0;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto
    }

    .page-header--hero-image .page-header__content p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5em
    }

        .page-header--hero-image .page-header__content p:last-child {
            margin-bottom: 0
        }

@media (min-width: 992px) {
    .page-header--hero-image .page-header__content p {
        max-width: 420px;
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width: 480px) {
    .page-header--hero-image {
        padding-bottom: 565px
    }
}

@media (min-width: 992px) {
    .page-header--hero-image {
        background-position: center center;
        padding: 5em 0;
        background-size: auto 100%
    }

        .page-header--hero-image .page-header__content {
            width: 55%;
            text-align: left
        }

        .page-header--hero-image h1 {
            margin-left: 0;
            margin-right: 0
        }
}

@media (min-width: 1100px) {
    .page-header--hero-image .page-header__content {
        width: 45%
    }
}

@media (min-width: 1200px) {
    .page-header--hero-image {
        padding: 6em 0;
        background-size: auto
    }
}

.page-header--text-image {
    background: transparent none;
    padding: 2.5em 0
}

    .page-header--text-image:after {
        content: '';
        display: table;
        clear: both
    }

    .page-header--text-image h1 {
        margin: 0 auto 1em;
        max-width: 500px
    }

    .page-header--text-image .page-header__content p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto
    }

@media (min-width: 992px) {
    .page-header--text-image .page-header__content p {
        max-width: 420px;
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width: 992px) {
    .page-header--text-image .page-header__content {
        width: 55%;
        text-align: left;
        float: left
    }

    .page-header--text-image .page-header__image {
        width: 45%;
        float: right;
        margin: 0
    }
}

@media (min-width: 1200px) {
    .page-header--text-image {
        padding: 6em 0
    }
}

.page-header__inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em
}

@media (min-width: 768px) {
    .page-header__inner {
        padding-left: 3em;
        padding-right: 3em
    }
}

.page-header__image {
    margin-top: 3em
}

@media (min-width: 860px) {
    .page-header {
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        -o-transition: opacity 0.5s;
        transition: opacity 0.5s;
        -webkit-transition-delay: 0.5s;
        -o-transition-delay: 0.5s;
        transition-delay: 0.5s
    }

        .page-header .page-header__content {
            opacity: 0;
            -webkit-transition-property: opacity, transform;
            -webkit-transition-property: opacity, -webkit-transform;
            transition-property: opacity, -webkit-transform;
            -o-transition-property: opacity, transform;
            transition-property: opacity, transform;
            transition-property: opacity, transform, -webkit-transform;
            -webkit-transition-timing-function: cubic-bezier(0.49, 0.84, 0.22, 0.98);
            -o-transition-timing-function: cubic-bezier(0.49, 0.84, 0.22, 0.98);
            transition-timing-function: cubic-bezier(0.49, 0.84, 0.22, 0.98);
            -webkit-transition-duration: 1.25s;
            -o-transition-duration: 1.25s;
            transition-duration: 1.25s;
            -webkit-transition-delay: 0.75s;
            -o-transition-delay: 0.75s;
            transition-delay: 0.75s;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0)
        }

    .loaded .page-header {
        opacity: 1
    }

        .loaded .page-header .page-header__content {
            opacity: 1;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0)
        }
}

@media (min-width: 480px) {
    .page-header__secondary-img {
        display: none
    }
}

@media (min-width: 992px) {
    .page-header__secondary-img--show-tablet {
        display: none
    }
}

.email-signup-heading {
    font-size: 1.875em;
    font-weight: 100
}

@media (min-width: 768px) {
    .email-signup-heading {
        display: block;
        vertical-align: top;
        margin-right: 0.5em
    }
}

@media (min-width: 1100px) {
    .email-signup-heading {
        display: inline-block
    }
}

.updates-signup {
    position: relative;
    color: #000;
    max-width: 100%;
    margin: 0 auto
}

    .updates-signup:after {
        content: '';
        display: table;
        clear: both
    }

    .updates-signup label {
        position: absolute;
        top: 0;
        left: 0.5em;
        line-height: 38px;
        font-weight: 300;
        cursor: text
    }

    .updates-signup input {
        padding-left: 0.45em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 38px;
        float: left;
        border: none;
        width: 100%;
        border: 1px solid #a7a7a7;
        margin-bottom: 1em
    }

        .updates-signup input:focus {
            outline: none
        }

    .updates-signup .button {
        border: 2px solid #000;
        height: 38px;
        background: transparent;
        color: #000;
        text-transform: uppercase;
        font-size: 1em;
        line-height: 33px;
        padding: 0 1em;
        margin: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        -webkit-transition: background-color 0.25s, color 0.25s, border-color 0.25s;
        -o-transition: background-color 0.25s, color 0.25s, border-color 0.25s;
        transition: background-color 0.25s, color 0.25s, border-color 0.25s;
        font-weight: normal;
        width: 40%
    }

        .updates-signup .button:hover, .updates-signup .button:focus {
            background: #D11B32;
            outline: none;
            color: #fff;
            border-color: #D11B32
        }

    .updates-signup .form-notification {
        display: inline-block;
        clear: both;
        position: absolute;
        color: #000;
        font-size: 0.8em;
        border-radius: 4px;
        left: 0;
        top: 36px
    }

        .updates-signup .form-notification.error {
            color: red
        }

@media (min-width: 400px) {
    .updates-signup input {
        width: auto;
        margin-right: 0.5em
    }

    .updates-signup .button {
        width: auto
    }
}

@media (min-width: 768px) {
    .updates-signup {
        margin-top: 2px;
        display: inline-block
    }
}

@media (min-width: 992px) {
    .updates-signup input {
        width: 250px
    }
}

.select-option {
    display: block;
    font-size: 14px;
    list-style: none outside;
    margin: 0;
    text-align: center;
    padding: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
}

    .select-option li {
        border: 2px solid transparent;
        -webkit-transition: border-color 0.25s;
        -o-transition: border-color 0.25s;
        transition: border-color 0.25s;
        max-width: calc(55px + 1.5em);
        margin-right: 0.25em;
        display: inline-block
    }

        .select-option li.selected {
            border-color: #D11B32
        }

        .select-option li:last-child {
            margin-right: 0
        }

    .select-option a {
        display: block;
        border: 1px solid transparent;
        padding: 1.2em 0.25em 1em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 120px;
        -webkit-transition: border-color 0.25s;
        -o-transition: border-color 0.25s;
        transition: border-color 0.25s;
        line-height: 1.4;
        text-decoration: none;
        color: #000
    }

        .select-option a:hover {
            border-color: #e1e1e1
        }

    .select-option img {
        max-width: 55px;
        margin: 0 auto 5px;
        display: block
    }

@media (min-width: 360px) {
    .select-option {
        border: 1px solid #e1e1e1;
        padding: 1em
    }

        .select-option a {
            padding-left: 0.5em;
            padding-right: 0.5em
        }

        .select-option li {
            max-width: calc(55px + 2em)
        }
}

@media (min-width: 992px) {
    .select-option {
        float: left;
        padding-left: 2em;
        padding-right: 2em
    }
}

.microsite-image-content {
    margin-top: 2em;
    margin-bottom: 2em
}

.microsite-image-content__image {
    position: relative;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
}

    .microsite-image-content__image img {
        display: block;
        margin-left: auto;
        margin-right: auto
    }

.microsite-image-content__image--has-padding {
    padding: 1em
}

.microsite-image-content__image--has-lg-padding {
    padding: 1em 4em
}

.microsite-image-content__image--transparent {
    background: #fff;
    max-width: 100%
}

    .microsite-image-content__image--transparent img {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 100%
    }

@media (min-width: 992px) {
    .microsite-image-content__image--desktop-right img {
        margin-right: 0
    }
}

@media (min-width: 992px) {
    .microsite-image-content__image {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        max-width: 100%;
        margin: 0;
        width: 50%;
        float: left;
        text-align: center
    }

        .microsite-image-content__image img {
            display: inline;
            margin-left: 0;
            margin-right: 0;
            width: auto
        }

    .microsite-image-content__image--transparent {
        background: transparent
    }

        .microsite-image-content__image--transparent img {
            max-width: 100%;
            width: auto
        }

    .microsite-image-content__image--not-full {
        display: block
    }

    .microsite-image-content__image--full img {
        width: 100%
    }

    .microsite-image-content__image--align-bottom {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .flexboxtweener .microsite-image-content__image--align-bottom {
        -ms-flex-align: end;
        -ms-flex-pack: end
    }

    .no-flexbox .microsite-image-content__image--align-bottom {
        position: absolute;
        bottom: 0;
        right: 0
    }

    .microsite-image-content__image--extra-padding, .section-bg-color .microsite-image-content__image--extra-padding {
        padding-top: 6em;
        padding-bottom: 6em
    }
}

.microsite-image-content__img--hidden {
    display: none !important
}

.microsite-image-content__img--fading {
    position: absolute !important;
    display: block;
    opacity: 0;
    top: 0;
    left: 0
}

@media (min-width: 992px) {
    .microsite-image-content__img--fading {
        left: 50% !important;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

.microsite-image-content__image-group {
    position: relative;
    padding-bottom: 110%;
    height: 0;
    display: block;
    margin-top: 2em
}

    .microsite-image-content__image-group img {
        position: static;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto
    }

    .microsite-image-content__image-group img--hidden {
        position: absolute
    }

@media (min-width: 992px) {
    .microsite-image-content__image-group {
        position: relative;
        padding-bottom: 0;
        height: auto;
        width: 100%;
        margin-top: 0
    }

        .microsite-image-content__image-group img {
            top: 0;
            left: 0
        }

    .microsite-image-content__image--has-padding .microsite-image-content__image-group img {
        top: 1em;
        right: 1em;
        left: 1em;
        bottom: 1em
    }
}

.microsite-image-content__video video {
    width: 100%;
    display: block
}

.microsite-image-content__content {
    padding: 2em 1em 0;
    text-align: center
}

.section-padding .microsite-image-content__content {
    padding: 0
}

.section-bg-color .microsite-image-content__content {
    padding-bottom: 2em
}

.microsite-image-content__content h1:first-child, .microsite-image-content__content h2:first-child, .microsite-image-content__content h3:first-child, .microsite-image-content__content h4:first-child, .microsite-image-content__content h5:first-child, .microsite-image-content__content h6:first-child {
    margin-top: 0
}

.microsite-image-content__content .heading--has-micro-divider:after {
    margin-left: auto;
    margin-right: auto
}

.microsite-image-content__content > div {
    width: 100%;
    max-width: 445px;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 992px) {
    .microsite-image-content__content--align-left > div {
        margin-left: 0
    }
}

@media (min-width: 992px) {
    .microsite-image-content__content {
        width: 50%;
        float: right;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: left;
        padding-bottom: 2em;
        font-size: 0.8em
    }

        .microsite-image-content__content .heading--has-micro-divider:after {
            margin-left: 0;
            margin-right: 0
        }

    .microsite-image-content__content--extra-padding, .section-bg-color .microsite-image-content__content--extra-padding {
        padding-top: 6em;
        padding-bottom: 6em
    }

    .microsite-image-content__content > div {
        width: 90%
    }
}

@media (min-width: 1200px) {
    .microsite-image-content__content {
        font-size: 1em
    }
}

@media (min-width: 1400px) {
    .no-flexbox .microsite-image-content__content {
        padding-top: 5em
    }
}

.microsite-image-content--condensed {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto
}

    .microsite-image-content--condensed .microsite-image-content__image {
        max-width: 500px
    }

    .microsite-image-content--condensed .microsite-image-content__image--transparent {
        background: transparent
    }

        .microsite-image-content--condensed .microsite-image-content__image--transparent img {
            width: auto;
            max-width: 100%
        }

@media (min-width: 992px) {
    .microsite-image-content--condensed .microsite-image-content__content > div {
        max-width: 360px;
        margin-left: 0;
        margin-right: auto
    }

    .microsite-image-content--condensed .microsite-image-content__content--no-max > div {
        max-width: 100%
    }

    .microsite-image-content--condensed .microsite-image-content__image img {
        float: right;
        margin-right: 10%;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
}

@media (min-width: 992px) {
    .microsite-image-content--condensed.microsite-image-content--content-first .microsite-image-content__content > div {
        max-width: 360px;
        margin-left: auto;
        margin-right: 0
    }

    .microsite-image-content--condensed.microsite-image-content--content-first .microsite-image-content__image img {
        float: left;
        margin-left: 10%
    }
}

.microsite-image-content--flush-btm {
    margin-bottom: 0
}

@media (min-width: 992px) {
    .microsite-image-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0;
        margin-bottom: 0
    }

        .microsite-image-content:after {
            content: '';
            display: table;
            clear: both
        }

    .microsite-image-content--content-first {
        padding-top: 0
    }

        .microsite-image-content--content-first .microsite-image-content__image {
            float: right;
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2
        }

        .microsite-image-content--content-first .microsite-image-content__content {
            float: left;
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1
        }

    .no-flexbox .microsite-image-content {
        position: relative
    }
}

.addthis_sharing_toolbox .at-share-btn {
    max-width: none !important;
    max-height: 44px !important;
    background-color: transparent !important
}

.addthis_sharing_toolbox .at-icon-wrapper {
    background-color: #D11B32 !important;
    border-radius: 50%;
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 5px
}

    .addthis_sharing_toolbox .at-icon-wrapper:hover {
        background-color: #e21e31 !important
    }

.addthis_sharing_toolbox .at_flat_counter {
    display: none
}

.icon-content {
    text-align: center;
    max-width: 425px;
    margin-left: auto;
    margin-right: auto
}

    .icon-content .heading--has-micro-divider:after {
        margin-left: auto;
        margin-right: auto
    }

@media (min-width: 992px) {
    .icon-content__icon {
        display: none
    }
}

@media (min-width: 1200px) {
    .icon-content__icon {
        display: inline
    }
}

.icon-content--thin {
    max-width: 310px;
    margin-left: auto;
    margin-right: auto
}

.image-list {
    margin: 0 auto;
    list-style: none outside;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

    .image-list:after {
        content: '';
        display: table;
        clear: both
    }

.image-list__item {
    float: left;
    min-height: 175px;
    margin: 0.25em;
    background: #fff;
    border: 1px solid #dedede;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(50% - 0.5em);
    flex: 1 1 calc(50% - 0.5em);
    max-width: calc(50% - 0.5em);
    min-width: 48%;
    min-width: calc(50% - 0.5em)
}

    .image-list__item a, .image-list__item div {
        display: block
    }

.flexboxtweener .image-list__item a, .flexboxtweener .image-list__item div {
    display: -ms-flexbox;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    height: 200px
}

.image-list__item .img-responsive {
    -webkit-transition: transform 0.25s;
    -o-transition: transform 0.25s;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    min-height: 1px;
    display: block;
    margin: 0 auto
}

.image-list__item img {
    margin: 0 auto;
    display: block
}

.no-flexbox .image-list__item {
    position: relative
}

    .no-flexbox .image-list__item img {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-65%);
        -ms-transform: translateY(-65%);
        transform: translateY(-65%);
        width: 80%;
        left: 0;
        right: 0;
        max-width: 125px
    }

.flexboxtweener .image-list__item {
    display: -ms-flexbox;
    -ms-flex-pack: center;
    -ms-flex-align: center
}

    .flexboxtweener .image-list__item img {
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
        position: static;
        width: auto;
        max-width: 100%;
        margin: 0;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none
    }

.image-list__btn {
    background: #D11B32;
    color: #fff;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out
}

@media (min-width: 992px) {
    .flexboxtweener .image-list__item img {
        width: 100%;
        max-width: 130px
    }
}

.site-wrapper .faqs .faqList {
    max-width: 1085px
}

.faqs {
    padding-bottom: 102px;
    padding-top: 80px
}

    .faqs .faqList {
        list-style: none;
        padding: 0 15px !important;
        max-width: 1100px;
        margin: 0 auto !important;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    .faqs .faqItem {
        border-top: 1px solid #EBEDEC
    }

        .faqs .faqItem.active {
            border-top: 2px solid #000;
            margin-top: -1px
        }

        .faqs .faqItem h4 {
            font-size: 1.5em;
            font-weight: 100;
            padding: 23px 30px 24px 0;
            cursor: pointer;
            margin: 0;
            position: relative;
            color: #000;
            -webkit-transition: color 0.25s;
            -o-transition: color 0.25s;
            transition: color 0.25s
        }

        .faqs .faqItem.active h4 {
            color: #03adee
        }

        .faqs .faqItem .arrowIcon {
            float: right;
            width: 14px;
            height: 16px;
            cursor: pointer;
            position: relative;
            -webkit-transition: -webkit-transform 0.25s;
            transition: -webkit-transform 0.25s;
            -o-transition: transform 0.25s;
            transition: transform 0.25s;
            transition: transform 0.25s, -webkit-transform 0.25s;
            margin-top: 26px;
            font-family: FontAwesome
        }

        .faqs .faqItem.active .arrowIcon {
            -webkit-transform: rotateZ(90deg);
            -ms-transform: rotate(90deg);
            transform: rotateZ(90deg);
            color: #03adee
        }

        .faqs .faqItem .arrowIcon .arrowInactive {
            width: 100%;
            height: 100%
        }

            .faqs .faqItem .arrowIcon .arrowInactive:before {
                content: '\f107';
                position: absolute
            }

        .faqs .faqItem .arrowIcon .arrowActive {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            -webkit-transition: opacity 0.25s;
            -o-transition: opacity 0.25s;
            transition: opacity 0.25s
        }

        .faqs .faqItem.active .arrowIcon .arrowActive {
            opacity: 1
        }

        .faqs .faqItem .accrContent {
            color: #000;
            overflow: hidden;
            margin: -12px 0 26px !important;
            display: none;
            font-weight: 300;
            line-height: 1.5
        }

.section--compatibility {
    text-align: center
}

    .section--compatibility ul {
        list-style: none outside;
        margin: 0;
        padding: 0
    }

    .section--compatibility h3 {
        margin-bottom: 0.5em;
        font-size: 1.8em
    }

    .section--compatibility a {
        color: #fff
    }

    .section--compatibility > div:last-child > div {
        margin-bottom: 0
    }

@media (min-width: 1200px) {
    .section--compatibility ul {
        font-size: 16px
    }
}

.compatibility-link-container {
    position: absolute;
    bottom: 0;
    left: 15px
}

.compatibility-header {
    text-align: center;
    padding-bottom: 1em
}

.compatibility-header__lead {
    font-size: 20px;
    font-weight: 100;
    line-height: 1.2
}

@media (min-width: 992px) {
    .compatibility-header__lead {
        font-size: 28px;
        margin-bottom: 1em;
        max-width: 425px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 1200px) {
    .compatibility-header__lead {
        font-size: 36px
    }
}

.compatibility-header small {
    display: block
}

.microsite-image-content--compatibility {
    margin-top: 0
}

@media (min-width: 992px) {
    .microsite-image-content--compatibility {
        margin-top: 2em
    }

        .microsite-image-content--compatibility .microsite-image-content__image {
            display: block
        }

            .microsite-image-content--compatibility .microsite-image-content__image img {
                position: absolute;
                bottom: 0
            }
}

.nav-tabs-wrap--compatibility {
    font-size: 16px
}

    .nav-tabs-wrap--compatibility ul {
        list-style: none outside;
        margin: 0 0 2em
    }

    .nav-tabs-wrap--compatibility li {
        margin-bottom: 0.25em
    }

@media (max-width: 991px) {
    .section--compatibility {
        text-align: left;
        cursor: pointer;
        border-top: 2px solid #000;
        margin-left: 1em;
        margin-right: 1em
    }

        .section--compatibility:after {
            content: '';
            display: table;
            clear: both
        }

        .section--compatibility.media-block-container--has-right-border {
            border-top: none
        }

        .section--compatibility h3 {
            position: relative;
            font-size: 1.3em;
            padding: 0
        }

            .section--compatibility h3:after {
                font-family: FontAwesome;
                content: '\f107';
                position: absolute;
                right: 1em;
                -webkit-transition: transform 0.25s;
                -o-transition: transform 0.25s;
                -webkit-transition: -webkit-transform 0.25s;
                transition: -webkit-transform 0.25s;
                transition: transform 0.25s;
                transition: transform 0.25s, -webkit-transform 0.25s
            }

            .section--compatibility h3:hover {
                color: #D11B32
            }

            .section--compatibility h3:focus {
                color: #D11B32;
                outline: none
            }

    .container--compatibility {
        background: #f7f7f7;
        color: #000
    }

        .container--compatibility a {
            color: #000
        }

        .container--compatibility .section--compatibility__device-wrapper {
            display: none;
            clear: both
        }

        .container--compatibility .section--compatibility__device-wrapper--open {
            display: block
        }

        .container--compatibility .margin-b-xl {
            margin-bottom: 1em
        }

        .container--compatibility .media-block {
            text-align: left;
            width: 100%;
            float: none;
            max-width: 100%;
            margin-bottom: 1em;
            padding-bottom: 1em;
            border-bottom: 1px solid #dedede;
            padding-left: 0;
            padding-right: 0
        }

            .container--compatibility .media-block img {
                display: none
            }

            .container--compatibility .media-block:last-child {
                border: none
            }

        .container--compatibility .media-block__heading--open {
            color: #D11B32
        }

            .container--compatibility .media-block__heading--open:after {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg)
            }
}

.compatibility-list {
    font-size: 14px;
    -webkit-columns: 2;
    columns: 2;
    padding: 0;
    margin: 0;
    -webkit-column-gap: 3em;
    column-gap: 3em
}

    .compatibility-list li {
        overflow: hidden;
        -webkit-column-break-inside: avoid;
        break-inside: avoid-column
    }

@media (min-width: 992px) {
    .compatibility-list {
        -webkit-columns: 3;
        columns: 3;
        font-size: 16px
    }
}

.has-subnav .site-wrapper {
    padding-top: calc(54px + 1em)
}

@media (min-width: 860px) {
    .site-wrapper {
        padding: 52px 2em 2em
    }

    .site-body {
        padding: 2em;
        min-height: calc(100vh - 52px - 2em)
    }

    .has-subnav .site-wrapper {
        padding-top: 96px
    }

    .main-nav-toggle {
        display: none
    }

    .branding {
        text-align: left;
        -webkit-transition: transform 0.2s;
        -o-transition: transform 0.2s;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0
    }

        .branding a {
            display: block
        }

    .main-nav-container {
        position: static;
        top: auto;
        left: auto;
        display: block;
        float: right;
        width: calc(100% - 170px)
    }

    .main-nav {
        font-size: 1em;
        text-align: center;
        -webkit-transition: opacity 0.25s;
        -o-transition: opacity 0.25s;
        transition: opacity 0.25s;
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s;
        opacity: 0;
        display: block
    }

    .main-nav__item {
        display: inline-block;
        border: none;
        line-height: 1.5
    }

    .main-nav__item--has-subitem > a:after {
        content: none
    }

    .main-nav__item--subitem {
        padding: 1em 0;
        line-height: 1
    }

    .main-nav__item--open .main-nav__span {
        border-bottom: 2px solid #D11B32
    }

    .main-nav__link {
        padding: 0.74em 0.95em
    }

    .main-nav__link--btn {
        padding: .05em .75em
    }

    .main-nav__subnav {
        background: #fff;
        margin-top: -1px;
        padding-left: 175px;
        padding-right: 0;
        border: none;
        font-size: 0.8em;
        opacity: 0;
        -webkit-transition: opacity 0.75s;
        -o-transition: opacity 0.75s;
        transition: opacity 0.75s;
        -webkit-transition-delay: 0.75s;
        -o-transition-delay: 0.75s;
        transition-delay: 0.75s
    }

    .main-nav__subnav--open {
        position: absolute;
        width: 100%;
        left: 0
    }

    .main-nav__subnav .main-nav__link {
        padding: 0.5em 0.5em 0.3em
    }

    .loaded .main-nav {
        opacity: 1
    }

    .site-header {
        background: #fff;
        padding: 0 2.6em 0;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        -webkit-transition: transform 0.2s;
        -o-transition: transform 0.2s;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s
    }

        .site-header:after {
            content: '';
            display: table;
            clear: both
        }

    .site-header--mini {
        -webkit-transform: translateY(-52px);
        -ms-transform: translateY(-52px);
        transform: translateY(-52px)
    }

        .site-header--mini .branding--kwikset {
            -webkit-transform: translateY(52px) scale(0.95);
            -ms-transform: translateY(52px) scale(0.95);
            transform: translateY(52px) scale(0.95)
        }

    .branding--kwikset {
        float: left;
        position: absolute;
        left: 2.6em;
        top: 0;
        z-index: 1
    }

        .branding--kwikset a {
            padding: 0.8em 1.5em
        }

    .branding__logo--kwikset {
        width: 125px
    }

    .main-nav__item--selected > .main-nav__link {
        font-weight: 700
    }

    .main-nav__item--selected .main-nav__subnav {
        position: absolute;
        width: 100%;
        left: 0;
        display: block;
        opacity: 0
    }

    .loaded .main-nav__item--selected .main-nav__subnav {
        opacity: 1
    }
}

@media (min-width: 992px) {
    .image-list {
        padding: 0 2em
    }

    .image-list__item {
        margin: 1em;
        min-height: 200px;
        -ms-flex: 1 1 calc(25% - 2em);
        -webkit-box-flex: 1;
        flex: 1 1 calc(25% - 2em);
        max-width: calc(25% - 2em);
        min-width: calc(25% - 2em)
    }

        .image-list__item a {
            padding: 1em
        }

            .image-list__item a:hover .image-list__btn {
                -webkit-transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
            }

            .image-list__item a:hover .img-responsive {
                -webkit-transform: translate3d(0, -25px, 0);
                -ms-transform: translate3d(0, -25px, 0);
                transform: translate3d(0, -25px, 0)
            }
}

@media (min-width: 1100px) {
    .main-nav-container {
        width: calc(100% - 333px);
        float: none;
        margin: 0 auto
    }

    .site-wrapper {
        padding-top: 58px
    }

    .has-subnav .site-wrapper {
        padding-top: 99px
    }

    .site-body {
        min-height: calc(100vh - 58px - 2em)
    }

    .main-nav {
        font-size: 1.1em;
        text-align: center
    }

    .main-nav__subnav {
        padding-left: 130px;
        padding-right: 130px;
        font-size: 0.7em
    }

        .main-nav__subnav .main-nav__link {
            padding-left: 1em;
            padding-right: 1em
        }

    .site-header--mini {
        -webkit-transform: translateY(-53px);
        -ms-transform: translateY(-53px);
        transform: translateY(-53px)
    }

        .site-header--mini .branding--kwikset {
            -webkit-transform: translateY(53px) scale(0.9);
            -ms-transform: translateY(53px) scale(0.9);
            transform: translateY(53px) scale(0.9)
        }
}

@media (min-width: 1200px) {
    .main-nav {
        font-size: 1.3em;
        font-weight: 100
    }

    .main-nav__subnav {
        font-size: 0.65em
    }

    .site-wrapper {
        padding-top: 70px
    }

    .has-subnav .site-wrapper {
        padding-top: 116px
    }

    .site-body {
        min-height: calc(100vh - 70px - 2em)
    }

    .site-header--mini {
        -webkit-transform: translateY(-62px);
        -ms-transform: translateY(-62px);
        transform: translateY(-62px)
    }

        .site-header--mini .branding--kwikset {
            -webkit-transform: translateY(62px) scale(0.83);
            -ms-transform: translateY(62px) scale(0.83);
            transform: translateY(62px) scale(0.83)
        }

    .branding--kwikset a {
        padding-top: 1.3em;
        padding-bottom: 1.3em
    }
}

.sf_wrapper .nav-tabs-content--hidden {
    display: block
}

.sf_wrapper .site-header {
    position: static !important
}

.full-width-element {
    margin-left: -1em;
    margin-right: -1em
}

@media (min-width: 860px) {
    .full-width-element {
        margin-left: -2em;
        margin-right: -2em
    }
}

.flush-top {
    margin-top: -1em
}

@media (min-width: 860px) {
    .flush-top {
        margin-top: -2em
    }
}

.micro-divider--side span {
    display: inline-block;
    position: relative
}

    .micro-divider--side span:before, .micro-divider--side span:after {
        content: '';
        position: absolute;
        height: 5px;
        border-top: 2px solid #525252;
        top: 14px;
        width: 48px
    }

    .micro-divider--side span:before {
        right: 100%;
        margin-right: 30px
    }

    .micro-divider--side span:after {
        left: 100%;
        margin-left: 30px
    }

.site-footer .input-group--label-inside .input-group__label.input-group__label--absolute {
    top: 12px !important
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

    .mfp-container:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle
    }

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

    .mfp-preloader a {
        color: #CCC
    }

        .mfp-preloader a:hover {
            color: #FFF
        }

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-close, button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

    .mfp-close:hover, .mfp-close:focus {
        opacity: 1
    }

    .mfp-close:active {
        top: 1px
    }

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

    .mfp-arrow:active {
        margin-top: -54px
    }

    .mfp-arrow:hover, .mfp-arrow:focus {
        opacity: 1
    }

    .mfp-arrow:before, .mfp-arrow:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 35px;
        margin-left: 35px;
        border: medium inset transparent
    }

    .mfp-arrow:after {
        border-top-width: 13px;
        border-bottom-width: 13px;
        top: 8px
    }

    .mfp-arrow:before {
        border-top-width: 21px;
        border-bottom-width: 21px;
        opacity: 0.7
    }

.mfp-arrow-left {
    left: 0
}

    .mfp-arrow-left:after {
        border-right: 17px solid #FFF;
        margin-left: 31px
    }

    .mfp-arrow-left:before {
        margin-left: 25px;
        border-right: 27px solid #3F3F3F
    }

.mfp-arrow-right {
    right: 0
}

    .mfp-arrow-right:after {
        border-left: 17px solid #FFF;
        margin-left: 39px
    }

    .mfp-arrow-right:before {
        border-left: 27px solid #3F3F3F
    }

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

    .mfp-iframe-holder .mfp-content {
        line-height: 0;
        width: 100%;
        max-width: 900px
    }

    .mfp-iframe-holder .mfp-close {
        top: -40px
    }

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

    .mfp-iframe-scaler iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
        box-shadow: 0 0 8px rgba(0,0,0,0.6);
        background: #000
    }

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

    .mfp-figure:after {
        content: '';
        position: absolute;
        left: 0;
        top: 40px;
        bottom: 40px;
        display: block;
        right: 0;
        width: auto;
        height: auto;
        z-index: -1;
        -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
        box-shadow: 0 0 8px rgba(0,0,0,0.6);
        background: #444
    }

    .mfp-figure small {
        color: #BDBDBD;
        display: block;
        font-size: 12px;
        line-height: 14px
    }

    .mfp-figure figure {
        margin: 0
    }

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0,0,0,0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

        .mfp-img-mobile .mfp-bottom-bar:empty {
            padding: 0
        }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0,0,0,0.6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
        transform: scale(0.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        -ms-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        -ms-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-content {
    max-width: 600px;
    background-color: #fff;
    padding: 30px
}

.aura-hero {
    margin: 111px auto 50px;
    max-width: 720px;
    text-align: center
}

@media (max-width: 500px) {
    .aura-hero {
        margin: 90px auto 50px
    }
}

.aura-hero__logo {
    width: 65%;
    max-width: 254px
}

.aura-hero__subtitle {
    margin: 45px 0 28px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase
}

.aura-hero__product {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin: 45px 0
}

@media (max-width: 500px) {
    .aura-hero__product {
        margin: 35px 0 10px
    }
}

.aura-hero__product-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 647px;
    margin: 0 auto
}

.aura-hero__product-label {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 34%;
    flex: 1 1 34%;
    padding: 0 8%;
    font-weight: bold;
    text-transform: uppercase
}

    .aura-hero__product-label:first-of-type {
        padding-left: 0
    }

    .aura-hero__product-label:last-of-type {
        padding-right: 0
    }

.aura-hero > p {
    line-height: 24px;
    margin-bottom: 40px
}

.aura-hero > a {
    font-size: 18px;
    color: #000;
    text-transform: uppercase
}

    .aura-hero > a .fa {
        color: #d12231
    }

.site-wrapper-mid h2, .site-wrapper--mid h2 {
    line-height: 1.4
}

.aura-bluetooth {
    padding: 99px 0 125px;
    text-align: center
}

    .aura-bluetooth a {
        text-decoration: underline;
        color: currentColor
    }

.aura-bluetooth__logo {
    max-width: 210px
}

.aura-bluetooth__subtitle {
    padding: 0 30px;
    font-size: 24px;
    font-weight: 300
}

.aura-bluetooth__label {
    font-weight: bold;
    text-transform: uppercase
}

.aura-bluetooth__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 70px auto 60px
}

@media (max-width: 1550px) {
    .aura-bluetooth__container {
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 55px auto 60px
    }
}

@media (max-width: 575px) {
    .aura-bluetooth__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.aura-bluetooth__mobile, .aura-bluetooth__lock {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0
}

    .aura-bluetooth__mobile:after, .aura-bluetooth__lock:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 11%;
        height: 20px;
        width: 30.5%;
        background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset-aura-arrow-right?scl=1&fmt=png-alpha);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .aura-bluetooth__mobile > img, .aura-bluetooth__lock > img {
        width: 100%;
        max-width: 64px;
        margin-top: 45px;
        margin-bottom: 17px
    }

@media (max-width: 1550px) {
    .aura-bluetooth__mobile, .aura-bluetooth__lock {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 20%;
        flex: 0 1 20%
    }

        .aura-bluetooth__mobile > img, .aura-bluetooth__lock > img {
            max-width: 39px;
            margin-top: 0
        }

        .aura-bluetooth__mobile:after, .aura-bluetooth__lock:after {
            top: 26%;
            right: -23%;
            width: 50%;
            background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset-aura-arrow-right-small-2?scl=1&fmt=png-alpha)
        }
}

@media (max-width: 575px) {
    .aura-bluetooth__mobile, .aura-bluetooth__lock {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 200px;
        flex: 1 1 200px
    }

        .aura-bluetooth__mobile:after, .aura-bluetooth__lock:after {
            top: auto;
            bottom: 16%;
            right: 19.5%;
            width: 81px;
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg)
        }
}

.aura-bluetooth__lock:after {
    right: auto;
    left: 11%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg)
}

@media (max-width: 1550px) {
    .aura-bluetooth__lock:after {
        left: -23%
    }
}

@media (max-width: 575px) {
    .aura-bluetooth__lock {
        padding-top: 95px
    }

        .aura-bluetooth__lock:after {
            top: 13%;
            left: auto;
            right: 25.5%;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg)
        }
}

.aura-bluetooth__range {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 550px;
    flex: 0 0 550px
}

    .aura-bluetooth__range:before, .aura-bluetooth__range:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 196px;
        width: 84px;
        background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset-aura-bluetooth-signal-right?scl=1&fmt=png-alpha);
        background-size: 100%;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    .aura-bluetooth__range:after {
        left: auto;
        right: 0;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    .aura-bluetooth__range sub {
        bottom: 0;
        left: 5px;
        font-size: 0.4em;
        letter-spacing: -0.6px
    }

@media (max-width: 1550px) {
    .aura-bluetooth__range {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 290px;
        flex: 0 0 290px
    }

        .aura-bluetooth__range:before, .aura-bluetooth__range:after {
            width: 50px;
            height: 114px
        }

        .aura-bluetooth__range .aura-bluetooth__label {
            max-width: 180px;
            margin: 10px auto
        }
}

@media (max-width: 575px) {
    .aura-bluetooth__range {
        max-width: 160px;
        padding-top: 50px
    }

        .aura-bluetooth__range:before {
            top: -10%;
            left: 33%;
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg)
        }

        .aura-bluetooth__range:after {
            top: auto;
            bottom: -7%;
            left: 35%;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg)
        }
}

.aura-bluetooth__number {
    margin-bottom: -15px;
    font-size: 120px;
    letter-spacing: -8.4px;
    line-height: 1.5
}

@media (max-width: 1550px) {
    .aura-bluetooth__number {
        font-size: 70px
    }
}

.aura-bluetooth > p {
    max-width: 660px;
    margin: 0 auto;
    padding: 0 30px
}

.aura-product-list {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    // grid-template-rows: 1fr;
    margin: 35px 0 140px;
    @media (max-width: 1280px) {
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: 1fr;
      padding: 0 25px
    }
}


@media (max-width: 575px) {
    .aura-product-list {
        padding: 0
    }
}

.aura-product-list__img {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    @media (min-width: 1280px) {
      -ms-grid-column: 2;
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1

    }
}


.aura-product-list__img-item {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    margin: 0 4px;
    overflow: hidden
}

    .aura-product-list__img-item:after {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 100%
    }

    .aura-product-list__img-item:first-of-type {
        -webkit-box-flex: 2;
        -ms-flex: 2 1 100%;
        flex: 2 1 100%;
        margin-bottom: 8px
    }

        .aura-product-list__img-item:first-of-type:after {
            padding-bottom: 76%
        }

    .aura-product-list__img-item img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 100%;
        max-width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

@media (max-width: 1280px) {
    .aura-product-list__img-item:first-of-type:after {
        padding-bottom: 48%
    }

    .aura-product-list__img-item img {
        width: 100%;
        height: auto
    }
}

@media (max-width: 575px) {
    .aura-product-list__img-item:after {
        width: 100%;
        padding-bottom: 104%
    }

    .aura-product-list__img-item:first-of-type:after {
        width: 100%;
        padding-bottom: 78%
    }

    .aura-product-list__img-item img {
        width: auto;
        height: 100%
    }
}

.aura-product-list__list {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  margin-left: 4px;
  padding: 0 50px;
  @media (min-width: 1400px) {
          padding: 0 120px 0 105px

  }
  @media (max-width: 1280px) {
-ms-grid-row: 2;
    margin-top: 8px;
    padding: 35px 75px
  }
  @media (max-width: 575px) {
    padding: 0 25px
  }
}


.aura-product-list__list-item {
    padding: 20px 0
}

@media (min-width: 1300px) {
    .aura-product-list__list-item {
        padding: 40px 0 35px
    }
}

.aura-product-list__list-item:not(:last-child) {
    border-bottom: 1px solid #cdcdcd
}

@media (max-width: 1280px) {
    .aura-product-list__list-item {
        padding: 25px 0 35px
    }
}

@media (max-width: 575px) {
    .aura-product-list__list-item {
        padding: 25px 0 22px
    }

        .aura-product-list__list-item > p {
            font-size: 16px
        }
}

.aura-product-list__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase
}

.aura-app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto 140px
}

@media (max-width: 750px) {
    .aura-app {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.aura-app__phone {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto
}

    .aura-app__phone:first-child {
        margin-right: 50px
    }

    .aura-app__phone:last-child {
        margin-left: 50px
    }

@media (max-width: 750px) {
    .aura-app__phone {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        max-height: 400px;
        margin-right: 0 !important;
        margin-left: 0 !important
    }
}

.aura-app__content {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 540px;
    flex: 0 1 540px;
    margin-top: 40px
}

@media (max-width: 750px) {
    .aura-app__content {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-top: 0;
        text-align: center
    }
}

.aura-app__content > p {
    max-width: 470px;
    line-height: 1.67
}

@media (max-width: 750px) {
    .aura-app__content > p {
        margin: 0 auto
    }
}

.aura-app__title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 900
}

.aura-app__closeup {
    margin-top: 40px
}

@media (max-width: 750px) {
    .app-stores {
        margin: 0 0.5em 1em !important
    }
}

.row--align-buttons .col-md-6 {
    margin-bottom: 4em
}

    .row--align-buttons .col-md-6:last-child {
        margin-bottom: 0
    }

@media (min-width: 768px) {
    .row--align-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

        .row--align-buttons:before {
            content: none
        }

        .row--align-buttons .col-md-6 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 50%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding-bottom: calc(50px + 1em);
            position: relative;
            margin-bottom: 0
        }

            .row--align-buttons .col-md-6 .btn {
                position: absolute;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                bottom: 0;
                width: 100%;
                max-width: 340px
            }
}

.min-wrapper--app-page {
    margin-top: 15px;
    max-width: 100%
}

@media (min-width: 860px) {
    .min-wrapper--app-page {
        margin-top: 100px
    }
}

@media (min-width: 860px) {
    .branding__logo--aura {
        background: transparent url('//images.kwikset.com/is/image/Kwikset/aura-logo?scl=1&fmt=png-alpha') center center no-repeat;
        width: 120px;
        background-size: contain;
        margin: 0 auto;
        height: 0;
        padding-bottom: 31.333333%;
    }
}


.aura-product-list__list--text-content {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex: 1 1 auto;
}

@media (max-width: 1280px) {
    .aura-product-list__list--text-content {
        margin-top: 0
    }
}

@media (min-width: 992px) {
    .aura-product-list__list--text-content {
        padding-left: 1em;
        padding-right: 1em
    }
}

.aura-product-list a {
    color: currentColor
}

.text-container--375 {
    max-width: 375px;
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

.text-container--narrow {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

.kwikset-app__flush-top {
  @media (min-width: 860px) {
    margin-top: -7em;
  }
}

.kwikset-app-icon {
    max-width: 91px;
    margin-top: 1em
}

.product-purchase-list--centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.aura-product-list__img--app-page {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    flex: 1 1 auto;
}

@media (min-width: 1280px) {
    .aura-product-list__img--app-page {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.aura-product-list__img--app-page .aura-product-list__img-item:after {
    padding-bottom: 84.2857%
}

@media (max-width: 1280px) {
    .aura-product-list__img--app-page .aura-product-list__img-item:first-of-type {
        margin: 0
    }
}

.aura-product-list__img--app-page .aura-product-list__img-item:first-of-type:after {
    padding-bottom: 63.8542%
}

@media (min-width: 575px) and (max-width: 1280px) {
    .aura-product-list__img--app-page .aura-product-list__img-item:first-of-type:after {
        padding-bottom: 42.94355%
    }
}

.aura-product-list__img-item--hidden-mobile {
    display: none
}

@media (min-width: 1280px) {
    .aura-product-list__img-item--hidden-mobile {
        display: block
    }
}

.margin-left-auto {
    margin-left: auto
}

.margin-right-auto {
    margin-right: auto
}

p {
    font-weight: 300
}

.section-bg-color--offwhite {
    background-color: #f6f6f6
}

.site-body {
    min-height: 500px
}

.back-to-top {
    position: fixed;
    background: rgba(0,0,0,0.5);
    color: #fff;
    display: block;
    right: 1rem;
    bottom: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    text-align: center;
    line-height: 1;
    font-size: 30px;
    opacity: 0;
    -webkit-transition: opacity 0.25s;
    -o-transition: opacity 0.25s;
    transition: opacity 0.25s
}

.show-back-to-top .back-to-top {
    opacity: 1
}

.back-to-top a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 7px
}

.back-to-top .fa-angle-up {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    left: 50%
}

.image-toggle {
    background: #fff;
    border: 1px solid #d2d2d2;
    padding: 1em;
    -ms-flex-item-align: center;
    align-self: center
}

@media (min-width: 992px) {
    .image-toggle {
        -ms-flex-item-align: start;
        align-self: flex-start
    }
}

.image-toggle__list {
    list-style: none outside;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.image-toggle__item {
    margin-right: 5px;
    max-width: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

    .image-toggle__item:last-child {
        margin-right: 0
    }

.image-toggle__button {
    border: 3px solid transparent;
    padding: 3px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 0.9rem;
    width: 100%
}

    .image-toggle__button:hover {
        border-color: #d2d2d2
    }

.image-toggle__button--selected, .image-toggle__button--selected:hover {
    border-color: #d90114
}

.image-toggle__button img {
    display: block;
    margin: 0 auto 0.5em
}

.finish-switcher {
    background: #f9f9f9;
    padding: 1rem
}

@media (min-width: 992px) {
    .finish-switcher {
        position: -webkit-sticky;
        position: sticky;
        top: 55px;
        left: 0;
        right: 0;
        z-index: 1;
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
}

@media (min-width: 1100px) {
    .finish-switcher {
        padding-left: 4rem;
        padding-right: 4rem
    }
}

.finish-switcher__selected-finish {
    margin: 0;
    font-size: 1rem
}

@media (min-width: 992px) {
    .finish-switcher__selected-finish {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-left: 1.5rem
    }
}

.finish-switcher__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 992px) {
    .finish-switcher__wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.finish-switcher__list {
    list-style: none outside;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 992px) {
    .finish-switcher__list {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.finish-switcher__item {
    margin-right: 0.25em;
    margin-bottom: 0.25em
}

@media (min-width: 768px) {
    .finish-switcher__item {
        margin: 0
    }
}

.finish-switcher__item--selected .finish-switcher__link {
    border-color: #000
}

.finish-switcher__link {
    display: block;
    -webkit-transition: border-color 0.25s;
    -o-transition: border-color 0.25s;
    transition: border-color 0.25s;
    border: 3px solid transparent
}

.finish-switcher__image {
    display: block;
    border: 1px solid #000;
    margin: 2px
}

.finish-switcher--category-browsing .finish-switcher__selected-finish {
    margin: 0 0.5rem 0 0
}

@media (min-width: 992px) {
    .finish-switcher--category-browsing .finish-switcher__selected-finish {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
        flex: 0 0 300px;
        min-width: 300px;
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }
}

.finish-switcher--category-browsing .filters__list {
    margin-bottom: 0
}

@media (min-width: 992px) {
    .finish-switcher--category-browsing .filters__list {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.finish-switcher--category-browsing .filters__item {
    margin-bottom: 0
}

.nav-tabs {
    list-style: none outside;
    margin: 0;
    padding: 0 0.5em;
    border-bottom: 1px solid #ddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem
}

@media (min-width: 415px) {
    .nav-tabs {
        padding: 0 1em
    }
}

.nav-tabs__tab {
    width: 50%;
    text-align: center;
    max-width: 165px
}

@media (min-width: 768px) {
    .nav-tabs__tab {
        max-width: 200px
    }
}

.nav-tabs__tab:first-child {
    margin-right: 1em
}

.nav-tabs__tab--selected .nav-tabs__link {
    background: #fff;
    border: 1px solid #ddd;
    margin-bottom: -1px;
    border-bottom-color: #fff
}

.nav-tabs__link {
    display: block;
    background: #f6f6f6;
    text-decoration: none;
    padding: 1em 0.25em;
    line-height: 1
}

@media (min-width: 415px) {
    .nav-tabs__link {
        padding: 1em
    }
}

.nav-tabs-wrap {
    position: relative;
    overflow: hidden
}

.nav-tabs-wrap__content-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 0.25s, -webkit-transform 0.5s;
    transition: opacity 0.25s, -webkit-transform 0.5s;
    -o-transition: opacity 0.25s, transform 0.5s;
    transition: opacity 0.25s, transform 0.5s;
    transition: opacity 0.25s, transform 0.5s, -webkit-transform 0.5s;
    position: relative;
    z-index: 100
}

.nav-tabs-wrap__content-wrapper--with-padding {
    padding: 1rem
}

@media (min-width: 600px) {
    .nav-tabs-wrap__content-wrapper--with-padding {
        padding: 2rem
    }
}

.nav-tabs-wrap__content-wrapper--hidden {
    opacity: 0;
    -webkit-transform: translate3d(0, 4em, 0);
    transform: translate3d(0, 4em, 0);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1
}

.no-csspositionsticky .fixedsticky {
    position: static
}

.no-csspositionsticky .fixedsticky-off {
    position: static
}

.no-csspositionsticky .fixedsticky-on {
    position: fixed
}

.same-page-nav {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #000;
    color: #fff;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s
}

@media (min-width: 992px) {
    .same-page-nav {
        font-size: 1rem;
        opacity: 1;
        background: #fff;
        color: #000;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        border-bottom: 1px solid #e6e6e6
    }
}

@media (min-width: 1100px) {
    .same-page-nav {
        padding-left: 4rem;
        padding-right: 4rem
    }
}

@media (min-width: 992px) {
    .same-page-nav--product-details {
        top: 60px;
        overflow: hidden
    }

        .same-page-nav--product-details.same-page-nav--anchored {
            overflow: visible
        }
}

@media (min-width: 992px) {
    .same-page-nav--product-thumbnails {
        top: calc(-100px - 1em);
        text-align: center
    }

        .same-page-nav--product-thumbnails .same-page-nav__btn-group {
            -ms-flex-item-align: end;
            align-self: flex-end
        }
}

.same-page-nav__nav-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto
}

.same-page-nav--centered .same-page-nav__btn-group {
    display: none
}

@media (min-width: 992px) {
    .same-page-nav--centered .same-page-nav__nav-wrap {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.same-page-nav--anchored {
    opacity: 1;
    -webkit-box-shadow: 0 1px 7px rgba(0,0,0,0.2);
    box-shadow: 0 1px 7px rgba(0,0,0,0.2)
}

.same-page-nav__product {
    display: none
}

@media (min-width: 992px) {
    .same-page-nav__product {
        display: block;
        position: absolute;
        -webkit-transform: translateY(-60px);
        -ms-transform: translateY(-60px);
        transform: translateY(-60px);
        height: 60px;
        left: 0;
        right: 0;
        top: 0;
        background: #fff;
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
}

@media (min-width: 1100px) {
    .same-page-nav__product {
        padding-left: 4rem;
        padding-right: 4rem
    }
}

.same-page-nav--anchored .same-page-nav__product {
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px)
}

.same-page-nav__product-image {
    padding: 0.4rem;
    border: 1px solid #e6e6e6;
    vertical-align: middle;
    max-height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 1rem
}

.same-page-nav__product-heading {
    margin: 0;
    line-height: 60px;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
    -webkit-transition: opacity 0.25s;
    -o-transition: opacity 0.25s;
    transition: opacity 0.25s;
    font-size: 1.4rem;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    opacity: 0
}

.same-page-nav--anchored .same-page-nav__product-heading {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.same-page-nav__link {
    text-decoration: none;
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #474747;
    margin-right: 3em
}

@media (max-width: 991px) {
    .same-page-nav__item:last-child .same-page-nav__link {
        border-bottom: none
    }
}

@media (min-width: 992px) {
    .same-page-nav__link {
        border-bottom: 4px solid transparent
    }
}

.same-page-nav__item-image {
    display: none
}

@media (min-width: 992px) {
    .same-page-nav__item-image {
        display: block;
        max-width: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em
    }
}

.same-page-nav__item {
    padding-left: 1.5em;
    position: relative
}

@media (min-width: 992px) {
    .same-page-nav__item {
        padding-left: 0
    }
}

.same-page-nav__item:before, .same-page-nav__item:after {
    content: '';
    background: #181818;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%)
}

@media (min-width: 992px) {
    .same-page-nav__item:before, .same-page-nav__item:after {
        content: none
    }
}

.same-page-nav__item:before {
    width: 10px;
    height: 20px
}

.same-page-nav__item:after {
    border: 2px solid #626262;
    border-radius: 100%;
    width: 10px;
    height: 10px
}

.same-page-nav__item--selected {
    font-weight: 700
}

    .same-page-nav__item--selected:after {
        border-color: #fff
    }

@media (min-width: 992px) {
    .same-page-nav__item--selected .same-page-nav__link {
        border-color: #000
    }
}

.same-page-nav__item:last-child .same-page-nav__link {
    margin-right: 0
}

.same-page-nav__text-links {
    margin: 0;
    padding: 0 0 0 1.5em;
    list-style: none outside;
    background: #181818 url("//images.kwikset.com/is/image/Kwikset/border?scl=1&fmt=png-alpha") 1.5em top repeat-y;
    max-height: 0;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    overflow: hidden
}

@media (min-width: 992px) {
    .same-page-nav__text-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: static;
        overflow: visible;
        max-height: none;
        background: transparent none;
        padding: 0
    }
}

.same-page-nav__text-links:before {
    content: '';
    background: #181818;
    position: absolute;
    left: 1.5em;
    top: 0;
    height: 1.5em;
    width: 1px
}

@media (min-width: 992px) {
    .same-page-nav__text-links:before {
        content: none
    }
}

.same-page-nav__text-links:after {
    content: '';
    background: #181818;
    position: absolute;
    left: 1.5em;
    bottom: 0;
    height: 1.5em;
    width: 1px
}

@media (min-width: 992px) {
    .same-page-nav__text-links:after {
        content: none
    }
}

.same-page-nav__text-links .same-page-nav__item {
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    transition-delay: 0.15s
}

@media (min-width: 992px) {
    .same-page-nav__text-links .same-page-nav__item {
        opacity: 1;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s
    }
}

.same-page-nav__btn-drop--open + .same-page-nav__text-links {
    opacity: 1;
    max-height: 600px
}

    .same-page-nav__btn-drop--open + .same-page-nav__text-links .same-page-nav__item {
        opacity: 1
    }

.same-page-nav__btn-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.same-page-nav__btn {
    font-size: 0.8rem;
    white-space: nowrap;
    padding-left: 14px;
    padding-right: 14px
}

@media (min-width: 992px) {
    .same-page-nav__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0.5em;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        padding-right: 12px
    }

        .same-page-nav__btn.btn--transparent {
            color: #000
        }

            .same-page-nav__btn.btn--transparent:hover {
                color: #fff
            }
}

.same-page-nav__btn-drop {
    border: none;
    background: transparent none;
    padding: 14px 32px 14px 50px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative
}

@media (min-width: 992px) {
    .same-page-nav__btn-drop {
        display: none
    }
}

.same-page-nav__btn-drop:before {
    content: '\f078';
    font-family: FontAwesome;
    position: absolute;
    left: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.same-page-nav__btn-drop--open:before {
    content: '\f077'
}

@media (min-width: 768px) {
    .same-page-nav.same-page-nav--sm {
        font-size: 1rem;
        opacity: 1;
        background: #fff;
        color: #000;
        padding-left: 2.65rem;
        padding-right: 2.65rem
    }

        .same-page-nav.same-page-nav--sm .same-page-nav__btn-drop {
            display: none
        }

        .same-page-nav.same-page-nav--sm .same-page-nav__text-links {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            position: static;
            overflow: visible;
            max-height: none;
            background: transparent none;
            padding: 0
        }

            .same-page-nav.same-page-nav--sm .same-page-nav__item:before, .same-page-nav.same-page-nav--sm .same-page-nav__item:after, .same-page-nav.same-page-nav--sm .same-page-nav__text-links:before, .same-page-nav.same-page-nav--sm .same-page-nav__text-links:after {
                content: none
            }

        .same-page-nav.same-page-nav--sm .same-page-nav__item {
            padding-left: 0
        }

        .same-page-nav.same-page-nav--sm .same-page-nav__item--selected .same-page-nav__link {
            border-color: #000
        }

        .same-page-nav.same-page-nav--sm .same-page-nav__link {
            border-bottom: 4px solid transparent
        }

        .same-page-nav.same-page-nav--sm.same-page-nav--centered .same-page-nav__nav-wrap {
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            justify-content: center
        }
}

.content-card-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 375px) {
    .content-card-row--4 .content-card {
        width: 50%
    }
}

@media (min-width: 992px) {
    .content-card-row--4 .content-card {
        width: 25%
    }
}

.content-card-list {
    list-style: none outside;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

    .content-card-list:before, .content-card-list:after {
        content: '';
        width: calc(25% - 0.5rem);
        display: block
    }

    .content-card-list:before {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

.content-card-list__item {
    width: calc(50% - 0.5rem);
    text-align: center
}

.content-card-list--no-text-content .content-card-list__item {
    margin-bottom: 1rem
}

@media (min-width: 768px) {
    .content-card-list--thirds .content-card-list__item {
        width: calc(33.3333% - 0.34rem)
    }
}

@media (min-width: 768px) {
    .content-card-list--thirds.content-card-list--spaced-out .content-card-list__item {
        width: calc(33.3333% - 1rem)
    }
}

@media (min-width: 768px) {
    .content-card-list--fourths .content-card-list__item {
        width: calc(25% - 0.5rem)
    }
}

@media (min-width: 768px) {
    .content-card-list--fourths.content-card-list--spaced-out .content-card-list__item {
        width: calc(25% - 1.5rem);
        margin-left: 0;
        margin-right: 0
    }
}

.content-card {
    margin-bottom: 3.25rem
}

.content-card--with-btn {
    margin-bottom: 5rem
}

    .content-card--with-btn a {
        margin-top: 1.5rem
    }

@media (min-width: 992px) {
    .content-card--with-btn a {
        margin-top: 3rem
    }
}

@media (max-width: 992px) {
    .content-card--limit-width {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto
    }
}

.content-card--no-mb {
    margin-bottom: 0
}

@media (min-width: 992px) {
    .content-card {
        margin-bottom: 0
    }
}

.content-card__link {
    display: block;
    text-decoration: none;
    color: currentColor
}

    .content-card__link:hover .content-card__image-wrap:after {
        border-width: 7px;
        border-color: rgba(255,255,255,0.5)
    }

.content-card__heading {
    display: block;
    line-height: 1;
    margin-top: 1.5em;
    letter-spacing: -0.04em
}

    .content-card__heading.heading--paragraph {
        font-size: 1.05rem;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.6
    }

@media (min-width: 600px) {
    .content-card__heading.heading--paragraph {
        width: 80%
    }
}

@media (min-width: 768px) {
    .content-card__heading {
        font-size: 1.4em;
        font-weight: 800
    }
}

.content-card__heading--extra-mt {
    margin-top: 1rem
}

@media (min-width: 768px) {
    .content-card__heading--extra-mt {
        margin-top: 2.25rem
    }
}

.content-card__image-wrap {
    position: relative;
    background: #fff
}

    .content-card__image-wrap:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border: 1px solid transparent;
        -webkit-transition: border-width 0.25s, border-color 0.25s;
        -o-transition: border-width 0.25s, border-color 0.25s;
        transition: border-width 0.25s, border-color 0.25s
    }

.content-card__image-wrap--no-bg {
    background-color: transparent
}

.content-card__image {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.content-card__image--contain {
    max-height: 390px
}

.content-card__content-wrap {
    padding: 0.5em
}

@media (min-width: 768px) {
    .content-card__content-wrap {
        padding: 1em
    }
}

.content-card__body {
    margin: 0;
    margin-top: 1em
}

@media (min-width: 768px) {
    .content-card__body {
        max-width: 95%
    }
}

.content-card__body--smaller {
    font-size: 0.9rem
}

.content-card__body--wide {
    width: 85% !important
}

.content-card--centered .content-card__body {
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 768px) {
    .content-card--centered .content-card__body {
        max-width: 375px;
        line-height: 1.6
    }
}

.content-card--small-image .text-link {
    display: inline-block
}

.content-card--small-image:hover .content-card__image-wrap {
    border-color: #b1b1b1
}

.content-card--small-image .content-card__image-wrap {
    border: 1px solid #e6e6e6;
    padding-bottom: 65%;
    -webkit-transition: border-color 0.25s;
    -o-transition: border-color 0.25s;
    transition: border-color 0.25s
}

.content-card--small-image .content-card__image-wrap--no-border {
    border: none
}

.content-card--small-image .content-card__content-wrap {
    font-size: 1rem
}

.content-card--small-image .content-card__image {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    max-height: 80%;
    max-width: 90%
}

.content-card--small-image .content-card__heading {
    font-size: 1.2rem;
    font-weight: 700
}

.content-card--full-border {
    font-size: 1rem
}

    .content-card--full-border .content-card__heading {
        font-size: 1rem
    }

@media (min-width: 768px) {
    .content-card--full-border .content-card__heading {
        font-size: 1.2em
    }
}

@media (min-width: 992px) {
    .content-card--full-border .content-card__body {
        min-width: 175px;
        width: 50%
    }
}

.content-card--full-border .content-card__link {
    position: relative;
    padding: 0.5rem
}

@media (min-width: 560px) {
    .content-card--full-border .content-card__link {
        padding: 1rem
    }
}

.content-card--full-border .content-card__link:after {
    -webkit-transition: border-color 0.25s, border-width 0.25s;
    -o-transition: border-color 0.25s, border-width 0.25s;
    transition: border-color 0.25s, border-width 0.25s;
    border: 1px solid transparent;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

.content-card--full-border .content-card__link:hover:after {
    border-color: #e6e6e6;
    border-width: 8px
}

.columns-with-buttons {
    list-style: none;
    margin: 0;
    margin-bottom: -5em;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.columns-with-buttons__column {
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-bottom: 5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (min-width: 768px) {
    .columns-with-buttons__column--thirds {
        width: 50%
    }
}

@media (min-width: 992px) {
    .columns-with-buttons__column--thirds {
        width: 33.33%
    }
}

.columns-with-buttons__column-content {
    width: 100%
}

.kicker {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-size: 0.9rem
}

    .kicker + .heading {
        margin-top: 1rem
    }

.kicker--support {
    font-weight: 400;
    vertical-align: top;
    display: block;
    margin-top: 3px
}

    .kicker--support .fa {
        color: #d90114;
        font-size: 1.5rem;
        line-height: 1;
        float: left;
        margin-right: 0.45em;
        margin-top: -3px
    }

.kicker--no-margin + .heading {
    margin-top: 0
}

@media (min-width: 780px) {
    .kicker {
        font-size: 1rem
    }
}

.padding-top-none {
    padding-top: 0
}

@media (min-width: 320px) {
    .padding-top-none-min-smallest {
        padding-top: 0
    }
}

@media (max-width: 320px) {
    .padding-top-none-max-smallest {
        padding-top: 0
    }
}

@media (min-width: 479px) {
    .padding-top-none-min-smartphones {
        padding-top: 0
    }
}

@media (max-width: 479px) {
    .padding-top-none-max-smartphones {
        padding-top: 0
    }
}

@media (min-width: 768px) {
    .padding-top-none-min-tablet {
        padding-top: 0
    }
}

@media (max-width: 768px) {
    .padding-top-none-max-tablet {
        padding-top: 0
    }
}

@media (min-width: 992px) {
    .padding-top-none-min-desktop {
        padding-top: 0
    }
}

@media (max-width: 992px) {
    .padding-top-none-max-desktop {
        padding-top: 0
    }
}

@media (min-width: 1199px) {
    .padding-top-none-min-largest {
        padding-top: 0
    }
}

@media (max-width: 1199px) {
    .padding-top-none-max-largest {
        padding-top: 0
    }
}

.padding-top-xs {
    padding-top: .5em
}

@media (min-width: 320px) {
    .padding-top-xs-min-smallest {
        padding-top: .5em
    }
}

@media (max-width: 320px) {
    .padding-top-xs-max-smallest {
        padding-top: .5em
    }
}

@media (min-width: 479px) {
    .padding-top-xs-min-smartphones {
        padding-top: .5em
    }
}

@media (max-width: 479px) {
    .padding-top-xs-max-smartphones {
        padding-top: .5em
    }
}

@media (min-width: 768px) {
    .padding-top-xs-min-tablet {
        padding-top: .5em
    }
}

@media (max-width: 768px) {
    .padding-top-xs-max-tablet {
        padding-top: .5em
    }
}

@media (min-width: 992px) {
    .padding-top-xs-min-desktop {
        padding-top: .5em
    }
}

@media (max-width: 992px) {
    .padding-top-xs-max-desktop {
        padding-top: .5em
    }
}

@media (min-width: 1199px) {
    .padding-top-xs-min-largest {
        padding-top: .5em
    }
}

@media (max-width: 1199px) {
    .padding-top-xs-max-largest {
        padding-top: .5em
    }
}

.padding-top-sm {
    padding-top: 1em
}

@media (min-width: 320px) {
    .padding-top-sm-min-smallest {
        padding-top: 1em
    }
}

@media (max-width: 320px) {
    .padding-top-sm-max-smallest {
        padding-top: 1em
    }
}

@media (min-width: 479px) {
    .padding-top-sm-min-smartphones {
        padding-top: 1em
    }
}

@media (max-width: 479px) {
    .padding-top-sm-max-smartphones {
        padding-top: 1em
    }
}

@media (min-width: 768px) {
    .padding-top-sm-min-tablet {
        padding-top: 1em
    }
}

@media (max-width: 768px) {
    .padding-top-sm-max-tablet {
        padding-top: 1em
    }
}

@media (min-width: 992px) {
    .padding-top-sm-min-desktop {
        padding-top: 1em
    }
}

@media (max-width: 992px) {
    .padding-top-sm-max-desktop {
        padding-top: 1em
    }
}

@media (min-width: 1199px) {
    .padding-top-sm-min-largest {
        padding-top: 1em
    }
}

@media (max-width: 1199px) {
    .padding-top-sm-max-largest {
        padding-top: 1em
    }
}

.padding-top-md {
    padding-top: 1.5em
}

@media (min-width: 320px) {
    .padding-top-md-min-smallest {
        padding-top: 1.5em
    }
}

@media (max-width: 320px) {
    .padding-top-md-max-smallest {
        padding-top: 1.5em
    }
}

@media (min-width: 479px) {
    .padding-top-md-min-smartphones {
        padding-top: 1.5em
    }
}

@media (max-width: 479px) {
    .padding-top-md-max-smartphones {
        padding-top: 1.5em
    }
}

@media (min-width: 768px) {
    .padding-top-md-min-tablet {
        padding-top: 1.5em
    }
}

@media (max-width: 768px) {
    .padding-top-md-max-tablet {
        padding-top: 1.5em
    }
}

@media (min-width: 992px) {
    .padding-top-md-min-desktop {
        padding-top: 1.5em
    }
}

@media (max-width: 992px) {
    .padding-top-md-max-desktop {
        padding-top: 1.5em
    }
}

@media (min-width: 1199px) {
    .padding-top-md-min-largest {
        padding-top: 1.5em
    }
}

@media (max-width: 1199px) {
    .padding-top-md-max-largest {
        padding-top: 1.5em
    }
}

.padding-top-lg {
    padding-top: 2em
}

@media (min-width: 320px) {
    .padding-top-lg-min-smallest {
        padding-top: 2em
    }
}

@media (max-width: 320px) {
    .padding-top-lg-max-smallest {
        padding-top: 2em
    }
}

@media (min-width: 479px) {
    .padding-top-lg-min-smartphones {
        padding-top: 2em
    }
}

@media (max-width: 479px) {
    .padding-top-lg-max-smartphones {
        padding-top: 2em
    }
}

@media (min-width: 768px) {
    .padding-top-lg-min-tablet {
        padding-top: 2em
    }
}

@media (max-width: 768px) {
    .padding-top-lg-max-tablet {
        padding-top: 2em
    }
}

@media (min-width: 992px) {
    .padding-top-lg-min-desktop {
        padding-top: 2em
    }
}

@media (max-width: 992px) {
    .padding-top-lg-max-desktop {
        padding-top: 2em
    }
}

@media (min-width: 1199px) {
    .padding-top-lg-min-largest {
        padding-top: 2em
    }
}

@media (max-width: 1199px) {
    .padding-top-lg-max-largest {
        padding-top: 2em
    }
}

.padding-top-xl {
    padding-top: 3em
}

@media (min-width: 320px) {
    .padding-top-xl-min-smallest {
        padding-top: 3em
    }
}

@media (max-width: 320px) {
    .padding-top-xl-max-smallest {
        padding-top: 3em
    }
}

@media (min-width: 479px) {
    .padding-top-xl-min-smartphones {
        padding-top: 3em
    }
}

@media (max-width: 479px) {
    .padding-top-xl-max-smartphones {
        padding-top: 3em
    }
}

@media (min-width: 768px) {
    .padding-top-xl-min-tablet {
        padding-top: 3em
    }
}

@media (max-width: 768px) {
    .padding-top-xl-max-tablet {
        padding-top: 3em
    }
}

@media (min-width: 992px) {
    .padding-top-xl-min-desktop {
        padding-top: 3em
    }
}

@media (max-width: 992px) {
    .padding-top-xl-max-desktop {
        padding-top: 3em
    }
}

@media (min-width: 1199px) {
    .padding-top-xl-min-largest {
        padding-top: 3em
    }
}

@media (max-width: 1199px) {
    .padding-top-xl-max-largest {
        padding-top: 3em
    }
}

.padding-top-xxl {
    padding-top: 4em
}

@media (min-width: 320px) {
    .padding-top-xxl-min-smallest {
        padding-top: 4em
    }
}

@media (max-width: 320px) {
    .padding-top-xxl-max-smallest {
        padding-top: 4em
    }
}

@media (min-width: 479px) {
    .padding-top-xxl-min-smartphones {
        padding-top: 4em
    }
}

@media (max-width: 479px) {
    .padding-top-xxl-max-smartphones {
        padding-top: 4em
    }
}

@media (min-width: 768px) {
    .padding-top-xxl-min-tablet {
        padding-top: 4em
    }
}

@media (max-width: 768px) {
    .padding-top-xxl-max-tablet {
        padding-top: 4em
    }
}

@media (min-width: 992px) {
    .padding-top-xxl-min-desktop {
        padding-top: 4em
    }
}

@media (max-width: 992px) {
    .padding-top-xxl-max-desktop {
        padding-top: 4em
    }
}

@media (min-width: 1199px) {
    .padding-top-xxl-min-largest {
        padding-top: 4em
    }
}

@media (max-width: 1199px) {
    .padding-top-xxl-max-largest {
        padding-top: 4em
    }
}

.padding-top-xxxl {
    padding-top: 5em
}

@media (min-width: 320px) {
    .padding-top-xxxl-min-smallest {
        padding-top: 5em
    }
}

@media (max-width: 320px) {
    .padding-top-xxxl-max-smallest {
        padding-top: 5em
    }
}

@media (min-width: 479px) {
    .padding-top-xxxl-min-smartphones {
        padding-top: 5em
    }
}

@media (max-width: 479px) {
    .padding-top-xxxl-max-smartphones {
        padding-top: 5em
    }
}

@media (min-width: 768px) {
    .padding-top-xxxl-min-tablet {
        padding-top: 5em
    }
}

@media (max-width: 768px) {
    .padding-top-xxxl-max-tablet {
        padding-top: 5em
    }
}

@media (min-width: 992px) {
    .padding-top-xxxl-min-desktop {
        padding-top: 5em
    }
}

@media (max-width: 992px) {
    .padding-top-xxxl-max-desktop {
        padding-top: 5em
    }
}

@media (min-width: 1199px) {
    .padding-top-xxxl-min-largest {
        padding-top: 5em
    }
}

@media (max-width: 1199px) {
    .padding-top-xxxl-max-largest {
        padding-top: 5em
    }
}

.padding-right-none {
    padding-right: 0
}

@media (min-width: 320px) {
    .padding-right-none-min-smallest {
        padding-right: 0
    }
}

@media (max-width: 320px) {
    .padding-right-none-max-smallest {
        padding-right: 0
    }
}

@media (min-width: 479px) {
    .padding-right-none-min-smartphones {
        padding-right: 0
    }
}

@media (max-width: 479px) {
    .padding-right-none-max-smartphones {
        padding-right: 0
    }
}

@media (min-width: 768px) {
    .padding-right-none-min-tablet {
        padding-right: 0
    }
}

@media (max-width: 768px) {
    .padding-right-none-max-tablet {
        padding-right: 0
    }
}

@media (min-width: 992px) {
    .padding-right-none-min-desktop {
        padding-right: 0
    }
}

@media (max-width: 992px) {
    .padding-right-none-max-desktop {
        padding-right: 0
    }
}

@media (min-width: 1199px) {
    .padding-right-none-min-largest {
        padding-right: 0
    }
}

@media (max-width: 1199px) {
    .padding-right-none-max-largest {
        padding-right: 0
    }
}

.padding-right-xs {
    padding-right: .5em
}

@media (min-width: 320px) {
    .padding-right-xs-min-smallest {
        padding-right: .5em
    }
}

@media (max-width: 320px) {
    .padding-right-xs-max-smallest {
        padding-right: .5em
    }
}

@media (min-width: 479px) {
    .padding-right-xs-min-smartphones {
        padding-right: .5em
    }
}

@media (max-width: 479px) {
    .padding-right-xs-max-smartphones {
        padding-right: .5em
    }
}

@media (min-width: 768px) {
    .padding-right-xs-min-tablet {
        padding-right: .5em
    }
}

@media (max-width: 768px) {
    .padding-right-xs-max-tablet {
        padding-right: .5em
    }
}

@media (min-width: 992px) {
    .padding-right-xs-min-desktop {
        padding-right: .5em
    }
}

@media (max-width: 992px) {
    .padding-right-xs-max-desktop {
        padding-right: .5em
    }
}

@media (min-width: 1199px) {
    .padding-right-xs-min-largest {
        padding-right: .5em
    }
}

@media (max-width: 1199px) {
    .padding-right-xs-max-largest {
        padding-right: .5em
    }
}

.padding-right-sm {
    padding-right: 1em
}

@media (min-width: 320px) {
    .padding-right-sm-min-smallest {
        padding-right: 1em
    }
}

@media (max-width: 320px) {
    .padding-right-sm-max-smallest {
        padding-right: 1em
    }
}

@media (min-width: 479px) {
    .padding-right-sm-min-smartphones {
        padding-right: 1em
    }
}

@media (max-width: 479px) {
    .padding-right-sm-max-smartphones {
        padding-right: 1em
    }
}

@media (min-width: 768px) {
    .padding-right-sm-min-tablet {
        padding-right: 1em
    }
}

@media (max-width: 768px) {
    .padding-right-sm-max-tablet {
        padding-right: 1em
    }
}

@media (min-width: 992px) {
    .padding-right-sm-min-desktop {
        padding-right: 1em
    }
}

@media (max-width: 992px) {
    .padding-right-sm-max-desktop {
        padding-right: 1em
    }
}

@media (min-width: 1199px) {
    .padding-right-sm-min-largest {
        padding-right: 1em
    }
}

@media (max-width: 1199px) {
    .padding-right-sm-max-largest {
        padding-right: 1em
    }
}

.padding-right-md {
    padding-right: 1.5em
}

@media (min-width: 320px) {
    .padding-right-md-min-smallest {
        padding-right: 1.5em
    }
}

@media (max-width: 320px) {
    .padding-right-md-max-smallest {
        padding-right: 1.5em
    }
}

@media (min-width: 479px) {
    .padding-right-md-min-smartphones {
        padding-right: 1.5em
    }
}

@media (max-width: 479px) {
    .padding-right-md-max-smartphones {
        padding-right: 1.5em
    }
}

@media (min-width: 768px) {
    .padding-right-md-min-tablet {
        padding-right: 1.5em
    }
}

@media (max-width: 768px) {
    .padding-right-md-max-tablet {
        padding-right: 1.5em
    }
}

@media (min-width: 992px) {
    .padding-right-md-min-desktop {
        padding-right: 1.5em
    }
}

@media (max-width: 992px) {
    .padding-right-md-max-desktop {
        padding-right: 1.5em
    }
}

@media (min-width: 1199px) {
    .padding-right-md-min-largest {
        padding-right: 1.5em
    }
}

@media (max-width: 1199px) {
    .padding-right-md-max-largest {
        padding-right: 1.5em
    }
}

.padding-right-lg {
    padding-right: 2em
}

@media (min-width: 320px) {
    .padding-right-lg-min-smallest {
        padding-right: 2em
    }
}

@media (max-width: 320px) {
    .padding-right-lg-max-smallest {
        padding-right: 2em
    }
}

@media (min-width: 479px) {
    .padding-right-lg-min-smartphones {
        padding-right: 2em
    }
}

@media (max-width: 479px) {
    .padding-right-lg-max-smartphones {
        padding-right: 2em
    }
}

@media (min-width: 768px) {
    .padding-right-lg-min-tablet {
        padding-right: 2em
    }
}

@media (max-width: 768px) {
    .padding-right-lg-max-tablet {
        padding-right: 2em
    }
}

@media (min-width: 992px) {
    .padding-right-lg-min-desktop {
        padding-right: 2em
    }
}

@media (max-width: 992px) {
    .padding-right-lg-max-desktop {
        padding-right: 2em
    }
}

@media (min-width: 1199px) {
    .padding-right-lg-min-largest {
        padding-right: 2em
    }
}

@media (max-width: 1199px) {
    .padding-right-lg-max-largest {
        padding-right: 2em
    }
}

.padding-right-xl {
    padding-right: 3em
}

@media (min-width: 320px) {
    .padding-right-xl-min-smallest {
        padding-right: 3em
    }
}

@media (max-width: 320px) {
    .padding-right-xl-max-smallest {
        padding-right: 3em
    }
}

@media (min-width: 479px) {
    .padding-right-xl-min-smartphones {
        padding-right: 3em
    }
}

@media (max-width: 479px) {
    .padding-right-xl-max-smartphones {
        padding-right: 3em
    }
}

@media (min-width: 768px) {
    .padding-right-xl-min-tablet {
        padding-right: 3em
    }
}

@media (max-width: 768px) {
    .padding-right-xl-max-tablet {
        padding-right: 3em
    }
}

@media (min-width: 992px) {
    .padding-right-xl-min-desktop {
        padding-right: 3em
    }
}

@media (max-width: 992px) {
    .padding-right-xl-max-desktop {
        padding-right: 3em
    }
}

@media (min-width: 1199px) {
    .padding-right-xl-min-largest {
        padding-right: 3em
    }
}

@media (max-width: 1199px) {
    .padding-right-xl-max-largest {
        padding-right: 3em
    }
}

.padding-right-xxl {
    padding-right: 4em
}

@media (min-width: 320px) {
    .padding-right-xxl-min-smallest {
        padding-right: 4em
    }
}

@media (max-width: 320px) {
    .padding-right-xxl-max-smallest {
        padding-right: 4em
    }
}

@media (min-width: 479px) {
    .padding-right-xxl-min-smartphones {
        padding-right: 4em
    }
}

@media (max-width: 479px) {
    .padding-right-xxl-max-smartphones {
        padding-right: 4em
    }
}

@media (min-width: 768px) {
    .padding-right-xxl-min-tablet {
        padding-right: 4em
    }
}

@media (max-width: 768px) {
    .padding-right-xxl-max-tablet {
        padding-right: 4em
    }
}

@media (min-width: 992px) {
    .padding-right-xxl-min-desktop {
        padding-right: 4em
    }
}

@media (max-width: 992px) {
    .padding-right-xxl-max-desktop {
        padding-right: 4em
    }
}

@media (min-width: 1199px) {
    .padding-right-xxl-min-largest {
        padding-right: 4em
    }
}

@media (max-width: 1199px) {
    .padding-right-xxl-max-largest {
        padding-right: 4em
    }
}

.padding-right-xxxl {
    padding-right: 5em
}

@media (min-width: 320px) {
    .padding-right-xxxl-min-smallest {
        padding-right: 5em
    }
}

@media (max-width: 320px) {
    .padding-right-xxxl-max-smallest {
        padding-right: 5em
    }
}

@media (min-width: 479px) {
    .padding-right-xxxl-min-smartphones {
        padding-right: 5em
    }
}

@media (max-width: 479px) {
    .padding-right-xxxl-max-smartphones {
        padding-right: 5em
    }
}

@media (min-width: 768px) {
    .padding-right-xxxl-min-tablet {
        padding-right: 5em
    }
}

@media (max-width: 768px) {
    .padding-right-xxxl-max-tablet {
        padding-right: 5em
    }
}

@media (min-width: 992px) {
    .padding-right-xxxl-min-desktop {
        padding-right: 5em
    }
}

@media (max-width: 992px) {
    .padding-right-xxxl-max-desktop {
        padding-right: 5em
    }
}

@media (min-width: 1199px) {
    .padding-right-xxxl-min-largest {
        padding-right: 5em
    }
}

@media (max-width: 1199px) {
    .padding-right-xxxl-max-largest {
        padding-right: 5em
    }
}

.padding-bottom-none {
    padding-bottom: 0
}

@media (min-width: 320px) {
    .padding-bottom-none-min-smallest {
        padding-bottom: 0
    }
}

@media (max-width: 320px) {
    .padding-bottom-none-max-smallest {
        padding-bottom: 0
    }
}

@media (min-width: 479px) {
    .padding-bottom-none-min-smartphones {
        padding-bottom: 0
    }
}

@media (max-width: 479px) {
    .padding-bottom-none-max-smartphones {
        padding-bottom: 0
    }
}

@media (min-width: 768px) {
    .padding-bottom-none-min-tablet {
        padding-bottom: 0
    }
}

@media (max-width: 768px) {
    .padding-bottom-none-max-tablet {
        padding-bottom: 0
    }
}

@media (min-width: 992px) {
    .padding-bottom-none-min-desktop {
        padding-bottom: 0
    }
}

@media (max-width: 992px) {
    .padding-bottom-none-max-desktop {
        padding-bottom: 0
    }
}

@media (min-width: 1199px) {
    .padding-bottom-none-min-largest {
        padding-bottom: 0
    }
}

@media (max-width: 1199px) {
    .padding-bottom-none-max-largest {
        padding-bottom: 0
    }
}

.padding-bottom-xs {
    padding-bottom: .5em
}

@media (min-width: 320px) {
    .padding-bottom-xs-min-smallest {
        padding-bottom: .5em
    }
}

@media (max-width: 320px) {
    .padding-bottom-xs-max-smallest {
        padding-bottom: .5em
    }
}

@media (min-width: 479px) {
    .padding-bottom-xs-min-smartphones {
        padding-bottom: .5em
    }
}

@media (max-width: 479px) {
    .padding-bottom-xs-max-smartphones {
        padding-bottom: .5em
    }
}

@media (min-width: 768px) {
    .padding-bottom-xs-min-tablet {
        padding-bottom: .5em
    }
}

@media (max-width: 768px) {
    .padding-bottom-xs-max-tablet {
        padding-bottom: .5em
    }
}

@media (min-width: 992px) {
    .padding-bottom-xs-min-desktop {
        padding-bottom: .5em
    }
}

@media (max-width: 992px) {
    .padding-bottom-xs-max-desktop {
        padding-bottom: .5em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-xs-min-largest {
        padding-bottom: .5em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-xs-max-largest {
        padding-bottom: .5em
    }
}

.padding-bottom-sm {
    padding-bottom: 1em
}

@media (min-width: 320px) {
    .padding-bottom-sm-min-smallest {
        padding-bottom: 1em
    }
}

@media (max-width: 320px) {
    .padding-bottom-sm-max-smallest {
        padding-bottom: 1em
    }
}

@media (min-width: 479px) {
    .padding-bottom-sm-min-smartphones {
        padding-bottom: 1em
    }
}

@media (max-width: 479px) {
    .padding-bottom-sm-max-smartphones {
        padding-bottom: 1em
    }
}

@media (min-width: 768px) {
    .padding-bottom-sm-min-tablet {
        padding-bottom: 1em
    }
}

@media (max-width: 768px) {
    .padding-bottom-sm-max-tablet {
        padding-bottom: 1em
    }
}

@media (min-width: 992px) {
    .padding-bottom-sm-min-desktop {
        padding-bottom: 1em
    }
}

@media (max-width: 992px) {
    .padding-bottom-sm-max-desktop {
        padding-bottom: 1em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-sm-min-largest {
        padding-bottom: 1em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-sm-max-largest {
        padding-bottom: 1em
    }
}

.padding-bottom-md {
    padding-bottom: 1.5em
}

@media (min-width: 320px) {
    .padding-bottom-md-min-smallest {
        padding-bottom: 1.5em
    }
}

@media (max-width: 320px) {
    .padding-bottom-md-max-smallest {
        padding-bottom: 1.5em
    }
}

@media (min-width: 479px) {
    .padding-bottom-md-min-smartphones {
        padding-bottom: 1.5em
    }
}

@media (max-width: 479px) {
    .padding-bottom-md-max-smartphones {
        padding-bottom: 1.5em
    }
}

@media (min-width: 768px) {
    .padding-bottom-md-min-tablet {
        padding-bottom: 1.5em
    }
}

@media (max-width: 768px) {
    .padding-bottom-md-max-tablet {
        padding-bottom: 1.5em
    }
}

@media (min-width: 992px) {
    .padding-bottom-md-min-desktop {
        padding-bottom: 1.5em
    }
}

@media (max-width: 992px) {
    .padding-bottom-md-max-desktop {
        padding-bottom: 1.5em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-md-min-largest {
        padding-bottom: 1.5em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-md-max-largest {
        padding-bottom: 1.5em
    }
}

.padding-bottom-lg {
    padding-bottom: 2em
}

@media (min-width: 320px) {
    .padding-bottom-lg-min-smallest {
        padding-bottom: 2em
    }
}

@media (max-width: 320px) {
    .padding-bottom-lg-max-smallest {
        padding-bottom: 2em
    }
}

@media (min-width: 479px) {
    .padding-bottom-lg-min-smartphones {
        padding-bottom: 2em
    }
}

@media (max-width: 479px) {
    .padding-bottom-lg-max-smartphones {
        padding-bottom: 2em
    }
}

@media (min-width: 768px) {
    .padding-bottom-lg-min-tablet {
        padding-bottom: 2em
    }
}

@media (max-width: 768px) {
    .padding-bottom-lg-max-tablet {
        padding-bottom: 2em
    }
}

@media (min-width: 992px) {
    .padding-bottom-lg-min-desktop {
        padding-bottom: 2em
    }
}

@media (max-width: 992px) {
    .padding-bottom-lg-max-desktop {
        padding-bottom: 2em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-lg-min-largest {
        padding-bottom: 2em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-lg-max-largest {
        padding-bottom: 2em
    }
}

.padding-bottom-xl {
    padding-bottom: 3em
}

@media (min-width: 320px) {
    .padding-bottom-xl-min-smallest {
        padding-bottom: 3em
    }
}

@media (max-width: 320px) {
    .padding-bottom-xl-max-smallest {
        padding-bottom: 3em
    }
}

@media (min-width: 479px) {
    .padding-bottom-xl-min-smartphones {
        padding-bottom: 3em
    }
}

@media (max-width: 479px) {
    .padding-bottom-xl-max-smartphones {
        padding-bottom: 3em
    }
}

@media (min-width: 768px) {
    .padding-bottom-xl-min-tablet {
        padding-bottom: 3em
    }
}

@media (max-width: 768px) {
    .padding-bottom-xl-max-tablet {
        padding-bottom: 3em
    }
}

@media (min-width: 992px) {
    .padding-bottom-xl-min-desktop {
        padding-bottom: 3em
    }
}

@media (max-width: 992px) {
    .padding-bottom-xl-max-desktop {
        padding-bottom: 3em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-xl-min-largest {
        padding-bottom: 3em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-xl-max-largest {
        padding-bottom: 3em
    }
}

.padding-bottom-xxl {
    padding-bottom: 4em
}

@media (min-width: 320px) {
    .padding-bottom-xxl-min-smallest {
        padding-bottom: 4em
    }
}

@media (max-width: 320px) {
    .padding-bottom-xxl-max-smallest {
        padding-bottom: 4em
    }
}

@media (min-width: 479px) {
    .padding-bottom-xxl-min-smartphones {
        padding-bottom: 4em
    }
}

@media (max-width: 479px) {
    .padding-bottom-xxl-max-smartphones {
        padding-bottom: 4em
    }
}

@media (min-width: 768px) {
    .padding-bottom-xxl-min-tablet {
        padding-bottom: 4em
    }
}

@media (max-width: 768px) {
    .padding-bottom-xxl-max-tablet {
        padding-bottom: 4em
    }
}

@media (min-width: 992px) {
    .padding-bottom-xxl-min-desktop {
        padding-bottom: 4em
    }
}

@media (max-width: 992px) {
    .padding-bottom-xxl-max-desktop {
        padding-bottom: 4em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-xxl-min-largest {
        padding-bottom: 4em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-xxl-max-largest {
        padding-bottom: 4em
    }
}

.padding-bottom-xxxl {
    padding-bottom: 5em
}

@media (min-width: 320px) {
    .padding-bottom-xxxl-min-smallest {
        padding-bottom: 5em
    }
}

@media (max-width: 320px) {
    .padding-bottom-xxxl-max-smallest {
        padding-bottom: 5em
    }
}

@media (min-width: 479px) {
    .padding-bottom-xxxl-min-smartphones {
        padding-bottom: 5em
    }
}

@media (max-width: 479px) {
    .padding-bottom-xxxl-max-smartphones {
        padding-bottom: 5em
    }
}

@media (min-width: 768px) {
    .padding-bottom-xxxl-min-tablet {
        padding-bottom: 5em
    }
}

@media (max-width: 768px) {
    .padding-bottom-xxxl-max-tablet {
        padding-bottom: 5em
    }
}

@media (min-width: 992px) {
    .padding-bottom-xxxl-min-desktop {
        padding-bottom: 5em
    }
}

@media (max-width: 992px) {
    .padding-bottom-xxxl-max-desktop {
        padding-bottom: 5em
    }
}

@media (min-width: 1199px) {
    .padding-bottom-xxxl-min-largest {
        padding-bottom: 5em
    }
}

@media (max-width: 1199px) {
    .padding-bottom-xxxl-max-largest {
        padding-bottom: 5em
    }
}

.padding-left-none {
    padding-left: 0
}

@media (min-width: 320px) {
    .padding-left-none-min-smallest {
        padding-left: 0
    }
}

@media (max-width: 320px) {
    .padding-left-none-max-smallest {
        padding-left: 0
    }
}

@media (min-width: 479px) {
    .padding-left-none-min-smartphones {
        padding-left: 0
    }
}

@media (max-width: 479px) {
    .padding-left-none-max-smartphones {
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .padding-left-none-min-tablet {
        padding-left: 0
    }
}

@media (max-width: 768px) {
    .padding-left-none-max-tablet {
        padding-left: 0
    }
}

@media (min-width: 992px) {
    .padding-left-none-min-desktop {
        padding-left: 0
    }
}

@media (max-width: 992px) {
    .padding-left-none-max-desktop {
        padding-left: 0
    }
}

@media (min-width: 1199px) {
    .padding-left-none-min-largest {
        padding-left: 0
    }
}

@media (max-width: 1199px) {
    .padding-left-none-max-largest {
        padding-left: 0
    }
}

.padding-left-xs {
    padding-left: .5em
}

@media (min-width: 320px) {
    .padding-left-xs-min-smallest {
        padding-left: .5em
    }
}

@media (max-width: 320px) {
    .padding-left-xs-max-smallest {
        padding-left: .5em
    }
}

@media (min-width: 479px) {
    .padding-left-xs-min-smartphones {
        padding-left: .5em
    }
}

@media (max-width: 479px) {
    .padding-left-xs-max-smartphones {
        padding-left: .5em
    }
}

@media (min-width: 768px) {
    .padding-left-xs-min-tablet {
        padding-left: .5em
    }
}

@media (max-width: 768px) {
    .padding-left-xs-max-tablet {
        padding-left: .5em
    }
}

@media (min-width: 992px) {
    .padding-left-xs-min-desktop {
        padding-left: .5em
    }
}

@media (max-width: 992px) {
    .padding-left-xs-max-desktop {
        padding-left: .5em
    }
}

@media (min-width: 1199px) {
    .padding-left-xs-min-largest {
        padding-left: .5em
    }
}

@media (max-width: 1199px) {
    .padding-left-xs-max-largest {
        padding-left: .5em
    }
}

.padding-left-sm {
    padding-left: 1em
}

@media (min-width: 320px) {
    .padding-left-sm-min-smallest {
        padding-left: 1em
    }
}

@media (max-width: 320px) {
    .padding-left-sm-max-smallest {
        padding-left: 1em
    }
}

@media (min-width: 479px) {
    .padding-left-sm-min-smartphones {
        padding-left: 1em
    }
}

@media (max-width: 479px) {
    .padding-left-sm-max-smartphones {
        padding-left: 1em
    }
}

@media (min-width: 768px) {
    .padding-left-sm-min-tablet {
        padding-left: 1em
    }
}

@media (max-width: 768px) {
    .padding-left-sm-max-tablet {
        padding-left: 1em
    }
}

@media (min-width: 992px) {
    .padding-left-sm-min-desktop {
        padding-left: 1em
    }
}

@media (max-width: 992px) {
    .padding-left-sm-max-desktop {
        padding-left: 1em
    }
}

@media (min-width: 1199px) {
    .padding-left-sm-min-largest {
        padding-left: 1em
    }
}

@media (max-width: 1199px) {
    .padding-left-sm-max-largest {
        padding-left: 1em
    }
}

.padding-left-md {
    padding-left: 1.5em
}

@media (min-width: 320px) {
    .padding-left-md-min-smallest {
        padding-left: 1.5em
    }
}

@media (max-width: 320px) {
    .padding-left-md-max-smallest {
        padding-left: 1.5em
    }
}

@media (min-width: 479px) {
    .padding-left-md-min-smartphones {
        padding-left: 1.5em
    }
}

@media (max-width: 479px) {
    .padding-left-md-max-smartphones {
        padding-left: 1.5em
    }
}

@media (min-width: 768px) {
    .padding-left-md-min-tablet {
        padding-left: 1.5em
    }
}

@media (max-width: 768px) {
    .padding-left-md-max-tablet {
        padding-left: 1.5em
    }
}

@media (min-width: 992px) {
    .padding-left-md-min-desktop {
        padding-left: 1.5em
    }
}

@media (max-width: 992px) {
    .padding-left-md-max-desktop {
        padding-left: 1.5em
    }
}

@media (min-width: 1199px) {
    .padding-left-md-min-largest {
        padding-left: 1.5em
    }
}

@media (max-width: 1199px) {
    .padding-left-md-max-largest {
        padding-left: 1.5em
    }
}

.padding-left-lg {
    padding-left: 2em
}

@media (min-width: 320px) {
    .padding-left-lg-min-smallest {
        padding-left: 2em
    }
}

@media (max-width: 320px) {
    .padding-left-lg-max-smallest {
        padding-left: 2em
    }
}

@media (min-width: 479px) {
    .padding-left-lg-min-smartphones {
        padding-left: 2em
    }
}

@media (max-width: 479px) {
    .padding-left-lg-max-smartphones {
        padding-left: 2em
    }
}

@media (min-width: 768px) {
    .padding-left-lg-min-tablet {
        padding-left: 2em
    }
}

@media (max-width: 768px) {
    .padding-left-lg-max-tablet {
        padding-left: 2em
    }
}

@media (min-width: 992px) {
    .padding-left-lg-min-desktop {
        padding-left: 2em
    }
}

@media (max-width: 992px) {
    .padding-left-lg-max-desktop {
        padding-left: 2em
    }
}

@media (min-width: 1199px) {
    .padding-left-lg-min-largest {
        padding-left: 2em
    }
}

@media (max-width: 1199px) {
    .padding-left-lg-max-largest {
        padding-left: 2em
    }
}

.padding-left-xl {
    padding-left: 3em
}

@media (min-width: 320px) {
    .padding-left-xl-min-smallest {
        padding-left: 3em
    }
}

@media (max-width: 320px) {
    .padding-left-xl-max-smallest {
        padding-left: 3em
    }
}

@media (min-width: 479px) {
    .padding-left-xl-min-smartphones {
        padding-left: 3em
    }
}

@media (max-width: 479px) {
    .padding-left-xl-max-smartphones {
        padding-left: 3em
    }
}

@media (min-width: 768px) {
    .padding-left-xl-min-tablet {
        padding-left: 3em
    }
}

@media (max-width: 768px) {
    .padding-left-xl-max-tablet {
        padding-left: 3em
    }
}

@media (min-width: 992px) {
    .padding-left-xl-min-desktop {
        padding-left: 3em
    }
}

@media (max-width: 992px) {
    .padding-left-xl-max-desktop {
        padding-left: 3em
    }
}

@media (min-width: 1199px) {
    .padding-left-xl-min-largest {
        padding-left: 3em
    }
}

@media (max-width: 1199px) {
    .padding-left-xl-max-largest {
        padding-left: 3em
    }
}

.padding-left-xxl {
    padding-left: 4em
}

@media (min-width: 320px) {
    .padding-left-xxl-min-smallest {
        padding-left: 4em
    }
}

@media (max-width: 320px) {
    .padding-left-xxl-max-smallest {
        padding-left: 4em
    }
}

@media (min-width: 479px) {
    .padding-left-xxl-min-smartphones {
        padding-left: 4em
    }
}

@media (max-width: 479px) {
    .padding-left-xxl-max-smartphones {
        padding-left: 4em
    }
}

@media (min-width: 768px) {
    .padding-left-xxl-min-tablet {
        padding-left: 4em
    }
}

@media (max-width: 768px) {
    .padding-left-xxl-max-tablet {
        padding-left: 4em
    }
}

@media (min-width: 992px) {
    .padding-left-xxl-min-desktop {
        padding-left: 4em
    }
}

@media (max-width: 992px) {
    .padding-left-xxl-max-desktop {
        padding-left: 4em
    }
}

@media (min-width: 1199px) {
    .padding-left-xxl-min-largest {
        padding-left: 4em
    }
}

@media (max-width: 1199px) {
    .padding-left-xxl-max-largest {
        padding-left: 4em
    }
}

.padding-left-xxxl {
    padding-left: 5em
}

@media (min-width: 320px) {
    .padding-left-xxxl-min-smallest {
        padding-left: 5em
    }
}

@media (max-width: 320px) {
    .padding-left-xxxl-max-smallest {
        padding-left: 5em
    }
}

@media (min-width: 479px) {
    .padding-left-xxxl-min-smartphones {
        padding-left: 5em
    }
}

@media (max-width: 479px) {
    .padding-left-xxxl-max-smartphones {
        padding-left: 5em
    }
}

@media (min-width: 768px) {
    .padding-left-xxxl-min-tablet {
        padding-left: 5em
    }
}

@media (max-width: 768px) {
    .padding-left-xxxl-max-tablet {
        padding-left: 5em
    }
}

@media (min-width: 992px) {
    .padding-left-xxxl-min-desktop {
        padding-left: 5em
    }
}

@media (max-width: 992px) {
    .padding-left-xxxl-max-desktop {
        padding-left: 5em
    }
}

@media (min-width: 1199px) {
    .padding-left-xxxl-min-largest {
        padding-left: 5em
    }
}

@media (max-width: 1199px) {
    .padding-left-xxxl-max-largest {
        padding-left: 5em
    }
}

.margin-top-none {
    margin-top: 0
}

@media (min-width: 320px) {
    .margin-top-none-min-smallest {
        margin-top: 0
    }
}

@media (max-width: 320px) {
    .margin-top-none-max-smallest {
        margin-top: 0
    }
}

@media (min-width: 479px) {
    .margin-top-none-min-smartphones {
        margin-top: 0
    }
}

@media (max-width: 479px) {
    .margin-top-none-max-smartphones {
        margin-top: 0
    }
}

@media (min-width: 768px) {
    .margin-top-none-min-tablet {
        margin-top: 0
    }
}

@media (max-width: 768px) {
    .margin-top-none-max-tablet {
        margin-top: 0
    }
}

@media (min-width: 992px) {
    .margin-top-none-min-desktop {
        margin-top: 0
    }
}

@media (max-width: 992px) {
    .margin-top-none-max-desktop {
        margin-top: 0
    }
}

@media (min-width: 1199px) {
    .margin-top-none-min-largest {
        margin-top: 0
    }
}

@media (max-width: 1199px) {
    .margin-top-none-max-largest {
        margin-top: 0
    }
}

.margin-top-xs {
    margin-top: .5em
}

@media (min-width: 320px) {
    .margin-top-xs-min-smallest {
        margin-top: .5em
    }
}

@media (max-width: 320px) {
    .margin-top-xs-max-smallest {
        margin-top: .5em
    }
}

@media (min-width: 479px) {
    .margin-top-xs-min-smartphones {
        margin-top: .5em
    }
}

@media (max-width: 479px) {
    .margin-top-xs-max-smartphones {
        margin-top: .5em
    }
}

@media (min-width: 768px) {
    .margin-top-xs-min-tablet {
        margin-top: .5em
    }
}

@media (max-width: 768px) {
    .margin-top-xs-max-tablet {
        margin-top: .5em
    }
}

@media (min-width: 992px) {
    .margin-top-xs-min-desktop {
        margin-top: .5em
    }
}

@media (max-width: 992px) {
    .margin-top-xs-max-desktop {
        margin-top: .5em
    }
}

@media (min-width: 1199px) {
    .margin-top-xs-min-largest {
        margin-top: .5em
    }
}

@media (max-width: 1199px) {
    .margin-top-xs-max-largest {
        margin-top: .5em
    }
}

.margin-top-sm {
    margin-top: 1em
}

@media (min-width: 320px) {
    .margin-top-sm-min-smallest {
        margin-top: 1em
    }
}

@media (max-width: 320px) {
    .margin-top-sm-max-smallest {
        margin-top: 1em
    }
}

@media (min-width: 479px) {
    .margin-top-sm-min-smartphones {
        margin-top: 1em
    }
}

@media (max-width: 479px) {
    .margin-top-sm-max-smartphones {
        margin-top: 1em
    }
}

@media (min-width: 768px) {
    .margin-top-sm-min-tablet {
        margin-top: 1em
    }
}

@media (max-width: 768px) {
    .margin-top-sm-max-tablet {
        margin-top: 1em
    }
}

@media (min-width: 992px) {
    .margin-top-sm-min-desktop {
        margin-top: 1em
    }
}

@media (max-width: 992px) {
    .margin-top-sm-max-desktop {
        margin-top: 1em
    }
}

@media (min-width: 1199px) {
    .margin-top-sm-min-largest {
        margin-top: 1em
    }
}

@media (max-width: 1199px) {
    .margin-top-sm-max-largest {
        margin-top: 1em
    }
}

.margin-top-md {
    margin-top: 1.5em
}

@media (min-width: 320px) {
    .margin-top-md-min-smallest {
        margin-top: 1.5em
    }
}

@media (max-width: 320px) {
    .margin-top-md-max-smallest {
        margin-top: 1.5em
    }
}

@media (min-width: 479px) {
    .margin-top-md-min-smartphones {
        margin-top: 1.5em
    }
}

@media (max-width: 479px) {
    .margin-top-md-max-smartphones {
        margin-top: 1.5em
    }
}

@media (min-width: 768px) {
    .margin-top-md-min-tablet {
        margin-top: 1.5em
    }
}

@media (max-width: 768px) {
    .margin-top-md-max-tablet {
        margin-top: 1.5em
    }
}

@media (min-width: 992px) {
    .margin-top-md-min-desktop {
        margin-top: 1.5em
    }
}

@media (max-width: 992px) {
    .margin-top-md-max-desktop {
        margin-top: 1.5em
    }
}

@media (min-width: 1199px) {
    .margin-top-md-min-largest {
        margin-top: 1.5em
    }
}

@media (max-width: 1199px) {
    .margin-top-md-max-largest {
        margin-top: 1.5em
    }
}

.margin-top-lg {
    margin-top: 2em
}

@media (min-width: 320px) {
    .margin-top-lg-min-smallest {
        margin-top: 2em
    }
}

@media (max-width: 320px) {
    .margin-top-lg-max-smallest {
        margin-top: 2em
    }
}

@media (min-width: 479px) {
    .margin-top-lg-min-smartphones {
        margin-top: 2em
    }
}

@media (max-width: 479px) {
    .margin-top-lg-max-smartphones {
        margin-top: 2em
    }
}

@media (min-width: 768px) {
    .margin-top-lg-min-tablet {
        margin-top: 2em
    }
}

@media (max-width: 768px) {
    .margin-top-lg-max-tablet {
        margin-top: 2em
    }
}

@media (min-width: 992px) {
    .margin-top-lg-min-desktop {
        margin-top: 2em
    }
}

@media (max-width: 992px) {
    .margin-top-lg-max-desktop {
        margin-top: 2em
    }
}

@media (min-width: 1199px) {
    .margin-top-lg-min-largest {
        margin-top: 2em
    }
}

@media (max-width: 1199px) {
    .margin-top-lg-max-largest {
        margin-top: 2em
    }
}

.margin-top-xl {
    margin-top: 3em
}

@media (min-width: 320px) {
    .margin-top-xl-min-smallest {
        margin-top: 3em
    }
}

@media (max-width: 320px) {
    .margin-top-xl-max-smallest {
        margin-top: 3em
    }
}

@media (min-width: 479px) {
    .margin-top-xl-min-smartphones {
        margin-top: 3em
    }
}

@media (max-width: 479px) {
    .margin-top-xl-max-smartphones {
        margin-top: 3em
    }
}

@media (min-width: 768px) {
    .margin-top-xl-min-tablet {
        margin-top: 3em
    }
}

@media (max-width: 768px) {
    .margin-top-xl-max-tablet {
        margin-top: 3em
    }
}

@media (min-width: 992px) {
    .margin-top-xl-min-desktop {
        margin-top: 3em
    }
}

@media (max-width: 992px) {
    .margin-top-xl-max-desktop {
        margin-top: 3em
    }
}

@media (min-width: 1199px) {
    .margin-top-xl-min-largest {
        margin-top: 3em
    }
}

@media (max-width: 1199px) {
    .margin-top-xl-max-largest {
        margin-top: 3em
    }
}

.margin-top-xxl {
    margin-top: 4em
}

@media (min-width: 320px) {
    .margin-top-xxl-min-smallest {
        margin-top: 4em
    }
}

@media (max-width: 320px) {
    .margin-top-xxl-max-smallest {
        margin-top: 4em
    }
}

@media (min-width: 479px) {
    .margin-top-xxl-min-smartphones {
        margin-top: 4em
    }
}

@media (max-width: 479px) {
    .margin-top-xxl-max-smartphones {
        margin-top: 4em
    }
}

@media (min-width: 768px) {
    .margin-top-xxl-min-tablet {
        margin-top: 4em
    }
}

@media (max-width: 768px) {
    .margin-top-xxl-max-tablet {
        margin-top: 4em
    }
}

@media (min-width: 992px) {
    .margin-top-xxl-min-desktop {
        margin-top: 4em
    }
}

@media (max-width: 992px) {
    .margin-top-xxl-max-desktop {
        margin-top: 4em
    }
}

@media (min-width: 1199px) {
    .margin-top-xxl-min-largest {
        margin-top: 4em
    }
}

@media (max-width: 1199px) {
    .margin-top-xxl-max-largest {
        margin-top: 4em
    }
}

.margin-top-xxxl {
    margin-top: 5em
}

@media (min-width: 320px) {
    .margin-top-xxxl-min-smallest {
        margin-top: 5em
    }
}

@media (max-width: 320px) {
    .margin-top-xxxl-max-smallest {
        margin-top: 5em
    }
}

@media (min-width: 479px) {
    .margin-top-xxxl-min-smartphones {
        margin-top: 5em
    }
}

@media (max-width: 479px) {
    .margin-top-xxxl-max-smartphones {
        margin-top: 5em
    }
}

@media (min-width: 768px) {
    .margin-top-xxxl-min-tablet {
        margin-top: 5em
    }
}

@media (max-width: 768px) {
    .margin-top-xxxl-max-tablet {
        margin-top: 5em
    }
}

@media (min-width: 992px) {
    .margin-top-xxxl-min-desktop {
        margin-top: 5em
    }
}

@media (max-width: 992px) {
    .margin-top-xxxl-max-desktop {
        margin-top: 5em
    }
}

@media (min-width: 1199px) {
    .margin-top-xxxl-min-largest {
        margin-top: 5em
    }
}

@media (max-width: 1199px) {
    .margin-top-xxxl-max-largest {
        margin-top: 5em
    }
}

.margin-right-none {
    margin-right: 0
}

@media (min-width: 320px) {
    .margin-right-none-min-smallest {
        margin-right: 0
    }
}

@media (max-width: 320px) {
    .margin-right-none-max-smallest {
        margin-right: 0
    }
}

@media (min-width: 479px) {
    .margin-right-none-min-smartphones {
        margin-right: 0
    }
}

@media (max-width: 479px) {
    .margin-right-none-max-smartphones {
        margin-right: 0
    }
}

@media (min-width: 768px) {
    .margin-right-none-min-tablet {
        margin-right: 0
    }
}

@media (max-width: 768px) {
    .margin-right-none-max-tablet {
        margin-right: 0
    }
}

@media (min-width: 992px) {
    .margin-right-none-min-desktop {
        margin-right: 0
    }
}

@media (max-width: 992px) {
    .margin-right-none-max-desktop {
        margin-right: 0
    }
}

@media (min-width: 1199px) {
    .margin-right-none-min-largest {
        margin-right: 0
    }
}

@media (max-width: 1199px) {
    .margin-right-none-max-largest {
        margin-right: 0
    }
}

.margin-right-xs {
    margin-right: .5em
}

@media (min-width: 320px) {
    .margin-right-xs-min-smallest {
        margin-right: .5em
    }
}

@media (max-width: 320px) {
    .margin-right-xs-max-smallest {
        margin-right: .5em
    }
}

@media (min-width: 479px) {
    .margin-right-xs-min-smartphones {
        margin-right: .5em
    }
}

@media (max-width: 479px) {
    .margin-right-xs-max-smartphones {
        margin-right: .5em
    }
}

@media (min-width: 768px) {
    .margin-right-xs-min-tablet {
        margin-right: .5em
    }
}

@media (max-width: 768px) {
    .margin-right-xs-max-tablet {
        margin-right: .5em
    }
}

@media (min-width: 992px) {
    .margin-right-xs-min-desktop {
        margin-right: .5em
    }
}

@media (max-width: 992px) {
    .margin-right-xs-max-desktop {
        margin-right: .5em
    }
}

@media (min-width: 1199px) {
    .margin-right-xs-min-largest {
        margin-right: .5em
    }
}

@media (max-width: 1199px) {
    .margin-right-xs-max-largest {
        margin-right: .5em
    }
}

.margin-right-sm {
    margin-right: 1em
}

@media (min-width: 320px) {
    .margin-right-sm-min-smallest {
        margin-right: 1em
    }
}

@media (max-width: 320px) {
    .margin-right-sm-max-smallest {
        margin-right: 1em
    }
}

@media (min-width: 479px) {
    .margin-right-sm-min-smartphones {
        margin-right: 1em
    }
}

@media (max-width: 479px) {
    .margin-right-sm-max-smartphones {
        margin-right: 1em
    }
}

@media (min-width: 768px) {
    .margin-right-sm-min-tablet {
        margin-right: 1em
    }
}

@media (max-width: 768px) {
    .margin-right-sm-max-tablet {
        margin-right: 1em
    }
}

@media (min-width: 992px) {
    .margin-right-sm-min-desktop {
        margin-right: 1em
    }
}

@media (max-width: 992px) {
    .margin-right-sm-max-desktop {
        margin-right: 1em
    }
}

@media (min-width: 1199px) {
    .margin-right-sm-min-largest {
        margin-right: 1em
    }
}

@media (max-width: 1199px) {
    .margin-right-sm-max-largest {
        margin-right: 1em
    }
}

.margin-right-md {
    margin-right: 1.5em
}

@media (min-width: 320px) {
    .margin-right-md-min-smallest {
        margin-right: 1.5em
    }
}

@media (max-width: 320px) {
    .margin-right-md-max-smallest {
        margin-right: 1.5em
    }
}

@media (min-width: 479px) {
    .margin-right-md-min-smartphones {
        margin-right: 1.5em
    }
}

@media (max-width: 479px) {
    .margin-right-md-max-smartphones {
        margin-right: 1.5em
    }
}

@media (min-width: 768px) {
    .margin-right-md-min-tablet {
        margin-right: 1.5em
    }
}

@media (max-width: 768px) {
    .margin-right-md-max-tablet {
        margin-right: 1.5em
    }
}

@media (min-width: 992px) {
    .margin-right-md-min-desktop {
        margin-right: 1.5em
    }
}

@media (max-width: 992px) {
    .margin-right-md-max-desktop {
        margin-right: 1.5em
    }
}

@media (min-width: 1199px) {
    .margin-right-md-min-largest {
        margin-right: 1.5em
    }
}

@media (max-width: 1199px) {
    .margin-right-md-max-largest {
        margin-right: 1.5em
    }
}

.margin-right-lg {
    margin-right: 2em
}

@media (min-width: 320px) {
    .margin-right-lg-min-smallest {
        margin-right: 2em
    }
}

@media (max-width: 320px) {
    .margin-right-lg-max-smallest {
        margin-right: 2em
    }
}

@media (min-width: 479px) {
    .margin-right-lg-min-smartphones {
        margin-right: 2em
    }
}

@media (max-width: 479px) {
    .margin-right-lg-max-smartphones {
        margin-right: 2em
    }
}

@media (min-width: 768px) {
    .margin-right-lg-min-tablet {
        margin-right: 2em
    }
}

@media (max-width: 768px) {
    .margin-right-lg-max-tablet {
        margin-right: 2em
    }
}

@media (min-width: 992px) {
    .margin-right-lg-min-desktop {
        margin-right: 2em
    }
}

@media (max-width: 992px) {
    .margin-right-lg-max-desktop {
        margin-right: 2em
    }
}

@media (min-width: 1199px) {
    .margin-right-lg-min-largest {
        margin-right: 2em
    }
}

@media (max-width: 1199px) {
    .margin-right-lg-max-largest {
        margin-right: 2em
    }
}

.margin-right-xl {
    margin-right: 3em
}

@media (min-width: 320px) {
    .margin-right-xl-min-smallest {
        margin-right: 3em
    }
}

@media (max-width: 320px) {
    .margin-right-xl-max-smallest {
        margin-right: 3em
    }
}

@media (min-width: 479px) {
    .margin-right-xl-min-smartphones {
        margin-right: 3em
    }
}

@media (max-width: 479px) {
    .margin-right-xl-max-smartphones {
        margin-right: 3em
    }
}

@media (min-width: 768px) {
    .margin-right-xl-min-tablet {
        margin-right: 3em
    }
}

@media (max-width: 768px) {
    .margin-right-xl-max-tablet {
        margin-right: 3em
    }
}

@media (min-width: 992px) {
    .margin-right-xl-min-desktop {
        margin-right: 3em
    }
}

@media (max-width: 992px) {
    .margin-right-xl-max-desktop {
        margin-right: 3em
    }
}

@media (min-width: 1199px) {
    .margin-right-xl-min-largest {
        margin-right: 3em
    }
}

@media (max-width: 1199px) {
    .margin-right-xl-max-largest {
        margin-right: 3em
    }
}

.margin-right-xxl {
    margin-right: 4em
}

@media (min-width: 320px) {
    .margin-right-xxl-min-smallest {
        margin-right: 4em
    }
}

@media (max-width: 320px) {
    .margin-right-xxl-max-smallest {
        margin-right: 4em
    }
}

@media (min-width: 479px) {
    .margin-right-xxl-min-smartphones {
        margin-right: 4em
    }
}

@media (max-width: 479px) {
    .margin-right-xxl-max-smartphones {
        margin-right: 4em
    }
}

@media (min-width: 768px) {
    .margin-right-xxl-min-tablet {
        margin-right: 4em
    }
}

@media (max-width: 768px) {
    .margin-right-xxl-max-tablet {
        margin-right: 4em
    }
}

@media (min-width: 992px) {
    .margin-right-xxl-min-desktop {
        margin-right: 4em
    }
}

@media (max-width: 992px) {
    .margin-right-xxl-max-desktop {
        margin-right: 4em
    }
}

@media (min-width: 1199px) {
    .margin-right-xxl-min-largest {
        margin-right: 4em
    }
}

@media (max-width: 1199px) {
    .margin-right-xxl-max-largest {
        margin-right: 4em
    }
}

.margin-right-xxxl {
    margin-right: 5em
}

@media (min-width: 320px) {
    .margin-right-xxxl-min-smallest {
        margin-right: 5em
    }
}

@media (max-width: 320px) {
    .margin-right-xxxl-max-smallest {
        margin-right: 5em
    }
}

@media (min-width: 479px) {
    .margin-right-xxxl-min-smartphones {
        margin-right: 5em
    }
}

@media (max-width: 479px) {
    .margin-right-xxxl-max-smartphones {
        margin-right: 5em
    }
}

@media (min-width: 768px) {
    .margin-right-xxxl-min-tablet {
        margin-right: 5em
    }
}

@media (max-width: 768px) {
    .margin-right-xxxl-max-tablet {
        margin-right: 5em
    }
}

@media (min-width: 992px) {
    .margin-right-xxxl-min-desktop {
        margin-right: 5em
    }
}

@media (max-width: 992px) {
    .margin-right-xxxl-max-desktop {
        margin-right: 5em
    }
}

@media (min-width: 1199px) {
    .margin-right-xxxl-min-largest {
        margin-right: 5em
    }
}

@media (max-width: 1199px) {
    .margin-right-xxxl-max-largest {
        margin-right: 5em
    }
}

.margin-bottom-none {
    margin-bottom: 0
}

@media (min-width: 320px) {
    .margin-bottom-none-min-smallest {
        margin-bottom: 0
    }
}

@media (max-width: 320px) {
    .margin-bottom-none-max-smallest {
        margin-bottom: 0
    }
}

@media (min-width: 479px) {
    .margin-bottom-none-min-smartphones {
        margin-bottom: 0
    }
}

@media (max-width: 479px) {
    .margin-bottom-none-max-smartphones {
        margin-bottom: 0
    }
}

@media (min-width: 768px) {
    .margin-bottom-none-min-tablet {
        margin-bottom: 0
    }
}

@media (max-width: 768px) {
    .margin-bottom-none-max-tablet {
        margin-bottom: 0
    }
}

@media (min-width: 992px) {
    .margin-bottom-none-min-desktop {
        margin-bottom: 0
    }
}

@media (max-width: 992px) {
    .margin-bottom-none-max-desktop {
        margin-bottom: 0
    }
}

@media (min-width: 1199px) {
    .margin-bottom-none-min-largest {
        margin-bottom: 0
    }
}

@media (max-width: 1199px) {
    .margin-bottom-none-max-largest {
        margin-bottom: 0
    }
}

.margin-bottom-xs {
    margin-bottom: .5em
}

@media (min-width: 320px) {
    .margin-bottom-xs-min-smallest {
        margin-bottom: .5em
    }
}

@media (max-width: 320px) {
    .margin-bottom-xs-max-smallest {
        margin-bottom: .5em
    }
}

@media (min-width: 479px) {
    .margin-bottom-xs-min-smartphones {
        margin-bottom: .5em
    }
}

@media (max-width: 479px) {
    .margin-bottom-xs-max-smartphones {
        margin-bottom: .5em
    }
}

@media (min-width: 768px) {
    .margin-bottom-xs-min-tablet {
        margin-bottom: .5em
    }
}

@media (max-width: 768px) {
    .margin-bottom-xs-max-tablet {
        margin-bottom: .5em
    }
}

@media (min-width: 992px) {
    .margin-bottom-xs-min-desktop {
        margin-bottom: .5em
    }
}

@media (max-width: 992px) {
    .margin-bottom-xs-max-desktop {
        margin-bottom: .5em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-xs-min-largest {
        margin-bottom: .5em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-xs-max-largest {
        margin-bottom: .5em
    }
}

.margin-bottom-sm {
    margin-bottom: 1em
}

@media (min-width: 320px) {
    .margin-bottom-sm-min-smallest {
        margin-bottom: 1em
    }
}

@media (max-width: 320px) {
    .margin-bottom-sm-max-smallest {
        margin-bottom: 1em
    }
}

@media (min-width: 479px) {
    .margin-bottom-sm-min-smartphones {
        margin-bottom: 1em
    }
}

@media (max-width: 479px) {
    .margin-bottom-sm-max-smartphones {
        margin-bottom: 1em
    }
}

@media (min-width: 768px) {
    .margin-bottom-sm-min-tablet {
        margin-bottom: 1em
    }
}

@media (max-width: 768px) {
    .margin-bottom-sm-max-tablet {
        margin-bottom: 1em
    }
}

@media (min-width: 992px) {
    .margin-bottom-sm-min-desktop {
        margin-bottom: 1em
    }
}

@media (max-width: 992px) {
    .margin-bottom-sm-max-desktop {
        margin-bottom: 1em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-sm-min-largest {
        margin-bottom: 1em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-sm-max-largest {
        margin-bottom: 1em
    }
}

.margin-bottom-md {
    margin-bottom: 1.5em
}

@media (min-width: 320px) {
    .margin-bottom-md-min-smallest {
        margin-bottom: 1.5em
    }
}

@media (max-width: 320px) {
    .margin-bottom-md-max-smallest {
        margin-bottom: 1.5em
    }
}

@media (min-width: 479px) {
    .margin-bottom-md-min-smartphones {
        margin-bottom: 1.5em
    }
}

@media (max-width: 479px) {
    .margin-bottom-md-max-smartphones {
        margin-bottom: 1.5em
    }
}

@media (min-width: 768px) {
    .margin-bottom-md-min-tablet {
        margin-bottom: 1.5em
    }
}

@media (max-width: 768px) {
    .margin-bottom-md-max-tablet {
        margin-bottom: 1.5em
    }
}

@media (min-width: 992px) {
    .margin-bottom-md-min-desktop {
        margin-bottom: 1.5em
    }
}

@media (max-width: 992px) {
    .margin-bottom-md-max-desktop {
        margin-bottom: 1.5em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-md-min-largest {
        margin-bottom: 1.5em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-md-max-largest {
        margin-bottom: 1.5em
    }
}

.margin-bottom-lg {
    margin-bottom: 2em
}

@media (min-width: 320px) {
    .margin-bottom-lg-min-smallest {
        margin-bottom: 2em
    }
}

@media (max-width: 320px) {
    .margin-bottom-lg-max-smallest {
        margin-bottom: 2em
    }
}

@media (min-width: 479px) {
    .margin-bottom-lg-min-smartphones {
        margin-bottom: 2em
    }
}

@media (max-width: 479px) {
    .margin-bottom-lg-max-smartphones {
        margin-bottom: 2em
    }
}

@media (min-width: 768px) {
    .margin-bottom-lg-min-tablet {
        margin-bottom: 2em
    }
}

@media (max-width: 768px) {
    .margin-bottom-lg-max-tablet {
        margin-bottom: 2em
    }
}

@media (min-width: 992px) {
    .margin-bottom-lg-min-desktop {
        margin-bottom: 2em
    }
}

@media (max-width: 992px) {
    .margin-bottom-lg-max-desktop {
        margin-bottom: 2em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-lg-min-largest {
        margin-bottom: 2em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-lg-max-largest {
        margin-bottom: 2em
    }
}

.margin-bottom-xl {
    margin-bottom: 3em
}

@media (min-width: 320px) {
    .margin-bottom-xl-min-smallest {
        margin-bottom: 3em
    }
}

@media (max-width: 320px) {
    .margin-bottom-xl-max-smallest {
        margin-bottom: 3em
    }
}

@media (min-width: 479px) {
    .margin-bottom-xl-min-smartphones {
        margin-bottom: 3em
    }
}

@media (max-width: 479px) {
    .margin-bottom-xl-max-smartphones {
        margin-bottom: 3em
    }
}

@media (min-width: 768px) {
    .margin-bottom-xl-min-tablet {
        margin-bottom: 3em
    }
}

@media (max-width: 768px) {
    .margin-bottom-xl-max-tablet {
        margin-bottom: 3em
    }
}

@media (min-width: 992px) {
    .margin-bottom-xl-min-desktop {
        margin-bottom: 3em
    }
}

@media (max-width: 992px) {
    .margin-bottom-xl-max-desktop {
        margin-bottom: 3em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-xl-min-largest {
        margin-bottom: 3em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-xl-max-largest {
        margin-bottom: 3em
    }
}

.margin-bottom-xxl {
    margin-bottom: 4em
}

@media (min-width: 320px) {
    .margin-bottom-xxl-min-smallest {
        margin-bottom: 4em
    }
}

@media (max-width: 320px) {
    .margin-bottom-xxl-max-smallest {
        margin-bottom: 4em
    }
}

@media (min-width: 479px) {
    .margin-bottom-xxl-min-smartphones {
        margin-bottom: 4em
    }
}

@media (max-width: 479px) {
    .margin-bottom-xxl-max-smartphones {
        margin-bottom: 4em
    }
}

@media (min-width: 768px) {
    .margin-bottom-xxl-min-tablet {
        margin-bottom: 4em
    }
}

@media (max-width: 768px) {
    .margin-bottom-xxl-max-tablet {
        margin-bottom: 4em
    }
}

@media (min-width: 992px) {
    .margin-bottom-xxl-min-desktop {
        margin-bottom: 4em
    }
}

@media (max-width: 992px) {
    .margin-bottom-xxl-max-desktop {
        margin-bottom: 4em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-xxl-min-largest {
        margin-bottom: 4em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-xxl-max-largest {
        margin-bottom: 4em
    }
}

.margin-bottom-xxxl {
    margin-bottom: 5em
}

@media (min-width: 320px) {
    .margin-bottom-xxxl-min-smallest {
        margin-bottom: 5em
    }
}

@media (max-width: 320px) {
    .margin-bottom-xxxl-max-smallest {
        margin-bottom: 5em
    }
}

@media (min-width: 479px) {
    .margin-bottom-xxxl-min-smartphones {
        margin-bottom: 5em
    }
}

@media (max-width: 479px) {
    .margin-bottom-xxxl-max-smartphones {
        margin-bottom: 5em
    }
}

@media (min-width: 768px) {
    .margin-bottom-xxxl-min-tablet {
        margin-bottom: 5em
    }
}

@media (max-width: 768px) {
    .margin-bottom-xxxl-max-tablet {
        margin-bottom: 5em
    }
}

@media (min-width: 992px) {
    .margin-bottom-xxxl-min-desktop {
        margin-bottom: 5em
    }
}

@media (max-width: 992px) {
    .margin-bottom-xxxl-max-desktop {
        margin-bottom: 5em
    }
}

@media (min-width: 1199px) {
    .margin-bottom-xxxl-min-largest {
        margin-bottom: 5em
    }
}

@media (max-width: 1199px) {
    .margin-bottom-xxxl-max-largest {
        margin-bottom: 5em
    }
}

.margin-left-none {
    margin-left: 0
}

@media (min-width: 320px) {
    .margin-left-none-min-smallest {
        margin-left: 0
    }
}

@media (max-width: 320px) {
    .margin-left-none-max-smallest {
        margin-left: 0
    }
}

@media (min-width: 479px) {
    .margin-left-none-min-smartphones {
        margin-left: 0
    }
}

@media (max-width: 479px) {
    .margin-left-none-max-smartphones {
        margin-left: 0
    }
}

@media (min-width: 768px) {
    .margin-left-none-min-tablet {
        margin-left: 0
    }
}

@media (max-width: 768px) {
    .margin-left-none-max-tablet {
        margin-left: 0
    }
}

@media (min-width: 992px) {
    .margin-left-none-min-desktop {
        margin-left: 0
    }
}

@media (max-width: 992px) {
    .margin-left-none-max-desktop {
        margin-left: 0
    }
}

@media (min-width: 1199px) {
    .margin-left-none-min-largest {
        margin-left: 0
    }
}

@media (max-width: 1199px) {
    .margin-left-none-max-largest {
        margin-left: 0
    }
}

.margin-left-xs {
    margin-left: .5em
}

@media (min-width: 320px) {
    .margin-left-xs-min-smallest {
        margin-left: .5em
    }
}

@media (max-width: 320px) {
    .margin-left-xs-max-smallest {
        margin-left: .5em
    }
}

@media (min-width: 479px) {
    .margin-left-xs-min-smartphones {
        margin-left: .5em
    }
}

@media (max-width: 479px) {
    .margin-left-xs-max-smartphones {
        margin-left: .5em
    }
}

@media (min-width: 768px) {
    .margin-left-xs-min-tablet {
        margin-left: .5em
    }
}

@media (max-width: 768px) {
    .margin-left-xs-max-tablet {
        margin-left: .5em
    }
}

@media (min-width: 992px) {
    .margin-left-xs-min-desktop {
        margin-left: .5em
    }
}

@media (max-width: 992px) {
    .margin-left-xs-max-desktop {
        margin-left: .5em
    }
}

@media (min-width: 1199px) {
    .margin-left-xs-min-largest {
        margin-left: .5em
    }
}

@media (max-width: 1199px) {
    .margin-left-xs-max-largest {
        margin-left: .5em
    }
}

.margin-left-sm {
    margin-left: 1em
}

@media (min-width: 320px) {
    .margin-left-sm-min-smallest {
        margin-left: 1em
    }
}

@media (max-width: 320px) {
    .margin-left-sm-max-smallest {
        margin-left: 1em
    }
}

@media (min-width: 479px) {
    .margin-left-sm-min-smartphones {
        margin-left: 1em
    }
}

@media (max-width: 479px) {
    .margin-left-sm-max-smartphones {
        margin-left: 1em
    }
}

@media (min-width: 768px) {
    .margin-left-sm-min-tablet {
        margin-left: 1em
    }
}

@media (max-width: 768px) {
    .margin-left-sm-max-tablet {
        margin-left: 1em
    }
}

@media (min-width: 992px) {
    .margin-left-sm-min-desktop {
        margin-left: 1em
    }
}

@media (max-width: 992px) {
    .margin-left-sm-max-desktop {
        margin-left: 1em
    }
}

@media (min-width: 1199px) {
    .margin-left-sm-min-largest {
        margin-left: 1em
    }
}

@media (max-width: 1199px) {
    .margin-left-sm-max-largest {
        margin-left: 1em
    }
}

.margin-left-md {
    margin-left: 1.5em
}

@media (min-width: 320px) {
    .margin-left-md-min-smallest {
        margin-left: 1.5em
    }
}

@media (max-width: 320px) {
    .margin-left-md-max-smallest {
        margin-left: 1.5em
    }
}

@media (min-width: 479px) {
    .margin-left-md-min-smartphones {
        margin-left: 1.5em
    }
}

@media (max-width: 479px) {
    .margin-left-md-max-smartphones {
        margin-left: 1.5em
    }
}

@media (min-width: 768px) {
    .margin-left-md-min-tablet {
        margin-left: 1.5em
    }
}

@media (max-width: 768px) {
    .margin-left-md-max-tablet {
        margin-left: 1.5em
    }
}

@media (min-width: 992px) {
    .margin-left-md-min-desktop {
        margin-left: 1.5em
    }
}

@media (max-width: 992px) {
    .margin-left-md-max-desktop {
        margin-left: 1.5em
    }
}

@media (min-width: 1199px) {
    .margin-left-md-min-largest {
        margin-left: 1.5em
    }
}

@media (max-width: 1199px) {
    .margin-left-md-max-largest {
        margin-left: 1.5em
    }
}

.margin-left-lg {
    margin-left: 2em
}

@media (min-width: 320px) {
    .margin-left-lg-min-smallest {
        margin-left: 2em
    }
}

@media (max-width: 320px) {
    .margin-left-lg-max-smallest {
        margin-left: 2em
    }
}

@media (min-width: 479px) {
    .margin-left-lg-min-smartphones {
        margin-left: 2em
    }
}

@media (max-width: 479px) {
    .margin-left-lg-max-smartphones {
        margin-left: 2em
    }
}

@media (min-width: 768px) {
    .margin-left-lg-min-tablet {
        margin-left: 2em
    }
}

@media (max-width: 768px) {
    .margin-left-lg-max-tablet {
        margin-left: 2em
    }
}

@media (min-width: 992px) {
    .margin-left-lg-min-desktop {
        margin-left: 2em
    }
}

@media (max-width: 992px) {
    .margin-left-lg-max-desktop {
        margin-left: 2em
    }
}

@media (min-width: 1199px) {
    .margin-left-lg-min-largest {
        margin-left: 2em
    }
}

@media (max-width: 1199px) {
    .margin-left-lg-max-largest {
        margin-left: 2em
    }
}

.margin-left-xl {
    margin-left: 3em
}

@media (min-width: 320px) {
    .margin-left-xl-min-smallest {
        margin-left: 3em
    }
}

@media (max-width: 320px) {
    .margin-left-xl-max-smallest {
        margin-left: 3em
    }
}

@media (min-width: 479px) {
    .margin-left-xl-min-smartphones {
        margin-left: 3em
    }
}

@media (max-width: 479px) {
    .margin-left-xl-max-smartphones {
        margin-left: 3em
    }
}

@media (min-width: 768px) {
    .margin-left-xl-min-tablet {
        margin-left: 3em
    }
}

@media (max-width: 768px) {
    .margin-left-xl-max-tablet {
        margin-left: 3em
    }
}

@media (min-width: 992px) {
    .margin-left-xl-min-desktop {
        margin-left: 3em
    }
}

@media (max-width: 992px) {
    .margin-left-xl-max-desktop {
        margin-left: 3em
    }
}

@media (min-width: 1199px) {
    .margin-left-xl-min-largest {
        margin-left: 3em
    }
}

@media (max-width: 1199px) {
    .margin-left-xl-max-largest {
        margin-left: 3em
    }
}

.margin-left-xxl {
    margin-left: 4em
}

@media (min-width: 320px) {
    .margin-left-xxl-min-smallest {
        margin-left: 4em
    }
}

@media (max-width: 320px) {
    .margin-left-xxl-max-smallest {
        margin-left: 4em
    }
}

@media (min-width: 479px) {
    .margin-left-xxl-min-smartphones {
        margin-left: 4em
    }
}

@media (max-width: 479px) {
    .margin-left-xxl-max-smartphones {
        margin-left: 4em
    }
}

@media (min-width: 768px) {
    .margin-left-xxl-min-tablet {
        margin-left: 4em
    }
}

@media (max-width: 768px) {
    .margin-left-xxl-max-tablet {
        margin-left: 4em
    }
}

@media (min-width: 992px) {
    .margin-left-xxl-min-desktop {
        margin-left: 4em
    }
}

@media (max-width: 992px) {
    .margin-left-xxl-max-desktop {
        margin-left: 4em
    }
}

@media (min-width: 1199px) {
    .margin-left-xxl-min-largest {
        margin-left: 4em
    }
}

@media (max-width: 1199px) {
    .margin-left-xxl-max-largest {
        margin-left: 4em
    }
}

.margin-left-xxxl {
    margin-left: 5em
}

@media (min-width: 320px) {
    .margin-left-xxxl-min-smallest {
        margin-left: 5em
    }
}

@media (max-width: 320px) {
    .margin-left-xxxl-max-smallest {
        margin-left: 5em
    }
}

@media (min-width: 479px) {
    .margin-left-xxxl-min-smartphones {
        margin-left: 5em
    }
}

@media (max-width: 479px) {
    .margin-left-xxxl-max-smartphones {
        margin-left: 5em
    }
}

@media (min-width: 768px) {
    .margin-left-xxxl-min-tablet {
        margin-left: 5em
    }
}

@media (max-width: 768px) {
    .margin-left-xxxl-max-tablet {
        margin-left: 5em
    }
}

@media (min-width: 992px) {
    .margin-left-xxxl-min-desktop {
        margin-left: 5em
    }
}

@media (max-width: 992px) {
    .margin-left-xxxl-max-desktop {
        margin-left: 5em
    }
}

@media (min-width: 1199px) {
    .margin-left-xxxl-min-largest {
        margin-left: 5em
    }
}

@media (max-width: 1199px) {
    .margin-left-xxxl-max-largest {
        margin-left: 5em
    }
}

.introlet__heading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
    font-size: 1.1rem
}

@media (min-width: 768px) {
    .introlet__heading {
        display: inline
    }
}

@media (min-width: 768px) {
    .introlet {
        font-size: 1.2rem
    }

    .introlet__heading {
        font-size: 1em
    }
}

.hero-banner {
    text-align: center;
    background: transparent none center center no-repeat;
    background-size: cover;
    color: #000
}

@media (min-width: 992px) {
    .hero-banner {
        color: #fff;
        background-size: cover
    }

    .hero-banner--dark-text {
        color: #000 !important
    }

    .hero-banner--intro {
        background-size: cover
    }

    .hero-banner--clipped {
        background-size: auto;
        background-position: calc(50% + 150px) center
    }

        .hero-banner--clipped.hero-banner--intro {
            height: auto
        }

        .hero-banner--clipped .hero-banner__content {
            padding-top: 4rem;
            padding-bottom: 4rem
        }
}

@media (min-width: 992px) and (min-width: 1100px) {
    .hero-banner--clipped {
        background-position: calc(50% + 100px) center
    }
}

@media (min-width: 992px) and (min-width: 1280px) {
    .hero-banner--clipped {
        background-position: center center
    }
}

@media (min-width: 992px) {
    .hero-banner__mobile-image {
        display: none
    }
}

.hero-banner__3rd-party-img {
    margin: 0 auto 2rem
}

@media (min-width: 992px) {
    .hero-banner__3rd-party-img {
        margin-right: 0
    }
}

.hero-banner__heading {
    margin: 0;
    line-height: 1.04;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 480px) {
    .hero-banner__heading {
        max-width: 80%
    }
}

@media (min-width: 992px) {
    .hero-banner__heading {
        max-width: 100%
    }

    .hero-banner--left .hero-banner__heading {
        margin-left: 0
    }

    .hero-banner--right .hero-banner__heading {
        margin-right: 0
    }
}

.hero-banner__superheader {
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Gotham', sans-serif;
    letter-spacing: .0625rem;
    line-height: 2.25;
    margin-top: 0;
    text-transform: uppercase
}

.hero-banner__link {
    color: currentColor;
    text-decoration: none
}

.hero-banner__logo {
    width: 165px;
    height: 100%;
    margin-bottom: 1.1em
}

@media (min-width: 992px) {
    .hero-banner__logo {
        margin-bottom: 2em
    }
}

.hero-banner__content {
    padding: 2em 1em;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

@media (max-width: 991px) {
    .hero-rotator .hero-banner__content {
        padding: 2em
    }
}

@media (min-width: 992px) {
    .hero-banner__content {
        padding: 7.75rem 4rem;
        min-height: 350px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .hero-banner--left .hero-banner__content {
        margin-left: 0;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .hero-banner--right .hero-banner__content {
        margin-right: 0;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

@media (min-width: 1200px) {
    .hero-banner__content {
        min-height: 375px
    }
}

@media (min-width: 1366px) {
    .hero-banner__content {
        padding-left: 0;
        padding-right: 0
    }
}

.hero-banner__content--product > *:not(:last-child) {
    margin: 0 !important;
    margin-bottom: 2rem !important
}

@media (min-width: 992px) {
    .hero-banner__content--product--right {
        margin-right: 0 !important;
        margin-left: auto !important
    }
}

.hero-banner__subcontent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width: 992px) {
    .hero-banner__subcontent {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.hero-banner__subcontent > * {
    margin: 0 1rem 2rem
}

    .hero-banner__subcontent > *:last-child {
        margin-bottom: 0
    }

@media (min-width: 992px) {
    .hero-banner__subcontent > *:not(:last-child) {
        margin: 0 2rem 0 0
    }
}

.hero-banner__content--wide {
    max-width: 715px
}

.hero-banner__content--extra-wide {
    max-width: 1042px
}

@media screen and (min-width: 992px) {
    .hero-banner__content--overlap {
        margin-top: -10rem
    }
}

@media (min-width: 768px) {
    .hero-banner .hero-banner__content-container {
        max-width: 1366px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 1366px) {
    .hero-banner .hero-banner__content-container {
        margin-left: 4rem;
        margin-right: 4rem
    }
}

@media (min-width: 1500px) {
    .hero-banner .hero-banner__content-container {
        margin-left: auto;
        margin-right: auto
    }
}

.hero-banner__body {
    max-width: 480px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5
}

@media (min-width: 768px) {
    .hero-banner__body {
        font-size: 1.125rem
    }
}

@media (min-width: 992px) {
    .hero-banner__body {
        min-width: 480px
    }

    .hero-banner--left .hero-banner__body {
        margin-left: 0
    }

    .hero-banner--right .hero-banner__body {
        margin-right: 0
    }
}

@media screen and (min-width: 992px) {
    .hero-banner__body--wide {
        max-width: 85%
    }
}

@media (min-width: 992px) {
    .slick-slide .hero-banner__mobile-image {
        display: none
    }
}

@media (min-width: 992px) {
    .hero-banner--intro {
        height: 600px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

        .hero-banner--intro .hero-banner__container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center
        }

        .hero-banner--intro .hero-banner__content-container {
            width: 100%
        }
}

@media (min-width: 992px) {
    .hero-banner--left {
        text-align: left
    }

    .hero-banner--right {
        text-align: right
    }
}

.hero-banner--full-link .hero-banner__link {
    display: block
}

.hero-banner--for-blog {
    max-height: 600px
}

@media (min-width: 992px) {
    .hero-banner--for-blog .hero-banner__content {
        padding-top: 4.5rem
    }
}

@media (min-width: 992px) {
    .hero-banner--extrabottom {
        height: 855px
    }

        .hero-banner--extrabottom.hero-banner--intro {
            background-size: auto 855px
        }

            .hero-banner--extrabottom.hero-banner--intro .hero-banner__content {
                padding-top: 0;
                padding-bottom: 18.125rem
            }
}

.single-img-banner {
    max-width: 1280px;
    background-size: cover;
    background-position: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: block;
    margin: 0 15px
}

@media (min-width: 768px) {
    .single-img-banner {
        margin: 3.25rem 15px 0
    }
}

.single-img-banner__mobile-image {
    display: none;
    margin: 0 auto 2.85rem
}

@media (max-width: 767px) {
    .single-img-banner__mobile-image {
        display: block
    }
}

.single-img-banner__content-container {
    padding: 0
}

@media (min-width: 768px) {
    .single-img-banner__container {
        width: 49%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.single-img-banner__content {
    max-width: none;
    text-align: center
}

@media (min-width: 768px) {
    .single-img-banner__content {
        max-width: 320px;
        text-align: left
    }
}

@media (min-width: 992px) {
    .single-img-banner__content {
        max-width: 370px
    }
}

.single-img-banner__content-heading {
    font-size: 26px;
    font-weight: 900;
    line-height: 1.15;
    letter-spacing: -0.6px;
    margin-top: 0
}

.single-img-banner__content-body {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: -0.4px
}

@media (min-width: 992px) {
    .single-img-banner__content-body {
        font-size: 1.125rem
    }
}

@media (min-width: 768px) {
    .single-img-banner {
        margin: 6rem auto 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 360px
    }

    .single-img-banner--text-white {
        color: #ffffff
    }
}

@media (min-width: 992px) {
    .single-img-banner {
        height: 600px;
        margin: 6rem auto 15px
    }
}

.single-img-banner--margin-top-none {
    margin-top: 0 !important
}

.modal--rotator .slick-next, .modal--rotator .slick-prev {
    z-index: 10;
    text-align: center;
    font-size: 1em;
    height: auto;
    width: auto
}

@media (min-width: 560px) {
    .modal--rotator .slick-next, .modal--rotator .slick-prev {
        bottom: 40%;
        top: auto
    }
}

.modal--rotator .slick-next:before, .modal--rotator .slick-prev:before {
    font-family: FontAwesome;
    font-size: 1.75em;
    display: inline-block;
    opacity: 1;
    color: #000
}

.modal--rotator .slick-next.slick-disabled, .modal--rotator .slick-prev.slick-disabled {
    opacity: 0
}

@media (min-width: 992px) {
    .modal--rotator .slick-next, .modal--rotator .slick-prev {
        background: rgba(0,0,0,0.5);
        height: 4em;
        font-size: 1rem;
        width: 100%;
        max-width: 2em;
        -webkit-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s
    }

        .modal--rotator .slick-next:before, .modal--rotator .slick-prev:before {
            color: #fff
        }

        .modal--rotator .slick-next:focus, .modal--rotator .slick-prev:focus {
            background-color: #000
        }

        .modal--rotator .slick-next:hover, .modal--rotator .slick-prev:hover {
            background-color: #d90114;
            max-width: 2.5em
        }
}

.modal--rotator .slick-next__text, .modal--rotator .slick-prev__text {
    display: block;
    text-indent: -9999px
}

.modal--rotator .slick-next {
    right: 0
}

    .modal--rotator .slick-next:before {
        content: '\f105'
    }

@media (max-width: 991px) {
    .modal--rotator .slick-next {
        right: 15px
    }
}

.modal--rotator .slick-prev {
    left: 0
}

    .modal--rotator .slick-prev:before {
        content: '\f104'
    }

@media (max-width: 991px) {
    .modal--rotator .slick-prev {
        left: 15px
    }
}

.modal--rotator .slick-dots li button:before {
    opacity: 1;
    color: #626262;
    font-size: 40px
}

.modal--rotator .slick-dots li.slick-active button:before {
    color: #fff;
    text-shadow: none
}

.hero-rotator {
    position: relative
}

@media (max-width: 991px) {
    .hero-rotator .hero-banner {
        padding-bottom: 1.75rem
    }
}

@media (min-width: 320px) and (max-width: 424px) {
    .hero-rotator .hero-banner--for-blog {
        margin-bottom: 4rem
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .hero-rotator .hero-banner--for-blog {
        margin-bottom: 27%
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hero-rotator .hero-banner--for-blog {
        margin-bottom: 45%
    }
}

.hero-rotator .slick-next, .hero-rotator .slick-prev {
    z-index: 10;
    text-align: center;
    font-size: 1em;
    height: auto;
    width: auto
}

@media (min-width: 560px) {
    .hero-rotator .slick-next, .hero-rotator .slick-prev {
        bottom: 40%;
        top: auto
    }
}

.hero-rotator .slick-next:before, .hero-rotator .slick-prev:before {
    font-family: FontAwesome;
    font-size: 1.75em;
    display: inline-block;
    opacity: 1;
    color: #000
}

.hero-rotator .slick-next.slick-disabled, .hero-rotator .slick-prev.slick-disabled {
    opacity: 0
}

@media (min-width: 992px) {
    .hero-rotator .slick-next, .hero-rotator .slick-prev {
        background: rgba(0,0,0,0.5);
        height: 4em;
        font-size: 1rem;
        width: 100%;
        max-width: 2em;
        -webkit-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s
    }

        .hero-rotator .slick-next:before, .hero-rotator .slick-prev:before {
            color: #fff
        }

        .hero-rotator .slick-next:focus, .hero-rotator .slick-prev:focus {
            background-color: #000
        }

        .hero-rotator .slick-next:hover, .hero-rotator .slick-prev:hover {
            background-color: #d90114;
            max-width: 2.5em
        }
}

.hero-rotator .slick-next__text, .hero-rotator .slick-prev__text {
    display: block;
    text-indent: -9999px
}

.hero-rotator .slick-next {
    right: 0
}

    .hero-rotator .slick-next:before {
        content: '\f105'
    }

@media (max-width: 991px) {
    .hero-rotator .slick-next {
        right: 15px
    }
}

.hero-rotator .slick-prev {
    left: 0
}

    .hero-rotator .slick-prev:before {
        content: '\f104'
    }

@media (max-width: 991px) {
    .hero-rotator .slick-prev {
        left: 15px
    }
}

.hero-rotator .slick-dots {
    bottom: 1.5rem
}

    .hero-rotator .slick-dots li button:before {
        opacity: 1;
        color: #fff;
        font-size: 30px;
        text-shadow: 0 0 5px rgba(0,0,0,0.6)
    }

    .hero-rotator .slick-dots li.slick-active button:before {
        color: #000;
        text-shadow: none
    }

@media (min-width: 992px) {
    .hero-rotator .hero-banner__container {
        width: 100vw
    }

    .hero-rotator .slick-track {
        width: 100% !important
    }

    .hero-rotator .slick-slide {
        opacity: 1 !important;
        width: 100vw;
        overflow: hidden;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        display: block !important;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

        .hero-rotator .slick-slide.slick-active {
            position: relative !important
        }

    .hero-rotator__mask {
        -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
        overflow: hidden;
        background: transparent none 0 0 no-repeat;
        background-size: cover;
        background-position: center;
        margin-left: auto
    }

    .hero-rotator__mask--animating-left {
        -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)
    }

    .no-allows-clip-path .hero-rotator__mask--animating-left {
        opacity: 1
    }

    .no-allows-clip-path .slick-slide .hero-rotator__mask {
        opacity: 0
    }

    .slick-active .hero-rotator__mask {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        -webkit-transition: 0.75s cubic-bezier(0.5, 0.5, 0.5, 0.5);
        -o-transition: 0.75s cubic-bezier(0.5, 0.5, 0.5, 0.5);
        transition: 0.75s cubic-bezier(0.5, 0.5, 0.5, 0.5);
        opacity: 1
    }

    .no-allows-clip-path .slick-active .hero-rotator__mask {
        opacity: 1
    }

    .slick-viewed .hero-rotator__mask {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
        opacity: 1;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
    }

    .no-allows-clip-path .slick-viewed .hero-rotator__mask {
        opacity: 1
    }

    .hero-rotator .hero-banner__content {
        opacity: 0;
        -webkit-transition: opacity 0.75s;
        -o-transition: opacity 0.75s;
        transition: opacity 0.75s;
        -webkit-transition-delay: 1s;
        -o-transition-delay: 1s;
        transition-delay: 1s
    }

    .hero-rotator .slick-active .hero-banner__content {
        opacity: 1
    }
}

.hero-rotator .hero-banner {
    position: relative
}

.interactive-header__list {
    list-style: none outside;
    margin: 0;
    padding: 0
}

.interactive-header__image {
    display: block
}

.interactive-header__image--large {
    display: none
}

.interactive-header__item {
    background: #f6f6f6;
    margin-bottom: 2px
}

.interactive-header__link {
    padding: 1.5rem;
    display: block;
    text-decoration: none
}

.interactive-header__heading {
    font-weight: 400;
    font-size: 1.5rem;
    border-bottom: 1px solid #b1b1b1;
    padding-bottom: 0.35em;
    margin-bottom: 0.5em
}

.interactive-header__content {
    font-size: 1rem;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto
}

.interactive-header__btn {
    font-weight: 400
}

@media (min-width: 600px) {
    .interactive-header__link {
        height: 100%
    }

    .interactive-header__content {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .interactive-header__btn {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .interactive-header__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .interactive-header__item {
        width: 33.3333%;
        margin-bottom: 0;
        margin-right: 2px
    }

    .interactive-header--halves .interactive-header__item {
        width: 50%
    }

    .interactive-header__item:last-child {
        margin-right: 0
    }

    .interactive-header .feature-list {
        font-size: 0.9rem
    }
}

@media (min-width: 768px) {
    .interactive-header__link {
        padding: 1.5rem 3rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

        .interactive-header__link:hover .btn, .interactive-header__link:focus .btn {
            background-color: #d90114;
            border-color: #d90114;
            color: #fff
        }

    .interactive-header .feature-list {
        font-size: 0.95em
    }
}

@media (min-width: 992px) {
    .interactive-header__btn {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        text-align: center
    }

    .interactive-header__link {
        position: relative;
        overflow: hidden
    }

    .interactive-header__list {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto
    }

    .interactive-header__image {
        display: none
    }

    .interactive-header__image--large {
        display: block;
        position: absolute;
        right: 225px;
        top: 10px;
        max-width: none;
        max-height: 130%
    }

    .interactive-header__content {
        width: 150px;
        margin-left: auto;
        margin-right: 0
    }

    .interactive-header__content-body {
        min-height: 25vw;
        height: calc(30vw - 6rem);
        max-height: calc(500px - 6rem)
    }

    .interactive-header__item {
        min-width: 24%;
        width: 100%
    }
}

@media (min-width: 1200px) {
    .interactive-header__content-body {
        min-height: 20vw
    }

    .interactive-header.interactive-header--halves {
        overflow: hidden;
        background: #f6f6f6
    }

    .interactive-header--halves .interactive-header__list {
        max-width: 1250px;
        margin-left: auto;
        margin-right: auto
    }

    .interactive-header--halves .interactive-header__item:first-child {
        margin-right: 0;
        border-right: 2px solid #fff
    }

    .interactive-header--halves .interactive-header__link {
        overflow: visible
    }
}

.feature-list {
    list-style: none outside;
    margin: 0;
    padding: 0;
    text-align: left
}

.feature-list--columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
}

.feature-list__item {
    line-height: 1.3125;
    margin-bottom: 0.75em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.feature-list--columns .feature-list__item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    line-height: 1.3;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 700;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.feature-list__item-name {
    width: 100%
}

.feature-list__icon {
    width: 1.3125rem;
    height: 1.3125rem;
    float: left;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding-right: 0.5rem;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

.feature-list__icon--png {
    display: none
}

.feature-list__icon--larger {
    width: auto;
    height: auto
}

.feature-list--columns .feature-list__icon {
    width: auto;
    width: 78px;
    display: block;
    max-width: none;
    height: auto;
    float: none;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em
}

.flexboxtweener .feature-list__icon {
    display: none
}

.flexboxtweener .feature-list__icon--png {
    display: block;
    width: 29px;
    height: 21px;
    margin: 0
}

.flexboxtweener .feature-list--png .feature-list__icon {
    display: block;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.flexboxtweener .feature-list--columns .feature-list__icon {
    display: block;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.linked-slider .hero-banner {
    padding: 0 1rem;
    color: #fff;
    background-size: cover;
    background-position: right top
}

.linked-slider .hero-banner__heading {
    max-width: none
}

.linked-slider .hero-banner__body {
    font-size: 1rem;
    margin-left: 0;
    margin-right: 0;
    min-width: 0
}

@media (min-width: 768px) {
    .linked-slider .hero-banner__body {
        margin: 2.125rem 0 2.5rem;
        width: 260px;
        font-size: 1.125rem
    }
}

@media (min-width: 768px) {
    .linked-slider .hero-banner__body {
        width: 372px
    }
}

@media (max-width: 768px) {
    .linked-slider .hero-banner__btn {
        font-size: 0.875rem
    }
}

@media (max-width: 1023px) {
    .linked-slider .hero-banner__btn {
        padding: 0.625rem 1.25rem
    }
}

.linked-slider .hero-banner__link {
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 4rem;
    position: relative;
    z-index: 1
}

@media (min-width: 768px) {
    .linked-slider .hero-banner__link {
        padding-left: 3.75rem;
        -ms-flex-item-align: center;
        align-self: center;
        padding-bottom: 0;
        position: relative;
        display: block;
        text-align: left
    }
}

@media (min-width: 1024px) {
    .linked-slider .hero-banner__link {
        padding-bottom: 5.375rem;
        padding-left: 5.75rem
    }
}

.linked-slider__primary {
    background-color: #000000;
    position: relative
}

    .linked-slider__primary .linked-slider__slide:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) 44%, #000 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.25)), color-stop(44%, rgba(0,0,0,0.25)), to(#000));
        background: -o-linear-gradient(top, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) 44%, #000 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) 44%, #000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#000000',GradientType=0 )
    }

@media (min-width: 768px) {
    .linked-slider__primary .linked-slider__slide:after {
        content: none
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .linked-slider__primary .linked-slider__slide:after {
        content: '';
        background: rgba(0,0,0,0.5)
    }
}

.linked-slider__secondary {
    background: rgba(255,255,255,0);
    width: 100%;
    height: 220px;
    margin-top: -24px
}

    .linked-slider__secondary .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .linked-slider__secondary .slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .linked-slider__secondary.slick-dotted.slick-slider {
        margin-bottom: 0
    }

    .linked-slider__secondary .slick-list {
        padding: 0 !important;
        margin-bottom: 3.2em
    }

    .linked-slider__secondary .slick-dots {
        bottom: 25px
    }

        .linked-slider__secondary .slick-dots li button:before {
            content: '';
            border: 1px solid #000000;
            border-radius: 50%;
            width: 10px;
            height: 10px
        }

        .linked-slider__secondary .slick-dots li.slick-active button:before {
            opacity: 1;
            background: #000000;
            border: none
        }

    .linked-slider__secondary .slick-prev, .linked-slider__secondary .slick-next {
        top: 63px
    }

        .linked-slider__secondary .slick-prev:before, .linked-slider__secondary .slick-next:before {
            font-family: 'FontAwesome';
            color: #888888;
            font-size: 13px
        }

    .linked-slider__secondary .slick-prev {
        left: 6px
    }

        .linked-slider__secondary .slick-prev:before {
            content: '\f053'
        }

    .linked-slider__secondary .slick-next {
        right: 6px
    }

        .linked-slider__secondary .slick-next:before {
            content: '\f054'
        }

@media (min-width: 768px) {
    .linked-slider__secondary {
        padding: 0 0.5em;
        height: auto
    }

        .linked-slider__secondary .slick-slide {
            margin: 0 10px
        }

        .linked-slider__secondary .slick-list {
            overflow: visible
        }
}

@media (min-width: 1024px) {
    .linked-slider__secondary {
        background: #ffffff;
        padding: 0;
        margin: -78px auto 0;
        position: relative;
        z-index: 1;
        width: 90%;
        -webkit-box-shadow: 0 9px 0px 0px white,0 -9px 0px 0px white,5px 0 5px -4px rgba(0,0,0,0.1),-5px 0 5px -4px rgba(0,0,0,0.1);
        box-shadow: 0 9px 0px 0px white,0 -9px 0px 0px white,5px 0 5px -4px rgba(0,0,0,0.1),-5px 0 5px -4px rgba(0,0,0,0.1)
    }

        .linked-slider__secondary .slick-list {
            padding: 0 1em !important
        }

        .linked-slider__secondary .slick-slide {
            margin-top: -44px
        }
}

.linked-slider__preview {
    position: relative;
    background: rgba(255,255,255,0);
    text-align: center;
    cursor: pointer;
    padding: 0 1em;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px
}

    .linked-slider__preview:before {
        position: absolute;
        top: -25px;
        right: 0;
        left: 0;
        margin: 0 auto;
        content: '';
        width: 0;
        height: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-bottom: 25px solid #ffffff;
        opacity: 0
    }

@media (min-width: 768px) {
    .linked-slider__preview:before {
        -webkit-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all
    }
}

@media (min-width: 768px) {
    .linked-slider__preview {
        padding: 0 0.35em;
        -webkit-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all
    }
}

@media (min-width: 1024px) {
    .linked-slider__preview {
        width: 147px;
        margin: 35px auto 0;
        padding: 0 1rem
    }

        .linked-slider__preview:hover .linked-slider__text {
            color: #d90114
        }
}

@media (min-width: 1500px) {
    .linked-slider__preview {
        max-width: none;
        width: 170px;
        padding: 0 0.3125rem
    }
}

.linked-slider__thumbnail {
    display: inline-block;
    margin: 10px auto 0;
    height: 55px;
    width: 55px;
    border: solid 5px #dddddd;
    border-radius: 50%;
    background-position: center;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    background-size: cover
}

@media (min-width: 768px) {
    .linked-slider__thumbnail {
        height: 60px;
        width: 60px
    }
}

@media (min-width: 1500px) {
    .linked-slider__thumbnail {
        height: 70px;
        width: 70px;
        margin: 20px auto 8px
    }
}

.linked-slider__text {
    margin: 10px 0 0 0;
    padding: 0;
    font-weight: 900;
    font-size: 0.9em;
    line-height: 1.3
}

@media (min-width: 1100px) {
    .linked-slider__text {
        font-size: 1em
    }
}

.linked-slider__slide {
    opacity: 0.5;
    text-decoration: none
}

@media (min-width: 768px) {
    .linked-slider__slide {
        opacity: 1
    }
}

.linked-slider__slide.hero-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 417px
}

@media (min-width: 768px) {
    .linked-slider__slide.hero-banner {
        height: 427px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (min-width: 1024px) {
    .linked-slider__slide.hero-banner {
        height: 638px
    }
}

.linked-slider__slide:focus {
    outline: none
}

.linked-slider__slide.slick-current {
    opacity: 1
}

    .linked-slider__slide.slick-current .linked-slider__thumbnail {
        width: 64px;
        height: 64px
    }

@media (min-width: 768px) {
    .linked-slider__slide.slick-current .linked-slider__thumbnail {
        margin-top: 15px;
        height: 70px;
        width: 70px
    }
}

@media (min-width: 1024px) {
    .linked-slider__slide.slick-current .linked-slider__thumbnail {
        margin-top: 22px;
        height: 75px;
        width: 75px
    }
}

@media (min-width: 1500px) {
    .linked-slider__slide.slick-current .linked-slider__thumbnail {
        margin-top: 18px;
        height: 100px;
        width: 100px
    }
}

.linked-slider__slide.slick-current .linked-slider__preview {
    background: #fff
}

@media (min-width: 768px) {
    .linked-slider__slide.slick-current .linked-slider__preview {
        -webkit-box-shadow: 0px 2px 6px 2px rgba(0,0,0,0.1);
        box-shadow: 0px 2px 6px 2px rgba(0,0,0,0.1);
        margin: 13px auto 0
    }
}

.linked-slider__slide.slick-current .linked-slider__preview:before {
    opacity: 1
}

.linked-slider__slide.slick-current .linked-slider__thumbnail {
    border-color: #8d8d8d
}

.linked-slider .slick-dots li button:before {
    font-size: 40px
}

@media (min-width: 768px) {
    .linked-slider {
        min-height: 578px
    }
}

@media (min-width: 1024px) {
    .linked-slider {
        min-height: 711px
    }
}

.group:after {
    content: "";
    display: table;
    clear: both
}

.masonry {
    position: relative
}

@media (min-width: 992px) {
    .masonry {
        font-size: 1rem
    }
}

@media (min-width: 1230px) {
    .masonry {
        font-size: 1.125rem
    }
}

.masonry__overlay {
    display: none
}

@media (min-width: 768px) {
    .masonry__overlay {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        max-width: 35%
    }
}

.masonry__overlay-img {
    max-width: 100%;
    position: relative;
    top: 18px
}

.masonry__column:first-of-type .masonry__block:first-child {
    margin-top: 0
}

@media (min-width: 768px) {
    .masonry__column {
        width: calc(50% - 2px);
        float: left
    }

        .masonry__column .masonry__block:first-child {
            margin-top: 0
        }

    .masonry__column--40 {
        width: calc(40% - 2px)
    }

    .masonry__column--60 {
        width: calc(60% - 2px)
    }

    .masonry__column:last-child {
        float: right
    }
}

@media (min-width: 992px) {
    .masonry .heading-- {
        font-size: 1.25rem
    }
}

@media (min-width: 1230px) {
    .masonry .heading-- {
        font-size: 1.75rem
    }
}

.masonry__block {
    margin-top: 4px
}

@media (min-width: 768px) {
    .masonry__block--tertiary {
        margin-right: 2px;
        width: calc(50% - 2px);
        float: left
    }

        .masonry__block--tertiary:last-child {
            margin-left: 2px;
            margin-right: 0
        }

    .masonry__block--tertiary-oblong {
        width: 100%
    }
}

.masonry__block--inner {
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding-bottom: 70.666666666%;
    position: relative
}

.masonry__block--primary .masonry__block--inner {
    padding-bottom: 117.3333333%
}

@media (min-width: 768px) {
    .masonry__block--primary .masonry__block--inner {
        padding-bottom: 87.691240875%
    }

    .masonry__column--60 .masonry__block--primary .masonry__block--inner {
        padding-bottom: 80%
    }
}

@media (max-width: 767px) {
    .masonry__block--primary-mobile .masonry__block--inner {
        padding-bottom: 70% !important
    }
}

@media (min-width: 768px) {
    .masonry__block--secondary .masonry__block--inner {
        padding-bottom: calc(52.6% - 4px)
    }

    .masonry__column--40 .masonry__block--secondary .masonry__block--inner {
        padding-bottom: 81%
    }
}

.masonry__block--tertiary .masonry__block--inner {
    padding-bottom: 70.666666666%
}

.masonry__block--tertiary-oblong .masonry__block--inner {
    padding-bottom: 30%
}

@media (min-width: 768px) {
    .masonry__block--tertiary-oblong .masonry__block--inner {
        width: 100%;
        padding-bottom: 25.2%
    }
}

.masonry__block--secondary-short .masonry__block--inner {
    padding-bottom: 35.1%
}

@media (min-width: 768px) {
    .masonry__column--40 .masonry__block--secondary-short .masonry__block--inner {
        padding-bottom: 38.65%
    }
}

.masonry__link {
    color: currentColor;
    text-decoration: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

    .masonry__link:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.7)));
        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)
    }

@media (min-width: 992px) {
    .masonry__link:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid rgba(255,255,255,0.5);
        opacity: 0;
        -webkit-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;
        z-index: 1
    }

    .masonry__link:hover:before {
        opacity: 1;
        border-width: 7px
    }
}

.masonry__content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1em 2em 1em;
    z-index: 1
}

@media (min-width: 768px) and (max-width: 991px) {
    .masonry__content {
        padding: 0 1em 1em 1em
    }
}

@media (min-width: 992px) {
    .masonry__content {
        padding-left: 2em;
        padding-bottom: 1.5em
    }
}

.masonry__heading {
    margin: 0
}

@media (min-width: 768px) and (max-width: 991px) {
    .masonry__heading {
        font-size: 1.1rem
    }
}

.masonry__desc {
    margin: 0
}

@media (min-width: 768px) and (max-width: 991px) {
    .masonry__desc {
        font-size: 1rem
    }
}

@media (min-width: 768px) {
    .masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 768px) {
    .masonry-grid__column {
        margin-left: 2px
    }

    .masonry-grid__column--first {
        margin-right: 2px
    }

    .masonry-grid__column--50 {
        width: 50%
    }

    .masonry-grid__column--55 {
        width: 55%
    }

    .masonry-grid__column--45 {
        width: 45%
    }
}

.masonry-grid__small-images-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 4px
}

.masonry-grid__image {
    width: 100%
}

.masonry-grid__image--small {
    width: calc(50% - 2px)
}

.masonry-grid__image-wrap {
    position: relative;
    display: block
}

    .masonry-grid__image-wrap:after {
        content: '';
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, #000 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(#000));
        background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, #000 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        top: 0
    }

@media (min-width: 992px) {
    .masonry-grid__image-wrap:after {
        top: 40%
    }
}

.masonry-grid__heading {
    margin: 0 0 0.5em 0;
    font-size: 1.2em
}

@media (min-width: 1100px) {
    .masonry-grid__heading {
        font-size: 1.4em
    }
}

.masonry-grid__text {
    margin: 0
}

@media (min-width: 1100px) {
    .masonry-grid__text {
        font-size: 1.1em
    }
}

.masonry-grid__text-wrap {
    position: absolute;
    bottom: 1.25em;
    left: 1.25em;
    right: 1.25em;
    z-index: 1;
    color: #fff
}

@supports (display: grid) {
    .masonry-grid {
        display: grid;
        grid-template-rows: 1fr;
        grid-gap: 4px
    }

    @media (min-width: 768px) {
        .masonry-grid--50-50 {
            grid-template-columns: 50% 50%
        }
    }

    @media (min-width: 768px) {
        .masonry-grid--45-55 {
            grid-template-columns: 45% 55%
        }
    }

    .masonry-grid__image-wrap {
        display: grid
    }

        .masonry-grid__image-wrap:after {
            bottom: 0
        }

    .masonry-grid__small-images-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 4px;
        margin-bottom: 0
    }

    .masonry-grid__column {
        width: 100%;
        display: grid;
        grid-row-gap: 4px
    }

    .masonry-grid__image--small {
        width: 100%
    }
}

.where-to-buy {
    text-align: center;
    background: #000 url("//images.kwikset.com/is/image/Kwikset/footer-where-to-buy-map?scl=1") center center no-repeat;
    background-size: cover;
    color: #fff;
    padding: 5rem 1rem
}

.where-to-buy__heading {
    line-height: 1.4;
    margin: 0 auto 1.5em auto;
    font-weight: 800;
    font-size: 1.2rem;
    max-width: 80%
}

    .where-to-buy__heading:before {
        content: '\f041';
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5em;
        font-family: FontAwesome;
        color: currentColor;
        opacity: 0.5;
        font-size: 3rem
    }

@media (min-width: 768px) {
    .where-to-buy__heading {
        font-weight: 400;
        font-size: 1.9rem
    }
}

.breadcrumb-wrapper {
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.45rem 4rem
}

@media (max-width: 991px) {
    .breadcrumb-wrapper {
        display: none
    }
}

.breadcrumb-wrapper--bottom-spacing {
    margin-bottom: 4.5rem
}

.topbreadcrumb {
    font-size: 14px;
    font-weight: 500;
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto
}

    .topbreadcrumb a, .topbreadcrumb span {
        color: #585858;
        text-decoration: none;
        font-weight: 300;
        padding: 0 5px
    }

        .topbreadcrumb a:first-child {
            padding-left: 0
        }

.sidenav {
    background: #f4f4f4;
    color: #000;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    z-index: 99;
    position: relative;
    font-size: 1.1rem
}

@media (min-width: 992px) {
    .sidenav {
        background: transparent
    }
}

.sidenav__link {
    text-decoration: none;
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #dedede
}

@media (min-width: 992px) {
    .sidenav__link {
        border: none;
        padding: 0;
        line-height: 1.4
    }

        .sidenav__link:hover {
            color: #d90114
        }
}

.sidenav__btn-drop {
    border: none;
    background: transparent none;
    padding: 14px 32px 14px 50px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: left
}

    .sidenav__btn-drop:before {
        content: '\f078';
        font-family: FontAwesome;
        position: absolute;
        left: 1em;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

.sidenav__btn-drop--open:before {
    content: '\f077'
}

.sidenav__btn-drop--open + .sidenav__list {
    display: block
}

@media (min-width: 992px) {
    .sidenav__btn-drop {
        display: none
    }
}

.sidenav__list {
    margin: 0;
    list-style-type: none;
    display: none;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 1em;
    position: absolute;
    background: #f4f4f4;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    -webkit-box-shadow: 1px 5px 5px rgba(0,0,0,0.1);
    box-shadow: 1px 5px 5px rgba(0,0,0,0.1)
}

@media (min-width: 992px) {
    .sidenav__list {
        background: transparent;
        position: static;
        top: auto;
        left: auto;
        right: auto;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        display: block
    }
}

.sidenav__list-item--active {
    font-weight: bold
}

.sidenav__list-item:last-child .sidenav__link {
    border: none
}

@media (min-width: 992px) {
    .sidenav__list-item {
        margin-bottom: 1.5em
    }
}

.about-wrapper {
    margin-bottom: 7.5rem;
    line-height: 1.5
}

    .about-wrapper > *:first-child {
        margin-top: 0
    }

    .about-wrapper p {
        line-height: 1.8
    }

    .about-wrapper h1 {
        font-size: 1.625rem;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5em;
        font-weight: 800
    }

@media (min-width: 992px) {
    .about-wrapper h1 {
        margin-top: 0
    }
}

@media (min-width: 768px) {
    .about-wrapper h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        padding-bottom: 1.5rem
    }
}

.about-wrapper h2 {
    font-size: 1.125rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-weight: 900
}

@media (min-width: 768px) {
    .about-wrapper h2 {
        font-size: 1.625rem;
        margin-top: 4rem;
        margin-bottom: 1.75rem
    }
}

.about-wrapper h1 + h2 {
    margin-top: 0
}

.about-wrapper ul, .about-wrapper ol {
    line-height: 1.5
}

    .about-wrapper ul li, .about-wrapper ol li {
        margin-bottom: 0.5em
    }

.about-wrapper--press h1, .about-wrapper--events h1 {
    margin-bottom: 0.75em;
    padding-bottom: 0.75em
}

.article-detail h1 {
    border: none;
    padding: 0;
    margin: 1.5em 0 1em
}

.article-detail__contacts {
    border-top: 1px solid #e6e6e6;
    padding-top: 3rem;
    margin-top: 3rem
}

    .article-detail__contacts > *:first-child {
        margin-top: 0
    }

.article-detail__heading {
    border: none;
    padding: 0;
    margin-bottom: 2em
}

.link--standalone {
    display: block;
    color: currentColor
}

    .link--standalone:hover {
        color: #d90114
    }

.custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0
}

    .custom-list li.custom-list__item {
        color: #000000;
        border-top: 1px solid #e6e6e6;
        margin: 0;
        padding: 3.2rem 0
    }

        .custom-list li.custom-list__item:first-child {
            border: none;
            padding-top: 0
        }

.custom-list__date {
    font-size: 16px;
    font-weight: bold
}

.custom-list__title {
    font-size: 18px
}

.custom-list__btn {
    color: #000000
}

.social-networks {
    list-style: none outside;
    margin: 0;
    padding: 0;
    font-size: 1.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    margin-left: auto;
    margin-right: auto
}

.social-networks__item {
    width: 20%;
    text-align: center
}

.social-networks a {
    -webkit-transition: color 0.25s;
    -o-transition: color 0.25s;
    transition: color 0.25s
}

    .social-networks a:hover {
        color: #d90114
    }

.auxiliary-nav {
    text-align: center
}

.auxiliary-nav__list {
    list-style: none outside;
    margin: 0;
    padding: 0
}

.auxiliary-nav__link {
    text-decoration: none;
    border-bottom: 1px solid rgba(0,0,0,0.4);
    -webkit-transition: color, border-color 0.25s;
    -o-transition: color, border-color 0.25s;
    transition: color, border-color 0.25s
}

    .auxiliary-nav__link:hover {
        color: #d90114;
        border-color: currentColor
    }

.auxiliary-nav__item {
    display: inline-block;
    margin-right: 2em
}

    .auxiliary-nav__item:last-child {
        margin: 0
    }

.trade-resources__header {
    margin-top: 2.6rem;
    margin-bottom: 2.6rem
}

    .trade-resources__header p {
        line-height: 1.67
    }

.trade-resources__container {
    width: 100%;
    max-width: 1022px;
    border: solid 1px #d1d1d1;
    padding: 2rem 3.4rem;
    margin-top: 2rem;
    margin-bottom: 4.25rem
}

@media (max-width: 768px) {
    .trade-resources__container {
        width: calc(100% + 4rem);
        margin-left: -2rem
    }
}

@media (max-width: 767px) {
    .trade-resources__container {
        text-align: center;
        padding: 2rem 1.4rem
    }
}

.trade-resources__container--search {
    background-color: #f6f6f6;
    padding: 3rem 0 4.625rem
}

@media (max-width: 767px) {
    .trade-resources__container--search {
        text-align: center;
        padding: 3rem 0 3.3rem
    }

        .trade-resources__container--search .heading {
            font-size: 1.5rem
        }
}

.trade-resources__row {
    margin: 0 -2.25rem
}

.trade-resources__col {
    padding: 0 2.25rem
}

.trade-resources--vertical .heading {
    margin-top: 0
}

@media (max-width: 767px) {
    .trade-resources--vertical .heading {
        font-size: 1.5rem;
        margin-bottom: 0
    }
}

.trade-resources--vertical p {
    margin-bottom: 0;
    line-height: 1.67
}

@media (max-width: 767px) {
    .trade-resources--vertical p {
        margin-top: 0.5rem;
        margin-bottom: 2rem
    }
}

@media (min-width: 768px) {
    .trade-resources--vertical {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (min-width: 768px) {
    .trade-resources--right-align {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

.trade-resources__search-icon {
    color: #848484;
    font-size: 2.25rem;
    vertical-align: sub
}

.trade-resources__search-box {
    float: left;
    width: 100%;
    background-color: #fff;
    border: solid 1px #d5d5d5;
    padding: 1.25rem 1.75rem;
    margin-top: 1.25rem
}

@media (max-width: 767px) {
    .trade-resources__search-box {
        padding: 1.25rem 1.25rem
    }
}

.trade-resources__search-input {
    width: calc(100% - 17rem);
    border: none;
    margin-left: 0.75rem;
    padding: 0.75rem 0
}

    .trade-resources__search-input:focus {
        outline: none
    }

    .trade-resources__search-input::-webkit-input-placeholder {
        color: #000
    }

@media (max-width: 767px) {
    .trade-resources__search-input {
        width: calc(100% - 3.6rem)
    }
}

.trade-resources__search-btn {
    float: right;
    padding: 0.75rem 4rem;
    font-size: 0.875rem
}

@media (min-width: 769px) {
    .trade-resources__search-btn {
        font-size: 1rem
    }
}

@media (max-width: 767px) {
    .trade-resources__search-btn {
        display: none
    }
}

.featured-block__container {
    padding: 5rem 0
}

.featured-block__container--has-divider {
    max-width: 1360px;
    margin-right: auto;
    margin-left: auto;
    border-bottom: 1px solid #474747
}

.featured-block__container--grey {
    background-color: #f6f6f6
}

@media (min-width: 769px) {
    .featured-block__container--txt-first .featured-block__content {
        padding-right: 3rem;
        text-align: right
    }
}

@media (min-width: 769px) {
    .featured-block__container--img-first .featured-block__content {
        padding-left: 3rem
    }
}

@media (min-width: 768px) {
    .featured-block__container .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 767px) {
    .featured-block__container {
        text-align: center;
        padding: 3rem 0 4rem
    }
}

.featured-block__container--padding-none {
    padding: 0
}

@media (max-width: 414px) {
    .featured-block__container--padding-none-mobile {
        padding: 0
    }
}

.featured-block__content {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 100%;
    max-width: 384px;
    margin-left: auto;
    margin-right: auto
}

.featured-block__title {
    font-weight: 900;
    margin: 1.2rem 0 2.5rem
}

@media (max-width: 767px) {
    .featured-block__title {
        font-size: 2.5rem;
        margin: 1.2rem 0 1rem
    }
}

.featured-block__subtitle {
    font-weight: 900;
    letter-spacing: -0.6px;
    font-size: 1.25rem;
    margin: 1rem auto
}

@media (min-width: 768px) {
    .featured-block__subtitle {
        font-size: 1.625rem;
        margin: 0.65rem 0 0
    }
}

.featured-block__text {
    line-height: 1.67
}

.featured-block__btn {
    margin-top: 2.2rem;
    padding: 12px 28px
}

@media (max-width: 767px) {
    .featured-block__btn {
        margin-top: 0.5rem
    }
}

.featured-block__img {
    display: block;
    margin: auto;
    width: 100%;
    height: auto
}

@media (max-width: 767px) {
    .featured-block__img {
        margin-bottom: 2rem
    }
}

@media (min-width: 768px) {
    .contact__container {
        padding: 0 1.75rem
    }
}

.contact__container .heading--tertiary {
    font-size: 1.75rem
}

.contact__container--banner {
    padding: 0 1.75rem;
    margin-top: 3rem
}

@media (min-width: 768px) {
    .contact__container--banner {
        margin-top: 4.5rem
    }
}

.contact__container--nav .same-page-nav {
    margin-bottom: 0;
    border-bottom: 1px solid #dedede;
    z-index: 99
}

@media (min-width: 768px) {
    .contact__container--nav .same-page-nav {
        margin-bottom: 2.5rem
    }
}

.contact__container .contact__wrapper {
    padding: 2.7rem 2.3rem
}

    .contact__container .contact__wrapper.contact__wrapper--bottom {
        margin-bottom: 7rem
    }

@media (min-width: 768px) {
    .contact__container .contact__wrapper {
        padding: 2.7rem 0 4.6rem
    }
}

@media (max-width: 320px) {
    .contact__container .contact__wrapper {
        padding: 2.7rem 0.5rem
    }
}

.contact__container .contact__heading {
    margin-top: 0
}

@media (min-width: 992px) {
    .contact__container .contact__heading {
        margin: 0
    }
}

.contact__container .contact__subheading {
    margin: 1rem auto 2.5rem;
    max-width: 735px
}

@media (min-width: 992px) {
    .contact__container .contact__subheading {
        margin: 1rem auto 4rem
    }
}

.contact__container .contact-info__heading {
    margin-top: 0
}

.contact__container .contact-info__info {
    margin-bottom: 1rem
}

.contact__container .contact-info__text {
    margin: 0
}

.contact__container .contact-info__link {
    text-decoration: none;
    color: #d90114
}

.contact__container .contact-info__cards--wrapper {
    padding: 0 7.5px
}

.contact__container .contact-info__cards--wide {
    width: inherit
}

.contact__container .contact-info__card {
    background-color: #fff;
    margin: 2rem auto 0;
    padding: 1.8rem 1.5rem;
    max-width: 282px
}

@media (min-width: 992px) {
    .contact__container .contact-info__card {
        padding: 2.8rem 1.5rem;
        margin: 3rem auto 0
    }
}

.contact__container .contact-info__card-text {
    font-size: 1.625rem;
    font-weight: 900;
    line-height: 1.08;
    letter-spacing: -0.7px;
    margin-top: 0.25rem
}

@media (min-width: 992px) {
    .contact__container .contact-info__card-text {
        margin-top: 1rem
    }
}

.contact__container .contact-info__icon {
    font-size: 1.625rem;
    margin-bottom: 0
}

@media (min-width: 992px) {
    .contact__container .contact-info__icon {
        font-size: 2.5rem;
        margin-bottom: 1rem
    }
}

.contact__container .contact-info__btn {
    padding: 0.5rem 0.5rem;
    letter-spacing: 0.8px;
    width: 100%
}

@media (min-width: 992px) {
    .contact__container .contact-info__btn {
        padding: 0.8rem 0.5rem
    }
}

.contact__container .contact-info__location {
    margin-top: 2rem
}

    .contact__container .contact-info__location .contact-corp__title {
        margin-bottom: 0.25rem
    }

    .contact__container .contact-info__location .contact-info__btn {
        margin: 0.75rem 0 2.25rem;
        width: auto;
        padding: 0.6rem 1.75rem
    }

    .contact__container .contact-info__location .contact-info__text--top {
        margin-bottom: 0.5rem
    }

.contact__container .contact-corp__container {
    max-width: 540px;
    padding: 3rem 2.3rem 6.5rem
}

@media (min-width: 768px) {
    .contact__container .contact-corp__container {
        padding: 3rem 0 6.5rem
    }
}

.contact__container .contact-corp__container .contact-info__info {
    margin: 3rem 0 2rem
}

.contact__container .contact-corp__icon {
    font-size: 3rem;
    line-height: 1;
    letter-spacing: -1.2px;
    color: #999999
}

.contact__container .contact-corp__title {
    margin-top: 0.75rem
}

.image-list {
    list-style: none outside;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.image-list__item {
    float: left;
    min-height: 175px;
    margin: 0.25em;
    background: #fff;
    border: 1px solid #dedede;
    position: relative;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(50% - 0.5em);
    flex: 1 1 calc(50% - 0.5em);
    max-width: calc(50% - 0.5em);
    min-width: calc(50% - 0.5em);
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

@media (min-width: 992px) {
    .image-list__item {
        margin: 1em;
        min-height: 200px;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 calc(25% - 2em);
        flex: 1 1 calc(25% - 2em);
        max-width: calc(25% - 2em);
        min-width: calc(25% - 2em)
    }
}

.image-list__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1em;
    text-decoration: none;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.image-list__image {
    min-height: 1px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    -o-transition: transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
    display: block
}

.image-list__wrap:hover .image-list__image {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0)
}

.image-list__btn {
    background: #d90114;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 0.5em;
    position: absolute;
    left: 0;
    bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out
}

.image-list__wrap:hover .image-list__btn {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {
    .contact-us__wrapper {
        max-width: 590px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .contact-us__wrapper .contact-us__block:nth-child(1), .contact-us__wrapper .contact-us__block:nth-child(2) {
        margin-bottom: 1em
    }
}

@media (min-width: 1200px) {
    .contact-us__wrapper {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        max-width: none;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.contact-us__block {
    background: #fff;
    max-width: 282px;
    width: 100%
}

@media (max-width: 767px) {
    .contact-us__block {
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto
    }

        .contact-us__block:last-child {
            margin-bottom: 0
        }
}

@media (min-width: 1200px) {
    .contact-us__block {
        margin-right: 0.75em
    }

        .contact-us__block:last-child {
            margin-right: 0
        }
}

.contact-us__link {
    padding: 1.5em 1.5em calc(1.5em + 70px) 1.5em;
    text-decoration: none;
    display: block;
    position: relative;
    height: 100%
}

    .contact-us__link .btn {
        position: absolute;
        bottom: 1.5em;
        left: 1.5em;
        right: 1.5em
    }

.contact-us__icon {
    font-size: 2em;
    margin-bottom: 1em
}

.contact-us__heading {
    margin-top: 0;
    font-size: 1.35em;
    line-height: 1;
    letter-spacing: -0.7px;
    font-weight: 800
}

.contact-us__btn {
    padding: 1em
}

.interactive-photo-wrap {
    overflow-x: scroll
}

@media (min-width: 768px) {
    .interactive-photo-wrap {
        overflow: visible
    }
}

.interactive-photo {
    width: 200vw;
    margin-left: auto;
    margin-right: auto;
    background: transparent none center center no-repeat;
    background-size: contain;
    position: relative
}

@media (min-width: 768px) {
    .interactive-photo {
        max-width: 1480px;
        width: auto
    }
}

.interactive-photo:before {
    content: '';
    height: 0;
    display: block;
    padding-bottom: 47.3%
}

.interactive-photo__link-text {
    display: block;
    text-indent: -9999px
}

    .interactive-photo__link-text:before {
        content: '\f055';
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 0.5em
    }

@media (min-width: 992px) {
    .interactive-photo__link-text {
        font-size: 0.9rem;
        margin: 0;
        text-indent: 0;
        text-transform: uppercase;
        background: #d90114;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.35rem;
        text-align: center;
        font-weight: 700;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 1
    }
}

.interactive-photo__kicker {
    font-size: 0.9rem;
    display: block
}

.interactive-photo__heading {
    font-size: 1.65em;
    margin-bottom: 0.5rem;
    margin-top: 0
}

.interactive-photo__content-inner {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.5);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    overflow: hidden;
    padding: 1.5rem
}

@media (min-width: 992px) {
    .interactive-photo__content-inner {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0)
    }
}

.mfp-content .interactive-photo__content-inner {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.interactive-photo__content {
    font-size: 1rem;
    width: 335px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(calc(-50% - 15px)) translateY(calc(-50% - 45px));
    -ms-transform: translateX(calc(-50% - 15px)) translateY(calc(-50% - 45px));
    transform: translateX(calc(-50% - 15px)) translateY(calc(-50% - 45px));
    display: none
}

@media (min-width: 992px) {
    .interactive-photo__content {
        display: block
    }
}

.mfp-content .interactive-photo__content {
    opacity: 1;
    display: block;
    -webkit-transform: scale(1) translateX(0) translateY(0);
    -ms-transform: scale(1) translateX(0) translateY(0);
    transform: scale(1) translateX(0) translateY(0);
    position: static;
    margin-left: auto;
    width: 100%;
    max-width: 335px;
    margin-right: auto;
    pointer-events: auto
}

.interactive-photo__content--open {
    pointer-events: auto
}

    .interactive-photo__content--open .interactive-photo__content-inner {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

@media (min-width: 992px) {
    .interactive-photo__content {
        top: 0;
        left: 0;
        -webkit-transform: scale(0) translateX(0) translateY(0);
        -ms-transform: scale(0) translateX(0) translateY(0);
        transform: scale(0) translateX(0) translateY(0)
    }

    .interactive-photo__content--open {
        -webkit-transform: scale(1) translateX(-50%) translateY(-55%);
        -ms-transform: scale(1) translateX(-50%) translateY(-55%);
        transform: scale(1) translateX(-50%) translateY(-55%);
        -webkit-transform: scale(1) translateX(-50%) translateY(calc(-50% - 63px));
        -ms-transform: scale(1) translateX(-50%) translateY(calc(-50% - 63px));
        transform: scale(1) translateX(-50%) translateY(calc(-50% - 63px))
    }
}

.interactive-photo__pin-holder {
    position: absolute
}

@media (min-width: 992px) {
    .interactive-photo__pin-holder--left-edge .interactive-photo__content--open {
        -webkit-transform: scale(1) translateX(-15%) translateY(-50%);
        -ms-transform: scale(1) translateX(-15%) translateY(-50%);
        transform: scale(1) translateX(-15%) translateY(-50%);
        -webkit-transform: scale(1) translateX(calc(-50% + 100px)) translateY(calc(-50% - 126px));
        -ms-transform: scale(1) translateX(calc(-50% + 100px)) translateY(calc(-50% - 126px));
        transform: scale(1) translateX(calc(-50% + 100px)) translateY(calc(-50% - 126px))
    }

    .interactive-photo__pin-holder--right-edge .interactive-photo__content--open {
        -webkit-transform: scale(1) translateX(-50%) translateY(-15%);
        -ms-transform: scale(1) translateX(-50%) translateY(-15%);
        transform: scale(1) translateX(-50%) translateY(-15%);
        -webkit-transform: scale(1) translateX(calc(-50% - 100px)) translateY(calc(-50%));
        -ms-transform: scale(1) translateX(calc(-50% - 100px)) translateY(calc(-50%));
        transform: scale(1) translateX(calc(-50% - 100px)) translateY(calc(-50%))
    }
}

.interactive-photo__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 1
}

.mfp-content .interactive-photo__close {
    display: none
}

.interactive-photo__close span {
    display: block;
    text-indent: -9999px
}

.interactive-photo__close:before {
    content: '\f057';
    font-family: FontAwesome
}

.interactive-photo__pin {
    background: #fff;
    width: 30px;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    border: 6px solid #d90114;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 15px #000;
    box-shadow: 0 0 15px #000;
    -webkit-transform: translateX(-15px) translateY(-38px);
    -ms-transform: translateX(-15px) translateY(-38px);
    transform: translateX(-15px) translateY(-38px);
    text-decoration: none
}

    .interactive-photo__pin:before {
        content: '';
        top: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        border-top: 15px solid #d90114;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent
    }

    .interactive-photo__pin:after {
        content: '';
        height: 12px;
        width: 12px;
        background: #d01b32;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        border-radius: 100%
    }

@media (min-width: 992px) {
    .interactive-photo__pin {
        width: 95px;
        height: 110px;
        padding-top: 0.25rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transform: translateX(-47.5px) translateY(-126px);
        -ms-transform: translateX(-47.5px) translateY(-126px);
        transform: translateX(-47.5px) translateY(-126px);
        border-radius: 0;
        border: none
    }

        .interactive-photo__pin:after {
            content: none
        }
}

.interactive-photo__pin--point-up {
    -webkit-transform: translateX(-15px) translateY(15px);
    -ms-transform: translateX(-15px) translateY(15px);
    transform: translateX(-15px) translateY(15px)
}

@media (min-width: 992px) {
    .interactive-photo__pin--point-up {
        -webkit-transform: translateX(-47.5px) translateY(15px);
        -ms-transform: translateX(-47.5px) translateY(15px);
        transform: translateX(-47.5px) translateY(15px)
    }

        .interactive-photo__pin--point-up + .interactive-photo__content--open {
            -webkit-transform: scale(1) translateX(-50%) translateY(calc(-50% - 0px));
            -ms-transform: scale(1) translateX(-50%) translateY(calc(-50% - 0px));
            transform: scale(1) translateX(-50%) translateY(calc(-50% - 0px))
        }

    .interactive-photo__pin-holder--left-edge .interactive-photo__pin--point-up + .interactive-photo__content--open {
        -webkit-transform: scale(1) translateX(-40%) translateY(-40%);
        -ms-transform: scale(1) translateX(-40%) translateY(-40%);
        transform: scale(1) translateX(-40%) translateY(-40%);
        -webkit-transform: scale(1) translateX(calc(-50% + 100px)) translateY(-50%);
        -ms-transform: scale(1) translateX(calc(-50% + 100px)) translateY(-50%);
        transform: scale(1) translateX(calc(-50% + 100px)) translateY(-50%)
    }

    .interactive-photo__pin-holder--right-edge .interactive-photo__pin--point-up + .interactive-photo__content--open {
        -webkit-transform: scale(1) translateX(-65%) translateY(-40%);
        -ms-transform: scale(1) translateX(-65%) translateY(-40%);
        transform: scale(1) translateX(-65%) translateY(-40%);
        -webkit-transform: scale(1) translateX(calc(-50% - 100px)) translateY(-50%);
        -ms-transform: scale(1) translateX(calc(-50% - 100px)) translateY(-50%);
        transform: scale(1) translateX(calc(-50% - 100px)) translateY(-50%)
    }
}

.interactive-photo__pin--point-up:before {
    top: -100%;
    top: calc(-100% + 2px);
    border-top: none;
    border-bottom: 15px solid #d90114
}

@media (min-width: 992px) {
    .interactive-photo__pin--point-up:before {
        top: -15px;
        border-bottom-color: #fff
    }
}

.interactive-photo__pin-image {
    display: none
}

@media (min-width: 992px) {
    .interactive-photo__pin-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 80px
    }
}

.interactive-photo__content-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%
}

.video-container {
    position: relative;
    display: block;
    max-width: 970px;
    margin: 2.625rem auto 0
}

.video-container--full {
    width: 100%
}

@media (max-width: 414px) {
    .video-container {
        padding: 0 15px;
        margin: 2.25rem auto 0
    }
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden
}

    .video-wrapper__iframe, .video-wrapper .video-embedded-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }

.video-thumbnail {
    position: relative;
    display: block;
    max-width: 1172px;
    margin: 2.625rem auto 0;
    text-decoration: none
}

@media (max-width: 414px) {
    .video-thumbnail {
        padding: 0 15px;
        margin: 2.25rem auto 0
    }
}

.video-thumbnail__img {
    width: 100%;
    display: block
}

.video-thumbnail:after {
    display: inline-block;
    font-family: FontAwesome;
    font-size: 100px;
    color: #ffffff;
    content: '\f144';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: 100px
}

@media (max-width: 414px) {
    .video-thumbnail:after {
        font-size: 52px;
        height: 52px
    }
}

.swipe-for-more {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    display: block
}

    .swipe-for-more:before {
        content: '';
        display: block;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        background: transparent url("/dist/images/swipe-icon.svg") center center no-repeat;
        margin-bottom: 0.5rem
    }

@media (min-width: 768px) {
    .swipe-for-more {
        display: none
    }
}

.quote {
    text-align: center
}

.quote__blockquote {
    font-size: 1.65rem;
    line-height: 1.25;
    max-width: 95%;
    margin: 0 auto 2.5rem
}

    .quote__blockquote:after {
        content: '';
        margin-top: 1.5em;
        max-width: 305px;
        width: 50%;
        height: 1px;
        background-color: #a1a1a1;
        display: block;
        margin-left: auto;
        margin-right: auto
    }

@media (min-width: 768px) {
    .quote__blockquote {
        font-size: 1.75rem
    }
}

@media (min-width: 992px) {
    .quote__blockquote {
        font-size: 48px
    }
}

.quote__citation {
    font-style: normal
}

.quote__name {
    display: block;
    font-weight: 700
}

.quote__image {
    margin: 2rem auto;
    max-width: 100px
}

.smartlocks-basics__container {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
}

@media (max-width: 414px) {
    .smartlocks-basics__container--installation .single-img-banner__mobile-image {
        margin: 0 auto 1.35rem
    }
}

.smartlocks-basics__container--browse .heading--sub {
    margin-bottom: 2.5rem
}

.smartlocks-basics__container--browse .btn {
    margin-bottom: 2.2rem
}

@media (max-width: 414px) {
    .smartlocks-basics__container--browse img {
        max-width: 266px
    }

    .smartlocks-basics__container--browse .btn {
        margin-bottom: 0.6rem
    }
}

@media (max-width: 414px) {
    .smartlocks-basics__container {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem
    }

        .smartlocks-basics__container .video-container {
            margin: 1.65rem auto 0
        }
}

.video-container--no-mt {
    margin-top: 0
}

.innovation-detail-header-main {
    padding-top: 3.85rem
}

    .innovation-detail-header-main .heading {
        margin-top: 0;
        margin-bottom: 1rem
    }

    .innovation-detail-header-main .subheading {
        margin-bottom: 0
    }

@media (min-width: 992px) {
    .innovation-detail-header-main {
        padding-top: 5.75rem
    }
}

.innovation-detail-header-support {
    margin-top: 4rem;
    margin-bottom: 3rem
}

@media (min-width: 992px) {
    .innovation-detail-header-support {
        margin-top: 6rem;
        margin-bottom: 6rem
    }
}

.innovation-detail-header-support--cta {
    margin-top: 3rem;
    margin-bottom: 1.25rem
}

@media (min-width: 415px) {
    .innovation-detail-header-support--cta {
        margin-top: 6.5rem
    }
}

.innovation-detail-image {
    margin: 2.625rem auto 0
}

.innovation-detail-image--cross {
    max-width: 435px;
    width: 100%
}

.innovation-detail-image--kobalt {
    width: 254px
}

.innovation-detail-image--wider {
    max-width: 600px;
    width: 100%
}

.innovation-detail-product-wrapper {
    padding-top: 2rem;
    padding-bottom: 3rem
}

@media (min-width: 992px) {
    .innovation-detail-product-wrapper {
        padding-top: 6rem;
        padding-bottom: 4rem
    }
}

.innovation-detail-product-wrapper .heading {
    margin-top: 0;
    font-size: 1.625rem
}

.single-image {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto
}

    .single-image img {
        margin-left: auto;
        margin-right: auto
    }

.single-image--with-overlay {
    height: 600px
}

.comparison-table-holder {
    position: relative
}

.comparison-table-holder__btn {
    overflow: hidden;
    text-decoration: none;
    display: block;
    text-align: center;
    width: 35px;
    height: 71px;
    line-height: 71px;
    position: absolute;
    font-size: 30px;
    top: 0;
    background: red;
    color: #fff;
    z-index: 1;
    -webkit-transition: background-color 0.25s, color 0.25s;
    -o-transition: background-color 0.25s, color 0.25s;
    transition: background-color 0.25s, color 0.25s
}

    .comparison-table-holder__btn:hover {
        background-color: red;
        color: #fff
    }

    .comparison-table-holder__btn:before {
        font-family: 'FontAwesome';
        display: block
    }

.comparison-table-holder__btn--left {
    left: 0
}

    .comparison-table-holder__btn--left:before {
        content: '\f104'
    }

.comparison-table-holder__btn--right {
    right: 0
}

    .comparison-table-holder__btn--right:before {
        content: '\f105'
    }

.comparison-table .fa {
    font-size: 2.5em
}

@media (min-width: 992px) {
    .comparison-table .fa {
        font-size: 1em
    }
}

.comparison-table .fa-check {
    color: #1ca563
}

.comparison-table .fa-close {
    color: #d90114
}

.comparison-table__product-variant {
    font-weight: normal;
    display: block
}

.stacked-banner {
    padding-top: 3rem;
    padding-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 41rem;
    height: 1rem;
    margin: 0 auto .5rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: url("/dist/images/kwikset/row-of-clipped.png") center center no-repeat;
    background-size: contain;
    background-position-y: 38%
}

@media screen and (min-width: 375px) {
    .stacked-banner {
        background-position-y: 43%
    }
}

@media screen and (min-width: 768px) {
    .stacked-banner {
        min-height: 50rem;
        background-position-y: 52%
    }
}

@media screen and (min-width: 1024px) {
    .stacked-banner {
        background-position-y: 48%;
        min-height: 64rem;
        margin-bottom: 2rem
    }
}

.stacked-banner__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 1
}

@media screen and (min-width: 1024px) {
    .stacked-banner__header {
        padding-bottom: 1rem
    }
}

.stacked-banner__header__left {
    text-align: center;
    margin: 0 auto
}

@media screen and (min-width: 768px) {
    .stacked-banner__header__left {
        text-align: left;
        margin: 0
    }
}

.stacked-banner__header__left h1 {
    font-weight: 900;
    line-height: 1;
    font-size: 3rem;
    margin: 0;
    color: #010000
}

@media screen and (min-width: 375px) {
    .stacked-banner__header__left h1 {
        font-size: 3.5rem;
        letter-spacing: -5.5px
    }
}

@media screen and (min-width: 768px) {
    .stacked-banner__header__left h1 {
        max-width: 70%;
        font-size: 5.5rem;
        margin-left: -4px
    }
}

@media screen and (min-width: 1024px) {
    .stacked-banner__header__left h1 {
        padding-top: 1rem;
        font-size: 110.6px;
        max-width: 80%
    }
}

@media screen and (min-width: 1500px) {
    .stacked-banner__header__left h1 {
        max-width: 70%
    }
}

.stacked-banner__header__left h2 {
    margin: 0 0 .75rem;
    font-size: 14px;
    letter-spacing: 2.8px
}

@media screen and (min-width: 768px) {
    .stacked-banner__header__left h2 {
        font-size: 1rem
    }
}

.stacked-banner__header__right {
    display: none;
    font-size: 14px;
    width: 172px
}

@media screen and (min-width: 768px) {
    .stacked-banner__header__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        padding-top: 1.5rem;
        width: 250px
    }
}

@media screen and (min-width: 1024px) {
    .stacked-banner__header__right {
        padding-top: 6rem
    }
}

.stacked-banner__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 20px auto 0;
    color: #000000;
    letter-spacing: -0.3px
}

@media screen and (min-width: 1024px) {
    .stacked-banner__footer {
        padding-bottom: 1rem
    }
}

.stacked-banner__footer h2 {
    margin: 0;
    font-size: 26px;
    font-weight: 900;
    line-height: 1.15
}

.stacked-banner__footer p {
    font-size: 16px;
    line-height: 1.6;
    max-width: 95%
}

@media screen and (min-width: 768px) {
    .stacked-banner__footer p {
        font-size: 18px
    }
}

@media screen and (min-width: 1024px) {
    .stacked-banner__footer p {
        max-width: 75%
    }
}

@media screen and (min-width: 1024px) {
    .stacked-banner__footer p {
        max-width: 745px
    }
}

.stacked-banner .stacking-mobile-img {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

@media screen and (min-width: 992px) {
    .stacking-background {
        position: relative
    }
}

.stacking-background__img img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block
}

@media screen and (min-width: 992px) {
    .stacking-background__img .stacking-mobile-img {
        display: none
    }
}

.stacking-background__img .stacking-desktop-img {
    display: none
}

@media screen and (min-width: 992px) {
    .stacking-background__img .stacking-desktop-img {
        display: block
    }
}

@media screen and (min-width: 992px) {
    .stacking-background__container {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }
}

.stacking-background__container--left {
    right: -32%
}

@media screen and (min-width: 1200px) {
    .stacking-background__container--left {
        right: -36%
    }
}

.stacking-background__container--right {
    left: 80%
}

.stacking-background__quote {
    color: #d90014;
    font-size: 30px;
    text-align: center;
    padding-top: 2rem
}

.stacking-background__content {
    font-size: 26px;
    text-align: center;
    font-weight: 900
}

@media screen and (min-width: 992px) {
    .stacking-background__content {
        max-width: 80%;
        margin: 0 auto
    }
}

.stacking-background__quotee {
    font-size: 16px;
    text-align: center
}

.stacking-background__title {
    font-size: 26px;
    text-align: center;
    margin-top: 0;
    padding-top: 1rem;
    font-weight: 900
}

@media screen and (min-width: 992px) {
    .stacking-background__title {
        text-align: left
    }
}

.stacking-background__main {
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: -0.4px;
    max-width: 90%;
    margin: 0 auto
}

@media screen and (min-width: 425px) {
    .stacking-background__main {
        max-width: 85%
    }
}

@media screen and (min-width: 992px) {
    .stacking-background__main {
        text-align: left;
        width: 20rem;
        margin: 0
    }
}

.flexible-pb {
    padding-bottom: 1.5em
}

@media screen and (min-width: 768px) {
    .flexible-pb {
        padding-bottom: 3rem
    }
}

@media screen and (min-width: 1024px) {
    .flexible-pb {
        padding-bottom: 4rem
    }
}

@media screen and (min-width: 1200px) {
    .flexible-pb {
        padding-bottom: 5rem
    }
}

.double-col {
    max-width: 1115px;
    margin: 0 -15px;
    padding-top: 2rem;
    padding-bottom: 4rem
}

@media screen and (min-width: 768px) {
    .double-col {
        background-color: white
    }
}

.double-col__title {
    font-size: 26px;
    font-weight: 900;
    line-height: 1.15;
    letter-spacing: -0.6px;
    text-align: center
}

@media screen and (min-width: 768px) {
    .double-col__title {
        max-width: 35rem;
        text-align: left;
        margin: 0 auto
    }
}

@media screen and (min-width: 1200px) {
    .double-col__title {
        margin-left: 3rem
    }
}

.double-col__content {
    text-align: center;
    max-width: 85%;
    margin: 2rem auto
}

@media screen and (min-width: 468px) {
    .double-col__content {
        max-width: 40rem
    }
}

@media screen and (min-width: 768px) {
    .double-col__content {
        text-align: left;
        max-width: 35rem;
        font-size: 18px
    }
}

@media screen and (min-width: 1200px) {
    .double-col__content {
        margin-left: 3rem
    }
}

@media screen and (min-width: 768px) {
    .double-col__first-img {
        padding-top: 15%
    }
}

@media screen and (min-width: 768px) {
    .double-col__second-column {
        margin-top: .5rem
    }
}

.text-next-vid__title {
    margin-top: 0;
    text-align: center
}

@media screen and (min-width: 992px) {
    .text-next-vid__title {
        text-align: left;
        max-width: 24rem;
        margin: auto auto
    }
}

.text-next-vid__body {
    text-align: center;
    width: 95%;
    max-width: 40rem;
    margin: 0 auto
}

@media screen and (min-width: 992px) {
    .text-next-vid__body {
        text-align: left;
        max-width: 24rem;
        margin-top: 1rem
    }
}

@media screen and (min-width: 992px) {
    .text-next-vid__body--wide {
        max-width: 28rem;
        width: 100%
    }
}

@media screen and (min-width: 992px) {
    .text-next-vid--left * {
        margin-left: 0
    }
}

.ks-video {
    position: relative
}

    .ks-video:after {
        content: '';
        position: absolute;
        height: 87px;
        width: 87px;
        background-image: url("/dist/images/kwikset/icon-play-video.svg");
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

.topic-wrapper {
    max-width: 1000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 auto -2rem;
    padding-left: 0
}

.topic-wrapper--wide {
    max-width: 1200px
}

.topic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% - 2rem);
    margin: 0 1rem 2rem;
    height: 5rem;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    padding: 0;
    position: relative;
    text-align: center
}

    .topic h4 {
        margin: 0;
        font-weight: 800
    }

    .topic p {
        margin: 0;
        font-size: 1rem
    }

.topic__link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff
}

    .topic__link:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid transparent;
        -webkit-transition: border-color 0.25s, border-width 0.25s;
        -o-transition: border-color 0.25s, border-width 0.25s;
        transition: border-color 0.25s, border-width 0.25s
    }

    .topic__link:hover:before {
        border-color: #e6e6e6;
        border-width: 7px
    }

@media screen and (min-width: 768px) {
    .topic {
        width: calc(50% - 2rem)
    }
}

@media screen and (min-width: 1200px) {
    .topic {
        width: calc(33% - 2rem)
    }
}

@media (min-width: 992px) {
    .horizontal-card-wrapper--inline-wrapper {
        max-width: 70%
    }
}

.horizontal-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto -2rem;
    padding-left: 0
}

    .horizontal-card-wrapper:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: calc(50% - 2rem)
    }

.horizontal-card-wrapper--inline {
    max-width: calc(100%);
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0
}

@media (min-width: 992px) {
    .horizontal-card-wrapper--inline {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 22rem
    }
}

@media (min-width: 1088px) {
    .horizontal-card-wrapper--inline {
        height: 19rem
    }
}

@media (min-width: 1100px) {
    .horizontal-card-wrapper--inline {
        height: 22rem
    }
}

@media (min-width: 1264px) {
    .horizontal-card-wrapper--inline {
        height: 16rem
    }
}

.horizontal-card-wrapper--support {
    width: 100%;
    height: auto;
    margin-top: 3rem;
    margin-bottom: -1rem
}

.horizontal-card {
    position: relative;
    background-color: white;
    text-decoration: none;
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    min-height: 107px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    padding-left: 3rem;
    padding-right: 1rem
}

@media (min-width: 425px) {
    .horizontal-card {
        padding-left: 4rem;
        padding-right: 2rem
    }
}

@media (min-width: 992px) {
    .horizontal-card {
        padding-left: 4rem;
        padding-right: 1rem
    }
}

@media (min-width: 1200px) {
    .horizontal-card {
        margin: 0 auto 2rem;
        width: calc(50% - 2rem)
    }
}

@media (min-width: 1024px) {
    .horizontal-card--full-width {
        max-width: 100%;
        width: 100%
    }
}

.horizontal-card--inline {
    width: calc(100%);
    margin: 0;
    margin-bottom: 1rem
}

@media (min-width: 768px) {
    .horizontal-card--inline {
        margin-bottom: 3rem
    }
}

@media (min-width: 992px) {
    .horizontal-card--inline {
        width: calc(48%);
        margin-bottom: 0
    }
}

.horizontal-card__content {
    margin-top: 0;
    margin-bottom: .25rem;
    font-size: 20px
}

.horizontal-card__sub-content {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.85em
}

.horizontal-card__icon {
    position: absolute;
    font-size: 1.25rem;
    top: 1.5rem;
    left: 1.5rem;
    color: #d90114
}

@media (min-width: 768px) {
    .horizontal-card__icon {
        font-size: 1.5rem
    }
}

.horizontal-card__icon--answer {
    font-size: 1.5rem
}

.horizontal-card__link {
    text-decoration: none;
    padding-left: .5rem
}

    .horizontal-card__link:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid transparent;
        -webkit-transition: border-color 0.25s, border-width 0.25s;
        -o-transition: border-color 0.25s, border-width 0.25s;
        transition: border-color 0.25s, border-width 0.25s
    }

    .horizontal-card__link:hover:before {
        border-color: #e6e6e6;
        border-width: 7px
    }

.notice-popup {
    margin: 0 auto;
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.2);
    width: 95%;
    max-width: 953px;
    min-height: 131px;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2rem 0 2rem 1rem
}

.notice-popup__icon {
    position: absolute;
    top: 10%;
    left: 4%;
    color: red;
    font-size: 2rem
}

@media (min-width: 425px) {
    .notice-popup__icon {
        top: 11%;
        left: 3%;
        font-size: 2.5rem
    }
}

@media (min-width: 768px) {
    .notice-popup__icon {
        top: 25%;
        left: 3.5%;
        font-size: 4rem
    }
}

.notice-popup__wrapper {
    width: 80%
}

@media (min-width: 768px) {
    .notice-popup__wrapper {
        width: 76%
    }
}

.notice-popup__title {
    margin: 0;
    margin-bottom: .5rem;
    padding: 0;
    font-size: 18px
}

@media (min-width: 768px) {
    .notice-popup__title {
        margin-bottom: 0
    }
}

.notice-popup__content {
    margin: 0;
    font-size: 1rem
}

.notice-popup__exit {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.5rem
}

.searchbar {
    border: 1px solid #dedede;
    width: 100%;
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0 0 0.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 425px) {
    .searchbar {
        padding: 1em
    }
}

@media (min-width: 768px) {
    .searchbar {
        height: 96px;
        padding: 1.5em
    }
}

.searchbar__clear {
    background: transparent;
    border: none;
    font-size: 1rem;
    padding: 0.8rem;
    line-height: 1;
    display: none
}

    .searchbar__clear:before {
        content: '\2573'
    }

.searchbar__icon {
    font-size: 1.5rem;
    margin: 0
}

@media (min-width: 375px) {
    .searchbar__icon {
        margin: 0 1rem 0 0
    }
}

@media (min-width: 768px) {
    .searchbar__icon {
        margin: 0 2rem 0 0;
        font-size: 2.5rem
    }
}

.searchbar__input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: none;
    width: 100%
}

.searchbar__input--filled + .searchbar__clear {
    display: block
}

.searchbar__input:focus {
    outline: none
}

.searchbar ::-webkit-input-placeholder {
    color: black;
    font-size: 14px;
    line-height: 1.88;
    letter-spacing: -0.4px
}

.searchbar :-ms-input-placeholder {
    color: black;
    font-size: 14px;
    line-height: 1.88;
    letter-spacing: -0.4px
}

.searchbar ::-ms-input-placeholder {
    color: black;
    font-size: 14px;
    line-height: 1.88;
    letter-spacing: -0.4px
}

.searchbar ::placeholder {
    color: black;
    font-size: 14px;
    line-height: 1.88;
    letter-spacing: -0.4px
}

@media (min-width: 375px) {
    .searchbar ::-webkit-input-placeholder {
        font-size: 16px
    }

    .searchbar :-ms-input-placeholder {
        font-size: 16px
    }

    .searchbar ::-ms-input-placeholder {
        font-size: 16px
    }

    .searchbar ::placeholder {
        font-size: 16px
    }
}

.searchbar button[type="submit"] {
    display: none
}

@media (min-width: 550px) {
    .searchbar button[type="submit"] {
        display: block
    }
}

.support-banner {
    position: relative
}

.support-banner--detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 992px) {
    .support-banner--detail {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (min-width: 992px) {
    .support-banner--interactive-guide {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.support-banner__left #ytplayer {
    max-width: 100%
}

.support-banner__guide {
    width: 100%;
    height: 10rem;
    background-color: blue
}

@media (min-width: 992px) {
    .support-banner__guide {
        width: 43rem;
        height: 30rem
    }
}

.support-banner__right {
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 30rem
}

@media (min-width: 992px) {
    .support-banner__right {
        max-width: 45%;
        margin-left: 2rem
    }
}

.support-banner__icon {
    font-size: 2.25rem;
    color: #d90114
}

@media (min-width: 1024px) {
    .support-banner__icon {
        position: absolute;
        left: -35px;
        font-size: 2rem
    }
}

@media (min-width: 1440px) {
    .support-banner__icon {
        left: -45px;
        font-size: 2.25rem
    }
}

.support-banner__superheader {
    display: inline;
    text-transform: uppercase;
    padding-left: .5rem
}

@media (min-width: 1024px) {
    .support-banner__superheader {
        display: none
    }
}

.support-banner__title {
    font-size: 1.8rem;
    max-width: 688px;
    color: #000;
    font-weight: 900;
    line-height: 1.2
}

.support-banner__title--detail {
    text-align: center
}

@media (min-width: 992px) {
    .support-banner__title--detail {
        text-align: left
    }
}

.support-banner__title--interactive-guide {
    text-align: center
}

.support-banner__subtitle {
    text-align: center
}

@media (min-width: 425px) {
    .support-banner__subtitle {
        text-align: left
    }
}

.support-banner__content {
    max-width: 730px;
    font-size: 1.125rem;
    line-height: 1.67;
    letter-spacing: -0.2px;
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center
}

@media (min-width: 425px) {
    .support-banner__content {
        text-align: left
    }
}

.support-banner__subcontent {
    text-align: center
}

@media (min-width: 425px) {
    .support-banner__subcontent {
        text-align: left
    }
}

.support-banner__buttons {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

@media (min-width: 425px) {
    .support-banner__buttons {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (min-width: 1024px) {
    .support-banner__buttons {
        margin: initial
    }
}

.support-banner__button {
    background-color: #fff;
    border: 1px solid #000;
    min-width: 7rem;
    padding: .5rem 0;
    font-weight: 500;
    letter-spacing: 0.8px
}

@media (min-width: 425px) {
    .support-banner__button {
        margin-right: 1.5rem
    }
}

.blog-nav {
    background-color: #000;
    color: #fff
}

.blog-nav__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1420px;
    height: 9em;
    padding: 1rem 0;
    margin: 0 auto
}

@media (min-width: 992px) {
    .blog-nav__wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        justifiy-content: space-between;
        height: 5rem;
        padding: 0 2.5rem
    }
}

.blog-nav__icon {
    margin-right: 1rem;
    height: 32px;
    width: 38px
}

.blog-nav__title {
    font-size: 1.25rem;
    letter-spacing: -0.3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width: 992px) {
    .blog-nav__title {
        font-size: 1.5rem
    }
}

.blog-nav__select {
    color: #000;
    border-radius: 2px
}

@media (max-width: 991px) {
    .blog-nav .item-sort {
        padding: .6rem
    }
}

.cloud-list {
    width: 95%;
    max-width: 890px;
    margin: auto auto
}

.cloud-list__title--in-article {
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: -1rem
}

.cloud-list__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid #dfdfdf;
    padding-left: 0
}

.cloud-list__list--no-line {
    border-top: none
}

.cloud-list__item {
    list-style-type: none;
    margin: 1.5rem 1rem .5rem 0
}

.cloud-list__link {
    font-style: italic;
    text-decoration: none;
    background-color: white;
    padding: .5rem .85rem;
    border-radius: 8px;
    border: 1px solid black
}

    .cloud-list__link:hover {
        color: white;
        background-color: black
    }

.blog-listing {
    max-width: 890px;
    margin: auto auto
}

.blog-listing__article {
    position: relative;
    border-bottom: 1px solid #dfdfdf;
    margin-top: 2.5rem
}

.blog-listing__title {
    font-weight: 800
}

.blog-listing__tags {
    font-style: italic
}

    .blog-listing__tags a:hover {
        color: #d90114
    }

.blog-listing__text {
    height: 8rem;
    overflow-y: hidden
}

@media (min-width: 992px) {
    .blog-listing__text {
        min-height: 10rem
    }
}

.blog-listing__link {
    padding-bottom: 2.5rem;
    cursor: pointer
}

    .blog-listing__link:hover {
        color: #d90114
    }

.blog-listing__icon {
    padding-left: 1rem;
    color: #d90114
}

.blog-article {
    width: 95%;
    max-width: 890px;
    margin: auto auto;
    border-bottom: 1px solid #dfdfdf
}

.blog-article__text-block {
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    letter-spacing: -0.04em;
    font-size: 1rem;
    line-height: 1.38;
    color: #2d2c2c
}

@media (min-width: 768px) {
    .blog-article__text-block {
        font-size: 1.25rem
    }
}

.blog-article__text-block--link {
    text-decoration: none;
    border-bottom: 2px solid #e6e6e6;
    cursor: pointer
}

    .blog-article__text-block--link:hover {
        color: #d90114
    }

.blog-article__ordered-list {
    padding-left: 1.5rem
}

.addthis-wrapper {
    border: 1px solid #e6e6e6;
    border-top: 6px solid #cacaca;
    padding: 1.25rem 2rem 2rem;
    max-width: 890px;
    margin-left: auto;
    margin-right: auto
}

.addthis-wrapper__title {
    font-family: 'Gotham', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .8px;
    margin-bottom: 1.5rem;
    text-transform: uppercase
}

.addthis-wrapper .at-share-btn-elements {
    max-width: 502px
}

@media (min-width: 768px) {
    .addthis-wrapper .at-share-btn-elements {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 992px) {
    .comparison-table-holder {
        font-size: 0.9rem;
        max-width: 1280px;
        position: relative
    }

        .comparison-table-holder:before {
            content: '';
            width: 1px;
            height: 100%;
            background: #e5e5e5;
            display: block;
            position: absolute;
            left: 14.2857143%;
            top: 0;
            z-index: 1
        }

    .comparison-table-holder--3-cols:before {
        left: 33.3333333%
    }

    .comparison-table-holder__btn {
        display: none;
        background: #f4f4f4;
        color: currentColor;
        top: 40px
    }

    .comparison-table-holder__btn--left {
        left: 200px
    }

    .comparison-table-holder__btn--right {
        right: 0
    }

    .comparison-table-holder__btn-text {
        display: block;
        text-indent: -9999px
    }

    .comparison-table-wrapper {
        max-width: 1280px;
        position: relative
    }

    .comparison-table {
        border-collapse: collapse;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        width: 100%;
        table-layout: fixed
    }

        .comparison-table .btn {
            padding: 0.5rem;
            font-size: 0.8rem
        }

        .comparison-table td, .comparison-table th {
            border: 1px solid #e5e5e5;
            padding: 1rem
        }

    .comparison-table__product {
        font-weight: bold;
        min-width: 110px
    }

    .comparison-table__product--empty {
        min-width: none;
        background: #fff;
        position: -webkit-sticky;
        position: sticky;
        left: 0
    }

    .comparison-table__product-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-bottom: 1rem
    }

    .comparison-table__body-header {
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: inherit;
        min-width: 200px
    }

    .comparison-table__body-header--details {
        background-color: #fff;
        color: #000
    }

    .comparison-table__row {
        background-color: #fff
    }

        .comparison-table__row:nth-child(odd) {
            background: #f4f4f4
        }

    .comparison-table__row--details {
        background: #fff
    }

        .comparison-table__row--details:nth-child(odd) {
            background: inherit
        }
}

@media (min-width: 1100px) {
    .comparison-table__body-header {
        font-size: 1.1em
    }
}

@media (max-width: 991px) {
    .comparison-table-holder {
        background: #000;
        padding-top: 1px;
        font-size: 1rem
    }

    .comparison-table-wrap {
        overflow: hidden;
        width: 100vw
    }

    .comparison-table-wrapper {
        background: #fff
    }

    .comparison-table {
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        margin-top: 70px
    }

    .comparison-table__header {
        width: 50%;
        border-left: 1px solid #e4e1df;
        display: block;
        font-weight: 700
    }

    .comparison-table__header-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .comparison-table__body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 50%;
        border-right: 1px solid #e4e1df
    }

    .comparison-table__product, .comparison-table__item {
        min-height: 240px;
        height: 1px
    }

    .comparison-table__product {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0.5rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-right: 1px solid #e4e1df;
        border-bottom: 1px solid #e4e1df
    }

    .comparison-table__product--empty {
        min-height: 0;
        display: none
    }

    .comparison-table__product-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5em
    }

    .comparison-table__body-header {
        position: absolute;
        padding: 0;
        height: 70px;
        top: -70px;
        padding-left: 45px;
        padding-right: 45px;
        left: 0;
        right: 0;
        color: #fff;
        background: #000;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-weight: 700
    }

    .comparison-table__body-header--visible {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        opacity: 1
    }

    .comparison-table__item {
        border-bottom: 1px solid #e4e1df
    }

    .comparison-table__row {
        width: 100%;
        display: block
    }

        .comparison-table__row .comparison-table__item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center
        }

    .comparison-table__row--hidden {
        display: none
    }
}

@media (min-width: 992px) {
    .image-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (min-width: 992px) {
    .image-content--light-text {
        color: #fff
    }
}

.image-content--background {
    position: relative
}

@media (max-width: 991px) {
    .image-content--text-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

        .image-content--text-top .image-content__image-wrap {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1
        }
}

.image-content__image-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

@media (min-width: 992px) {
    .image-content__image-wrap {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
        max-width: 50%
    }

    .image-content--content-right .image-content__image-wrap {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }
}

.image-content__picture {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.no-flexbox .image-content__picture {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none
}

.image-content__image {
    max-width: 100%;
    min-width: 1px
}

.image-content__image--hidden {
    display: none
}

.image-content__image--tablet-sm {
    max-width: 300px
}

@media (min-width: 992px) {
    .image-content__image--tablet-sm {
        max-width: 100%
    }
}

.image-content__content-wrap {
    padding: 1.5em
}

@media (min-width: 992px) {
    .image-content__content-wrap {
        padding: 0;
        width: 100%;
        max-width: 50%
    }
}

@media (min-width: 992px) {
    .image-content--background .image-content__content-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (min-width: 992px) {
    .image-content--background.image-content--content-right .image-content__content-wrap {
        left: 50%
    }
}

.image-content__content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    max-width: 500px;
    width: 100%;
    min-width: 1px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

    .image-content__content > *:first-child {
        margin-top: 0
    }

    .image-content__content > * {
        width: 100%
    }

@media (min-width: 992px) {
    .image-content__content {
        margin: 0;
        max-width: none;
        text-align: left;
        height: 100%;
        padding: 2rem;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .image-content--has-overlay .image-content__content {
        background: rgba(255,255,255,0.75);
        height: auto
    }
}

@media (min-width: 992px) {
    .image-content__content {
        padding: 2rem;
    }
}

@media (max-width: 1250px) and (min-width:992px) {
    .image-content__content {
       font-size: 0.895rem !important;
    }
}

.image-content--rows {
    padding: 20px 0 0
}

@media (min-width: 992px) {
    .image-content--rows {
        padding: 120px 0 40px
    }
}

.image-content--rows .image-content__image-wrap {
    display: none
}

@media (min-width: 992px) {
    .image-content--rows .image-content__image-wrap {
        display: block;
        padding-left: 100px
    }
}

@media (min-width: 992px) {
    .image-content__rows {
        padding-bottom: 70px
    }
}

.image-content__row {
    margin: 0 -40px
}

@media (min-width: 768px) {
    .image-content__row {
        padding: 20px 0
    }
}

@media (min-width: 992px) {
    .image-content__row {
        padding: 50px 0
    }
}

.image-content__row:before, .image-content__row:after {
    content: '';
    display: table
}

.image-content__row:after {
    clear: both
}

@media (max-width: 993px) {
    .image-content__row:first-of-type {
        padding: 40px 0 20px
    }
}

.image-content__row-item {
    padding: 20px 40px
}

@media (min-width: 768px) {
    .image-content__row-item {
        width: 50%;
        padding: 0 40px;
        float: left
    }
}

@media (min-width: 992px) {
    .image-content-halves {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.image-content-halves__content-wrap {
    text-align: center
}

@media (min-width: 992px) {
    .image-content-halves__content-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 50%;
        text-align: left
    }
}

.image-content-halves__content {
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
}

.image-content-halves__image-wrap {
    margin-bottom: 1.5rem
}

@media (min-width: 992px) {
    .image-content-halves__image-wrap {
        width: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0
    }
}

.image-content-halves__image {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 992px) {
    .image-content-halves--content-left .image-content-halves__content-wrap {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .image-content-halves--content-left .image-content-halves__image-wrap {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }
}

.image-content-full-bg {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-size: cover
}

@media (min-width: 992px) {
    .image-content-full-bg {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (min-width: 992px) {
    .image-content-full-bg--light-text {
        color: #fff
    }
}

.image-content-full-bg__picture {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

@media (min-width: 992px) {
    .image-content-full-bg__picture {
        display: none
    }
}

.image-content-full-bg__content-wrap {
    padding: 1.5em
}

@media (min-width: 992px) {
    .image-content-full-bg__content-wrap {
        padding: 0;
        width: 100%;
        max-width: 50%
    }
}

.image-content-full-bg__content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    min-width: 1px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

    .image-content-full-bg__content > * {
        width: 100%
    }

@media (min-width: 992px) {
    .image-content-full-bg__content {
        padding-top: 8rem;
        padding-bottom: 8rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 0;
        max-width: none;
        text-align: left;
        height: 100%;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width: 1200px) {
    .image-content-full-bg__content {
        padding: 8rem 4rem
    }
}

.image-spanned-columns__image-large {
    display: none
}

@media (min-width: 768px) {
    .image-spanned-columns__image-large {
        display: block;
        width: 100%
    }
}

.image-spanned-columns__image {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 768px) {
    .image-spanned-columns__image {
        display: none
    }
}

@media (min-width: 768px) {
    .image-spanned-columns__column-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .image-spanned-columns__column-wrap--three-col .image-spanned-columns__column {
        -ms-flex-preferred-size: 33.3333%;
        flex-basis: 33.3333%
    }
}

.image-spanned-columns__column {
    text-align: center;
    margin-bottom: 4em;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

    .image-spanned-columns__column:last-child {
        margin-bottom: 0
    }

@media (min-width: 768px) {
    .image-spanned-columns__column {
        margin-bottom: 0;
        padding-left: 1.5em;
        padding-right: 1.5em
    }
}

@media (min-width: 992px) {
    .image-spanned-columns__column {
        margin-bottom: 0;
        padding-left: 3em;
        padding-right: 3em
    }
}

.img-on-border__container {
    max-width: 100%;
    width: 706px;
    margin: auto;
    text-align: center;
    border: solid 1px #e6e6e6;
    padding: 4rem 5rem 1.5rem;
    position: relative
}

@media (max-width: 414px) {
    .img-on-border__container {
        padding: 4rem 2rem 1.5rem
    }
}

.img-on-border__img {
    position: absolute;
    top: -100%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 80%
}

.img-on-border__body {
    font-size: 1rem
}

.buy-iframe {
    width: 100%;
    height: 100%;
    border: none
}

.buy-iframe {
    width: 100%;
    height: 100%;
    border: none
}

.accordion {
    list-style: none outside;
    margin: 0;
    padding: 0
}

.accordion__drawer {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    display: none
}

.accordion__drawer--expanded {
    display: block
}

.accordion__trigger {
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    font-weight: 700;
    padding: 1.5rem 1rem;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    -webkit-transition: color 0.25s;
    -o-transition: color 0.25s;
    transition: color 0.25s
}

    .accordion__trigger:after {
        content: '\f107';
        font-family: FontAwesome;
        position: absolute;
        right: 1rem;
        color: #000;
        top: 1.5rem;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s
    }

.accordion__trigger--expanded:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.faqs__item {
    border-top: 1px solid #e6e6e6
}

.faqs .accordion__trigger--expanded {
    color: #d90114
}

.microban-cleanliness-img {
    max-width: 532px;
    width: 100%
}

@media (min-width: 992px) {
    .featured-block__content--extra-left-padding {
        padding-left: 6rem
    }
}

.featured-block__title {
    font-size: 1.75rem
}

@media (min-width: 768px) {
    .featured-block__title {
        font-size: 2.5rem
    }
}

.contact-information {
    font-style: normal
}

.contact-information__line {
    display: block
}

css-loader {
    display: block
}

.css-loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px
}

    .css-loader div {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid #626262;
        border-radius: 50%;
        -webkit-animation: css-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        animation: css-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #626262 transparent transparent transparent
    }

        .css-loader div:nth-child(1) {
            -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s
        }

        .css-loader div:nth-child(2) {
            -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s
        }

        .css-loader div:nth-child(3) {
            -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s
        }

@-webkit-keyframes css-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes css-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.modal .mfp-content {
    background: #fff;
    color: #000;
    max-width: 800px
}

.modal--text .mfp-content {
    padding: 1.5rem
}

.modal--rotator .mfp-content {
    padding: 0
}

.sf_pagerNumeric {
    border-left: none;
    background: #fff;
    float: left;
    font-size: 0.9rem
}

    .sf_pagerNumeric:before, .sf_pagerNumeric:after {
        clear: both
    }

    .sf_pagerNumeric:before {
        content: "Pages:";
        color: #000;
        display: block;
        height: 35px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-right: .75rem
    }

@media (min-width: 425px) {
    .sf_pagerNumeric:before {
        float: left
    }
}

.sf_pagerNumeric a {
    display: block;
    float: left;
    width: 1em;
    padding: 0.75em 0.5em;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    text-align: center;
    line-height: 1;
    text-decoration: none;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6
}

    .sf_pagerNumeric a:last-child {
        border-right: 1px solid #e6e6e6
    }

    .sf_pagerNumeric a:hover {
        background-color: #e6e6e6
    }

.sf_pagerNumeric .sf_PagerCurrent {
    background: #d90114;
    color: #fff
}

    .sf_pagerNumeric .sf_PagerCurrent:hover {
        background-color: #d01b32
    }

.productDetailModal {
    position: relative;
    background-color: #fff;
    width: auto;
    margin: 0 auto;
    width: 100vw;
    max-width: 900px;
    height: 100%
}

@media (min-width: 992px) {
    .productDetailModal {
        max-height: 852px
    }
}

.productDetailModal__mobile-top {
    background-color: white;
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #eeeeee
}

    .productDetailModal__mobile-top h2 {
        margin: 0;
        font-size: 22px;
        font-weight: 800
    }

@media (min-width: 992px) {
    .productDetailModal__mobile-top {
        display: none
    }
}

.productDetailModal__top {
    background-color: #f6f6f6;
    padding-top: 2rem;
    padding-bottom: 1.5rem
}

.productDetailModal__top-content {
    width: 90%;
    max-width: 790px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

    .productDetailModal__top-content p {
        margin-top: 0;
        margin-bottom: .25rem
    }

.productDetailModal__img {
    width: auto;
    height: 100%;
    margin-right: 1.5rem
}

.productDetailModal__middle {
    max-width: 790px;
    max-width: 822px;
    margin: 0 auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

    .productDetailModal__middle h2 {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1rem;
        font-size: 22px;
        font-weight: 800;
        text-align: center;
        padding-bottom: 1rem
    }

@media (min-width: 992px) {
    .productDetailModal__middle h2 {
        text-align: left
    }
}

.productDetailModal__card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 420px;
    overflow-y: auto;
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
    max-width: 526px
}

@media (min-width: 664px) {
    .productDetailModal__card-wrapper {
        max-width: 664px
    }
}

@media (min-width: 820px) {
    .productDetailModal__card-wrapper {
        max-width: 820px
    }
}

@media (min-width: 992px) {
    .productDetailModal__card-wrapper {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.productDetailModal__card-wrapper:after, .productDetailModal__card-wrapper:before {
    content: "";
    display: block;
    width: 177px;
    height: 0
}

.productDetailModal__card-wrapper:before {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.productDetailModal__card-wrapper::-webkit-scrollbar {
    width: 0px;
    background: transparent
}

.productDetailModal__card {
    -webkit-box-shadow: 0px 5px 16px 0 rgba(0,0,0,0.15);
    box-shadow: 0px 5px 16px 0 rgba(0,0,0,0.15);
    border: solid 1px #d9d9d9;
    width: 43vw;
    height: 43vw;
    max-width: 177px;
    max-height: 178px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    margin-bottom: 1.5rem
}

    .productDetailModal__card:hover img, .productDetailModal__card:hover p, .productDetailModal__card:hover a {
        -webkit-transition: -webkit-transform .25s ease-in;
        transition: -webkit-transform .25s ease-in;
        -o-transition: transform .25s ease-in;
        transition: transform .25s ease-in;
        transition: transform .25s ease-in, -webkit-transform .25s ease-in
    }

    .productDetailModal__card:hover img, .productDetailModal__card:hover p {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    .productDetailModal__card:hover a {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    .productDetailModal__card img, .productDetailModal__card p {
        -webkit-transition: -webkit-transform .25s ease-in;
        transition: -webkit-transform .25s ease-in;
        -o-transition: transform .25s ease-in;
        transition: transform .25s ease-in;
        transition: transform .25s ease-in, -webkit-transform .25s ease-in;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    .productDetailModal__card p {
        margin-top: 0;
        margin-bottom: 0;
        width: 90%;
        text-align: center
    }

    .productDetailModal__card a {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #d90114;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 37px;
        -webkit-transition: -webkit-transform .25s ease-in;
        transition: -webkit-transform .25s ease-in;
        -o-transition: transform .25s ease-in;
        transition: transform .25s ease-in;
        transition: transform .25s ease-in, -webkit-transform .25s ease-in;
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
        font-weight: 400
    }

.productDetailModal__status {
    display: inline-block
}

.productDetailModal__bottom {
    border-top: 1px solid #d9d9d9;
    width: 90%;
    max-width: 790px;
    margin: 0 auto;
    padding-top: 1.5rem;
    padding-bottom: 2rem
}

    .productDetailModal__bottom h2 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-size: 22px;
        font-weight: 800;
        text-align: center
    }

@media (min-width: 992px) {
    .productDetailModal__bottom h2 {
        text-align: left
    }
}

.productDetailModal__bottom p {
    margin: 0;
    margin-bottom: .5rem
}

.productDetailModal__input {
    border: 1px solid #000;
    padding: .75rem 1rem;
    width: 100%;
    margin: 0 auto 1.5rem;
    height: 56px
}

@media (min-width: 992px) {
    .productDetailModal__input {
        width: 90%;
        max-width: 420px;
        margin: 0;
        margin-right: 2rem
    }
}

.productDetailModal__find-btn {
    background-color: #d90114;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: .75rem 1.25rem;
    font-weight: 400;
    display: block;
    text-align: center;
    width: 100%
}

@media (min-width: 992px) {
    .productDetailModal__find-btn {
        max-width: 222px;
        display: inline-block
    }
}

.productDetailModal .mfp-close {
    font-weight: bold;
    font-weight: bold;
    font-size: 2.5rem;
    color: #000;
    opacity: 1;
    top: 4px;
    right: 4px
}

.productDetailModal .reviews-preview__link-text {
    display: inline-block
}

@media (max-width: 900px) {
    .mfp-container {
        padding: 0
    }
}

.inline-guide-slider {
    width: 95%;
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden
}

@media (min-width: 768px) {
    .inline-guide-slider {
        max-width: 1100px
    }
}

.inline-guide-slider .slick-dots {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width: 768px) {
    .inline-guide-slider .slick-dots {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        position: absolute;
        bottom: 2.5rem;
        left: 17.75rem
    }
}

.inline-guide-slider .slick-dots li {
    opacity: 0.5;
    height: 50px;
    width: 50px
}

    .inline-guide-slider .slick-dots li button {
        padding: 0;
        border: 0;
        margin: 0;
        height: 50px;
        width: 50px
    }

        .inline-guide-slider .slick-dots li button img {
            max-width: 100%
        }

    .inline-guide-slider .slick-dots li:not(:last-child) {
        margin-right: 1rem
    }

.inline-guide-slider .slick-dots .slick-active {
    opacity: 1
}

.inline-guide {
    font-family: sans-serif;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 768px) {
    .inline-guide {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        max-width: 1100px
    }
}

.inline-guide__image {
    margin-bottom: 1rem
}

@media (min-width: 768px) {
    .inline-guide__image {
        margin-right: 2rem
    }
}

.inline-guide__content {
    width: 95%;
    max-width: 500px
}

@media (min-width: 768px) {
    .inline-guide__content {
        max-width: 768px
    }
}

.inline-guide__subtitle {
    margin: 0
}

.diagram {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto 50px;
    padding: 0 30px
}

@media (max-width: 799px) {
    .diagram {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.diagram--traditional {
    margin: 0 auto 50px
}

    .diagram--traditional li:nth-child(1) {
        top: 16%
    }

    .diagram--traditional li:nth-child(2) {
        top: 42%
    }

    .diagram--traditional li:nth-child(3) {
        top: 69%
    }

    .diagram--traditional li:nth-child(4) {
        top: 93%
    }

.diagram--contemporary {
    margin: 0 auto 20px
}

    .diagram--contemporary li:nth-child(1) {
        top: 12%
    }

    .diagram--contemporary li:nth-child(2) {
        top: 34%
    }

    .diagram--contemporary li:nth-child(3) {
        top: 56%
    }

    .diagram--contemporary li:nth-child(4) {
        top: 77%
    }

.diagram--key-control li:nth-child(1) {
    top: 32%
}

.diagram--key-control li:nth-child(2) {
    top: 53%
}

.diagram--key-control .diagram__img img {
    padding-left: 20%;
    padding-right: 12%
}

.diagram__list {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 10px;
    flex: 0 1 10px
}

@media (max-width: 799px) {
    .diagram__list {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

        .diagram__list ol {
            height: auto !important
        }

            .diagram__list ol li {
                position: relative !important;
                padding-bottom: 25px
            }
}

.diagram__list ol, .diagram__img ol {
    counter-reset: list-counter;
    position: relative;
    height: 100%;
    margin: 0;
    padding-left: 45px
}

    .diagram__list ol li, .diagram__img ol li {
        position: absolute;
        padding-right: 10px;
        list-style: none;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        text-transform: uppercase;
        text-align: left;
        background-color: #fff;
        z-index: 2
    }

        .diagram__list ol li:before, .diagram__img ol li:before {
            content: counter(list-counter);
            counter-increment: list-counter;
            position: absolute;
            left: -45px;
            top: -6px;
            height: 30px;
            width: 30px;
            padding-top: 5px;
            background-color: #000;
            font-weight: bold;
            color: #fff;
            text-align: center;
            vertical-align: middle;
            border-radius: 50%
        }

.diagram__img {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin-bottom: 40px
}

@media (min-width: 800px) {
    .diagram__img {
        margin-bottom: 0;
        padding-left: 31%;
        overflow: hidden
    }
}

.diagram__img img {
    width: 100%
}

.diagram__img ol {
    position: absolute;
    right: 0;
    width: 100%
}

@media (min-width: 800px) {
    .diagram__img ol {
        width: 69%
    }
}

.diagram__img ol li:before {
    left: -10px;
    height: 22px;
    width: 22px;
    padding-top: 3px;
    font-size: 12px
}

@media (min-width: 800px) {
    .diagram__img ol li {
        position: absolute;
        right: 0;
        height: 1px;
        width: 150%;
        margin-top: 9px;
        background-color: #000;
        opacity: 0.25;
        z-index: 1
    }

        .diagram__img ol li:before {
            display: none
        }
}

.smartkey-hero-banner {
    background: #000 url("//images.kwikset.com/is/image/Kwikset/sk%2Dpage%2Dheader?scl=1") center top no-repeat;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-bottom: 35%;
    color: #fff;
    text-align: center;
    position: relative
}

@media (min-width: 575px) {
    .smartkey-hero-banner {
        margin-bottom: 200px
    }
}

.smartkey-hero-banner__heading {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto
}

.smartkey-hero-banner__content {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em
}

@media (min-width: 992px) {
    .smartkey-hero-banner__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.smartkey-hero-banner__first {
    margin-bottom: 2em
}

@media (min-width: 992px) {
    .smartkey-hero-banner__first {
        border-right: 1px solid #fff;
        margin-bottom: 0
    }
}

.smartkey-hero-banner__video {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -35%
}

@media (min-width: 575px) {
    .smartkey-hero-banner__video {
        margin-bottom: -200px
    }
}

.residential-security__tested {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1160px;
    padding: 0 6px
}

@media (max-width: 992px) {
    .residential-security__tested {
        -ms-flex-pack: distribute;
        justify-content: space-around;
        padding-bottom: 20px
    }
}

.residential-security__tested-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin: 0 20px
}

@media (max-width: 992px) {
    .residential-security__tested-title {
        margin: 0 20px 20px;
        text-align: center
    }
}

.residential-security__tested-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 20px
}

@media (max-width: 992px) {
    .residential-security__tested-item {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%;
        margin: 0
    }
}

@media (max-width: 425px) {
    .residential-security__tested-item {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%
    }
}

.residential-security__icon {
    color: #d1142f;
    font-size: 50px
}

@media (max-width: 1199px) {
    .clear--tablet {
        clear: both
    }
}

.sfPageEditor .mfp-hide {
    display: block !important
}

    .sfPageEditor .mfp-hide:before {
        content: 'Following content only visible in  sitefinity editor';
        display: block;
        padding: 10px;
        background-color: #e12;
        color: #fff;
        text-align: center
    }

.key-control-deadbolt {
    position: absolute;
    top: 1%;
    right: 9.5%;
    width: 70%;
    padding-top: 82%;
    background-image: url(//images.kwikset.com/is/image/Kwikset/keycontrol-interactive-2nd-cylinder-lock?scl=1&fmt=png-alpha);
    background-repeat: no-repeat;
    background-size: 100%
}

@media (min-width: 500px) {
    .key-control-deadbolt {
        top: 6%;
        right: 29.6%;
        width: 34.33%;
        padding-top: 40%
    }
}

@media (min-width: 992px) {
    .key-control-deadbolt {
        top: 10%;
        right: 11%
    }
}

.key-control-deadbolt--turned .key-control-deadbolt__cover {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.key-control-deadbolt__cover {
    position: absolute;
    top: 19.3%;
    left: 13.6%;
    height: 49%;
    width: 57.65%;
    background-image: url(https://images.kwikset.com/is/image/Kwikset/keycontrol-interactive-2nd-cylinder-cover?scl=1&fmt=png-alpha);
    background-repeat: no-repeat;
    background-size: 100%;
    -webkit-transition: -webkit-transform 2s;
    transition: -webkit-transform 2s;
    -o-transition: transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s
}

.key-control-deadbolt__btn {
    display: none
}

@media (min-width: 500px) {
    .key-control-deadbolt__btn {
        display: inline-block
    }
}

.key-control-deadbolt__btn--mobile {
    display: block;
    position: absolute;
    left: 50%;
    margin-top: 78%;
    padding: 14px 20px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    white-space: nowrap
}

@media (min-width: 500px) {
    .key-control-deadbolt__btn--mobile {
        display: none
    }
}

.heading--million {
    position: relative;
    display: inline-block;
    margin: 0;
    font-size: 4em;
    font-weight: normal
}

@media (min-width: 992px) {
    .heading--million {
        font-size: 5.625em
    }
}

.heading--million:after {
    content: 'million';
    position: absolute;
    left: 100%;
    bottom: 0.4em;
    font-size: 0.4em
}

.hr--subtle {
    width: 180px;
    margin: 0.5em auto 0.5em 0;
    border-color: #dedede
}

.double-col-center {
    max-width: 1115px;
    margin: 0 auto
}

@media (max-width: 992px) {
    .same-line-mobile.same-line-mobile {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 500px) {
    .same-line-mobile.same-line-mobile picture {
        margin: 0 0.5rem 1rem
    }
}

.list {
    padding-left: 1.25em
}

.list--border-dividers {
    padding-left: 0;
    list-style: none outside
}

    .list--border-dividers .list__item {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px solid #d2d2d2
    }

        .list--border-dividers .list__item:last-child {
            border-bottom: none
        }

.list-checkmarks {
    list-style: none outside;
    padding-left: 0
}

    .list-checkmarks li {
        padding-left: 1.25em;
        position: relative;
        margin-bottom: 0.75em
    }

        .list-checkmarks li:before {
            content: '\f00c';
            font-family: FontAwesome;
            display: block;
            position: absolute;
            left: 0;
            top: 0
        }

.list-arrows {
    list-style: none outside;
    padding-left: 0
}

.list-arrows-wrapper h3 {
    font-size: 1.5rem
}

.list-arrows li {
    position: relative;
    padding: 1rem 0;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 1.3em
}

    .list-arrows li:before {
        font-size: 1rem;
        content: '\f0a9';
        font-family: FontAwesome;
        display: inline-block;
        position: relative;
        color: #d90114;
        margin-right: 0.5em;
        margin-left: -1.3em
    }

    .list-arrows li:last-child {
        border-bottom: none
    }

    .list-arrows li a {
        line-height: 1.4
    }

.list-icons {
    list-style: none outside;
    padding: 0;
    max-width: 520px;
    margin: auto;
    margin-top: 1.5rem
}

.list-icons--full {
    max-width: none
}

@media (max-width: 375px) {
    .list-icons--small-kicker-text .list-icons__kicker {
        font-size: .75rem !important
    }
}

.list-icons--extratop {
    margin-top: 2.5rem
}

@media (min-width: 415px) {
    .list-icons--extratop {
        margin-top: 3.25rem
    }
}

.list-icons--extrabottom {
    margin-bottom: 5.5rem
}

@media (max-width: 768px) {
    .list-icons--no-col-padding {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width: 992px) {
    .list-icons {
        max-width: 1520px
    }
}

.list-icons li {
    text-align: center
}

    .list-icons li:not(:last-child) {
        margin-bottom: 2.5rem
    }

    .list-icons li.visible-sm, .list-icons li.visible-xs {
        margin-bottom: 0
    }

@media (min-width: 992px) {
    .list-icons li {
        margin-bottom: 0
    }
}

.list-icons li.list--extrapadding {
    padding: 0 45px
}

.list-icons li img.list-icons__icons {
    max-width: 84px
}

.list-icons li p.list-icons__kicker {
    font-size: 1rem;
    letter-spacing: 0.8px;
    color: #000000;
    margin: 1rem 0 0;
    padding: 0 0.5rem;
    font-weight: 400
}

.list-icons li p.list-icons__kicker--bold {
    font-weight: bold
}

.list-icons li p.list-icons__kicker--allcap {
    text-transform: uppercase
}

@media (min-width: 992px) {
    .list-icons li p.list-icons__kicker {
        padding: 0
    }
}

.list-icons li p.list-icons__text {
    font-size: 1.125rem;
    line-height: 1.31;
    text-align: center;
    color: #231f20;
    margin-right: auto;
    margin-left: auto;
    font-weight: 300
}

.list-icons li p.list-icons__text--sm {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.63;
    letter-spacing: -0.3px
}

.list-icons li p.list-icons__text--light {
    font-weight: 300
}

.list-icons li p.list-icons__text--wide {
    max-width: 290px
}

.list-icons li p.list-icons__text--narrow {
    max-width: 150px
}

.list-icons li p.list-icons__text--md {
    max-width: 90%;
    margin: 18px auto
}

@media screen and (min-width: 1024px) {
    .list-icons li p.list-icons__text--md {
        max-width: 12rem
    }
}

.list-icons li p.list-icons__text--more-md {
    width: 95%;
    max-width: 14rem
}

@media (min-width: 992px) {
    .list-icons li {
        margin-top: 0
    }
}

.list-icons--smartlocks li img.list-icons__icons {
    max-width: 80px
}

.list-icons--smartlocks .btn {
    letter-spacing: 0.8px
}

@media (max-width: 414px) {
    .list-icons--smartlocks li {
        margin-bottom: 2.25rem
    }

        .list-icons--smartlocks li p.list-icons__kicker {
            font-size: 0.875rem;
            margin: 0.35rem 0 0
        }

        .list-icons--smartlocks li p.list-icons__text {
            font-size: 1rem
        }

        .list-icons--smartlocks li .btn {
            margin-top: 0.35rem
        }

    .list-icons--smartlocks.list-icons--extrabottom {
        margin-bottom: 1rem
    }
}

.list-icons--collapsable-toggle {
    display: none;
    text-decoration: none;
    font-size: 1rem;
    margin-top: 0.5rem;
    font-weight: 400
}

@media (max-width: 414px) {
    .list-icons--collapsable-toggle {
        display: inline-block
    }
}

.list-icons--collapsable-icon {
    color: #d90114;
    font-size: 1.375rem;
    margin-right: 0.25rem;
    vertical-align: middle
}

.list-icons--collapsable-icon-minus {
    display: none
}

.list-icons--collapsable li {
    margin-bottom: 4.5rem
}

@media (max-width: 414px) {
    .list-icons--collapsable li.list--extrapadding {
        border-bottom: 1px solid #e9e9e9;
        padding: 0 2.8125rem 1.5rem;
        margin-bottom: 1.875rem
    }

        .list-icons--collapsable li.list--extrapadding:last-child {
            border-bottom: none;
            margin-bottom: 0
        }

    .list-icons--collapsable li p.list-icons__kicker {
        margin-top: 0.25rem
    }

    .list-icons--collapsable li p.list-icons__text {
        display: none
    }
}

.list-icons--opened .list-icons--collapsable-icon-plus {
    display: none
}

.list-icons--opened .list-icons--collapsable-icon-minus {
    display: inline-block
}

.list-icons__max-width {
    max-width: 235px;
    margin-left: auto;
    margin-right: auto
}

.list-boxedlogo {
    list-style-type: none;
    max-width: 1530px;
    padding: 0 0.5rem;
    margin: 1.75rem auto 3rem
}

.list-boxedlogo--narrow {
    max-width: 1256px
}

@media (min-width: 768px) {
    .list-boxedlogo {
        margin: 2.75rem auto 3rem;
        padding: 0 2.25rem
    }
}

@media (min-width: 992px) {
    .list-boxedlogo {
        padding: 0 3rem
    }
}

.list-boxedlogo li {
    text-align: center;
    margin-bottom: 2.5rem;
    padding: 0 0.5rem
}

@media (min-width: 415px) {
    .list-boxedlogo li {
        margin-bottom: 3.25rem
    }
}

@media (min-width: 992px) {
    .list-boxedlogo li {
        padding: 0 1rem;
        margin-bottom: 2.75rem
    }
}

.list-boxedlogo__image {
    max-width: 100%;
    max-height: 60%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.list-boxedlogo__image-wrapper {
    position: relative;
    width: 287px;
    height: 115px;
    max-width: 100%;
    margin: auto;
    border: solid 1px #e6e6e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px
}

@media (min-width: 768px) {
    .list-boxedlogo__image-wrapper:before {
        content: '';
        display: block;
        border: 1px solid transparent;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0
    }

    .list-boxedlogo__image-wrapper:hover:before {
        border-color: #e6e6e6;
        border-width: 6px
    }
}

@media (min-width: 992px) {
    .list-boxedlogo__image-wrapper {
        height: 200px
    }
}

.list-boxedlogo__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: -0.4px;
    margin-top: 0.9rem;
    margin-bottom: 0.25rem
}

.list-boxedlogo__link {
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.3px
}

.btn-group {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto
}

    .btn-group .btn {
        display: block
    }

        .btn-group .btn:first-child {
            margin-bottom: 1em
        }

@media (min-width: 560px) {
    .btn-group {
        max-width: 100%
    }

        .btn-group .btn {
            display: inline-block
        }

            .btn-group .btn:first-child {
                margin-right: 1em;
                margin-bottom: 0
            }
}

.btn {
    font-size: 1rem;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: currentColor;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    letter-spacing: 0.1em;
    line-height: 1.2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

    .btn[disabled] {
        opacity: 0.5;
        cursor: not-allowed
    }

.btn--spacing-sm {
    letter-spacing: 0
}

.btn--solid {
    background: #d90114;
    color: #fff;
    border: 1px solid #d90114;
    padding: 14px 32px


    &:hover, &:focus {
        background-color: #f00016;
        border-color: #f00016
    }
    &-darker {
    background: #d01b32;
    border: 1px solid #d01b32;
    padding: 14px 32px;
    color: #ffffff
}
}



.btn--solid-darker--small {
    font-size: 0.875rem
}

.btn--solid-darker--narrow {
    padding: 14px 21px
}

.btn--solid-darker:hover {
    background-color: #d90114;
    border-color: #d90114
}

.btn--transparent {
    border: 1px solid currentColor;
    padding: 14px 32px;
    background: transparent;
    max-width: 90%
}

    .btn--transparent:hover {
        background-color: #f00016;
        border-color: #f00016;
        color: #fff
    }

.btn--full-width {
    max-width: 100%
}

.btn--block {
    display: block;
    width: 100%
}

.btn--dark {
    border: 1px solid currentColor;
    padding: 14px 32px
}

    .btn--dark:hover {
        background-color: #000;
        border-color: none;
        color: #fff
    }

.btn--text {
    text-align: left;
    text-transform: none;
    background: transparent;
    border: none;
    color: currentColor;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    letter-spacing: -0.04em
}

    .btn--text .fa {
        color: #d90114
    }

    .btn--text:hover {
        border-bottom-color: #626262;
        color: #d90114
    }

    .btn--text.btn--text-no-border {
        border: none
    }

.btn--view {
    border: none
}

    .btn--view:after {
        font-family: FontAwesome;
        content: '\f138';
        color: #d90114;
        margin-left: 0.5em
    }

.btn--video:before {
    font-size: 1.375rem;
    line-height: 1.18;
    letter-spacing: -0.4px;
    content: '\f144';
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    color: #d90114;
    margin-right: 0.8rem;
    vertical-align: top
}

.btn--video .btn__text {
    text-align: left;
    text-transform: none;
    background: transparent;
    border: none;
    color: currentColor;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    letter-spacing: -0.04em;
    line-height: 1.5;
    display: inline-block
}

.btn--video:hover .btn__text {
    border-bottom-color: #626262;
    color: #d90114
}

.btn--explore {
    color: currentColor;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.63;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    text-decoration: none
}

    .btn--explore:before {
        font-size: 1.375rem;
        line-height: 1.18;
        letter-spacing: -0.4px;
        content: '\f0ab';
        font-family: FontAwesome;
        display: inline-block;
        position: relative;
        color: #d90114;
        margin-right: 0.8rem;
        vertical-align: top
    }

.btn--explore-variable {
    color: currentColor;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.63;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    text-decoration: none
}

    .btn--explore-variable:before {
        font-size: 1.375rem;
        line-height: 1.18;
        letter-spacing: -0.4px;
        content: '\f0ab';
        font-family: FontAwesome;
        display: inline-block;
        position: relative;
        color: #d90114;
        margin-right: 0.8rem;
        vertical-align: top
    }

@media (min-width: 992px) {
    .btn--explore-variable:before {
        color: #fff
    }
}

.btn--info {
    color: currentColor;
    font-size: 1rem;
    text-decoration: underline;
    text-transform: none;
    line-height: 1rem;
    letter-spacing: -0.03em;
    font-weight: 300
}

    .btn--info:before {
        font-size: 1.375rem;
        content: '\f05a';
        font-family: FontAwesome;
        float: left;
        margin-right: 0.75rem
    }

.btn--padding-sm {
    padding: 12px 26px
}

.btn--has-text-fade {
    text-align: left;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    position: absolute;
    padding-top: 7.25rem;
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, #fff 60%, #fff 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), color-stop(60%, #fff), to(#fff));
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, #fff 60%, #fff 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #fff 60%, #fff 100%)
}

.btn--sm {
    font-size: 0.9rem;
    padding: 0.75rem 1rem
}

.btn--max-width {
    max-width: 100%
}

.btn--center {
    margin-right: auto;
    margin-left: auto
}

.group:after {
    content: "";
    display: table;
    clear: both
}

.mobile-only {
    display: block
}

@media (min-width: 768px) {
    .mobile-only {
        display: none !important
    }
}

.mobile-tablet-only {
    display: block
}

@media (min-width: 992px) {
    .mobile-tablet-only {
        display: none !important
    }
}

.tablet-desktop-only {
    display: none
}

@media (min-width: 768px) {
    .tablet-desktop-only {
        display: block
    }
}

.desktop-only {
    display: none !important
}

@media (min-width: 992px) {
    .desktop-only {
        display: block !important
    }
}

.not-mobile {
    display: none
}

@media (min-width: 768px) {
    .not-mobile {
        display: block
    }
}

.has-top-border {
    border-top: 1px solid transparent
}

.has-top-border--grey {
    border-color: #e6e6e6
}

.has-top-border--dark-grey {
    border-color: #ccc
}

.has-bottom-border {
    border-bottom: 1px solid transparent
}

.has-bottom-border--grey {
    border-color: #e6e6e6
}

.has-full-border {
    border: 1px solid transparent
}

.has-full-border--grey {
    border-color: #e6e6e6
}

.hidden {
    display: none !important
}

.position-relative {
    position: relative
}

.has-shadow {
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.25);
    box-shadow: 0 0 10px rgba(0,0,0,0.25)
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body {
    overflow-x: hidden
}

.full-screen-modal {
    overflow: hidden
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

img {
    max-width: 100%
}

.container-standard-content {
    padding-left: 1rem;
    padding-right: 1rem
}

@media (min-width: 768px) {
    .container-standard-content {
        padding-left: 2rem;
        padding-right: 2rem
    }
}

@media (min-width: 992px) {
    .container-standard-content {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
}

@media (min-width: 1100px) {
    .container-standard-content {
        margin-left: auto;
        margin-right: auto;
        padding-left: 4rem;
        padding-right: 4rem
    }
}

.container-max {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto
}

.container-1200 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto
}

.container-1280 {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto
}

.container-narrow {
    max-width: 1052px;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.container-text {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.container-intro-text {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto
}

.container-color--grey {
    background-color: #f6f6f6
}

.container-ghost {
    height: 1px
}

.js--sticky.stick {
    position: fixed;
    top: 0;
    z-index: 10000
}

.no-row .row {
    margin: 0
}

a.breakat {
    display: inline-block
}

@media (max-width: 1023px) {
    a.breakat--desktop {
        display: block
    }
}

@media (max-width: 767px) {
    a.breakat--tablet {
        display: block
    }
}

@media (max-width: 413px) {
    a.breakat--mobile {
        display: block
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 0
}

@media (min-width: 1200px) {
    .col-lg-offset-1-and-half {
        margin-left: 12.499999995%
    }
}

@media (min-width: 992px) {
    .col-md-offset-1-and-half {
        margin-left: 12.499999995%
    }
}

.center-block {
    display: block;
    margin: 0 auto
}

@media screen and (min-width: 992px) {
    .flex-vert-center {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media screen and (min-width: 768px) {
    .flex-vert-center--sm {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.row-flex .row {
    margin: 0
}

@media (min-width: 768px) {
    .row-flex .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .row-flex .col-md-6 {
        max-width: calc(50% - 1rem)
    }
}

.debug * {
    outline: 1px solid mediumseagreen
}

.img-overlap {
    width: 100%;
    max-width: 800px
}

@media screen and (min-width: 992px) {
    .img-overlap {
        margin-top: -10rem
    }
}

.clearfix:before, .clearfix:after {
    display: table;
    content: " "
}

.clearfix:after {
    clear: both
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
    display: none !important
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important
    }

    .visible-xs-block {
        display: block !important
    }

    .visible-xs-inline {
        display: inline !important
    }

    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important
    }

    .visible-sm-block {
        display: block !important
    }

    .visible-sm-inline {
        display: inline !important
    }

    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important
    }

    .visible-md-block {
        display: block !important
    }

    .visible-md-inline {
        display: inline !important
    }

    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important
    }

    .visible-lg-block {
        display: block !important
    }

    .visible-lg-inline {
        display: inline !important
    }

    .visible-lg-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 768px) {
    .align-bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline
    }
}

:root {
    --scrollbar-width: 0px
}

.full-width {
    clear: both;
    position: relative;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%)
}

@supports (--css: variables) {
    .full-width {
        margin-left: calc((-50vw + 50% + (var(--scrollbar-width) / 2)) + 8px);
        margin-right: calc((-50vw + 50% + (var(--scrollbar-width) / 2)) + 8px)
    }
}

.sf-backend-wrp .full-width {
    margin: 0 !important
}

.site-footer__form {
    background-color: #000 !important;
    color: #fff;
    border-bottom: 1px solid #2c2c2c;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 4em;

    padding-bottom: 4em;
    // microsite email footer overrides
        &--microsite {
            background-color: #f7f7f7 !important;
            color: #000;
            border-bottom: none;
        }

}

.site-footer__form-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px
}

.site-footer__form-subgroup {
    max-width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 2rem
}

.site-footer__checkbox-subgroup {
    position: relative;
    width: 90%
}

    .site-footer__checkbox-subgroup p {
        top: 90px
    }

@media (min-width: 768px) {
    .site-footer__checkbox-subgroup p {
        top: 72px
    }
}

.site-footer .input-group--label-inside .input-group__input {
    height: 50px;
    display: block;
    width: 100%;
    padding-top: 1em;
    padding: 14px;
    border-radius: 0;
    line-height: 1;
    border: 1px solid #b1b1b1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    color: black
}

@media (min-width: 992px) {
    .site-footer .input-group--label-inside .input-group__input {
        max-width: 247px
    }
}

.site-footer .input-group--label-inside .input-group__label.input-group__label--absolute {
    position: absolute;
    left: 1em;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    top: 25px;
    color: black;
    font-weight: 300
}

.site-footer .input-group--focused .input-group__label.input-group__label--absolute {
    top: 0;
    left: 6%;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    padding-top: 3px
}

.site-footer__form-input-invalid {
    display: none;
    color: #d90114;
    position: absolute;
    top: 50px;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 16px;
    left: 50%;
    max-width: 350px;
    width: 100% !important;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

    .site-footer__form-input-invalid i {
        color: #d90114;
        margin-right: 0.5rem
    }

.site-footer__relative-wrapper {
    position: relative;
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto
}

.site-footer__form-input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    display: block
}

@media (min-width: 992px) {
    .site-footer__form-input-group {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media (min-width: 992px) {
    .site-footer__form-input-group--half {
        width: 47%;
        margin-top: 2rem
    }
}

.site-footer__form-input-group--warning {
    margin-bottom: 60px
}

    .site-footer__form-input-group--warning input {
        border: 1px solid #d90114;
        border-style: solid
    }

    .site-footer__form-input-group--warning:before {
        position: absolute;
        content: "!";
        width: 18.5px;
        height: 19px;
        background-color: #d90114;
        right: -10px;
        top: -10px;
        font-weight: bold;
        border-radius: 100%;
        text-align: center;
        color: white;
        font-family: "proxima-nova", sans-serif;
        font-size: 16px;
        line-height: 1.25
    }

.site-footer__form-input-group--checkbox-warning {
    margin-bottom: 60px
}

    .site-footer__form-input-group--checkbox-warning input {
        border: 1px solid #d90114;
        border-style: solid
    }

.site-footer__form-input {
    color: black;
    padding: 1rem;
    border-radius: 0;
    font-size: 1rem;
    border: 1px solid black;
    -webkit-transition: border-color 0.25s;
    -o-transition: border-color 0.25s;
    transition: border-color 0.25s;
    display: block;
    width: 100%;
    padding-top: 1.5rem
}

.site-footer__form-btn {
    max-width: 75%;
    -ms-flex-item-align: center;
    align-self: center
}

@media (min-width: 992px) {
    .site-footer__form-btn {
        margin: 0 auto
    }
}

.site-footer__form-success {
    display: none;
    text-align: center;
    width: 90%;
    max-width: 35rem;
    margin: 0 auto
}

.site-footer__form-error {
    text-align: center;
    width: 90%;
    max-width: 35rem;
    margin: 0 auto;
    color: #d90114
}

    .site-footer__form-error p {
        margin-top: 0
    }

.site-footer__form-checkbox {
    border: none;
    background-color: black
}

.site-footer__form-label--checkbox a {
    color: currentColor
}

.site-footer__checkbox-warning {
    top: 110px
}

@media (min-width: 400px) {
    .site-footer__checkbox-warning {
        top: 85px
    }
}

.site-footer__checkbox-warning--microsite {
    top: 125px
}

@media (min-width: 460px) {
    .site-footer__checkbox-warning--microsite {
        top: 90px
    }
}

.site-footer__form-label--warn:before {
    color: #f00016
}

.inputText {
    font-size: 14px;
    width: 200px;
    height: 35px
}

.floating-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 18px;
    -webkit-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    color: black;
    font-weight: 400
}

.cssCheckbox input[type="checkbox"] {
    opacity: 0
}

.cssCheckbox label {
    position: relative;
    display: inline-block;
    padding-left: 40px;
    font-size: 16px;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

    .cssCheckbox label p {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%
    }

    .cssCheckbox label::before, .cssCheckbox label::after {
        position: absolute;
        content: "";
        display: inline-block
    }

    .cssCheckbox label::before {
        height: 24px;
        width: 24px;
        border: 2px solid;
        left: 0px;
        top: 3px
    }

    .cssCheckbox label::after {
        height: 7px;
        width: 13px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        left: 6px;
        top: 10px
    }

.cssCheckbox input[type="checkbox"] + label::after {
    content: none
}

.cssCheckbox input[type="checkbox"]:checked + label::after {
    content: ""
}

.cssCheckbox input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px
}

.mc-signup {
    width: 90%;
    max-width: 475px;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 768px) {
    .mc-signup {
        width: 100%;
        max-width: 768px
    }
}

.mc-signup__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

    .mc-signup__inputs .input-group:not(:nth-child(3)) {
        margin-bottom: 1rem
    }

@media (min-width: 992px) {
    .mc-signup__inputs {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

        .mc-signup__inputs .input-group {
            margin-bottom: 0 !important
        }
}

.mc-signup__text {
    max-width: 350px
}

/*.mc-signup__submit {
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    background: #d90114;
    color: #fff;
    border: 1px solid #d90114;
    padding: 14px 32px
}*/

    .mc-signup__submit[disabled] {
        opacity: 0.5;
        cursor: not-allowed
    }

.btn--email {
    color: currentColor !important;
    border: 2px solid currentColor !important
}

@media (max-width: 991px) {
    .mc-field-group {
        width: 100%
    }
}

footer.site-wrapper--bot {
    border-top: 1px solid #d6d6d6
}

.cta-box--bg-faq {
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D955%2Dfaqs?scl=1) !important
}

.cta-box--bg-support {
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D955%2Dsupport?scl=1) !important
}

.cta-box--bg-faq-ar {
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Daura%2Dfaqs?scl=1) !important
}

.cta-box--bg-support-ar {
    background-image: url(//images.kwikset.com/is/image/Kwikset/kwikset%2Daura%2Dsupport?scl=1) !important
}

.footer-body {
    max-width: 1100px;
    margin: 80px auto 110px
}

@media (min-width: 768px) {
    .site-footer {
        text-align: center;
        padding-left: 1em;
        padding-right: 1em
    }

    .site-footer__col {
        margin-bottom: 1em
    }

    .site-footer__col--email-signup {
        margin-bottom: 0.5em
    }
}

@media (min-width: 992px) {
    .site-footer__col {
        margin-bottom: 0
    }

    .site-footer__col--email-signup {
        width: 60%;
        float: left;
        text-align: left;
        padding-top: 0.25em
    }

    .site-footer__col--social {
        width: 40%;
        float: right;
        text-align: right
    }
}

@media (min-width: 1100px) {
    .site-footer__col--email-signup {
        padding-top: 0
    }
}

.at-share-tbx-element .at-share-btn {
    cursor: pointer;
    margin: 0 5px 5px 0;
    display: inline-block;
    overflow: hidden;
    border: 0;
    text-decoration: none;
    text-transform: none;
    background-color: transparent;
    color: inherit;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    fill: #fff
}

.at4-visually-hidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    overflow: hidden
}

.site-footer__links ul {
    list-style: none outside;
    margin: 0;
    padding: 0;
    line-height: 2
}

.site-footer__links a {
    display: block;
    color: #000
}

    .site-footer__links a:hover {
        color: #D11B32
    }

@media (min-width: 768px) {
    .site-footer__links {
        font-size: 1em
    }

        .site-footer__links ul {
            display: inline-block
        }

        .site-footer__links li {
            display: inline-block;
            margin: 0 0.5em
        }
}

@media (min-width: 992px) {
    .site-footer__links {
        clear: both;
        padding-top: 3em
    }

        .site-footer__links li {
            margin: 0 1.5em
        }
}

.updates-signup {
    margin-bottom: 1em
}

.logo-group img {
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em
}

.site-body {
    background: #fff
}

.site-header {
    border-bottom: 1px solid #f1f1f1
}

@media (min-width: 860px) {
    .site-header--mini {
        -webkit-transform: translateY(-52px);
        -ms-transform: translateY(-52px);
        transform: translateY(-52px)
    }

        .site-header--mini .branding--microsite {
            -webkit-transform: translateY(52px) scale(0.95);
            -ms-transform: translateY(52px) scale(0.95);
            transform: translateY(52px) scale(0.95)
        }
}

@media (min-width: 1100px) {
    .site-header--mini {
        -webkit-transform: translateY(-53px);
        -ms-transform: translateY(-53px);
        transform: translateY(-53px)
    }

        .site-header--mini .branding--microsite {
            -webkit-transform: translateY(53px) scale(0.9);
            -ms-transform: translateY(53px) scale(0.9);
            transform: translateY(53px) scale(0.9)
        }
}

@media (min-width: 1200px) {
    .site-header--mini {
        -webkit-transform: translateY(-62px);
        -ms-transform: translateY(-62px);
        transform: translateY(-62px)
    }

        .site-header--mini .branding--microsite {
            -webkit-transform: translateY(62px) scale(0.83);
            -ms-transform: translateY(62px) scale(0.83);
            transform: translateY(62px) scale(0.83)
        }
}

.obsidian__h2 {
    font-size: 42px;
    font-weight: 300
}

@media (max-width: 1199px) {
    .obsidian__h2 {
        font-size: 36px
    }
}

@media (max-width: 768px) {
    .obsidian__h2 {
        font-size: 32px
    }
}

@media (max-width: 414px) {
    .obsidian__h2 {
        font-size: 26px
    }
}

@media (min-width: 560px) {
    h2 {
        font-size: 2.4em
    }
}

@media (max-width: 859px) {
    .main-nav__link--btn {
        background-color: transparent;
        color: #313131;
        text-transform: none;
        font-size: 1em
    }

        .main-nav__link--btn:hover {
            background: transparent;
            color: #D11B32
        }
}

.obsidian-header {
    background: #000;
    color: #fff;
    padding: 1.15em;
    position: relative;
    z-index: 900;
    font-weight: 300
}

@media (max-width: 859px) {
    .obsidian-header--mobile-sticky .product-nav-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0
    }
}

.obsidian-header__right-link {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 66px;
    color: #fff;
    text-decoration: none;
    border-left: 1px solid #313131;
    padding: 0.75em 0.5em;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.8em
}

    .obsidian-header__right-link:hover {
        color: #fff
    }

@media (min-width: 860px) {
    .obsidian-header__right-link {
        display: none
    }
}

.branding {
    max-width: 100%
}

.branding--microsite {
    display: none
}

.branding__logo--microsite {
    background: transparent url("//images.kwikset.com/is/image/Kwikset/smartcode955logo?scl=1.2&fmt=png-alpha") center center no-repeat;
    width: 100%;
    background-size: contain;
    margin: 0 auto;
    height: 0;
    padding-bottom: 31.333333%
}

@media (max-width: 450px) {
    .branding__logo--microsite {
        width: 200px !important
    }
}

.branding__logo--obsidian-white {
    background: transparent url("/dist/images/Obsidian/obsidian-logo-white.png") center center no-repeat
}

.branding__logo--obsidian-only {
    background: transparent url("/dist/images/Obsidian/obsidian-logo-only.png") center center no-repeat;
    max-width: 80%;
    width: 270px;
    background-size: contain;
    margin: 0 auto;
    height: 0;
    padding-bottom: 22.0930232%
}

.branding__logo--obsidian-only-white {
    background: transparent url("//images.kwikset.com/is/image/Kwikset/obsidian%2Dlogo%2Donly%2Dwhite?scl=1&fmt=png-alpha") center center no-repeat;
    background-size: contain
}

.branding--inline .branding__logo--obsidian {
    width: 150px
}

.branding--inline .branding__logo--obsidian-only {
    width: 270px
}

@media (max-width: 414px) {
    .branding .branding__logo--obsidian-only {
        max-width: 100%
    }
}
/*
@media (min-width: 860px) {
    .branding--microsite {
        position: absolute;
        right: 1.6em;
        top: 0;
        z-index: 1;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
}

@media (min-width: 992px) {
    .branding__logo--microsite-only {
        max-width: 100%
    }
}
    */
@media (min-width: 1100px) {
    .branding--microsite {
        display: block;
        float: right;
        width: 182px;
        padding: 0.2em 0 0.2em 2em
    }
}

@media (min-width: 1200px) {
    .branding--microsite {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        right: 2.6em
    }

    .branding__logo--microsite {
        width: 150px
    }

    .branding--inline .branding__logo--microsite {
        width: 100%
    }

    .branding--inline .branding__logo--microsite-only {
        width: 341px
    }
}

.page-header p.text--larger {
    font-size: 1em
}

.page-header--smartcode-lock {
    background-color: #e8e8e8;
    background-image: url("//images.kwikset.com/is/image/Kwikset/smartcode-955-intro-mobile?scl=1");
    color: #000;
    margin-bottom: 0;
    background-position: center;
    background-size: cover
}

    .page-header--smartcode-lock a:hover {
        color: #fff
    }

    .page-header--smartcode-lock h1 {
        margin-bottom: 0.5em;
        font-size: 1.125em;
        line-height: 1.67
    }

    .page-header--smartcode-lock p {
        margin-bottom: 0.5em
    }

        .page-header--smartcode-lock p.price-buy {
            margin-top: 1.5em;
            margin-bottom: 1.5em;
            max-width: none;
            font-size: 1em
        }

            .page-header--smartcode-lock p.price-buy a {
                color: #fff;
                font-weight: normal
            }

                .page-header--smartcode-lock p.price-buy a span {
                    color: #d11b32
                }

@media (max-width: 991px) {
    .page-header--smartcode-lock {
        padding-bottom: 450px !important;
        background-position: center 410px;
        background-size: 925px
    }

        .page-header--smartcode-lock .page-header__inner {
            position: relative;
            background-color: #e8e8e8
        }

            .page-header--smartcode-lock .page-header__inner:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                height: 50px;
                width: 100%;
                background: -webkit-gradient(linear, left bottom, left top, from(rgba(232,232,232,0)), to(#e8e8e8));
                background: -webkit-linear-gradient(bottom, rgba(232,232,232,0) 0%, #e8e8e8 100%);
                background: -o-linear-gradient(bottom, rgba(232,232,232,0) 0%, #e8e8e8 100%);
                background: linear-gradient(0deg, rgba(232,232,232,0) 0%, #e8e8e8 100%)
            }
}

@media (max-width: 498px) {
    .page-header--smartcode-lock {
        padding-bottom: 340px !important;
        background-position: center bottom;
        background-size: 650px
    }

        .page-header--smartcode-lock .link-divider {
            visibility: hidden;
            display: block
        }
}

@media (min-width: 992px) {
    .page-header--smartcode-lock {
        background-image: url("//images.kwikset.com/is/image/Kwikset/smartcode-955-intro-desktop?scl=1");
        padding: 5em 0 8em
    }

        .page-header--smartcode-lock p.text--larger {
            font-size: 1em
        }

        .page-header--smartcode-lock h1 {
            margin-bottom: 1.5em
        }

            .page-header--smartcode-lock h1:before {
                margin: 0 0 1em
            }

        .page-header--smartcode-lock .page-header__content {
            width: 60%
        }

            .page-header--smartcode-lock .page-header__content p {
                margin-bottom: 1.5em
            }
}

@media (min-width: 1100px) {
    .page-header--smartcode-lock .page-header__content {
        width: 52%
    }
}

.page-header--smartcode-lock .logo-group {
    margin-top: 2em
}

    .page-header--smartcode-lock .logo-group img {
        max-width: 40%
    }

@media (min-width: 992px) and (max-width: 1199px) {
    .page-header--smartcode-lock {
        padding: 3em 0 4em
    }
}

@media (max-width: 414px) {
    .page-header--smartcode-lock .microsite-image-content {
        margin-top: 0
    }

    .page-header--smartcode-lock .btn--smartcode {
        width: 100%
    }
}

.section-bg-image {
    padding: 1em;
    background: transparent none center bottom no-repeat
}

    .section-bg-image .heading--has-micro-divider:after {
        margin-left: auto;
        margin-right: auto
    }

@media (min-width: 992px) {
    .section-bg-image .heading--has-micro-divider:after {
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width: 992px) {
    .section-bg-image.text--centered .heading--has-micro-divider:after {
        margin-left: auto;
        margin-right: auto
    }
}

.section-bg-image--video {
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D2?scl=1);
    color: #fff;
    text-align: center;
    padding: 6em 2em;
    background-size: cover;
    background-color: #000000
}

@media (min-width: 1100px) {
    .section-bg-image--video {
        padding-top: 7em;
        padding-bottom: 7em;
        background-size: contain
    }
}

.section-bg-image--video img {
    width: 65px
}

.section-bg-image--video .btn--smartcode {
    font-size: 0.8em
}

@media (min-width: 1200px) {
    .section-bg-image--video .btn--smartcode {
        font-size: 1em
    }
}

@media (max-width: 768px) {
    .section-bg-image--video .btn--smartcode {
        padding: 1em 2.5em
    }
}

@media (max-width: 414px) {
    .section-bg-image--video .btn--smartcode {
        width: 100%
    }
}
/*removed Obsidian Demo */

.btn--smartcode {
    background-color: #d11b32;
    color: #fff;
    padding: 1em 1.5em
}

    .btn--smartcode:hover {
        background-color: #a0192a
    }

.cta-box--bg-support {
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dfooter%2Dsupport?scl=1)
}

.cta-box--bg-faqs {
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dfooter%2Dfaqs?scl=1)
}

/*Removed Obsidian Demo @Media*/

.ss-tooltips {
    clear: both
}

@media (min-width: 992px) {
    .ss-tooltips {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%
    }
}

@media (min-width: 1250px) {
    .ss-tooltips {
        max-width: 275px;
        right: auto;
        width: 25%;
        top: 52%;
        left: calc(50% + 373px);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 5;
        overflow: hidden;
        font-size: 0.8em
    }
}

@media (min-width: 1400px) {
    .ss-tooltips {
        font-size: 0.9em;
        width: 100%;
        left: calc(50% + 388px)
    }
}

@media (min-width: 1474px) {
    .ss-tooltips {
        left: auto;
        right: -35px
    }
}

.ss-tooltip {
    background: #D11B32;
    padding: 1em;
    display: block;
    margin-bottom: 0;
    margin-left: -1.5em;
    margin-right: -1.5em;
    position: relative;
    z-index: 5
}

@media (min-width: 992px) {
    .ss-tooltip {
        margin: 0
    }
}

@media (min-width: 1250px) {
    .ss-tooltip {
        border-radius: 0 20px 20px 0;
        overflow: hidden;
        padding-left: 40px;
        background: transparent
    }

        .ss-tooltip:before {
            content: '';
            position: absolute;
            left: 30px;
            top: 0;
            right: 0;
            bottom: 0;
            background: #E82337;
            z-index: -1
        }
}

.ss-tooltip--hidden {
    display: none
}

.ss-tooltip__code {
    white-space: nowrap
}

.ss-tooltip-arrow {
    position: absolute;
    top: 33px;
    left: -7px;
    width: 74px;
    height: 74px;
    background-color: #E82337;
    -webkit-transform: rotate(45deg) skew(25deg, 15deg);
    transform: rotate(45deg) skew(25deg, 25deg);
    -ms-transform: rotate(45deg) skew(25deg, 25deg);
    border-radius: 10px
}

@media (max-width: 1249px) {
    .ss-tooltip-arrow {
        display: none
    }
}

.faqs {
    background: transparent !important
}

    .faqs .faqItem.active h4, .faqs .faqItem.active .arrowIcon {
        color: #D11B32 !important
    }

@media (max-width: 991px) {
    .page-header--hero-image {
        padding: 0
    }

    .microsite-image-content__image {
        margin: 3em auto;
        max-width: 450px
    }

    .branding--microsite {
        margin-bottom: 0.5em
    }

    .branding--inline .branding__logo--microsite-only {
        width: 341px
    }

    .section-bg-color .microsite-image-content__content {
        padding-bottom: 0
    }
}

@media (max-width: 414px) {
    .branding-obsidian {
        margin-bottom: 1.5em
    }
}

@media (max-width: 479px) {
    .page-header--hero-image {
        padding-bottom: 0
    }
}

.iframe--email-signup {
    margin-top: 0
}

.list--checkmarks li:before {
    content: '\f00c'
}

.main-nav__link--btn {
    border-radius: 0
}

    .main-nav__link--btn:hover {
        background-color: #a0192a
    }

@media (min-width: 860px) {
    .main-nav__link--btn {
        padding: .35em .95em;
        margin-left: 1.5em
    }
}

.site-footer .updates-signup {
    display: inline-block
}

    .site-footer .updates-signup input {
        width: auto;
        margin-right: 0.25em
    }

    .site-footer .updates-signup .button {
        width: auto
    }

@media (max-width: 480px) {
    .site-footer .updates-signup input {
        width: 60%
    }
}

@media (max-width: 320px) {
    .site-footer.container {
        padding-left: 1em;
        padding-right: 1em
    }
}

@media (max-width: 767px) {
    .site-footer__links {
        text-align: left
    }
}

.page-header--hero-image h1 {
    max-width: 320px
}

@media (min-width: 992px) {
    .page-header--hero-image h1 {
        max-width: 305px
    }

    .page-header--hero-image .microsite-image-content__image img {
        width: 100%
    }
}

@media (min-width: 1200px) {
    .page-header--hero-image h1 {
        max-width: 385px
    }
}

.text--small {
    font-size: 0.75em
}

.section__obsidian--mediablock .media-block__heading {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1em
}

@media (min-width: 1200px) {
    .section__obsidian--mediablock .media-block {
        margin-bottom: 0
    }
}

.section__obsidian--backup img {
    max-width: 380px;
    width: 70%;
    margin-bottom: 3em;
    margin-top: 2em
}

.section__obsidian--backup .microsite-image-content__content > div {
    max-width: 430px;
    margin-top: 3em
}

@media (min-width: 992px) {
    .section__obsidian--backup .microsite-image-content__content {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.section__obsidian--stylish {
    background: transparent none center bottom no-repeat;
    background-image: url("//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D1?scl=1");
    background-position: center;
    background-size: auto
}

    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 8em;
        padding-top: 8em;
        margin-left: 50%
    }

        .section__obsidian--stylish .microsite-image-content__content > div {
            max-width: 435px
        }

@media (max-width: 991px) {
    .section__obsidian--stylish .microsite-image-content__content {
        margin-left: 0;
        padding-bottom: 41em;
        padding-top: 1em
    }

        .section__obsidian--stylish .microsite-image-content__content > div {
            max-width: 600px
        }
}

@media (max-width: 859px) {
    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 38em
    }
}

@media (max-width: 768px) {
    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 35em;
        padding-top: 0
    }

        .section__obsidian--stylish .microsite-image-content__content > div {
            max-width: 505px;
            width: 75%
        }
}

@media (max-width: 414px) {
    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 30em
    }

        .section__obsidian--stylish .microsite-image-content__content > div {
            max-width: none;
            width: 100%
        }
}

@media (max-width: 375px) {
    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 25em
    }
}

@media (max-width: 320px) {
    .section__obsidian--stylish .microsite-image-content__content {
        padding-bottom: 21em
    }
}

@media (max-width: 1199px) {
    .section__obsidian--stylish {
        background-size: cover
    }
}

@media (max-width: 991px) {
    .section__obsidian--stylish {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D1%2Dtablet?scl=1);
        background-position: center bottom
    }
}

@media (max-width: 414px) {
    .section__obsidian--stylish {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D1%2Dmobile?scl=1);
        background-position: center bottom
    }
}

.section__obsidian--cuttingedge {
    background: transparent none center bottom no-repeat;
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D3?scl=1);
    background-position: center;
    background-size: auto
}

.section__obsidian--cuttingedge-tablet {
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D3%2Dtablet?scl=1);
    padding: 25em 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

@media (max-width: 414px) {
    .section__obsidian--cuttingedge-tablet {
        padding: 13.65em 0
    }
}

.section__obsidian--cuttingedge .microsite-image-content__content {
    padding-bottom: 11em;
    padding-top: 11em
}

    .section__obsidian--cuttingedge .microsite-image-content__content > div {
        max-width: 415px
    }

@media (max-width: 991px) {
    .section__obsidian--cuttingedge .microsite-image-content__content {
        padding-bottom: 0;
        padding-top: 0
    }

        .section__obsidian--cuttingedge .microsite-image-content__content > div {
            max-width: none;
            width: 80%
        }
}

@media (max-width: 414px) {
    .section__obsidian--cuttingedge .microsite-image-content__content > div {
        max-width: none;
        width: 100%
    }
}

@media (max-width: 991px) {
    .section__obsidian--cuttingedge {
        background-image: none
    }
}

@media (max-width: 1199px) {
    .section__obsidian--cuttingedge {
        background-size: cover
    }
}

.section__obsidian--wireless {
    background: transparent none center bottom no-repeat;
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D4?scl=1);
    background-position: 70% center;
    background-size: auto
}

    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 6em;
        padding-top: 6em
    }

        .section__obsidian--wireless .microsite-image-content__content > div {
            max-width: 435px
        }

        .section__obsidian--wireless .microsite-image-content__content img {
            margin-top: 5em
        }

@media (max-width: 991px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 37em;
        padding-top: 0
    }

        .section__obsidian--wireless .microsite-image-content__content > div {
            max-width: 455px
        }
}

@media (max-width: 859px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 33em
    }
}

@media (max-width: 768px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 29em;
        padding-top: 0
    }

        .section__obsidian--wireless .microsite-image-content__content > div {
            max-width: 505px;
            width: 75%
        }
}

@media (max-width: 414px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 28em
    }

        .section__obsidian--wireless .microsite-image-content__content > div {
            max-width: none;
            width: 100%
        }
}

@media (max-width: 375px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 25em
    }
}

@media (max-width: 320px) {
    .section__obsidian--wireless .microsite-image-content__content {
        padding-bottom: 21em
    }
}

@media (min-width: 1800px) {
    .section__obsidian--wireless {
        background-position: center
    }
}

@media (max-width: 1199px) {
    .section__obsidian--wireless {
        background-size: cover;
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D4%2D1100?scl=1)
    }
}

@media (max-width: 991px) {
    .section__obsidian--wireless {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D4%2Dtablet?scl=1);
        background-position: center bottom
    }
}

@media (max-width: 414px) {
    .section__obsidian--wireless {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D4%2Dmobile?scl=1);
        background-position: center bottom
    }
}

.section__obsidian--inbed {
    background: transparent none center bottom no-repeat;
    background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D5?scl=1);
    background-position: top right;
    background-size: auto
}

    .section__obsidian--inbed .microsite-image-content__content {
        padding-bottom: 9em;
        padding-top: 9em
    }

        .section__obsidian--inbed .microsite-image-content__content > div {
            max-width: 405px
        }

        .section__obsidian--inbed .microsite-image-content__content img {
            margin-top: 2em;
            max-width: 50%
        }

@media (max-width: 991px) {
    .section__obsidian--inbed .microsite-image-content__content {
        padding-bottom: 37em;
        padding-top: 0
    }

        .section__obsidian--inbed .microsite-image-content__content > div {
            max-width: 455px
        }
}

@media (max-width: 859px) {
    .section__obsidian--inbed .microsite-image-content__content {
        padding-bottom: 33em
    }
}

@media (max-width: 768px) {
    .section__obsidian--inbed .microsite-image-content__content {
        padding-bottom: 29em;
        padding-top: 0
    }

        .section__obsidian--inbed .microsite-image-content__content > div {
            max-width: 505px;
            width: 75%
        }
}

@media (max-width: 414px) {
    .section__obsidian--inbed .microsite-image-content__content {
        padding-bottom: 15em;
        padding-top: 0
    }

        .section__obsidian--inbed .microsite-image-content__content > div {
            max-width: none;
            width: 100%
        }
}

@media (min-width: 1800px) {
    .section__obsidian--inbed {
        background-position: center top;
        background-color: #000000;
        background-size: cover
    }
}

@media (max-width: 1199px) {
    .section__obsidian--inbed {
        background-size: cover
    }
}

@media (max-width: 991px) {
    .section__obsidian--inbed {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D5%2Dtablet?scl=1);
        background-position: center top
    }
}

@media (max-width: 414px) {
    .section__obsidian--inbed {
        background-image: url(//images.kwikset.com/is/image/Kwikset/obsidian%2Dbg%2D5%2Dmobile?scl=1);
        background-position: center bottom
    }
}

.section__obsidian--lockcolors .container-max {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.section__obsidian--lockcolors .microsite-image-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 35%;
    float: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.section__obsidian--lockcolors .microsite-image-content__content {
    text-align: center;
    width: 100%;
    padding: 0;
    font-size: 1em
}

.section__obsidian--lockcolors .microsite-image-content__image {
    width: 100%;
    min-height: 345px
}

.section__obsidian--lockcolors .microsite-image-content__img--homeconnect {
    position: absolute;
    top: auto;
    left: 0;
    bottom: -10px;
    right: -358px;
    margin: auto
}

.section__obsidian--lockcolors .microsite-image-content .select-option {
    float: none;
    border: none
}

.section__obsidian--lockcolors .microsite-image-content .btn--smartcode {
    padding: 1em 3em
}

@media (max-width: 1200px) {
    .section__obsidian--lockcolors .microsite-image-content {
        width: 45%
    }

    .section__obsidian--lockcolors .container-max {
        -ms-flex-pack: distribute;
        justify-content: space-around
    }
}

@media (max-width: 991px) {
    .section__obsidian--lockcolors .microsite-image-content__image {
        margin: 0 auto 1em;
        min-height: 0
    }

    .section__obsidian--lockcolors .microsite-image-content__image-group {
        position: relative;
        padding-bottom: 0;
        height: auto;
        width: 100%;
        margin-top: 0
    }

        .section__obsidian--lockcolors .microsite-image-content__image-group img {
            width: 70%
        }

            .section__obsidian--lockcolors .microsite-image-content__image-group img.microsite-image-content__img--homeconnect {
                right: -85%;
                width: 20%
            }
}

@media (max-width: 767px) {
    .section__obsidian--lockcolors .container-max {
        display: block
    }

    .section__obsidian--lockcolors .microsite-image-content {
        width: 100%
    }

    .section__obsidian--lockcolors .microsite-image-content__image-group img {
        width: 60%
    }

        .section__obsidian--lockcolors .microsite-image-content__image-group img.microsite-image-content__img--homeconnect {
            right: -70%;
            width: 15%
        }
}

.finishes {
  list-style: none outside;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: inline-block;

    &--selected {
      .finishes__label:before {
        content: '';
        border: 2px solid #000;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
      }
    }
  }

  &__name {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  img,
  &__image {
    display: block;
    width: 40px;
    height: 40px;
    -ms-interpolation-mode: bicubic;
    image-rendering: optimizeQuality;
  }

  &__label {
    display: block;
    padding: 3px;
    position: relative;
    cursor: pointer;
    &:hover, &:focus {
      border: 2px solid #d12231;
      padding: 0;
    }
    box-sizing: border-box;
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    &:checked {
      + label:before {
        content: '';
        border: 2px solid #000;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
      }
    }
  }

  &--list {
    display: block;

    .finishes__item {
      display: block;
      border-bottom: 1px solid $lightgrey;
      padding-bottom: 0.5em;
      padding-top: 0.5em;

      &:last-child {
        border-bottom: none;
      }
    }

    .finishes__name {
      position: static;
      clip: auto;
      margin: 0;
      height: auto;
      width: auto;
      overflow: visible;
      display: inline;
      margin-left: 0.25em;
    }

    img,
    .finishes_image {
      display: inline;
      vertical-align: middle;
    }
  }
}

.product-listing {
  &__list {
    list-style: none outside;
    margin: 0;
    padding: 0.5em 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // @media (max-width: 1184px) {
    //   flex-direction: column;
    //   align-items: center;
    // }
  }

  &__heading {
    span {
      font-size: 16px;
      color: $darkgrey;
    }
  }

  &__item {
    flex-basis: 324px;
    flex-grow: 1.2;

    min-width: 1px;

    width: calc(50% - 0.25rem);
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      margin-left: 0.25rem;
      margin-right: 0.25rem;

      &:nth-child(2n + 1) {
        margin-left: 0;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    .product {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 768px) {
      width: calc(33.33333% - 0.7rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      .product-listing__list--search-results & {
        margin-bottom: 0;
      }

      .product-listing__list--search-results &,
      .product-listing__list--category-style &,
      .product-listing__list--similar & {
        width: calc(25% - 0.75rem);
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        &:nth-child(4n + 4) {
          margin-right: 0;
        }
      }
    }
  }

  &--list {
    .product-listing {
      &__list {
        display: block;
      }

      &__item {
        width: auto;
        margin: 0;

        .product {
          max-width: none;
        }

        &:first-child {
          .product {
            border-top: 0;
          }
        }
      }
    }

    .product {
      border-top: 1px solid $grey;
      &:after {
        content: '';
        clear: both;
        display: table;
      }

      &__link {
        display: flex;
        flex: 0 0 auto;
      }

      &__image {
        float: left;
        order: -1;
        width: 70px;
        height: auto;
        margin-right: 2em;
        min-width: 70px;

        @media #{$sm-up} {
          width: 105px;
          min-width: 105px;
        }
      }

      &__name {
        border: none;
        padding: 0;
        margin-top: 0;
      }

      &__finishes {
        order: 1;
      }

      &__reviews {
        order: 2;
      }

      &__info {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
  }
}

.product {
    max-width: 300px;
    font-size: 0.9rem;
    height: 100%;
    position: relative;
    padding: 1rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
        transition: border-color 0.25s, border-width 0.25s;
    }

    &:hover {
        &:before {
            border-color: $grey;
            border-width: 7px;
        }
    }

    &__coming-soon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: $green;
        color: #fff;
        margin: 0;
        text-align: center;
        line-height: 1;
        padding: 0.5rem;
        z-index: 2;

        .product-listing--list & {
            position: relative;
            width: 100%;
            left: auto;
            right: auto;
            top: auto;
            flex: 0;
            white-space: nowrap;
            order: 3;
            background: white;
            color: darkgreen;
            font-weight: bold;
            padding-right: 1.25rem;
        }
    }
    &__discontinued {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #000;
        color: #fff;
        margin: 0;
        text-align: center;
        line-height: 1;
        padding: 0.5rem;
        z-index: 2;

        .fa {
            color: $brightred;
            margin-right: 0.25rem;
        }

        .product-listing--list & {
            position: relative;
            width: 100%;
            left: auto;
            right: auto;
            top: auto;
            padding-right: 1.25rem;
            font-weight: bold;
            color: black;
            flex: 0;
            white-space: nowrap;
            order: 3;
            background: white;
        }
    }

    &--unavailable {
        .product__image {
            opacity: 0.3;
            position: relative;
        }

        .product__image-wrap {
            position: relative;

            &:after {
                content: 'Unavailable in selected finish.';
                opacity: 1;
            }
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        position: relative;
        z-index: $base;
        color: currentColor;
    }

    &__image-wrap {
        height: 220px;
        overflow: hidden;
        position: relative;
        margin-bottom: 2em;

        &:after {
            content: '';
            opacity: 0;
            display: block;
            background: #fff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            padding: 0.5rem 0.5rem;
            transition: opacity 0.25s;
            margin-top: 0.5rem;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            position: absolute;
            top: 50%;
            font-size: 0.7rem;
        }

        .product-listing--list & {
            min-width: 70px;

            @media #{$sm-up} {
                min-width: 106px;
            }
        }

        @media (max-width: 1092px) and (min-width: 845px) {
            height: 140px;
        }


    }

    &__image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        transition: opacity 0.25s;
        -ms-interpolation-mode: bicubic;
        position: relative;
        object-fit: contain;
        // width: 100%;
        height: 100%;

        //.product--style & {
        //  @media (min-width: 1250px) {
        //    max-width: 230px;
        //  }
        //}

        .ie11 & {
            min-height: 100%;
            max-height: 100%;
            position: relative;
            left: 50%;
            top: 50%;
            right: auto;
            bottom: auto;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__finishes {
        margin-top: 0.25rem;
        margin-bottom: 0;

        .product-listing--list & {
            order: 4;
            white-space: nowrap;
        }
    }

    &__name {
      min-height: 2rem;
        font-size: 1rem;
        font-weight: $xbold;
        margin: 0.5rem 0 0.25rem;
        padding-top: 0.5em;
        border-top: 1px solid $anothergrey;

        .product--style & {
            border-top: none;
            padding-top: 0;
        }
    }

    &__collection-info {
        position: absolute;
        width: 300px;
        height: 150px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 100%;
        background: white;
        border: 1px solid $lightgrey;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s ease;

        &.state-active {
            visibility: visible;
            opacity: 1;
        }
    }

    &__reviews {
        .fa {
            color: $darkergrey;
        }
    }

    &__rating {
        color: $darkergrey;
        display: inline-block;
        margin-right: 0.25em;
    }

    &--alt {
        .product__info {
          display: flex;
            text-align: center;
            justify-items: center;
        }

        .product__name {
            border-top: 0;
        }

        .product__collection {
            text-transform: uppercase;
        }
    }
}

.marginleft {
  @media (min-width: 480px) {
    margin-left: 25%;
  }

  @media (max-width: 480px) {
    margin-left: 10%;
  }

  margin-top: -0.6em;
}

.product-result {
    font-size: 0.85rem;
    display: flex;
    transition: background-color 0.25s;

    &:hover {
        background-color: $grey;
    }

    &__image-wrap {
        background: $white;
        border: 1px solid $grey;
        display: block;
        padding: 0.5rem;
        float: left;
        margin-right: 0.75rem;
        min-width: 108px;
        max-width: 108px;
        min-height: 108px;
        max-height: 108px;
    }

    &__image {
        display: block;
    }

    &__name {
        font-weight: $bold;
        margin: 0 0 0.35rem 0;
        font-size: 1rem;
        line-height: 1.2;
    }

    &__information {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-shrink: 1;
        justify-content: center;

        a {
            text-decoration: none;
            display: block;
        }
    }

    &__ratings {
        margin: 0.35rem 0;
    }

    &__link-list {
      flex-wrap: nowrap;
        list-style: none outside;
        // margin: 0 auto;
        margin-top: 1em;
        padding: 0;

        a {
            text-decoration: underline;
        }
    }

    &__link-item {
        float: left;
        margin-right: 0.3em;
        margin-left: 0.1em;
        padding-right: 0.3em;
        padding-left: 0.1em;
        border-right: 1px solid $grey;
    }

    &__link-item-last {
        float: left;
        margin-right: 0.5em;
        padding-right: 0.5em;
    }
}

.halo-grid {
    &__outer-wrap {
    }

    &__box-content-wrap {
        max-width: 90%;
        margin: 0 auto;

        @media (min-width: 1366px) {
            padding: 1em 3em;
            max-width: 80%;
        }
    }

    &__box-item-heading {
        font-size: 25px;
        line-height: 30px;
        font-weight: 600;
        background-color: #f6f6f6;
        padding: 20px;
    }

    &__box-item {
        padding: 2em 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        display: -ms-grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-areas: "info prim";

        @media (max-width: 991px) {
            display: grid;
            grid-template-columns: 1fr;
            display: -ms-grid;
            -ms-grid-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "prim" "info";
        }

        &--reverse {
            grid-template-areas: "prim info";

            @media (max-width: 991px) {
                grid-template-areas: "prim" "info";
            }
        }
    }

    &__box-logo {
        margin: 0.6em 0 1em;

        @media (min-width: 1366px) {
            margin: auto 0;
            flex-shrink: 0;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            vertical-align: middle;
        }
    }

    &__box-image-wrap {
        grid-area: prim;
        text-align: center;
        margin: auto 0;
    }

    &__box-info {
        grid-area: info;
        font-size: 16px;
        margin: 0.6em 0;

        &-aligncenter {
            align-items: center;
        }

        @media (max-width:991px) {
            text-align: center;
            padding: 10px;
        }
    }

    &__box-item-link {
        text-decoration: none;
        font-weight: 500;
        color: #000;
    }
}

    .box-info-wrap {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        margin-bottom: 1em;
        font-weight: normal;

        @media (max-width: 991px) {
            display: -webkit-box;
            display: -ms-flexbox;           
        }
    }

    .box-info-logo {
        margin-bottom: 2em;
       /* min-width: 81px;*/
        height: 100px;
        justify-self: end;
        -ms-flex-item-align: center;
        align-self: center;
        margin-bottom: 0;
        padding-right: 1em;

        @media (min-width: 768px) {
            justify-self: end;
            -ms-flex-item-align: center;
            align-self: center;
            margin-bottom: 0;
            padding-right: 1em;
        }
    }

.box-info-subtitle {
    margin: 1em;
    border: none;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 1em;
    border-left: 1px solid #000;

    @media (max-width: 661px) {     
            width: 210px;      
    }
}
.leftpadding {
    padding-left: .5em;
}

