﻿.form {
    &__fieldset {
        border: none;
        padding: 0;
        &--top-divider {
            border-top: 1px solid $lightgrey;
        }
    }
    &__legend {
        font-size: 1.1em;
        font-weight: $xbold;
        display: block;
        width: 100%;
    }
}

.input-group {
    display: flex;
    justify-content: center;
    position: relative;

    &--border {
        border-color: #c5c5c5;
        border-style: solid;
        padding: 0.8em;
    };

    &--vertical {
        display: block;
    }

    &--label-inside {
        position: relative;
        color: $black;
    }

    &__error {
        color: $red;
        font-size: 0.8em;
    }

    &__success {
        color: $green;        
    }

    &__label {
        display: block;
        //margin-bottom: 1em;   // removed - we should use the margin classes for this since this won't be consistent across every label
        .input-group--label-inside & {
            position: absolute;
            top: 50%;
            left: 1em;
            transform: translateY(-50%);
            transform-origin: 0 0;
            transition: all 0.25s;
        }

        .input-group--focused &, .input-group--text-filled & {
            transform: scale(0.65) translateY(0%);
            top: 0.25em;
        }
    }

    &__prefix {
        width: 1em;
        display: flex;
        align-items: center;
        float: left;
        padding: 0.5em 0;
    }

    &__input-wrap {
        &--fake {
            background: #fff;
            padding: 0 0.5em;
            border-radius: 0;
            border: 1px solid $anothergrey;
            display: flex;
            align-items: center;

            .input-group__input {
                border: none;
                width: calc(100% - 1em);

                &:focus {
                    outline: none;
                }
            }
        }
    }

    &__select-wrap {
        position: relative;

        &:after {
            content: '\f107';
            font-family: FontAwesome;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    &__textarea {
        padding: 0.8rem;
        border-radius: 0;
        min-height: 3em;
        border: 1px solid $anothergrey;
        display: block;
        width: 100%;
        box-sizing: border-box;

        &:focus {
            border-color: $black;
            outline: none;
        }
    }

    &__input {
        padding: 0.8rem;
        border-radius: 0;
        line-height: 1;
        border: 1px solid $anothergrey;
        box-sizing: border-box;

        &:focus {
            border-color: $black;
            outline: none;
        }

        &--search {
            font-size: 1.05rem;
            border: none;
            border-bottom: 1px solid $anothergrey;
            transition: border-color 0.25s;
            width: 100%;
        }

        &--side-padding {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        &--block {
            display: block;
            width: 100%;
        }

        &.ng-touched {
            &.ng-invalid {
                border-color: $red;

                + .input-group__status {
                    &:before {
                        content: '\f06a';
                        color: $red;
                    }

                    display: block;
                }
            }

            &.ng-valid {
                + .input-group__status {
                    &:before {
                        content: '\f058';
                        color: $green;
                    }

                    display: block;
                }
            }
        }

        .input-group--label-inside & {
            display: block;
            width: 100%;
            padding-top: 1em;
        }
    }

    &__select, &__fake-select {
        &::-ms-expand {
            display: none;
        }

        border-radius: 0;
        appearance: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $anothergrey;
        padding: 0.8rem 2rem 0.8rem 0.8rem;

        &:focus {
            border-color: $black;
            outline: none;
        }

        &.ng-touched {
            &.ng-invalid {
                border-color: $red;

                + .input-group__status {
                    &:before {
                        content: '\f06a';
                        color: $red;
                    }

                    display: block;
                }
            }

            &.ng-valid {
                + .input-group__status {
                    &:before {
                        content: '\f058';
                        color: $green;
                    }

                    display: block;
                }
            }
        }

        @media (min-width: 480px) {
            //min-width: 275px;
            width: 100%;
            max-width: 100%;
        }
    }

    &__status {
        position: absolute;
        top: 1em;
        right: -0.5em;
        z-index: 1;
        line-height: 1;
        background-color: $white;

        .input-group__select-wrap & {
            top: -0.5em;
        }

        &:before {
            font-family: FontAwesome;
        }
    }
}

.input-increment {
    display: flex;

    &__btn {
        color: #fff;
        border: none;
        border-radius: 0;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.1rem;
        font-weight: $bold;

        &:before {
            font-family: FontAwesome;
        }

        &--up {
            background-color: $red;

            &:before {
                content: '\f067';
            }
        }

        &--down {
            background-color: $anothergrey;

            &:before {
                content: '\f068';
            }
        }
    }

    &__input {
        -moz-appearance: textfield;
        padding: 0.5em 0;
        text-align: center;
        width: calc(100% - 100px);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
}

/* ask a question form */

.aaq-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aaq-product {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__img-wrap {
        position: relative;
        margin-right: 1em;

        .product__discontinued {
            font-size: 0.65em;
        }
    }

    &__image {
        max-width: 100px;
        height: auto;
    }

    &__product-name {
        font-weight: $bold;
        display: block;
    }

    &__content {
    }

    &__not-mine {
        font-size: 0.8em;

        a {
            font-weight: $bold;
        }
    }
}

.aaq-textarea { min-height: 7em; }

.fake-select {
    position: relative;
    user-select: none;
    padding: 0.5em;
    height: 49px;

    &__default {
        display: flex;
        height: 100%;
        align-items: center;

        > img {
            margin-right: 0.25em;
        }
    }

    &__list {
        user-select: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        background: #fff;
        border: 1px solid #ccc;
        list-style: none outside;
        margin: 0;
        padding: 0;
        display: none;
        &--visible {
            display: block;
        }
    }

    &__item {
        padding: 0.5em;
        display: flex;
        align-items: center;

        &--selected {
            background-color: #f1f1f1;
        }

        img {
            margin-right: 0.25em;
        }

        &:hover {
            background-color: #f1f1f1;
        }
    }
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox label, .checkbox .faux-label {
    margin-right: 0.7em;
    /* margin-bottom: 0; */
}

.checkbox input[type="checkbox"]:checked {
    outline: 0;
    width: 16px;
    height: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.checkbox input[type="checkbox"] {
    border: 1.75px solid #94989c;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    background: white;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    position: relative;
    outline: 0;
    line-height: 16px;
}
.checkbox input[type="checkbox"]:checked:before {
    content: "✔";
    font-family: icomoon;
    color: #e24747;
    position: absolute;
    top: -2px;
    left: 2px;
    display: block;
    font-size: 12px;
    outline: 0;
    border: 0;
    cursor: pointer;
}

.checkbox input {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

.checkbox label {
    font-weight: normal;
    text-transform: none;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0;
}

.checkbox__text {
    padding-left: 6px;
    font-size: 14px;
    /* -webkit-box-flex: 1; */
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.aaq-file-input input[type="file"] {
    display: block;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.aaq-file-input__value {
    margin: 0.5em 0;
    font-size: 0.8125em;
    line-height: 1.25;
    word-break: break-word;
}
.aaq-file-input-close {
    color: #e24747;
    margin-left: 6px;
    position: relative;
    top: 2px;
    font-weight: bold;
    cursor: pointer;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-close:before {
    content: "X";
}

.csnumber-text {
    font-size: 1.2rem;
    font-weight: 800;
}
.old-case-alert{
    background-color: #3288cb;
    padding:2px;
    color: #fff; 
    border: 1px solid #545353;
}

.weiserheader, .weiserfooter {
    text-align: center;
    padding: 10px 0;
    width: 100%;
    background-color: #242424;
}