﻿.btn-group { max-width: 280px; margin-left: auto; margin-right: auto;
    .btn { display: block; }
    .btn:first-child { margin-bottom: 1em; }
    @media (min-width: 560px) {
        max-width: 100%;
        .btn { display: inline-block; }
        .btn:first-child { margin-right: 1em; margin-bottom: 0; }
    }
}

.btn-group-three { max-width: 380px; margin-left: auto; margin-right: auto;
    .btn { display: block; }
    @media (min-width: 560px) {
        max-width: 100%;
        .btn { display: inline-block; }
    }
}
.btn {
    font-size: 1rem;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: currentColor;
    font-weight: $medium;
    text-decoration: none;
    transition: all 0.25s;
    letter-spacing: 0.1em;
    line-height: 1.2;
    box-sizing: border-box;
    white-space: normal;
    cursor: pointer;

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &--spacing-sm {
        letter-spacing: 0;
    }
    &--solid {
        background: $red;
        color: $white;
        border: 1px solid $red;
        padding: 14px 32px;
        &--psbutton
        {
            background: $white;
            border: 1px solid $white;
        }
        &:hover {
            background-color: $brightred;
            border-color: $brightred;
        }

        &-darker {
            background: $darkred;
            border: 1px solid $darkred;
            padding: 14px 32px;
            color: #ffffff;

            &--small {
                font-size: 0.875rem;
            }

            &--narrow {
                padding: 14px 21px;
            }

            &:hover {
                background-color: $red;
                border-color: $red;
            }
        }
    }

    &--black {
        background: $black;
        color: $white;
        border: 1px solid $black;
        padding: 14px 32px;

        &:hover {
            background-color: $white;
            border-color: $black;
            color: $black;
        }
    }

    &--transparent {
        border: 1px solid currentColor;
        padding: 14px 32px;
        background: transparent;
        max-width: 100%;

        &:hover {
            background-color: $brightred;
            border-color: $brightred;
            color: $white;
        }
    }

    &--full-width {
        max-width: 100%;
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--jump-link {
        @include gothamBold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 2.4px;
        color:$black;
        position: relative;

        &:before {
            font-size: 1.375rem;
            line-height: 1.18;
            letter-spacing: -0.4px;
            content: '\f0ab';
            font-family: FontAwesome;
            display: inline-block;
            position: relative;
            color: #d90114;
            top: -4px;
            z-index: 2;
            margin-right: 0.3rem;
            vertical-align: top;
        }

        &:after {
            content: "";
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: white;
            display: block;
            position: absolute;
            top:0 ;
            left: 1px;
            z-index: 1;
        }
    }

    &--jump-link-white {
        @include gothamBold;
        font-size: 14px;
        color: $white;
        line-height: 16px;
        letter-spacing: 2.4px;
        position: relative;

        &:before {
            font-size: 1.375rem;
            line-height: 1.18;
            letter-spacing: -0.4px;
            content: '\f0ab';
            font-family: FontAwesome;
            display: inline-block;
            position: relative;
            color: #d90114;
            top: -4px;
            z-index: 2;
            margin-right: 0.3rem;
            vertical-align: top;
        }

        &:after {
            content: "";
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: white;
            display: block;
            position: absolute;
            top: 0px;
            left: 1px;
            z-index: 1;
        }
    }

    &--outline-white {
        border: 1px solid $white;
        padding: 14px 32px;
        background: transparent;
        color: $white;

        &:hover {
            background-color: $brightred;
            border-color: $brightred;
            color: $white;
        }
    }

    &--outline-red {
        border: 1px solid $red;
        padding: 14px 32px;
        background: transparent;
        color: $red;

        &:hover {
            background-color: $brightred;
            border-color: $brightred;
            color: $white;
        }
    }

    &--outline-black {
        border: 1px solid $black;
        padding: 14px 32px;
        background: transparent;
        color: $black;

        &:hover {
            background-color: $brightred;
            border-color: $brightred;
            color: $white;
        }
    }

    &--dark {
        border: 1px solid currentColor;
        padding: 14px 32px;

        &:hover {
            background-color: $black;
            border-color: none;
            color: $white;
        }
    }

    &--text {
        text-align: left;
        text-transform: none;
        background: transparent;
        border: none;
        color: currentColor;
        font-weight: $book;
        text-decoration: none;
        border-bottom: 1px solid rgba(0,0,0,0.25);
        letter-spacing: -0.04em;

        .fa { color: $red; }

        &:hover {
            border-bottom-color: $midgrey;
            color: $red;
        }
        &.btn--text-no-border {
            border: none;
        }
    }

    &--view {
        border: none;
        &:after {
            font-family: FontAwesome;
            content: '\f138';
            color: $red;
            margin-left: 0.5em;
        }
    }

    &--video {
        &:before {
            font-size: 1.375rem;
            line-height: 1.18;
            letter-spacing: -0.4px;
            content: '\f144';
            font-family: FontAwesome;
            display: inline-block;
            position: relative;
            color: $red;
            margin-right: 0.8rem;
            vertical-align: top;
        }

        .btn__text {
            text-align: left;
            text-transform: none;
            background: transparent;
            border: none;
            color: currentColor;
            font-weight: $book;
            text-decoration: none;
            border-bottom: 1px solid rgba(0,0,0,0.25);
            letter-spacing: -0.04em;
            line-height: 1.5;
            display: inline-block;
        }

        &:hover {
            .btn__text {
                border-bottom-color: $midgrey;
                color: $red;
            }
        }
    }

    &--explore {
        color: currentColor;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.63;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        text-decoration: none;

        &:before {
            font-size: 1.375rem;
            line-height: 1.18;
            letter-spacing: -0.4px;
            content: '\f0ab';
            font-family: FontAwesome;
            display: inline-block;
            position: relative;
            color: $red;
            margin-right: 0.8rem;
            vertical-align: top;
        }
    }

    &--explore-variable {
        color: currentColor;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.63;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        text-decoration: none;

        &:before {
            font-size: 1.375rem;
            line-height: 1.18;
            letter-spacing: -0.4px;
            content: '\f0ab';
            font-family: FontAwesome;
            display: inline-block;
            position: relative;
            color: $red;
            margin-right: 0.8rem;
            vertical-align: top;

            @media (min-width: 992px) {
                color: $white;
            }
        }
    }

    &--info {
        color: currentColor;
        font-size: 1rem;
        text-decoration: underline;
        text-transform: none;
        line-height: 1rem;
        letter-spacing: -0.03em;
        font-weight: $light;

        &:before {
            font-size: 1.375rem;
            content: '\f05a';
            font-family: FontAwesome;
            float: left;
            margin-right: 0.75rem;
        }
    }

    &--padding-sm {
        padding: 12px 26px;
    }

    &--has-text-fade {
        text-align: left;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: auto;
        position: absolute;
        padding-top: 7.25rem;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+57 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &--sm {
        font-size: 0.9rem;
        padding: 0.75rem 1rem;
    }

    &--xs {
        font-size: 0.7rem;
        padding: 0.5rem 0.75rem;
    }

    &--max-width {
        max-width: 100%;
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
    }
}
