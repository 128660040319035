﻿.group:after {
  content: "";
  display: table;
  clear: both;
}

.mobile-only { display: block;
  @media (min-width: 768px) {
    display: none !important;
  }
}

.mobile-tablet-only { display: block;
    @media (min-width: 992px) {
        display: none !important;
    }
}

.tablet-desktop-only { display: none;
    @media (min-width: 768px) {
        display: block;
    }
}

.desktop-only { display: none !important;
    @media (min-width: 992px){
        display: block !important;
    }
}

.not-mobile { display: none;
  // not sure why this was 415px. This class is being used in the site header (support section) and needs to be 768px.
  // if we need to change this please either change that classname to something unique or speak to caitlin @ WA to discuss
  //@media (min-width: 415px) {
  @media (min-width: 768px) {
    display: block;
  }
}

.has-top-border {
    border-top: 1px solid transparent;
    &--grey {
        border-color: $grey;
    }
    &--dark-grey {
        border-color: #ccc;
    }
}

.has-bottom-border {
    border-bottom: 1px solid transparent;

    &--grey {
        border-color: $grey;
    }
}

.has-full-border {
    border: 1px solid transparent;
    &--grey {
        border-color: $grey;
    }
}

.hidden { display: none !important; }

.position-relative{
    position: relative;
}

.has-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

html {
  box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

// what is this supposed to do? it causes errors in IE11.
// If you want to add this back in please get in touch with Caitlin and discuss
/*[class*='col-'] {
    flex: 1;
}*/

.full-screen-modal {
    overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img { max-width: 100%; }

.container-standard-content {
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 768px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (min-width: 992px) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    @media (min-width: 1100px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

// do we need this?
/*.standard-padding-sides {
    padding-right: 1rem;
    padding-left: 1rem;
    &--mobile {
        @media (max-width: 414px) {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }
}*/

.container-max {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    //position: relative; //removed because this can affect making position absolute children in a undesireable way
}

.container-1200 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.container-1280 {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.container-narrow {
    max-width: 1052px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    // removing this padding to see what it affects, i don't think we want this
    /*@media (max-width: 768px) {
        padding: 0 2.8rem;
    }
    &--no-padding {
        @media (max-width: 768px) {
            padding: 0;
        }
    }*/
}

.container-text {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.container-intro-text {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.container-color {
    &--grey {
        background-color: #f6f6f6;

        // do we really need this?
        /*@media (max-width: 414px) {
            &-mobile {
                background-color: #f6f6f6;
            }
        }*/
    }
}

.container-ghost {
    height: 1px; // Used for Sticky Nav invisible anchor point
}

.js--sticky.stick {
    position: fixed;
    top: 0;
    z-index: 10000;
}

.no-row .row {
    margin: 0;
}

a.breakat {
    display: inline-block;

    &--desktop {
        @media (max-width: 1023px) {
            display: block;
        }
    }

    &--tablet {
        @media (max-width: 767px) {
            display: block;
        }
    }

    &--mobile {
        @media (max-width: 413px) {
            display: block;
        }
    }
}

/* overrides */

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

@media (min-width: 1200px) {
    .col-lg-offset-1-and-half {
        margin-left: 12.499999995%;
    }
}

@media (min-width: 992px) {
    .col-md-offset-1-and-half {
        margin-left: 12.499999995%;
    }
}

.center-block{
  display: block;
  margin: 0 auto;
}

.flex-vert-center{
    @media screen and (min-width: 992px){
      display: flex;
      align-items: center;
    }
    &--sm{
      @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
      }
    }
}

.row-flex {

    &--space-between {
    }
    // overrides
    .row {
        margin: 0;
    }

    @media (min-width: 768px) {
        .row {
            display: flex;
            justify-content: space-between;
        }

        .col-md-6 {
            max-width: calc(50% - 1rem);
        }
    }
}

.debug *{
    outline: 1px solid mediumseagreen;
}

.img-overlap{
    width: 100%;
    max-width: 800px;
  @media screen and (min-width: 992px){
    margin-top: -10rem;
  }
}

/* Bootstrap Clearfix and Visible block */

.clearfix:before,
.clearfix:after {
    display: table;
    content: " ";
}

.clearfix:after {
    clear: both;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
    //margin: 0 !important; // 5/17/18 Caitlin @ WA: why is margin set to 0? Removing this since we should be able to set margin in other ways?
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    .visible-xs-block {
        display: block !important;
    }
    .visible-xs-inline {
        display: inline !important;
    }
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
    .visible-sm-block {
        display: block !important;
    }
    .visible-sm-inline {
        display: inline !important;
    }
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    .visible-md-block {
        display: block !important;
    }
    .visible-md-inline {
        display: inline !important;
    }
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    .visible-lg-block {
        display: block !important;
    }
    .visible-lg-inline {
        display: inline !important;
    }
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}


.align-bottom{
    // outline: 2px solid gold;
    // height: 100%;
    @media(min-width: $tablet){
        display: flex;
        align-items: baseline;
    }
}


/*================================================================================
[Full-width sections]
*/

:root {
    --scrollbar-width: 0px; // This value is updated by JavaScript
}

.full-width {
    clear: both;
    position: relative;
    margin-left: calc(-50vw + 50%); // Safe-ish scrollbar width for IE11
    margin-right: calc(-50vw + 50%); // Safe-ish scrollbar width for IE11

    @supports (--css: variables) {
        margin-left: calc((-50vw + 50% + (var(--scrollbar-width) / 2)) + 8px);
        margin-right: calc((-50vw + 50% + (var(--scrollbar-width) / 2)) + 8px);
    }

    /* Restoring SiteFinity value */
    .sf-backend-wrp & {
        margin: 0 !important;
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &:after {
       content: none;
       width: 0;
    }
}

.video-container {
    position: relative;
    padding-bottom: 44.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.balance-text {
    text-wrap: balance;
}

.text-center {
    text-align: center;
}
