$font: "Gotham SSm A", "Gotham SSm B", Tahoma, Sans-Serif;
$halo-grey-background: #f6f6f6;

body {
  font-family: $font;
  &:focus {
    outline: thin dotted;
  }
}

.site-header {
  position: relative !important;
}

.site-wrapper,
.site-body {
  padding: 0;
  @media (min-width: 768px) {
    /*padding: 1em;*/
  }
}

.filters__item input:focus + label {
  outline: thin dotted;
}

.halo-handle {
  padding-left: 30px;
  padding-right: 30px;
  &__wrap {
    padding: 2em 0;
    background-color: $halo-grey-background;
    @media (min-width: 768px) {
      padding: 3em 0;
    }
  }
  &__img {
    max-width: 40%;
    max-height: 529px;
    margin: 0 21px;
    @media (min-width: 660px) {
      max-width: 90%;
    }
    &--small {
      max-height: 297px;
    }
  }
  &__image-wrap {
    @media (min-width: 660px) {
      height: 500px;
      width: 278px;
    }
    @media (min-width: 1024px) {
      height: 529px;
      width: 382px;
    }
    &--small {
      height: initial;
      width: initial;
    }
  }
  &__finishes-label,
  &__finish-name {
    font-family: $font;
    &:focus {
      outline: thin dotted;
    }
  }
  &__finish-name {
    font-size: 16px;
    font-weight: normal;
  }
  &__product-detail-link {
    font-size: 16px;
    display: block;
    color: #000;
    text-decoration-color: #000;
    text-decoration: underline;
    margin-top: 1.9em;
  }
  &__button {
    min-width: 15em;
    font-size: 16px;
  }
}

.halo {
  &__heading {
    text-align: center;
    font-family: $font;
  }
  &__header {
    max-width: 100%;
    margin: 3.5em auto 65px;
    @media (min-width: 1024px) {
      max-width: 75%;
    }
    @media (min-width: 1666px) {
      max-width: 55%;
    }
  }
  &__header-icon {
    transform: translateY(0.25em);
    &:before {
      font-size: 32px;
      margin-right: 0.2em;
    }
  }
  &__header-copy-wrap {
    display: block;
    justify-content: center;
    margin-bottom: 1em;
    font-weight: normal;
    @media (min-width: 768px) {
      display: flex;
      margin-bottom: 38px;
    }
  }
  &__header-copy {
    margin: 0;
    border: none;
    text-align: left;
    box-sizing: border-box;
    font-size: 16px;
    @media (min-width: 768px) {
      padding-left: 2em;
      border-left: 1px solid #ddd;
    }
  }
  &__header-icon {
    // font-size: 22px;
    // transform: translate(-8px, 2px);
  }
  &__header-logo {
    margin-bottom: 2em;
    min-width: 81px;
    width: 81px;
    height: 81px;
    @media (min-width: 768px) {
      margin-bottom: 3em;
      min-width: 117px;
      width: 117px;
      justify-self: end;
      align-self: center;
      margin-bottom: 0;
      padding-right: 2em;
    }
  }
  &__header-hero-logo {
    // max-width: 100%;
    min-width: 200px;
    @media (min-width: 768px) {
      width: 302px;
      max-width: 302px;
      min-width: 302px;
    }
  }
  &__header-subtitle {
    display: block;
    font-size: 18px;
    margin: 2em 0;
    @media (min-width: 768px) {
      font-size: 20px;
      margin: 31px 0 51px;
    }
    &--subsection {
      display: block;
      @media (min-width: 460px) {
        display: inline;
      }
    }
  }
  &__header-video-link {
    text-transform: uppercase;
    font-size: 18px;
  }
}

.halo {
  &__handles {
    margin-bottom: none;
    @media (min-width: 1024px) {
      margin-bottom: 108px;
    }
  }
}

.halo {
  &__wifi-wrap {
    text-align: center;
    position: relative;
    margin-bottom: 3em;
    padding-top: 0;
    @media (min-width: 768px) {
      // padding-top: 27%;
      padding-top: 461px;
    }
    @media (min-width: 1366px) {
      padding-top: 461px;
    }
  }
  &__wifi-background-image {
    max-width: 100%;
    margin: auto;
    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 1182px;
      // center absolute position image
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  &__wifi-copy {
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-flex-align: center;
    padding: 3em 2em 6em;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    background-color: #f6f6f6;
    // @media (min-width: 768px) {
    //   max-width: 75%;
    //   padding: 45px;
    // }
    // @media (min-width: 1024px) {
    @media (min-width: 575px) {
      padding: 3em 0.5em;
    }
    @media (min-width: 1024px) {
      max-width: 998px;
    }
  }

  &__wifi-copy-heading {
    margin-top: 0;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.65px;
    margin-bottom: 12px;
    @media (min-width: 768px) {
      font-size: 26px;
    }
  }

  &__wifi-copy-diagram {
    display: grid;
    display: -ms-grid;
    display: block;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    // @media (min-width: 1024px) {
    @media (min-width: 575px) {
      display: grid;
      display: -ms-flexbox;
      -ms-flex-flow: row nowrap;
      grid-template-columns: 4fr 1fr 1fr 1fr 4fr;
      grid-template-rows: 1fr;
      -ms-grid-columns: 4fr 1fr 1fr 1fr 4fr;
      -ms-grid-rows: 1fr;
      justify-items: center;
    }
  }

  &__wifi-copy-diagram-item {
    margin: 0;
    // @media (min-width: 1024px) {
    @media (min-width: 575px) {
      // margin: 0 1em;
      margin: 0;
      display: inline-block;
      display: block;
      width: 100%;
    }
  }

  &__wifi-copy-paragraph {
    margin: 0;
    line-height: 1.6;
    max-width: 100%;
    @media (min-width: 1024px) {
      margin: 0 0 10px;
    }
  }

  &__wifi-copy-subheading {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.8px;
    @media (min-width: 1024px) {
      margin: 20px 0 10px;
      display: block;
    }
  }

  &__wifi-copy-icon {
    display: block;
    margin: 0 auto;
    @media (min-width: 1024px) {
      margin: 0 1em;
      display: inline-block;
    }
    &--arrow {
      margin: 0 auto;
      transform: rotate(90deg) scale(0.3);
      // @media (min-width: 460px) {
      //   transform: rotate(90deg) scale(0.3);
      // }
      @media (min-width: 575px) {
        margin: 10px auto;
        transform: rotate(0deg) scale(1);
        max-width: 140px;
        max-height: 33px;
      }
      @media (min-width: 1600px) {
        max-width: 163px;
        max-height: 38px;
      }
    }
    &--device {
      margin: 0.5em auto;
      max-width: 43px;
      @media (min-width: 1024px) {
        margin: 0 1em;
      }
    }
    &--wifi {
      max-width: 43px;
      @media (min-width: 1024px) {
        margin: 0 20px;
        max-width: 73px;
        max-height: 48px;
      }
    }
  }
}

.halo {
  &__stacked-image-wrap {
    margin: 6em 0 6em;
    padding: 0 1em;
    @media (min-width: 768px) {
      margin: 6em 4em 9em;
    }
    @media (min-width: 1366px) {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1.3fr 1fr;
      grid-template-rows: min-content 1fr;
      -ms-grid-columns: 1.3fr 1fr;
      -ms-grid-rows: min-content 1fr;
      margin: 6em 5.5em 4.5em 9em;
    }
  }
  &__stacked-image-heading {
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: center;
    font-weight: 900;
    max-width: 10em;
    margin: 0 auto 2em;
    @media (min-width: 600px) {
      max-width: 100%;
    }
    @media (min-width: 1024px) {
      padding-left: 20px;
    }
    @media (min-width: 1366px) {
      text-align: left;
      margin-bottom: 1em;
      grid-column: 2;
      grid-row: 1;
      -ms-grid-column: 2;
      -ms-grid-row: 1;
      align-self: end;
      padding-top: 2em;
      padding-left: 2em;
    }
    @media (min-width: 1666px) {
      margin: 2em 0 1em;
      padding-top: 1em;
      max-width: 10em;
    }
  }
  &__stacked-image-copy {
    font-size: 16px;
    margin-bottom: 2.8em;
    @media (min-width: 1366px) {
      // max-width: 20em;
      max-width: 70%;
    }
  }
  &__stacked-image-copy-wrap {
    @media (min-width: 1024px) {
      padding-left: 20px;
    }
    @media (min-width: 1366px) {
      grid-column: 2;
      grid-row: 2;
      -ms-grid-column: 2;
      -ms-grid-row: 2;
      align-self: start;
      padding-left: 4.5em;
      // max-width: 55%;
    }
  }
  &__stacked-image-app-link {
    & > img {
      height: 40px;
      @media (min-width: 400px) {
        height: initial;
      }
    }
    display: inline-block;
    margin: 0 auto 0;
    & + & {
      @media (min-width: 410px) {
        margin-left: 0.8em;
      }
    }
    &:hover,
    &:focus {
      outline: thin dotted;
    }
  }
  &__stacked-image-gallery {
    position: relative;
    min-height: 0px;
    max-width: 600px;
    margin: 0 auto 3.5em;
    @media (min-width: 1366px) {
      max-width: 100%;
      grid-column: 1;
      grid-row: 1 / span 2;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
    }
  }
  &__stacked-image-gallery-item {
    padding-right: 1em;
  }
  &__stacked-image-gallery-item--first {
    left: 0;
    top: 0;
    @media (min-width: 1366px) {
      left: auto;
      right: 45%;
    }
  }
  &__stacked-image-gallery-item--second {
    top: 10%;
    right: 0;
    @media (min-width: 680px) {
      top: 8%;
      right: 9%;
    }
  }
  &__stacked-image-gallery-item--third {
    max-width: 50%;
    top: 50%;
    left: 5%;
    @media (min-width: 640px) {
      top: 60%;
    }
    @media (min-width: 1366px) {
      left: auto;
      right: 52%;
      top: 420px;
    }
  }
}

.halo {
  &__user-codes-wrap {
    max-width: 1100px;
    margin: 3em 1em 5em;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: min-content min-content 1fr;
    @media (min-width: 500px) {
      margin: 5em auto 5em;
    }
    @media (min-width: 768px) {
      margin: 5em auto 5em;
    }
    @media (min-width: 1024px) {
      margin: 7em auto 5em;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: 1fr;
    }
    @media (min-width: 1366px) {
      margin: 5em auto 5em;
    }
  }
  &__user-codes-heading {
    min-width: 7em;
    max-width: 7em;
    margin: 0 auto 1em;
    grid-column: 1;
    font-size: 26px;
    text-align: left;
    font-weight: 900;
    @media (min-width: 1024px) {
      font-size: 40px;
      text-align: left;
      margin: 0;
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
    &--centered {
      text-align: center;
      @media (min-width: 1024px) {
        text-align: left;
      }
    }
  }
  &__user-codes-copy-feature {
    padding: 0 1em;
    margin: 0.5em 0 0;
    list-style: none;
    grid-column: 1;
    grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    // columns: 1;
    @media (min-width: 575px) {
      display: flex;
      display: -ms-flex;
      flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      align-content: flex-start;
      -ms-flex-align: flex-start;
      height: 420px;
    }
    @media (min-width: 1024px) {
      height: inherit;
      // display: inherit;
      padding: 0 3.5em 0 0;
      grid-column: 1;
      grid-row: 2;
      -ms-grid-column: 1;
      -ms-grid-row: 2;
      columns: 1;
    }
  }
  &__user-codes-copy-feature-item {
    position: relative;
    padding: 0 0 0 2.5em;
    display: block;
    margin: 0 0 1em 0;
    box-sizing: border-box;
    & + & {
      @media (min-width: 1024px) {
        border-top: 1px solid #ddd;
      }
    }
    @media (min-width: 575px) {
      flex: 40%;
      -ms-flex: 40%;
      margin: 0 1em;
    }
    @media (min-width: 1024px) {
      padding: 0 2.5em 0 2.5em;
      flex: 100%;
      margin: 0;
    }
  }
  &__user-codes-icon {
    position: absolute;
    left: 0;
    top: 1.7em;
    width: 34px;
    height: 34px;
  }
  &__user-codes-copy {
    font-family: $font;
    font-size: 16px;
    letter-spacing: -0.36px;
    line-height: 1.33;
    margin-bottom: 2em;
  }

  &__user-codes-subheading {
    display: inline-block;
    margin-top: 2.5em;
    font-family: $font;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.8px;
    font-size: 16px;
  }

  &__user-codes-image {
    grid-column: 1;
    grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 80%;
    }
    @media (min-width: 1024px) {
      max-width: 100%;
      margin: 0;
      grid-column: 2;
      grid-row: 2;
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }
  }
}

.halo {
  &__image-text-block-wrap {
    padding: 3em 1em;
    background-color: #f6f6f6;
    @media (min-width: 768px) {
      padding: 3em 2em;
    }
    @media (min-width: 1024px) {
      padding: 6em 3em;
      // padding: 140px 0;
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content 1fr;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: min-content min-content 1fr;
      column-gap: 5%;
    }
  }
  &__image-text-block-heading {
    margin: 0 auto 0.5em;
    padding: 0;
    font-size: 26px;
    font-weight: 900;
    text-align: center;
    max-width: 100%;
    @media (min-width: 768px) {
      margin: 1em auto 2em;
    }
    @media (min-width: 1024px) {
      text-align: left;
      font-size: 40px;
      max-width: 100%;
      // margin: 0.5em 0 0.6em 2.7em;
      margin: 0.5em 0 0.6em 0;
      grid-column: 2;
      grid-row: 1;
      -ms-grid-column: 2;
      -ms-grid-row: 1;
    }
  }
  &__image-text-block-copy {
    margin: 1em 0;
    @media (min-width: 768px) {
      margin: 1em auto;
      max-width: 80%;
    }
    @media (min-width: 1024px) {
      max-width: 100%;
      // padding: 0 1.5em 0 6em;
      padding: 0 1.5em 0 0;
      margin: 1em 0 2em;
      grid-column: 2;
      grid-row: 2;
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }
    @media (min-width: 1666px) {
      // padding: 0 3.5em 0 6em;
      max-width: 75%;
    }
  }
  &__image-text-block-main-image-wrap {
    text-align: center;
    @media (min-width: 1024px) {
      text-align: right;
      grid-column: 1;
      grid-row: 1 / span 3;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
    }
    // create margin on IE11 only
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none),
      screen and (min-width: 1024px) {
      margin-right: 5%;
    }
  }
  &__image-text-block-main-image {
    max-width: 90%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      min-height: 1px;
      max-height: 500px;
    }
  }
  &__image-text-block-secondary-image-wrap {
    max-width: 424px;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 70%;
      margin: 2em auto;
    }
    @media (min-width: 1024px) {
      margin: 0;
      // max-width: 90%;
      grid-column: 2;
      grid-row: 3;
      -ms-grid-column: 2;
      -ms-grid-row: 3;
      // padding: 0 0 0 6em;
      padding: 0;
    }
    @media (min-width: 1666px) {
      max-width: 60%;
    }
  }
}

.halo {
  &__notifications-wrap {
    padding: 3em 0.5em;
    margin: 3em auto 2em;
    max-width: 95%;
    @media (min-width: 1024px) {
      padding: 6em 1em;
      max-width: 90%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content 1fr;
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: min-content 1fr;
    }
    @media (min-width: 1366px) {
      max-width: 70%;
    }
  }
  &__notifications-heading {
    max-width: 11em;
    font-size: 26px;
    font-weight: 900;
    text-align: center;
    margin: 0 auto 1em;
    @media (min-width: 1024px) {
      // padding: 2em 3em 0 0;
      padding: 2em 1em 0 0;
      text-align: left;
      font-size: 40px;
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      margin: 0 0 1em;
    }
    @media (min-width: 1366px) {
      max-width: 480px;
    }
  }
  &__notifications-copy {
    margin: 0;
    @media (min-width: 1024px) {
      // padding: 1em 4em 0 0;
      padding: 1em 1em 0 0;
      max-width: 450px;
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 2;
      -ms-grid-row: 2;
    }
  }
  &__notifications-image-wrap {
    text-align: center;
    margin: 0 0 1.5em 0;
    @media (min-width: 1024px) {
      padding: 0 0 0 1em;
      grid-column: 2;
      grid-row: 1 / span 2;
      -ms-grid-column: 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
    }
  }
}

.halo {
  &__manage-locks-wrap {
    padding: 3em 0;
    margin-bottom: 4em;
    @media (min-width: 768px) {
      padding: 6em 3em;
    }
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__manage-locks-copy {
    margin-left: 0;
    padding-left: 1em;
    @media (min-width: 1024px) {
      // margin-left: 1em;
      margin-left: none;
      max-width: 625px;
    }
  }
  &__manage-locks-heading {
    margin-left: 0.4em;
    @media (min-width: 1024px) {
      // margin-left: 1em;
      margin-left: none;
      margin-top: 2.5em;
      max-width: 440px;
    }
  }
  &__manage-locks-image-wrap {
    @media (min-width: 1024px) {
      // padding-right: 2em;
    }
  }
}

.halo {
    &__features-box-heading {
        font-size: 22px;
        font-family: $font;
        font-weight: 800;
        text-align: center;
        margin: 0.7em 0;

        @media (min-width: 768px) {
            font-size: 40px;
            margin: 1em 0;
        }

        @media (min-width: 1366px) {
            text-align: left;
        }
    }

    &__features-box-wrap {
        @media (min-width: 1366px) {
            max-width: 90%;
            margin: 0 auto;
        }

        @media (min-width: 1666px) {
            max-width: 65%;
        }

        &--side-by-side {
            // max-width: 1100px;
            margin-bottom: 3em;

            @media (min-width: 1366px) {
                margin-top: 2.5em;
                display: grid;
                display: -ms-grid;
                // grid-template-columns: 2fr 1fr;
                grid-template-columns: 1fr 1fr;
                -ms-grid-columns: 1fr 1fr;
            }
        }
    }

    &__features-box-content-wrap {
        margin: 0 1em;
        text-align: center;
        padding: 0 1.5em;
        border: 1px solid #d9d9d9;

        @media (min-width: 768px) {
            padding: 0 4em;
        }

        @media (min-width: 1366px) {
            padding: 1em 3em;
        }
    }

    &__features-box-logo {
        margin: 0.6em 0 1em;

        @media (min-width: 1366px) {
            margin: auto 0;
            flex-shrink: 0;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            vertical-align: middle;
            // align-self: center;
        }
    }

    &__features-box-image-wrap {
        @media (min-width: 1366px) {
            -ms-grid-column: 1;
            grid-column: 1;
        }

        &--right {
            @media (min-width: 1366px) {
                -ms-grid-column: 2;
                grid-column: 2;
                margin: auto;
            }
        }
    }

    &__features-box-image {
        &--no-mobile {
            display: none;
        }

        @media (min-width: 1366px) {
            display: block;
            -ms-grid-column: 1;
            grid-column: 1;
            max-width: 85%;
            margin: 0 0 0 auto;
        }
    }

    p + &__features-box-button {
        font-weight: 500;
        padding: 0.9em 0.8em;
        white-space: nowrap;
        letter-spacing: 0.7px;
        margin-top: 1.5em;
        margin: 1.5em 0;
        font-size: 14px;

        @media (min-width: 768px) {
            // font-size: 16px;
        }

        @media (min-width: 1366px) {
            max-height: 3em;
            max-width: 15em;
            // justify-self: end;
            margin: auto 0 auto auto;
            -ms-grid-column: 3;
        }
    }

    &__features-box-copy {
        font-size: 16px;
        margin: 0.6em 0;

        @media (min-width: 1366px) {
            -ms-grid-column: 2;
            text-align: left;
            padding: 0 1em;
            margin: auto 0;
        }

        &--center {
            // text-align: center;
            text-align: left;
            padding: 0;
        }
    }

    &__features-box-item-list {
        padding: 1em;
        list-style: none;
        text-align: center;

        @media (min-width: 500px) {
            padding: 1em 3em;
        }

        @media (min-width: 1366px) {
            -ms-grid-column: 1;
            grid-column: 1;
            padding: 0 2.5em 0 0;
        }
    }

    &__features-box-item {
        padding: 2em 0;

        @media (min-width: 1366px) {
            display: grid;
            grid-template-columns: 25% 1fr 25%;
            display: -ms-grid;
            -ms-grid-columns: 25% 1fr 25%;

            &-twocolumn {
                grid-template-columns: 25% 1fr;
                -ms-grid-columns: 25% 1fr;
            }
        }

        &--border {
            border-bottom: 1px solid #d9d9d9;
        }

        &--no-grid {
            display: block;
            padding: 0.5em 0;

            @media (min-width: 1366px) {
                padding: 1em 0;
            }
        }
    }

    &__features-box-item-heading {
        text-align: left;
        font-family: $font;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.21px;
        font-weight: 600;

        @media (min-width: 1366px) {
            display: block;
            margin-top: 0.1em;
        }
    }
}

.halo {
  &__welcome-home {
    max-width: 100%;
    margin: 0 auto 0 auto;

    @media (min-width: 1200px) {
      max-width: 85%;
      margin: 0 auto 6em auto;
    }
    padding: 0 0 2em 0;
    // background-color: #e9e9e7;
  }
  &__welcome-home-badge-wrap {
    display: block;
    @media (min-width: 600px) {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: 992px) {
      display: block;
    }
    & + & {
      margin-left: 1em;
    }
  }
  &__welcome-home-badge-image-wrap {
    align-self: center;
    & + & {
      margin-top: 1em;
      @media (min-width: 600px) {
        margin-top: 0;
      }
      @media (min-width: 992px) {
        margin-top: 2.5em;
      }
    }
  }
  &__welcome-home-badge {
    @media (min-width: 992px) {
      margin-right: auto;
      margin-left: auto;
    }
    &--alexa {
      max-width: 209px;
    }
    &--google {
      max-width: 225px;
    }
  }
  &__welcome-home-heading {
    // display: none;
    @media (min-width: 992px) {
      display: block;
    }
    &--mobile {
      font-size: 26px;
      text-align: center;
      display: block;
      margin: 0 0 2em 0;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  &__welcome-home-copy {
    font-weight: 300;
    text-align: left;
  }
  &__welcome-home-image-content-wrap {
    @media (max-width: 991px) {
      padding: 2em 1em;
    }
  }
  &__welcome-home-image-content {
    @media (min-width: 992px) {
      padding: 0 0 0 7em;
      max-width: 85%;
    }
  }
}

.halo {
  &__aura-panel {
    margin: 8em 0;
    padding: 0 1em;
    text-align: center;
  }
  &__aura-panel-heading {
    font-size: 26px;
    font-weight: 900;
    margin: 0 0 2em 0;
  }
  &__aura-panel-logo {
    display: block;
    margin: 2em auto 0 auto;
  }
  &__aura-panel-copy {
    // padding: 0 1em;
    margin: 2em auto 2em auto;
    @media (min-width: 768px) {
      max-width: 50%;
    }
    @media (min-width: 1366px) {
      max-width: 25%;
    }
  }
  &__aura-panel-button {
    font-size: 14px;
  }
}

.col-sm-6 {
  margin-bottom: 1em;
}

.site-footer__form {
  padding: 0 0 2em 0;
}

.halo {
  &__where-to-buy-heading {
    text-align: center;
    font-family: $font;
    font-size: 26px;
    margin: 0 0 2em 0;
    font-weight: 900;
    @media (min-width: 768px) {
      font-size: 40px;
    }
  }
  &__product-label {
    font-family: $font;
  }
}

.halo {
  &__footer-heading {
    display: block;
    margin: 0 0 0.3em;
  }
  &__footer-toolbox {
    display: block;
    margin-bottom: 1em;
  }
}

.halo {
  &__three-ways-header {
    color: #000;
  }
}

.premis-code-demo__content {
  padding: 2em 1em;
}

.halo-demo {
  @media (min-width: 992px) {
    padding: 0 0 10% 3em;
  }
  @media (min-width: 1260px) {
    padding: 8% 0 10% 3em;
  }
  &__options {
    border-color: #000 !important;
    &--selected {
      border-color: #000 !important;
      background-color: #000;
    }
  }
  &__options-arrows {
    &--selected {
      border-top-color: #000;
    }
  }
  &__background-image {
    // display: none;
    @media (min-width: 992px) {
      max-width: 90%;
      left: 5%;
      top: 0;
      z-index: 0;
      position: absolute;
    }
    @media (min-width: 1260px) {
      max-width: 1260px;
    }
  }
  &__heading {
    font-size: 26px;
    margin-bottom: 1em;
    @media (min-width: 768px) {
      font-size: 40px;
    }
    font-weight: 800;
  }
  &__heading-code {
    color: #fff;
  }
  &__copy {
    margin-bottom: 0;
    & + & {
      margin-top: 1em;
    }
  }
  &__copy-code {
    color: #fff;
    max-width: 100%;
    @media (min-width: 992px) {
      max-width: 70%;
    }
    @media (min-width: 1118px) {
      max-width: 100%;
    }
  }
  &__copy-logo {
    margin: 0;
  }
  &__copy-wrap {
    background-color: #2d2e32;
    color: #fff;
    padding: 2em;
    @media (min-width: 992px) {
      padding: 5% 0 0 3em;
      color: #000;
      background-color: transparent;
      text-align: left;
      position: relative;
      z-index: 1;
      max-width: 40%;
    }
    @media (min-width: 1260px) {
      padding: 2em 0 2em 6em;
    }
  }
  // &__copy-wrap-code {
  //   padding-right: 20%;
  //   @media (min-width: 1118px) {
  //   }
  // }
}

.demo {
  &__options {
    color: #000;
    background-color: transparent;
    border: 2px solid #000;
    &:hover,
    &:focus {
      background-color: #000;
      color: #fff;
    }
    &--selected {
      color: #fff;
      background-color: #000 !important;
      border-top-color: #000 !important;
      &:after {
        border-top-color: #000 !important;
      }
    }
  }
  &__options-container {
    border-top-color: #000 !important;
    width: 33%;
    @media (max-width: 992px) {
      width: 31.5% !important;
    }
  }
  &__options-arrows {
    border-top-color: #000 !important;
    &--selected {
      border-top-color: #000 !important;
    }
  }
}

a .demo__options .text--link-white .demo__options--selected {
  border-color: #000 !important;
}

// #premisDemoComp {
//   padding: 4em 0 65%;
// }

.heading--has-micro-divider:after {
  display: none;
}

#premisCode {
  background-size: contain;
}

.premis-code {
  &__keypad {
    top: 21%;
    width: 100%;
    max-width: 38%;
  }
  &__securescreen {
    // display: none;
    opacity: 0.99;
    z-index: 1;
    position: absolute;
    width: 100%;
    max-width: 40%;
    height: 37%;
    top: 15%;
  }
}

#demo-siri {
  margin-bottom: 15em;
  @media (min-width: 992px) {
    margin-bottom: 10%;
  }
}

.ss-tooltips {
  @media (min-width: 1250px) {
    left: calc(50% + 297px);
  }
}

.ss-tooltip {
  @media (min-width: 1250px) {
    border-radius: 0 0.25em 0.25em 0;
  }
  &:after {
    display: none;
    @media (min-width: 1250px) {
      display: block;
      position: absolute;
      z-index: 100;
      left: 0;
      border-right: 40px solid #e82337;
      border-bottom: 64px solid transparent;
      border-top: 64px solid transparent;
      // top: 13%;
      // left: -43px !important;
      // border: 47px solid #e82337;
      // transform: rotate(45deg);
      // border-radius: 10px;
    }
    @media (min-width: 1400px) {
      border-bottom: 40px solid #e82337;
      border-bottom: 70px solid transparent;
      border-top: 70px solid transparent;
    }
  }
}

// #demo-siri,
// #demo-app {
//   max-height: 613px;
//   max-width: 1212px;
// }

// #demo-siri,
// #demo-app {
//   margin-bottom: 25%;
// }

#demo-siri {
  // margin-bottom: 15em;
  // @media (min-width: 992px) {
  //   margin-bottom: 10%;
  // }
  margin-bottom: 0;
}

.premis-demo {
  &__header {
    font-weight: 900;
    font-size: 26px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
    // padding-bottom: 4em;
  }
  &__wrapper {
    @media (max-width: 480px) {
      margin-bottom: 3em;
    }
  }
}

.premis-code-bg {
  &__door {
    background: transparent
      url("//images.kwikset.com/is/image/Kwikset/halo-door-for-securescreen?scl=1")
      left center no-repeat;
    max-height: 618px;
    width: auto;
  }
}

#premisCode {
  background: transparent
    url(//images.kwikset.com/is/image/Kwikset/halo-for-securescreen?scl=1&fmt=png-alpha)
    center / contain no-repeat;
}

.halo__demo-guide {
  // position: relative;
  // left: 0;
  // top: 0;
  // z-index: 10;
  &:after {
    @media (min-width: 992px) {
      // position: absolute;
      left: -38px;
      border-right: 40px solid #e82337;
      border-bottom: 64px solid transparent;
      border-top: 64px solid transparent;
      // top: 13%;
      // left: -43px !important;
      // border: 47px solid #e82337;
      // transform: rotate(45deg);
      // border-radius: 10px;
    }
  }
}

.container--condensed {
  margin-left: 10%;
  margin-right: 5%;
  @media (min-width: 480px) {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.cta-box {
  font-size: 2em;
  @media (min-width: 1366px) {
    font-size: 3em;
  }
}

.container--max {
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
}
.text--centered {
    text-align: center;
}

.section-padding-t-xl {
    padding-top: 4em;
}
.premis-demo {
    &__pdp{
    padding: 4em 0 20em;
    }
}

.margin-b-xs {
    margin-bottom: .5em;
}
.flush-top {
    margin-top: -1em;

    @media (min-width: 860px) {
        margin-top: -2em;
    }
}
.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after {
    clear: both;
}
