﻿// colors
$white: #fff;
$offwhite: #f6f6f6;
$grey: #e6e6e6;
$lightgrey:#d2d2d2;
$anothergrey: #b1b1b1;
$midgrey: #626262;
$darkergrey: #474747;
$subtlegrey: #3a3a3a;
$darkgrey: #181818;
$black: #000;
$brightred: #f00016;
$red: #d90114;
$darkred: #d01b32;
$green: #1ca563;
// fonts
$xlight: 200;
$light: 300;
$book: 400;
$medium: 500;
$bold: 700;
$xbold: 800;

// Breakpoints
$smallest: 320px;
$smartphones: 479px;
$tablet: 768px;
$desktop: 992px;
$largest: 1199px;

// sizing
$sizes: (
    none: 0,
    xs: 0.5em,
    sm: 1em,
    md: 1.5em,
    lg: 2em,
    xl: 3em,
    xxl: 4em,
    xxxl: 5em
);

$spacing-xxs: 0.25em;
$spacing-xs: 0.5em;
$spacing-sm: 1em;
$spacing-md: 1.5em;
$spacing-lg: 2em;
$spacing-xl: 4em;
$spacing-xxl: 6em;


// z-indices
$base: 0;
$overBase: 1;
$overContent: 20;
$navigation: 100;
$header: 101;
$modal: 200;

//screensizes
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-xsl:                  480px !default;
$screen-xsl-min:              $screen-xs !default;
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 0.1) !default;
$screen-xsl-max:             ($screen-sm-min - 0.1) !default;
$screen-sm-max:              ($screen-md-min - 0.1) !default;
$screen-md-max:              ($screen-lg-min - 0.1) !default;

// Media queries
$xs-only:               "(max-width: #{$screen-xsl-max})";
$xsl-up:                "(min-width: #{$screen-xsl-min})";
$xsl-to-sm:             "(min-width: #{$screen-xsl-min}) and (max-width: #{$screen-sm-max})";
$xsl-only:              "(min-width: #{$screen-xsl-min}) and (max-width: #{$screen-xsl-max})";
$sm-up:                 "(min-width: #{$screen-sm-min})";
$sm-down:               "(max-width: #{$screen-sm-max})";
$sm-only:               "(min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";
$md-up:                 "(min-width: #{$screen-md-min})";
$md-only:               "(min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})";
$md-down:               "(max-width: #{$screen-md-max})";
$lg-up:                 "(min-width: #{$screen-lg-min})";
$sm-to-md:              "(min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-max})";


$alert-colors: (
	info:       $darkgrey,
	success:    #92cb9e,
	warning:    #efdda6,
	danger:     #e24747
);

//================================================================================
// Transitions
//
$transition-faster: 0.05s;
$transition-fast:   0.15s;
$transition-med:    0.35s;
$transition-slow:   0.75s;

//BROUGHT OVER FROM WEISER
//=================================================================================
// Z-Index Handlng
//
$zindex-lower:              50;
$zindex-low:               100;
$zindex-medium:            300;
$zindex-high:              500;
$zindex-higher:            900;
$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-dropdown-high:     1020;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-modal-background:  1040;
$zindex-modal:             1050;
$zindex-navbar-reversed:   1080;


$line-height-base: 1.5 !default;
$grid-gutter-width:           1.5rem !default;

$sp-none: 		0;
$sp-micro: 		3px;
$sp-tiny: 		6px;
$sp-small: 		15px;
$sp-medium: 	24px;
$sp-large: 		36px;
$sp-huge: 		60px;
$sp-giant: 		80px;
$sp-massive: 	100px;

$container-padding-x: 30px;
$variable-prefix: bs- !default;


$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
) !default;


$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

