﻿
html, body, h1, h2, h3, h4, h5, h6, p, a, form, select, option, label {
    font-family: 'Gotham SSm A', 'Gotham SSm B', Tahoma, Sans-Serif;
    letter-spacing: -0.04em;
}
body { font-weight: 300; }

a { 
    color: currentColor; 
    &.link-red:hover {
        color: $red;
    }
    &.link-nostyle { text-decoration: none; }
}

.site-body {
    font-size: 1rem;
    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
}

p { line-height: 1.6; }

.heading {
    font-weight: $xbold;
    letter-spacing: -0.05em;

    &--main {
        font-size: 2.5rem; // On all breakpoint will have 40px
    }

    &--main-nav {
        font-size: 2.5em;
    }

    &--sub {
        font-size: 1.625rem; // On all breakpoint will have 26px
    }

    &--massive {
        font-size: 3rem; // "giant"
        @media (min-width: 768px) {
            font-size: 7rem;
        }
    }

    &--giant {
        font-size: 2.5rem; // "x-large
        @media (min-width: 768px) {
            font-size: 2.5rem;
        }

        @media (min-width: 992px) {
            font-size: 3.75rem;
        }

        @media (min-width: 1200px) {
            font-size: 4.5rem;
        }
    }

    &--rotator {
        font-size: 2rem; // "x-large
        @media (min-width: 768px) {
            font-size: 2.5rem;
        }

        @media (min-width: 992px) {
            font-size: 3.75rem;
        }

        @media (min-width: 1200px) {
            font-size: 4.5rem;
        }
    }

    &--primary { // "large"
        font-size: 2.5rem;

        @media (min-width: 768px) {
            font-size: 3.75rem;
        }
    }

    &--secondary { // "medium"
        font-size: 1.625rem;

        @media (min-width: 768px) {
            font-size: 2.5rem;
        }
    }

    &--tertiary { // "small"
        font-size: 1.25rem;

        @media (min-width: 768px) {
            font-size: 1.75rem;
        }
    }

    &--number{
        font-size: 24px;
        font-weight: bold;
        &::after{
            content: "";
            display: block;
            width: 40px;
            height: 1px;
            background-color: black;
            margin-left: auto;
            margin-right: auto;
            margin-top: .5rem;
            margin-bottom: .75rem;
        }
    }

    &--kicker {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: $bold;
    }

    &--kickernormal {
        font-size: 1rem;        
        font-weight: $book;
    }
    // "tiny"
    &--basic {
        font-size: 1.1rem;
        font-weight: $book;

        @media (min-width: 560px) {
            font-size: 1.3rem;
        }

        @media (min-width: 768px) {
            font-size: 1.9rem;
        }
    }
    // "regular"
    &--regular {
        font-weight: $book;
        font-size: 1.625rem;
        line-height: 1.69;
        letter-spacing: -1.3px;

        &-reduced {
            line-height: 1.2;
        }

        @media (min-width: 768px) {
            &-reduced {
                line-height: 1.47;
            }

            font-size: 1.875rem;
            line-height: 1.47;
            letter-spacing: -1.5px;
        }
    }

    &--paragraph {
        font-size: 1rem;
        font-weight: $book;
    }
    // add-ons
    &--has-micro-divider {
        &:after {
            content: '';
            height: 1px;
            width: 65px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0.5em;
            background: currentColor;
            opacity: 0.5;
        }
    }

    &--product{
        @media (max-width: 767px){
            font-size: 1.75rem;
        }
    }

    &__input {
        font-weight: 300;
        //-webkit-appearance: none;
        //-moz-appearance: none;
        max-width: 95%;
        //appearance: none;
        //background: url("/dist/images/kwikset/angle-down.svg") 98% / 9% no-repeat #FFF;
        &--temp {
            display: none;
        }
        &  i{
          color: $red;
        }
    }

    &__input::-ms-expand {
        //display: none;
    }

    &__input-wrapper {
        display: inline-block;
        position: relative;
    }
    &__input-wrapper:after {
        content: "";
        background: black;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 75%;
    }
    &--light{
      font-weight: 300;
    }
}

.subheading {
    line-height: 1.67;
    letter-spacing: -0.4px;

    &--narrow {
        max-width: 805px;
        margin-left: auto;
        margin-right: auto;
    }

    &--narrow-padding {
        max-width: 870px;
        padding: 0 2rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.text {
    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--book {
        font-weight: $book;
    }

    &--black {
        font-weight: $xbold;
    }

    &--italic {
        font-style: italic;
    }

    &--80 {
        font-size: 0.8em;
    }

    &--90 {
        font-size: 0.9em;
    }
}

.text-link { display: block; color: currentColor;
    &--underline { border-bottom: 1px solid $grey; line-height: 1.3; }
}
