.cta-box--bg-faq-917 {
  background-image: url(https://images.kwikset.com/is/image/Kwikset/kwikset-smartcode-917-faqs?scl=1);
}
.cta-box--bg-support-917 {
  background-image: url(https://images.kwikset.com/is/image/Kwikset/kwikset-smartcode-917-support?scl=1);
}

.branding__logo--smartcode917 {
  background: transparent
    url(https://images.kwikset.com/is/image/Kwikset/kwikset-smartcode-917-residential-lever-logo?scl=1&fmt=png-alpha)
    center center no-repeat;
  width: 120px;
  background-size: contain;
  margin: 0 auto;
  height: 0;
  padding-bottom: 31.333333%;
}

.sc917-hero__logo {
  margin-bottom: 45px;
}

.entry-mode--sc917 {
  // > div:first-of-type {
  //   position: relative;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }

  &.entry-mode--autolock {
    background-image: url("https://images.kwikset.com/is/image/Kwikset/kwikset-smartcode-917-passage?scl=1&fmt=png-alpha");
  }
  &.entry-mode--passage {
    background-image: url("https://images.kwikset.com/is/image/Kwikset/kwikset%2Dsmartcode%2D917%2Dauto%2Dlock?scl=1&fmt=png-alpha");
  }
  &.entry-mode--disable-passage {
    background-image: url("https://images.kwikset.com/is/image/Kwikset/kwikset-smartcode-917-disable-passage?scl=1&fmt=png-alpha");
  }
}

img.finishes__image {
  pointer-events: none;
}

.sc917-handles {
  display: flex;
  justify-content: center;

  @media (max-width: 660px) {
    flex-wrap: wrap;
  }
}
.sc917-handle {
  flex: 0 1 auto;
  padding: 0 35px;
  margin-bottom: 40px;
  text-align: center;

  &__finishes-label {
    display: inline-block;
  }

  &__finishes-image {
    pointer-events: none;
  }
}
.sc917-handle--narrow {
  flex-basis: 340px;

  .sc917-handle__img {
    max-height: 312px;
    margin-right: 35px;
    margin-left: -35px;
  }
}
.sc917-handle__img {
  margin-right: 55px;
  margin-left: -55px;

  max-height: 535px;

  @media (max-width: 992px) {
    margin-right: 45px;
    margin-left: -45px;
  }
  @media (max-width: 575px) {
    max-width: 90%;
    margin-right: 10px;
    margin-left: -10px;
  }
}
.sc917-handle__product-label {
  margin: 15px auto 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.21px;
  line-height: 1.88;
}
.sc917-handle__finish {
  margin: 0;
  justify-content: center;
}
.sc917-handle__finish-name {
  margin-bottom: 30px;
  text-align: center;
}

.sc917-link {
  display: block;
  margin-top: 28px;
  text-decoration: underline;
}

.sc917-product-list {
  display: flex;
  margin: 35px 0 140px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 0 25px;
  }
  @media (max-width: 575px) {
    padding: 0;
  }

  &__img {
    display: flex;
    flex: 1 1 50%;
    flex-wrap: wrap;
    order: -1;

    @media (min-width: 992px) {
      order: initial;
    }

    &-item {
      position: relative;
      flex: 1 1;
      margin: 0 4px;
      overflow: hidden;

      &:after {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }
      &:first-of-type {
        flex: 2 1 100%;
        margin-bottom: 8px;

        &:after {
          padding-bottom: 76%;
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 100%;
        max-width: auto;
        transform: translate(-50%, -50%);
      }
      @media (max-width: 991px) {
        &:first-of-type:after {
          padding-bottom: 48%;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      @media (max-width: 575px) {
        &:after {
          width: 100%;
          padding-bottom: 104%;
        }
        &:first-of-type:after {
          width: 100%;
          padding-bottom: 78%;
        }
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
  &__list {
    flex: 1 1 50%;
    margin-left: 4px;
    padding: 0 50px;
    @media (min-width: 1400px) {
      padding: 0 120px 0 105px;
    }

    &-item {
      padding: 20px 0;
      @media (min-width: 1300px) {
        padding: 40px 0 35px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #cdcdcd;
      }
    }
    @media (max-width: 991px) {
      margin-top: 8px;
      padding: 35px 75px;

      &-item {
        padding: 25px 0 35px;
      }
    }
    @media (max-width: 575px) {
      padding: 0 25px;

      &-item {
        padding: 25px 0 22px;

        > p {
          font-size: 16px;
        }
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
